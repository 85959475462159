import { configureStore, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit'

import userReducer from '../features/users/userSlice'

import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import { combineReducers } from 'redux'
import groupsSlice from '../features/groups/groupsSlice';
import groupSelectedSlice from '../features/groups/groupSelectedSlice';
import oneSignalSlice from '../features/oneSignal/oneSignalSlice';
import resourceSlice from '../features/resource/resourceSlice';
import itemsBiblioSlice from '../features/itemsBiblio/itemsBiblioSlice';
import activitiesSlice from '../features/activities/activitiesSlice';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  user: userReducer,
  groups: groupsSlice,
  groupSelected: groupSelectedSlice,
  oneSignal: oneSignalSlice,
  resource: resourceSlice,
  itemsBiblio: itemsBiblioSlice,
  activitiesSlice: activitiesSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];
const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredActions
})

export const store = configureStore({
  reducer: {
    reducer: persistedReducer,
  },
  middleware: [serializableMiddleware]
})

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
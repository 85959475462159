import { IonGrid, IonRow, IonCol, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter, IonBackdrop } from '@ionic/react';

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';


/* CSS */
import '../theme/global.css';

/* ICONOS */
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import MenuLateralBiblio from '../components/Biblioteca/MenuLateralBiblio';
import BiblActividad from '../components/Biblioteca/BiblActividad';
import BiblObjetivo from '../components/Biblioteca/BiblObjetivo';
import BiblRecurso from '../components/Biblioteca/BiblRecurso';
import BiblNuevaActividad from '../components/Biblioteca/BiblNuevaActividad';
import BiblNuevoRecurso from '../components/Biblioteca/BiblNuevoRecurso';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';
import ActividadesObjetivos from '../components/Actividades/ActividadesObjetivos';
import BiblActividadesObjetivos from '../components/Biblioteca/BiblActividadesObjetivos';
import BiblRecursosComunitecaa from '../components/Biblioteca/BiblRecursosComunitecaa';
//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'

interface Props {
  page?: string,
}
const Biblioteca: React.FC<Props> = ({ page }) => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);
  
  const [actualPage, setActualPage] = useState("biblActividad");
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [lastAction, setLastAction] = useState("");


  if (window.location.pathname == '/biblioteca') {
    var tabsBiblio = Array.from(document.getElementsByClassName('tab-biblio') as HTMLCollectionOf<HTMLElement>);
    var tabsApp = Array.from(document.getElementsByClassName('tab-app') as HTMLCollectionOf<HTMLElement>);

    var i;
    for (i = 0; i < tabsBiblio.length; i++) {
      tabsBiblio[i].setAttribute('style', 'display: block !important');
    }
    for (i = 0; i < tabsApp.length; i++) {
      tabsApp[i].setAttribute('style', 'display: none !important');
    }
  }

  

  
  const [selectedId, setSelectedId] = useState<number>();



  function mostrarContenido() {
    switch (actualPage) {
      case "biblObjetivo":
        return <BiblObjetivo setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} setSelectedId={setSelectedId} />
      case "biblActividad":
        return <BiblActividad setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} setSelectedId={setSelectedId} />
      case "biblRecurso":
        return <BiblRecurso setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} setSelectedId={setSelectedId} />

      case "biblNuevaActividad":
        return <BiblNuevaActividad setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} />
      case "biblModifActividad":
        return <BiblNuevaActividad setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} modificar={true} selectedId={selectedId} />
      case "biblActivComunitecaa":
        return <BiblActividadesObjetivos setShowAlert={setShowAlert} setTextAlert={setTextAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />

      case "biblNuevoRecurso":
        return <BiblNuevoRecurso setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} />
      case "biblModifRecurso":
        return <BiblNuevoRecurso setShowAlertMvl={setShowAlert} setTextAlertMvl={setTextAlert} setActualPage={setActualPage} modificar={true} selectedId={selectedId} />
      case "biblRecursoComunitecaa":
        return <BiblRecursosComunitecaa setShowAlert={setShowAlert} setTextAlert={setTextAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
  
      default:
        break;
    }
  }

  const userData = {
    'token': Cookies.get('token')
  }

  useEffect(() => {
    // setUserAvatar(window.localStorage.getItem('userPhoto')!);


  }, [])

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  const refresh = () => {
    window.location.href = window.location.origin;
  }


  return (
      <>
        {Cookies.get('token')
            ?
            <IonPage>
              <HeaderEmpty avatarProp={userAvatar!} />

              {window.screen.width > 500
                  ? <> {showAlert
                      ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
                      : null} </>
                  : null}
              <IonContent fullscreen>

                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg="3" sizeMd="4" className="left desktop">
                      <MenuLateralBiblio setActualPage={setActualPage} actualPage={actualPage} />
                    </IonCol>
                    <IonCol className="right">
                      {mostrarContenido()}
                    </IonCol>
                  </IonRow>
                </IonGrid>

              </IonContent>
              {/* <MvlTabsElement setActualPage={setActualPage}/> */}
            </IonPage>
            :<>{refresh()}</>}
      </>
  );
};

export default Biblioteca;



import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { User } from "../../interfaces/intUser";
import { Activities } from '../../interfaces/intActivity';

// Define a type for the slice state
interface ActivitiesSlice {
  objectiveActivities: Activities[],
  noObjectiveActivities: Activities | undefined,
}

const initialState: ActivitiesSlice = {
  objectiveActivities: [],
  noObjectiveActivities: undefined
}

// const initialState: User;

export const activitiesSlice = createSlice({
  name: 'activities',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setObjectiveActivities: (state, action: PayloadAction<Activities[]>) => {
      state.objectiveActivities = action.payload;
    }, 
    setNoObjectiveActivities: (state, action: PayloadAction<Activities>) => {
      state.noObjectiveActivities = action.payload;
    },


  },
});


export const { setObjectiveActivities, setNoObjectiveActivities } = activitiesSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default activitiesSlice.reducer
import { IonRow, IonCol, IonItem, IonInput, IonIcon, IonButton, IonImg } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import $ from 'jquery';

import remove_img from '../../img/iconos/remove_img.svg';
// ../../img/iconos/remove_img.svg';

import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {setPreview} from '../../features/resource/resourceSlice'
import { GlobalVariables } from "../../hooks/GlobalVariables";

interface ContainerProps {
  setFile: any, 
  myFile: File, 
  removeMedia: any,
  selectedFileName? :string,
  selectedType: string,
  setSelectedType: any,
  setLoadingFoto?: any,
  setLoadingVideo?: any
}

const DetalleTipoActividadComp: React.FC<ContainerProps> = ({ setFile, myFile,  removeMedia, selectedFileName, selectedType, setSelectedType, setLoadingVideo, setLoadingFoto }) => {
  const dispatch = useAppDispatch();
  const preview = useAppSelector(state => state.reducer.resource.preview);

  // const [selectedType, setSelectedType] = useState('');


  const fileInput = useRef(null);
  const videoInput = useRef(null);


  useEffect(() => {
    if (!myFile) {
        dispatch(setPreview(undefined));
        return
    }

    if (!selectedFileName) {
      const objectUrl = URL.createObjectURL(myFile);
      dispatch(setPreview(objectUrl));

      if(setLoadingFoto){
        setLoadingFoto(false);
        setLoadingVideo(false);
      }
      

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    } 
    
  }, [myFile])

  
  const onSelectFileImg = (e: any) => {
    if(setLoadingFoto){
      setLoadingFoto(true);
    }
    setSelectedType('imagen');
    if (!e.target.files || e.target.files.length === 0) {
        setFile(undefined);
        return
    }
    setFile(e.target.files[0]);
    $('.aviso-detalle-grande').addClass('hidden');
  }

  const onSelectFileVideo = (e: any) => {
    if(setLoadingVideo){
      setLoadingVideo(true);
    }
    setSelectedType('video');
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined);
      return
    }
    if (e.target.files[0].size > 100000000){
      $('.aviso-detalle-grande').removeClass('hidden');
    } else {
      setFile(e.target.files[0]);
      $('.aviso-detalle-grande').addClass('hidden');
    }
  }

  const {urlMedia} = GlobalVariables();


  const detalle = () => {
    switch (selectedType) {
      case 'video':
        return (
          <>
            {myFile &&
              <div className="col-12 d-flex justify-content-center" style={{'position':'relative'}}>
                <video id="video" controls className="preview-image mt-3">
                  <source src={preview} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <div className="edit-remove-container">
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div> 
            }

            {selectedFileName &&
              <div className="col-12 d-flex justify-content-center" style={{'position':'relative'}}>
                <video id="video" controls className="preview-image mt-3">
                  <source src={`${urlMedia}${selectedFileName}`} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <div className="edit-remove-container">
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div> 
            }
          </>
        )
        
      case 'imagen':
        return (
          <>
            {myFile &&
              <> 
                <IonImg src={preview} className="preview-image"/>
                <div className="edit-remove-container">
                  <IonIcon onClick={e => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </> 
            }
            {selectedFileName &&
              <> 
                <IonImg src={`${urlMedia}${selectedFileName}`} className="preview-image"/>
                <div className="edit-remove-container">
                  <IonIcon onClick={e => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </> 
            }
          </>
          )
    
      default:
        break;
    }
  }
  
  return (
    <>
      <input id="imageInput" ref={fileInput} hidden type="file" accept="image/*" onChange={onSelectFileImg} />
      <input id="videoInput" ref={videoInput} hidden type="file" accept="video/mp4" onChange={onSelectFileVideo}/>

      <IonRow>
        <IonCol offset="2" size="8" className="d-flex justify-content-center">
          {detalle()}
        </IonCol>
      </IonRow>


      {(!myFile && !selectedFileName) &&
        <>
          <IonRow>
            <IonCol className=' d-flex'>
              <button className="imagenBtn btn-actividades d-flex btn-new justify-content-end" onClick={() => {
                // @ts-ignore
                fileInput?.current?.click();
              }}><p className="">SUBIR IMAGEN</p>
              </button>

              <button className="imagenBtn btn-actividades btn-new" onClick={() => {
                // @ts-ignore
                videoInput?.current?.click();
              }}><p className="">SUBIR VIDEO</p>
              </button>
            </IonCol>
          </IonRow>
        </>
      }
      <p className="aviso-naranja aviso-detalle-grande small hidden">El archivo seleccionado es demasiado grande. Seleciona uno de máximo 100MB</p>

    </>
  );

};
export default DetalleTipoActividadComp;
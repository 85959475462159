import { IonGrid, IonRow, IonCol, IonIcon, IonButton, IonCheckbox, IonInput, IonItem, IonLabel, IonRadio, IonRadioGroup, IonTextarea, IonLoading } from '@ionic/react';
import { useHistory } from 'react-router-dom';

/* COMPONENTES */
/* CSS */
import '../../theme/global.css';

/* DATOS */
import Cookies from 'js-cookie';

/* ICONOS */
import imgColor from '../../img/iconos/img_color.svg';
import vidColor from '../../img/iconos/vid_color.svg';
import linkColor from '../../img/iconos/link_color.svg';
import pdfColor from '../../img/iconos/pdf_color.svg';
import audioColor from '../../img/iconos/audio_color.svg';
import imgBW from '../../img/iconos/img_bw.svg';
import vidBW from '../../img/iconos/vid_bw.svg';
import linkBW from '../../img/iconos/link_bw.svg';
import pdfBW from '../../img/iconos/pdf_bw.svg';
import audioBW from '../../img/iconos/audio_bw.svg';
import { useEffect, useRef, useState } from 'react';
import TituloRecurso from '../TituloRecurso';
import { getData, getRecursos } from '../../api/apiRecursos';
import { Resource, Recursos } from '../../interfaces/intRecurso';
import star from '../../img/iconos/star.svg';
import $ from 'jquery';

import edit_img from '../../img/iconos/edit_img.svg';
import remove_img from '../../img/iconos/remove_img.svg';

import lapiz from '../../img/iconos/lapiz_gris.svg';
import { Media } from '../../interfaces/intPosts';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';

//REDUX
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import DetalleTipoRecursoComp from '../helpers/DetalleTipoRecursoComp';
import {setSelectedFileImg, setSelectedFileVideo, setSelectedFilePdf, 
  setSelectedFileAudio, setSelectedFileName} from '../../features/resource/resourceSlice'


interface ContainerProps {
  setActualPage: any,
  selectedId: number,
  setLastAction: any,
  lastAction: string,
  setShowAlert: any,
  setTextAlert: any,
}

const RecursoModificar: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setActualPage, selectedId, setLastAction }) => {
  //REDUX 
  const resources = useAppSelector(state=> state.reducer.groupSelected.resources);
  const fileName = useAppSelector(state => state.reducer.resource.selectedFileName)
  const dispatch = useAppDispatch();

  const selectedFileName = useAppSelector(state => state.reducer.resource.selectedFileName);


  const [isMediaSelected, setIsMediaSelected] = useState(false);

  const [selectedType, setSelectedType] = useState("imagen");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [estado, setEstado] = useState(0);
  const [destacado, setDestacado] = useState(false);
  const [valor, setValor] = useState("");
  const [myFile, setFile] = useState<File>();
  // const [filename, setFilename] = useState<any>();

  const [recurso, setRecurso] = useState<Resource>();
  const {publicarRecurso, crearRecurso, unsetAllMedia} = GlobalFunctions();

  const axios = require('axios');


  const [loadingFoto, setLoadingFoto] = useState(false);
  const [loadingRecurso, setLoadingRecurso] = useState(false);




  useEffect(() => {
    setRecurso(resources.find(res => res.id == selectedId));
    setSelectedType(resources.find(res => res.id == selectedId)?.type!);
    setNombre(resources.find(res => res.id == selectedId)?.name!);
    setDescripcion(resources.find(res => res.id == selectedId)?.description!);
    dispatch(setSelectedFileName(resources.find(res => res.id == selectedId)?.media_name!))
    setValor(resources.find(res => res.id == selectedId)?.value!);
    if (resources.find(res => res.id == selectedId)?.important! == 1) {
      setDestacado(true);
    }

    setEstado(resources.find(res => res.id == selectedId)?.status!);
  }, [])




  const updateAndPost = () => {
    const formData = createFormData();

    if ((selectedType == 'imagen' && (!myFile && !fileName)) 
    || (selectedType == 'video' && ((!myFile && valor == "") && !fileName))
    || (selectedType == 'pdf' && (!myFile && !fileName))
    || (selectedType == 'audio' && (!myFile && !fileName))
    ||  (selectedType == "link" && valor == "")) {
      $('.aviso-naranja.aviso-detalle').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-detalle').addClass('hidden');
    }
    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }
    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }

    if (nombre != "" && descripcion != "" 
    && (
      (selectedType == 'imagen' && (myFile || fileName)) 
      || (selectedType == 'video' && ((myFile || valor != "") || fileName))
      || (selectedType == 'pdf' && (myFile || fileName))
      || (selectedType == 'audio' && (myFile || fileName))
      ||  (selectedType == "link" && valor != "")
    )) 
    {
      setLoadingRecurso(true);
      publicarRecurso('modificar', formData, setLastAction, setActualPage, setTextAlert, setShowAlert, selectedId, setLoadingRecurso);
    } 

  }

  const updateResource = () => {
    // const formData = createFormData(true);
    const formData = createFormData();

    if ((selectedType == 'imagen' && (!myFile && !fileName)) 
    || (selectedType == 'video' && ((!myFile && valor == "") && !fileName))
    || (selectedType == 'pdf' && (!myFile && !fileName))
    || (selectedType == 'audio' && (!myFile && !fileName))
    ||  (selectedType == "link" && valor == "")) {
      $('.aviso-naranja.aviso-detalle').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-detalle').addClass('hidden');
    }
    
    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }
    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }

    if (nombre != "" && descripcion != "" 
    && (
      (selectedType == 'imagen' && (myFile || fileName)) 
      || (selectedType == 'video' && ((myFile || valor != "") || fileName))
      || (selectedType == 'pdf' && (myFile || fileName))
      || (selectedType == 'audio' && (myFile || fileName))
      ||  (selectedType == "link" && valor != "")
    )) 
    {
      setLoadingRecurso(true);
      crearRecurso('modificar', formData, setActualPage, setShowAlert, setTextAlert, undefined, setLastAction, setLoadingRecurso, selectedId);
    } 

  }

  const createFormData = () => {
    const formData = new FormData();

    if (selectedType != 'link' && myFile) {
      formData.append('file', myFile!);
      formData.append('type', selectedType!);
    } 

    formData.append("token", Cookies.get('token')!);
    formData.append("group_id", Cookies.get('group_id')?.toString()!);
    formData.append("name", nombre);
    formData.append("value", valor);
    formData.append("description", descripcion);
    formData.append("type", selectedType);
    formData.append("status", estado.toString());
    formData.append("important", `${destacado ? "1" : "0"}`);


    return formData;
  }

  const removeMedia = () => {
    setIsMediaSelected(false);
    setFile(undefined);
    unsetAllMedia();
  }


  return (
    <>
      <div id="bannerEdicion" className="d-flex justify-content-center "><p className="okButton">Modo Edición</p></div>

      <IonGrid className="container">
        <IonRow>
          <TituloRecurso titulo="Editar recurso" />
        </IonRow>

        <IonGrid className="form">
          <IonLoading
            isOpen={loadingRecurso}
            onDidDismiss={() => setLoadingRecurso(false)}
            message={'Guardando modificación'}
            spinner={'bubbles'}
            duration={500000}
          />
          <IonRow>
            <IonCol size="12">
              <p>Tipo de Recurso</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <button disabled={true} onClick={() => setSelectedType("imagen")}>
                {selectedType === "imagen"
                  ? <IonIcon src={imgColor} className="muyGrande" />
                  : <IonIcon src={imgBW} className="muyGrande" />}
                <p className="body">Imagen</p>
              </button>

              {/* </IonCol>
                  <IonCol size="1"> */}
              <button disabled={true} onClick={() => setSelectedType("video")}>
                {selectedType === "video"
                  ? <IonIcon src={vidColor} className="muyGrande" />
                  : <IonIcon src={vidBW} className="muyGrande" />}
                <p className="body">Vídeo</p>
              </button>
              {/* </IonCol>
                  <IonCol size="1"> */}
              <button disabled={true} onClick={() => setSelectedType("link")}>
                {selectedType === "link"
                  ? <IonIcon src={linkColor} className="muyGrande" />
                  : <IonIcon src={linkBW} className="muyGrande" />}
                <p className="body">Enlace</p>
              </button>
              {/* </IonCol>
                  <IonCol size="1"> */}
              <button disabled={true} onClick={() => setSelectedType("pdf")}>
                {selectedType === "pdf"
                  ? <IonIcon src={pdfColor} className="muyGrande" />
                  : <IonIcon src={pdfBW} className="muyGrande" />}
                <p className="body">PDF</p>
              </button>
              {/* </IonCol>
                  <IonCol size="1"> */}
              <button disabled={true} onClick={() => setSelectedType("audio")}>
                {selectedType === "audio"
                  ? <IonIcon src={audioColor} className="muyGrande" />
                  : <IonIcon src={audioBW} className="muyGrande" />}
                <p className="body">Audio</p>
              </button>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <p>Nombre del recurso</p>
              <IonItem>
                <IonInput maxlength={70} type="text" value={nombre} onIonChange={e => <> {e.detail.value != undefined ? setNombre(e.detail.value?.toString()) : null}</>}></IonInput>
              </IonItem>
              <p className="aviso-naranja aviso-nombre small hidden"> * Campo obligatorio</p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <p>Descripción</p>
                <IonTextarea className="big-textarea" value={descripcion} onIonChange={e => <> {e.detail.value != undefined ? setDescripcion(e.detail.value?.toString()) : null}</>}></IonTextarea>
              <p className="aviso-naranja aviso-descripcion small hidden"> * Campo obligatorio</p>
            </IonCol>
          </IonRow>

          <DetalleTipoRecursoComp setFile={setFile} myFile={myFile!} selectedType={selectedType} valor={valor} setValor={setValor} removeMedia={removeMedia} setIsMediaSelected={setIsMediaSelected} selectedFileName={selectedFileName!}/>
          
          <p className="aviso-naranja aviso-detalle small hidden"> * Campo obligatorio</p>
          <p className="aviso-naranja aviso-detalle-grande small hidden">El archivo seleccionado es demasiado grande. Seleciona uno de máximo 100MB</p>

          <IonRow>
            <IonCol size="12">
              <p>Estado</p>
              <IonRadioGroup className="radioGroup" value={estado} onIonChange={e => { setEstado(e.detail.value); if (e.detail.value == 1) { setDestacado(true) } }}>
                <IonItem>
                  <IonLabel>Activo</IonLabel>
                  <IonRadio mode="md" slot="start" value={1}></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>Inactivo<p className="body2 text-gris desktop">No se mostrará a los acompañantes con rol "Colaborador"</p></IonLabel>
                  <IonRadio mode="md" slot="start" value={0}></IonRadio>
                </IonItem>
              </IonRadioGroup>
            </IonCol>
          </IonRow>
          <IonRow className="mt-3">
            <IonCol size="12">
              <IonItem className="destacadoCheck">
                <IonLabel>Destacado <IonIcon src={star} /> <br /><p className="body2 text-gris desktop">Se visualizará en el menú lateral. </p></IonLabel>
                <IonCheckbox slot="start"
                  disabled={estado != 0 ? false : true}
                  checked={estado != 0 ? destacado : false}
                  onIonChange={e => setDestacado(e.detail.checked)} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" className='lineal-flex'>
              <IonButton onClick={() => setActualPage("recursos")} className="cancelBtn"><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" onClick={() => updateResource()}><p>GUARDAR</p></IonButton>
            </IonCol>
            <IonCol size='12' className="">
              <IonButton className="timelineBtn " disabled={estado == 0 ? true : false} onClick={() => updateAndPost()}><p className='px-5'>Guardar y publicar en el timeline</p></IonButton>
            </IonCol>
          </IonRow>

        </IonGrid>


      </IonGrid>
    </>

  );
};

export default RecursoModificar;


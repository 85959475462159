import { IonCol, IonGrid, IonIcon, IonLoading, IonPopover, IonRow } from "@ionic/react";

import ModificarElemento from "../ModificarElemento";

/* ICONS */
import logoImg from '../../img/iconos/img-small.svg';
import logoVid from '../../img/iconos/video-small.svg';
import logoLink from '../../img/iconos/enlace-small.svg';
import logoPdf from '../../img/iconos/pdf-small.svg';
import logoAud from '../../img/iconos/audio-small.svg';
import star from '../../img/iconos/star.svg';
import { Fragment, useEffect, useState } from "react";

import { getData, getRecursos } from '../../api/apiRecursos'
import { Resource, Recursos } from "../../interfaces/intRecurso";
import Cookies from "js-cookie";
import chevronRight from '../../img/iconos/chevron-right.svg';
import { useHistory } from "react-router";
import noRecBiblio from '../../img/iconos/no_rec_biblio.svg';

//REDUX
import { useAppSelector, useAppDispatch } from '../../app/hooks'




interface Props {
  estado: string;
  orden: string;
  isReversed: boolean;
  showHide: boolean;
  setActualPage?: any,
  setSelectedId?: any,
  setLastAction: any,
  lastAction: string,
  setShowBack?: any,
  setShowAlert: any,
  setTextAlert: any,
}


const Recurso: React.FC<Props> = ({ setTextAlert, setShowAlert, setShowBack, estado, orden, isReversed, showHide, setActualPage, setSelectedId, setLastAction, lastAction }) => {
  //REDUX
  const resources = useAppSelector(state => state.reducer.groupSelected.resources);
  
  const role = useAppSelector(state => state.reducer.groupSelected.userRole);
  const [loading, setLoading] = useState(true);
  const [popoverStateResource, setShowPopoverResource] = useState({ showPopover: false, event: undefined });
  const history = useHistory();
  const [loadingObjetDefecto, setLoadingObjetDefecto] = useState(false);
  const [popoverStateNuevo, setShowPopoverNuevo] = useState({ showPopover: false, event: undefined });
  const axios = require('axios');



  let recursosOrdenados: Resource[];

  const userData = {
    token: Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  const showResource = (id: number) => {
    setSelectedId(id);
    setActualPage("resourceDetail");
    if (setShowBack) {
      setShowBack(true);
    }

  }

  useEffect(() => {
    setLoading(false)
    // getRecursos.post<Recursos>('/getResources', userData)
    //   .then(resp => {
    //     //@ts-ignore
    //     setRecursos(resp.data);
    //   })
    //   .then(() =>
        
    //   )
    //   .catch(err => console.log(err))
  }, [lastAction])

  if (orden == "Alfabéticamente") {
    recursosOrdenados = [...resources].sort(function (a, b) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  } else {
    recursosOrdenados = resources;
  }

  const showContent = () => {
    let filtrados;
    switch (estado) {
      case "todo":
        if (isReversed) {
          return mostrarRecursos(recursosOrdenados.reverse(), orden, showHide);
        } else {
          return mostrarRecursos(recursosOrdenados, orden, showHide);
        }

      case "activo":
        filtrados = recursosOrdenados.filter(recurso => recurso.status == 1);
        if (isReversed) {
          return mostrarRecursos(filtrados.reverse(), orden, showHide);
        } else {
          return mostrarRecursos(filtrados, orden, showHide);
        }

        break;
      case "inactivo":
        filtrados = recursosOrdenados.filter(recurso => recurso.status == 0);
        if (isReversed) {
          return mostrarRecursos(filtrados.reverse(), orden, showHide);
        } else {
          return mostrarRecursos(filtrados, orden, showHide);
        }

      default:
        break;
    }
  }
  const itemRecurso = (arrayLength: number, recurso: Resource, index: number) => {
    return (
      <>
        {insertLogo(recurso.type)}
        <a className="m-2" onClick={e => showResource(recurso.id)}><p className="body2">{recurso.name}</p></a>
        <div className={`tr-absolute lineal-flex`}>
          {recurso.important == 1
            ? <IonIcon src={star} className="grande" />
            : null}
          {showEditButton(recurso.id, showHide)}
        </div>
      </>
    )
  }

  const abrirBiblio = () => {
    setShowPopoverResource({ showPopover: false, event: undefined });
    history.push({
      pathname: '/biblioteca/recursos',
    })
  }

  const setDefaultResources = () => {
    setLoadingObjetDefecto(true)
    const data = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    };
    getData.post("/resources/storeDefault", data)
      .then((res: any) => {
        setShowPopoverNuevo({ showPopover: false, event: undefined });
        // setActualPage("recursos");
        // alert("Objetivos por defecto creados correctamente");
        setLastAction("Recursos por defecto asignados " + Date.now());
      })
      .then(() =>
        setLoadingObjetDefecto(false)
      )
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const textEmpty = () =>  {
    switch (estado) {
      case "activo":
        return (<IonRow>
          <IonCol size-md="12" size-lg="6" offset-lg="3" className="text-center my-5">
            <p className="body1">No hay recursos activos</p>
            <img src={noRecBiblio} className="my-4"></img> <br />
          </IonCol>
        </IonRow>);

      case "inactivo":
        return (<IonRow>
          <IonCol size-md="12" size-lg="6" offset-lg="3" className="text-center my-5">
            <p className="body1">No hay recursos inactivos</p>
            <img src={noRecBiblio} className="my-4"></img> <br />
          </IonCol>
        </IonRow>);

    }


  }
  const rowRecurso = (recursos: Resource []) =>{
    return (
      <> 
        {recursos.map((recurso, index) =>
          <Fragment key={index}>
            {role?.role == 1 &&  recurso.status == 0 
            ? null 
            : <IonRow>
                <div className={`cnt-recurso status-${recurso.status} `}>
                  {itemRecurso(recursos.length, recurso, index)}
                </div>
              </IonRow>
            }
          </Fragment>
        )}
    </>
    );
  }
  const mostrarRecursos = (recursos: Resource[], orden: string, showHide: boolean) => {
    if (orden === "Alfabéticamente") {
      return (
        <>
          {recursos.length < 1 
            ? textEmpty()  
            : <>
                {recursos.map((recurso, index) =>
                  <Fragment key={index}>
                    {role?.role == 1 &&  recurso.status == 0 
                    ? null 
                    : <IonRow >
                    <div className={`cnt-recurso status-${recurso.status} `}>
                      {insertLogo(recurso.type)}
                      <a className="m-2" onClick={e => showResource(recurso.id)}><p className="body2">{recurso.name}</p></a>
                      <div className={`tr-absolute lineal-flex`}>
                        {recurso.important == 1
                          ? <IonIcon src={star} className="grande" />
                          : null}
                        {showEditButton(recurso.id, showHide)}
                      </div>
                    </div>
                  </IonRow>
                    }
                  </Fragment>
                )}
            </>}
          

        </>
      )
    } else {
      const arrVideo = recursos.filter(recurso => recurso.type == "video");
      const arrImg = recursos.filter(recurso => recurso.type == "imagen");
      const arrEnlace = recursos.filter(recurso => recurso.type == "link");
      const arrAudio = recursos.filter(recurso => recurso.type == "audio");
      const arrPdf = recursos.filter(recurso => recurso.type == "pdf");

      return (
        <>
        {recursos.length == 0 
        ? textEmpty()
        : <>
          {arrVideo.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Vídeo</b></p>
                </IonCol>
              </IonRow>
                {rowRecurso(arrVideo)}
            </>
            : null}

          {arrImg.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Imagen</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrImg)}
            </>
            : null}

          {arrEnlace.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Enlace</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrEnlace)}
            </>
            : null}

          {arrAudio.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Audio</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrAudio)}
            </>
            : null}
          {arrPdf.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Pdf</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrPdf)}

            </>
            : null}
          </>}
        </>
      )
    }
  }
  const showEditButton = (id: number, showHide: boolean) => {
    return (
      <>
        {showHide
          ? <ModificarElemento setTextAlert={setTextAlert} setShowAlert={setShowAlert} tipo="recurso" id={id} setSelectedId={setSelectedId} setActualPage={setActualPage} setLastAction={setLastAction} />
          : null
        }
      </>
    )
  }
  const insertLogo = (tipo: string) => {
    switch (tipo) {
      case "imagen":
        return <IonIcon className="grande" src={logoImg} />

      case "video":
        return <IonIcon className="grande" src={logoVid} />

      case "link":
        return <IonIcon className="grande" src={logoLink} />

      case "pdf":
        return <IonIcon className="grande" src={logoPdf} />

      case "audio":
        return <IonIcon className="grande" src={logoAud} />

      default:
        break;
    }
  }

  return (
    <>
      <IonLoading
        isOpen={loadingObjetDefecto}
        onDidDismiss={() => setLoadingObjetDefecto(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={10000}
      />
      <IonGrid>
        {loading
          ? <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={'Cargando'}
            spinner={'bubbles'}
            duration={5000}
          />
          : <>
            {resources.length < 1
              ? <> {role?.role != 1
                ? <IonGrid className="my-5 cardEmptyElement">
                  <IonRow>
                    <IonCol size="8" offset='2' className="bg-white p-5">
                      <p className="body1">Empieza a definir los recursos.</p>
                      <a className="body2" onClick={
                        (e: any) => {
                          e.persist();
                          setShowPopoverResource({ showPopover: true, event: e })
                        }}><p className="body1 col-12 text-naranja mt-5">CREAR RECURSOS <IonIcon src={chevronRight} /></p></a>
                      <IonPopover
                    // cssClass='my-custom-class'
                        event={popoverStateResource.event}
                        isOpen={popoverStateResource.showPopover}
                        onDidDismiss={() => setShowPopoverResource({ showPopover: false, event: undefined })}
                      >
                        <a className='popup-select' onClick={() => setActualPage("resourceNew")}><p className="body1">Crear nuevo recurso</p></a>
                        {/* <a className='popup-select'><p className="body1" onClick={() => setDefaultResources()}>Añadir recursos de ComuniteCAA</p></a> */}
                        <a className='popup-select'><p className="body1" onClick={() => setActualPage("resourceBiblio")}>Añadir recursos de Mi biblioteca</p></a>

                      </IonPopover>
 
                    </IonCol>
                  </IonRow>
                </IonGrid>
                : <IonGrid className="my-5 cardEmptyElement">
                  <IonRow>
                    <IonCol size="8" offset='2' className="bg-white p-5">
                      <p className="body1">Aún no hay recursos creados.</p>
                    </IonCol>
                  </IonRow>
                </IonGrid>} </>
              : <>{showContent()}</>}

          </>}

        {/* {showContent()} */}
      </IonGrid>

    </>
  );

 




  
}

export default Recurso;






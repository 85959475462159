import { IonIcon } from "@ionic/react";
import { GlobalVariables } from "../../hooks/GlobalVariables";
import close from '../../img/iconos/close.svg';


interface Props {
  photoName: string,
  setShowPhoto: any,
}

const BigPhotoPopUp: React.FC<Props> = ({ photoName, setShowPhoto }) => {
  const {urlMedia} = GlobalVariables();

  return (
    <div className="modal modal_photo">
      <div className="modal_content ">
      <IonIcon src={close} className="close" onClick={() => setShowPhoto(false)} />
      <img src={urlMedia + photoName}></img>
      </div>
    </div>
  );

};
export default BigPhotoPopUp;
import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import checkNaranja from '../../img/iconos/check-naranja.svg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonIcon, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonToggle, IonButton } from '@ionic/react';
import { ExportGroup, UserGroup } from '../../interfaces/intGroups';
import { getData } from '../../api/apiRecursos';
import Cookies from 'js-cookie';
import { ActivityGroup } from '../../interfaces/intActivity';
import axios from 'axios';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';
import{useAppSelector} from '../../app/hooks';


interface Props {
  setShowImportModal?: any;
  setLastAction: any,
  setShowAlert: any,
  setTextAlert:any,
}

const ImportObjectivePopUp: React.FC<Props> = ({ setShowAlert, setTextAlert,setShowImportModal, setLastAction}) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [idSelected, setIdSelected] = useState<number>();
  const [userGroup, setUserGroup] = useState<ActivityGroup[]>([]);
  // const [exportList, setExportList] = useState<ExportGroup[]>([]);

  const exportList = useAppSelector(state=> state.reducer.groupSelected.exportedObjectives);

  const {getObjectives} = GlobalFunctions();


  const importSet = () => {
    const date = new Date();
    var today = date.getFullYear()+'/'+date.getMonth()+'/'+date.getDate();

    const dataRelation = {
      group_id: Cookies.get('group_id'),
      token: Cookies.get('token'),
      relation_template: idSelected
    };

      getData.post("/importSet", dataRelation)
        .then((res: any) => {
          setShowImportModal(false);
          setTextAlert('Objetivos de Mi biblioteca importados correctamente.')
          setShowAlert(true);

          setLastAction("creadoImport "+Date.now());
          getObjectives();

        })
        .catch((error: any) => {
          console.log("error", error);
        });
  }

  const setSelected = (id:number) => {
    const group = document.getElementById('group-'+id);
    const rows = Array.from(document.getElementsByClassName('row-group') as HTMLCollectionOf<HTMLElement>);
    var i; 
    
    if (group?.classList.contains('selected')) {
      setIdSelected(undefined);
      group.classList.remove('selected');
    } else {
      setIdSelected(id);
      for (i = 0; i < rows.length; i++) {
        rows[i].classList.remove('selected');
      }
      group!.classList.add('selected');
    }
  }

  const setTime = (date: Date) => {
    date = new Date(date);
    return date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
  }

  return (
    <div className="modal modal_import">
      <div className="modal_content">
        <IonIcon src={close} className="close" onClick={e => setShowImportModal(false)} />
        <IonGrid className="border-none">
          <IonRow>
            <IonCol>
              <h5>Importar set de objetivos:</h5>
            </IonCol>
          </IonRow>


          {exportList.map((objExport, index) =>
            <IonRow className={`border-botom mx-4`} key={index}>
              <IonCol size='8' id={`group-${objExport.id}`} className="lineal  row-group align-self-center">
                <a  className={`px-3`} onClick={e =>  setSelected(objExport.id)}>
                  <p className={`body2 capitalize ${objExport.id == idSelected ? 'text-naranja' : ""}`}>Objetivos de {objExport.name}_{setTime(objExport.created_at)}</p>
                </a>
              </IonCol>
              <IonCol className="lineal justify-content-end align-self-center">
              {objExport.id == idSelected ? <img className="medio mx-3" src={checkNaranja}/> : <img className="medio mx-3" src={''}/>}
              </IonCol>
            </IonRow>
          )}
          {exportList.length < 1
            ? <IonRow>
                <IonCol>
                  <p className='body2'>Todavía no tienes ningún set de objetivos guardado.</p>
                </IonCol>
            </IonRow>
            : null }

          <IonRow>
            <IonCol className="d-flex justify-content-end mt-5">
              <IonButton className="cancelBtn" onClick={e => setShowImportModal(false)}><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" disabled={idSelected == undefined ? true : false} onClick={e => importSet()}><p>Importar</p></IonButton>
            </IonCol>
          </IonRow>


        </IonGrid>
      </div>
    </div>
  );

};
export default ImportObjectivePopUp;
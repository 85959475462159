import { IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import Cookies from 'js-cookie';
import { User } from "../../interfaces/intUser";
import { getData } from "../../api/apiRecursos";
import OneSignal from 'onesignal-cordova-plugin';

import logoComuniteCaa from '../../img/iconos/logoComuniteCAA.svg';
import loading from '../../img/iconos/loading.gif';
import AvisoPopUp from "../PopUpModals/AvisoPopUp";



interface Props {
  setActualPage: any;
}

const ActivarUsuario: React.FC<Props> = ({ setActualPage }) => {
  const [user, setUser] = useState<User>();
  const [mail, setMail] = useState<string>();
  const [showCountdown, setShowCountdown] = useState(false);
  const [activar, setActivar] = useState(false);
  const axios = require('axios');


  let history = useHistory();
  function tryLogin(mail: string | undefined, contraseña: string | undefined): void {
    const loginData = {
      email: mail,
      password: contraseña,
    };




    const getUserData = async (token: string) => {
      const userData = {
        'token': token
      }
      await getData.post<User>('/userDetail', userData)
        .then((res: any) => {
          setUser(res.data);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }

    getData.post("/login", loginData)
      .then((res: any) => {
        if (res.status === 200) {
          getUserData(res.data.token);
          Cookies.set('token', res.data.token, { sameSite: 'strict' });
          if (window.screen.width <= 425) {
            // OneSignal.setEmail(mail!);
            // OneSignal.setExternalUserId(res.data.user_id);
          }
          history.push({
            pathname: "/principal/", state: { actualPage: 'home' }
          });
        } if (res.status == 202) {
          setActualPage('activarUsuario');

          // alert("El mail/contraseña no coincide. Vuelve a intentarlo por favor.");
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        // alert("Este usuario no existe en nuestro sistema");
      });
  }


  const activarUser = () => {
    getData.get("/activarUser/" + mail)
      .then(() => {
        setShowCountdown(true);
        setTimeout(() => {
          setActualPage('login');

        }, 2500);

      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  return (
    <>
      <IonGrid className="px-4 container bg-gris-clar registerPage">
        <IonRow className="mt-4"><IonCol className="d-flex justify-content-center"><img style={{ 'width': '5%' }} src={logoComuniteCaa} /><p className="body2">COMUNITE<b>CAA</b></p></IonCol></IonRow>
        <IonRow className="mt-3">
          <IonCol size-md="0" size-xs="0" size-lg="1" className="p-5 desktop">
          </IonCol>
          <IonCol className="bg-white p-5">
            {activar
              ? <>
                <div className="mb-5 headerLogin ">
                  <h3>Introduce tu mail para activar la cuenta</h3>
                </div>
                <IonItem>
                  <IonInput placeholder="Introduce tu correo electrónico" value={mail} type="text" onIonChange={e => <> {e.detail.value != undefined ? setMail(e.detail.value?.toString()) : null}</>}></IonInput>
                </IonItem>
                <br />
                <IonButton className="saveBtn" onClick={() => activarUser()}><p>Activar usuario</p></IonButton>

                {showCountdown
                  ? <IonRow className="my-4">
                      <IonCol className="">
                        <p className="body1 text-center">* El usuario se ha activado correctamente, serás redirigido a la pantalla principal para hacer el login</p>
                        <p className="body1 text-center"><img src={loading} /></p>
                      </IonCol>
                    </IonRow>
                  : null}

              </>
              : null
              // <>
              //   <div className="mb-5 headerLogin ">
              //     <h3>¡Bienvenido!</h3>
              //   </div>
              //   <p className="body1">Tu cuenta todavía no se ha activado. Puedes introducir el codigo que te facilitamos al crear la cuenta a continuación o puedes hacer click en el enlace que te enviamos al correo.</p>
              //   <br />
              //   <p className="body1">Si no has recibido el  mail puedes hacer click <a onClick={e => setActivar(true)}>aqui</a> para activar la cuenta</p>
              // </>
            }

          </IonCol>
          <IonCol size-md="0" size-xs="0" size-lg="1" className="p-5 desktop">
          </IonCol>
        </IonRow>

      </IonGrid>
    </>
  );
}

export default ActivarUsuario;



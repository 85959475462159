import OneSignalMvl from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';
import Cookies from 'js-cookie';
import { GlobalFunctions } from './GlobalFunctions';
import { Group, Groups } from '../interfaces/intGroups';
import { getData } from "../api/apiRecursos";
import { useState } from 'react';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setGroups as setGroupsSlice } from '../features/groups/groupsSlice';
import { setUserID, setNotifications, setGroups as setOneSignalGroups } from '../features/oneSignal/oneSignalSlice'

export function OneSignalFunctions() {
  //REDUX
  const dispatch = useAppDispatch();
  const [groups, setGroups] = useState<Group[]>(useAppSelector(state=>state.reducer.groups.group));
  // const [groups, setGroups] = useState<Group[]>([]);
  const OSUserID = useAppSelector(state=>state.reducer.oneSignal.userID);
  const userID = useAppSelector(state=>state.reducer.user.id);


  const {getNames} = GlobalFunctions();
  const axios = require('axios');
  // const api = axios.create({
  //   baseURL: `https://onesignal.com/api/v1/notifications`,
  // });

  var headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Basic OGQxODIxMmYtNDBjNS00ZGJjLWEwZmUtM2U3ZDczOTNlZDQ3"
  };


  const loginUser = (user_id: string) => {
    const options = {
      method: 'GET',
      headers: headers
    };

    if (isPlatform('hybrid') ) {
      OneSignalMvl.getDeviceState( function(userId) {
        dispatch(setUserID(userId.userId));
      });
    } else {
      //@ts-ignore
      OneSignal.getUserId( function(userId) {
        dispatch(setUserID(userId));
      });
    }

    var groups_string = "[";
    groups.filter(group => group.status_relation == 1).map(group => {
      groups_string += `{"id":${group.id}},`;
    })
    groups_string += "]";

    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`groups`, "");
      OneSignalMvl.sendTag(`groups`, groups_string);
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`groups`, "");
        //@ts-ignore
        OneSignal.sendTag(`groups`, groups_string);
      });
    }

    fetch('https://onesignal.com/api/v1/players/'+userID+'?app_id=1fd3aeb7-6b3d-4684-9352-56f69862d7b7', options)
        .then(response => response.json())
        .then(response => {
          dispatch(setOneSignalGroups(JSON.parse(response.tags.groups)));
          dispatch(setNotifications(JSON.parse(response.tags.notifications)));
        })
        .catch((err: any) => console.error(err));    
  }

  const sendNotification = (data : any) => { 
    var options = {
      host: "onesignal.com",
      port: 443,
      path: "/api/v1/notifications",
      method: "POST",
      headers: headers
    };
    
    var https = require('https');
    
    var req = https.request(options, function(res : any) {  
      res.on('data', function(data : any) {
      });
    });
    
    req.on('error', function(e : any) {
      console.log(e);
    });
    
    req.write(JSON.stringify(data));
    req.end();
  };

  const storeUserInfo = (email:string, id:string) => {
    Cookies.set("user_id", id);
    if (isPlatform('hybrid') ) {
      OneSignalMvl.setExternalUserId(id);
    }
  }

  const storeNewGroupTag = (group_id:string) => {
    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`group_${group_id}`, "true");
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`group_${group_id}`, "true", function(tagsSent) {
        });
      });
    }
  }
  const unlinkGroupTag = (group_id:string) => {
    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`group_${group_id}`, "false");
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`group_${group_id}`, "", function(tagsSent) {
        });
      });
    }
  }

  const notifyNewPost = (group_id: string) => {
    var promise = getNames();
    promise.then(function(result) {
      var message = { 
        app_id: "1fd3aeb7-6b3d-4684-9352-56f69862d7b7",
        contents: {"en": result.user+" ha publicado en el grupo "+result.group},
        filters: [
          {"field": "tag", "key": "group_"+group_id, "relation": "=", "value": "true"}, 
          {"operator":"AND"}, 
          {"field": "tag", "key": "user_id", "relation": "!=", "value":Cookies.get('user_id')}
        ]
      };
      
      sendNotification(message);
    });
  }

  const changeNotificationsStatus = (status: boolean) => {
    var val_string: string;

    if (status){
      val_string = "true"
    } else {
      val_string = "false"
    }

    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`notif_all`, val_string);
      //@ts-ignore
      OneSignalMvl.disablePush(!status) 
    } else {
      //@ts-ignore
      OneSignal.setSubscription(status);
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`notif_all`, val_string);
      });
    }
  }

  const changeNotificationPublicaciones = (status: boolean) => {
    var val_string: string;

    if (status){
      val_string = "true"
    } else {
      val_string = "false"
    }

    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`notif_publi`, val_string);
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`notif_publi`, val_string);
      });
    }
  }

  const changeNotificationReacciones = (status: boolean) => {
    var val_string: string;

    if (status){
      val_string = "true"
    } else {
      val_string = "false"
    }

    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`notif_reac`, val_string);
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`notif_reac`, val_string);
      });
    }
  }

  const changeNotificationComentarios = (status: boolean) => {
    var val_string: string;

    if (status){
      val_string = "true"
    } else {
      val_string = "false"
    }

    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`notif_coment`, val_string);
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`notif_coment`, val_string);
      });
    }
  }
  const changeNotificationGrupo = (status: boolean) => { 
    var val_string: string;

    if (status){
      val_string = "true"
    } else {
      val_string = "false"
    }

    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`notif_grupo`, val_string);
    } else {
      //@ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`notif_grupo`, val_string);
      });
    }
  }

  const changeNotificationTag = (group_id: number, status: string) => { 
    if (isPlatform('hybrid') ) {
      OneSignalMvl.sendTag(`group_${group_id}`, status);
    } else {
      // @ts-ignore
      OneSignal.push(function() {
        //@ts-ignore
        OneSignal.sendTag(`group_${group_id}`, status, function(tagsSent) {
        });
      });
    }
  }


  const getActiveState = (setDone: any) => {
    if (isPlatform('hybrid') ) {
      OneSignalMvl.getDeviceState( function(userId) {
        if (userId.userId) {
          dispatch(setUserID(userId.userId));
          setDone(true);
        } else {
          setDone(false);
        }
      });
    } else {
      //@ts-ignore
      OneSignal.getUserId( function(userId) {
        if (userId) {
          dispatch(setUserID(userId));
          setDone(true);
        } else {
          setDone(false);
        }
      });
    }
  }

  const getAllData = () => {
    const options = {
      method: 'GET',
      headers: headers
    };

    if (isPlatform('hybrid') ) {
      OneSignalMvl.getDeviceState( function(userId) {
        dispatch(setUserID(userId.userId));
      });
    } else {
      //@ts-ignore
      // OneSignal.getUserId( function(userId) {
      //   dispatch(setUserID(userId));
      // });
    }


    
    setTimeout(() => {
      fetch('https://onesignal.com/api/v1/players/'+OSUserID+'?app_id=1fd3aeb7-6b3d-4684-9352-56f69862d7b7', options)
        .then(response => response.json())
        .then(response => {
          dispatch(setOneSignalGroups(JSON.parse(response.tags.groups)));
          dispatch(setNotifications(JSON.parse(response.tags.notifications)));
        })
        .catch((err: any) => console.error(err));
    }, 500);

  }

  return {
    storeUserInfo, storeNewGroupTag, unlinkGroupTag, notifyNewPost, loginUser,
    changeNotificationsStatus, changeNotificationGrupo,
    changeNotificationPublicaciones, changeNotificationReacciones, changeNotificationComentarios,
    changeNotificationTag,

    getAllData, getActiveState
  };
}



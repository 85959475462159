import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonLoading,
  IonIcon,
  IonImg,
} from "@ionic/react";

/* CSS */
import "../../theme/global.css";

import { useEffect, useRef, useState } from "react";
import { getData } from "../../api/apiRecursos";
import { Activities } from "../../interfaces/intActivity";
import Cookies from "js-cookie";
import TituloActividades from "../TituloActividad";
import ChooseObjPopUp from "../PopUpModals/ChooseObjPopUp";
import { Editor } from "@tinymce/tinymce-react";
import star from "../../img/iconos/star.svg";
import edit_img from "../../img/iconos/edit_img.svg";
import remove_img from "../../img/iconos/remove_img.svg";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import $ from "jquery";
import DetalleTipoActividadComp from "../helpers/DetalleTipoActividadComp";

interface ContainerProps {
  setActualPage: any;
  selectedId: number;
  setLastAction: any;
  lastAction: string;
  setShowAlert: any;
  setTextAlert: any;
}

const ActividadModificar: React.FC<ContainerProps> = ({
  setShowAlert,
  setTextAlert,
  setActualPage,
  selectedId,
  setLastAction,
}) => {
  const [showLoading, setShowLoading] = useState(true);
  const { publicarActividad, crearActividad, saveFile } = GlobalFunctions();
  const [searches, setSearches] = useState([]);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [estado, setEstado] = useState(0);
  const [destacado, setDestacado] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);

  const [myFile, setFile] = useState<any>();
  const [filename, setFilename] = useState<any>();
  const [selectedType, setSelectedType] = useState("");
  const [loadingActividad, setLoadingActividad] = useState(false);
  const { setLastPhoto } = usePhotoGallery();
  

  useEffect(() => {
    getData
      .get<Activities>("/activity/" + selectedId)
      .then((resp) => {
        setNombre(resp.data.name);
        setDescripcion(resp.data.description);
        setEstado(resp.data.status);
        if (resp.data.media_name) {
          setFilename(resp.data.media_name);
          setSelectedType(resp.data.media_type);
        }

        resp.data.objectives.map((obj) => {
          //@ts-ignore
          setSearches((searches) => [...searches, obj.id.toString()]);
        });
      })
      .then(() => {
        setTimeout(() => {
          setShowModal(false);
          setShowLoading(false);
        }, 1000);
      })
      .catch((err) => console.log(err));
  }, []);

  const createFormData = () => {
    const formData = new FormData();
    if (deleteMedia) {
      formData.append('deleteMedia', 'si');
    } else {
      formData.append('deleteMedia', 'no');
    }
    if (myFile != undefined) {
      formData.append("file", myFile);
      formData.append("type", selectedType);
      formData.append('deleteMedia', 'no');
    }

    formData.append("token", Cookies.get("token")!);
    formData.append("group_id", Cookies.get("group_id")?.toString()!);
    formData.append("name", nombre);
    formData.append("description", descripcion);
    formData.append("media", myFile);
    formData.append("status", estado.toString());
    formData.append("important", `${destacado ? "1" : "0"}`);
    formData.append("isTemplate", `no`);
    //@ts-ignore
    formData.append("objectives_id", searches);

    return formData;
  };

  const updateAndPost = () => {
    const formData = createFormData();

    if (nombre == "") {
      $(".aviso-naranja.aviso-nombre").removeClass("hidden");
    } else {
      $(".aviso-naranja.aviso-nombre").addClass("hidden");
    }
    if (descripcion == "") {
      $(".aviso-naranja.aviso-descripcion").removeClass("hidden");
    } else {
      $(".aviso-naranja.aviso-descripcion").addClass("hidden");
    }
    if (nombre != "" && descripcion != "") {
      setLoadingActividad(true);
      publicarActividad(
        "modificar",
        formData,
        setLastAction,
        setActualPage,
        setTextAlert,
        setShowAlert,
        selectedId,
        loadingActividad
      );
    }
  };

  const modificaActividad = () => {
    const formData = createFormData();

    if (nombre == "") {
      $(".aviso-naranja.aviso-nombre").removeClass("hidden");
    } else {
      $(".aviso-naranja.aviso-nombre").addClass("hidden");
    }
    if (descripcion == "") {
      $(".aviso-naranja.aviso-descripcion").removeClass("hidden");
    } else {
      $(".aviso-naranja.aviso-descripcion").addClass("hidden");
    }

    
    if (nombre != "" && descripcion != "") {
      setLoadingActividad(true);
      crearActividad(
        "modificar",
        setLoadingActividad,
        formData,
        setLastAction,
        setActualPage,
        setTextAlert,
        setShowAlert,
        selectedId
      );
    }
  };

  const removeImage = () => {
    setLastPhoto(undefined);
    setFilename(undefined);
    setFile(undefined);
    setDeleteMedia(true);
    $('#videoInput').val('');
    $('#imageInput').val('');
  };

  return (
    <>
      <div id="bannerEdicion" className="d-flex justify-content-center ">
        <p className="okButton">Modo Edición</p>
      </div>
      <IonGrid className="container">
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Cargando"}
          spinner={"bubbles"}
          showBackdrop={true}
          duration={5000}
        />
        <IonLoading
          isOpen={loadingActividad}
          onDidDismiss={() => setShowLoading(false)}
          message={'Guardando actividad'}
          spinner={'bubbles'}
          showBackdrop={true}
          duration={500000}
        />
        <IonRow>
          <TituloActividades titulo="Editar actividad" />
        </IonRow>

        <IonGrid className="form">
          <IonRow>
            <IonCol size="12">
              <p>Nombre de la actividad</p>
              <IonItem>
                <IonInput
                  maxlength={70}
                  value={nombre}
                  type="text"
                  onIonChange={(e: any) => (
                    <>
                      {" "}
                      {e.detail.value != undefined
                        ? setNombre(e.detail.value?.toString())
                        : null}
                    </>
                  )}
                ></IonInput>
              </IonItem>
            </IonCol>
            <p className="aviso-naranja aviso-nombre small hidden">
              {" "}
              * Campo obligatorio
            </p>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <p>Descripción</p>
              <Editor
                apiKey="zcwhxx8ze45rb7b73snp6zr957eqtcu9hglafgu9fybxu8zs"
                initialValue=""
                value={descripcion}
                onEditorChange={(newValue) => setDescripcion(newValue)}
                init={{
                  height: 500,
                  menubar: false,
                  content_css: "theme/global.css",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                }}
              />
              <p className="aviso-naranja aviso-descripcion small hidden">
                {" "}
                * Campo obligatorio
              </p>
            </IonCol>
          </IonRow>

          <DetalleTipoActividadComp
            selectedFileName={filename!}
            setFile={setFile}
            myFile={myFile}
            removeMedia={removeImage}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
          <IonRow>
            <IonCol>
              <p className="my-2">Asociar objetivos</p>
              <button
                className={`btn-objetivos`}
                onClick={() => setShowModal(true)}
              >
                <p>ELEGIR OBJETIVOS</p>
              </button>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <p>Estado</p>
              <IonRadioGroup
                className="radioGroup"
                onIonChange={(e: any) => {
                  setEstado(e.detail.value);
                  if (e.detail.value == "1") {
                    setDestacado(true);
                  }
                }}
                value={estado.toString()}
              >
                <IonItem >
                  <IonLabel>Pendiente</IonLabel>
                  <IonRadio mode="md" slot="start" value="0"></IonRadio>
                </IonItem>
                <IonItem >
                  <IonLabel>En curso</IonLabel>
                  <IonRadio mode="md" slot="start" value="1"></IonRadio>
                </IonItem>
                <IonItem >
                  <IonLabel>Terminada</IonLabel>
                  <IonRadio mode="md" slot="start" value="2"></IonRadio>
                </IonItem>
              </IonRadioGroup>
            </IonCol>
          </IonRow>
          <IonRow className="mt-3">
            <IonCol size="12">
              <IonItem className="destacadoCheck">
                <IonLabel>
                  Destacada <IonIcon src={star} /> <br />
                  <p className="body2 text-gris desktop">
                    Se visualizará en el menú lateral.{" "}
                  </p>
                </IonLabel>
                <IonCheckbox
                  slot="start"
                  checked={estado != 1 ? false : destacado}
                  disabled={estado != 1 ? true : false}
                  onIonChange={(e: any) => setDestacado(e.detail.checked)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" className="lineal-flex">
              <IonButton
                onClick={() => setActualPage("actividades")}
                className="cancelBtn">
                <p>CANCELAR</p>
              </IonButton>
              <IonButton
                className="saveBtn"
                onClick={() => modificaActividad()}>
                <p>GUARDAR</p>
              </IonButton>
              
            </IonCol>
            <IonCol size="12" className="">
              <IonButton
                className="timelineBtn"
                disabled={estado == 0 ? true : false}
                onClick={() => updateAndPost()}>
                <p className='px-5'>Guardar y publicar en el timeline</p>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonGrid>
      {showModal ? (
        <ChooseObjPopUp
          setShowModal={setShowModal}
          setSearchesActivity={setSearches}
          searchesActivity={searches}
        />
      ) : null}
    </>
  );
};

export default ActividadModificar;

import { IonButton, IonCol, IonIcon, IonPopover, IonSelect, IonSelectOption, useIonAlert } from '@ionic/react';

// ICONES
import punts from '../img/iconos/punts.svg';
import { Fragment, useState } from 'react';
import { getData } from '../api/apiRecursos';
import Cookies from 'js-cookie';
import { Category, Etapa } from '../interfaces/intObjective';


interface Props {
  etapaSelected: number;
  categorySelected: number;
  setCategorySelected: any;
  setEtapaSelected: any;
  categories: Category[];
  etapa: Etapa[];
  isEditable?:boolean;

}

const SelectorCategoria: React.FC<Props> = ({ isEditable = true, setCategorySelected, categorySelected, etapaSelected,setEtapaSelected, categories, etapa }) => {

  const checkCategory = (valor: number) => {
    setCategorySelected(valor);
    if (valor == 5) {
      setEtapaSelected(10);
    }
  }

  return (
    <>
      <IonCol sizeLg="6" sizeMd='6' sizeSm="12" sizeXs='12'>
        <p>Categoría</p>
        <p className="small">Competencia</p>
        <IonSelect disabled={!isEditable} className="alert-etapa" cancelText="CANCELAR" okText="ACEPTAR" interface="alert" value={categorySelected} onIonChange={e => checkCategory(e.detail.value)} placeholder="Competencia">
          <IonSelectOption value={5}>Sin competencia</IonSelectOption>
          {categories.map((category, index) =>
            <Fragment key={index}> {category.id != 5 ? <IonSelectOption value={category.id}>{category.name}</IonSelectOption> : null} </Fragment>
          )}
        </IonSelect>
      </IonCol>
      <IonCol sizeLg="6" sizeMd='6' sizeSm="12" sizeXs='12'>
        <p className='desktop'><br /></p>
        <p className="small">Etapa</p>
        <IonSelect cancelText="CANCELAR" okText="ACEPTAR" className="alert-etapa" interface="alert" disabled={categorySelected == 5 ? true : !isEditable ? true : false} value={etapaSelected} onIonChange={e => setEtapaSelected(e.detail.value)} placeholder="Etapa">
          <IonSelectOption value={10}>Sin etapa</IonSelectOption>
          {etapa.map((etapa, index) =>
            <Fragment key={index}> {etapa.titulo != 'Sin etapa' ? <IonSelectOption  value={etapa.id}>{etapa.titulo}</IonSelectOption> : null} </Fragment>
          )}
        </IonSelect>
      </IonCol>


    </>
  )
}

export default SelectorCategoria;


import { IonGrid, IonRow, IonCol, IonContent, IonPage, IonIcon, IonButton, IonImg, IonLoading } from '@ionic/react';

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';


/* CSS */
import '../theme/global.css';

/* ICONOS */
import BibliotecaLogo from '../img/iconos/biblioteca.svg';
import ActivUsers from '../img/iconos/activ-todos.svg';
import NuevoUser from '../img/iconos/nuevo-usuario.svg';
import { useEffect, useState } from 'react';
import PantallaPrincipal from '../components/PantallaPrincipal';
import NuevoUsuario from '../components/NuevoUsuario';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { User } from '../interfaces/intUser';
import { getData } from '../api/apiRecursos';
import UltimaActividad from '../components/UltimaActividad';
import { Group, Groups } from '../interfaces/intGroups';

import noGroupIcons from '../img/iconos/noGroups_icons.svg';
import noGroupLogo from '../img/iconos/noGroups_logo.svg';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setGroups } from '../features/groups/groupsSlice';
import { OneSignalFunctions } from '../hooks/OneSignalFunctions';
import { GlobalFunctions } from '../hooks/GlobalFunctions';




const Principal: React.FC = (props) => {
  //REDUX
  const dispatch = useAppDispatch();
  const image_name = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const groups = useAppSelector(state=>state.reducer.groups.group);
  const OSUserID = useAppSelector(state=>state.reducer.oneSignal.userID);
  // const test = useAppSelector(state=>state.reducer.groups.group);

  const {getAllData} = OneSignalFunctions();
  const {getGroups} = GlobalFunctions();

  const [actualPage, setActualPage] = useState("todo");
  const [lastAction, setLastAction ] = useState('');
  let history = useHistory();
  const [userAvatar, setUserAvatar] = useState('');
  const [loading, setLoading] = useState(true);
  const [showPage, setShowPage] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState<string>('');

  
  if (window.location.pathname == '/principal') {
    var tabsBiblio = Array.from(document.getElementsByClassName('tab-biblio') as HTMLCollectionOf<HTMLElement>);
    var tabsApp = Array.from(document.getElementsByClassName('tab-app') as HTMLCollectionOf<HTMLElement>);

    var i;
    for (i = 0; i < tabsBiblio.length; i++) {
      tabsBiblio[i].setAttribute('style', 'display: none !important');
    }
    for (i = 0; i < tabsApp.length; i++) {
      tabsApp[i].setAttribute('style', 'display: none !important');
    }
  }



  function mostrarContenido(state: string) {
    switch (state) {
      case "todo":
        return <PantallaPrincipal lastAction={lastAction}setLastAction={setLastAction} setActualPage={setActualPage} setTextAlert={setTextAlert} setShowAlert={setShowAlert} />
      case "nuevoUsuario":
        return <NuevoUsuario setActualPage={setActualPage} setTextAlert={setTextAlert} setShowAlert={setShowAlert} />
      case "ultimaActividad":
        return <UltimaActividad/>
      // case "biblioteca":
      //   return <Biblioteca setActualPage={setActualPage}/>
      default:
        break;
    }
  }

  const userData = {
    'token': Cookies.get('token')
  }



  useEffect(() => {
    getAllData();

      setUserAvatar(image_name == null ? "" : image_name);
      getGroups(setShowPage, setLoading);


  }, [lastAction])

  const changeVisibility = (value: string) => {
    let removeClass = document.getElementsByClassName("invisible");
    for (var i = 0; i < removeClass.length; i++) {
      // @ts-ignore
      removeClass.item(i)?.classList.remove("invisible");
    }
    if (value == "app") {
      document.getElementById("tab-button-actividadesBiblio")?.classList.remove("invisible");
    }
    let array = document.getElementsByClassName("tab-" + value);
    for (var i = 0; i < array.length; i++) {
      if (!array.item(i)?.classList.contains("invisible")) {
        // @ts-ignore
        array.item(i).className += " invisible";
      }
    }
  }

  const checkWindow = () => {
    if (window.screen.width <= 425) {
      if (window.location.pathname == "/principal/" || window.location.pathname == "/principal") {
        document.getElementById("tabBar")?.setAttribute("style", "display:none !important")
      } else {
        document.getElementById("tabBar")?.setAttribute("style", "display:flex !important")
      }

      if (window.location.pathname == "/biblioteca") {
        changeVisibility("app");
      } if (window.location.pathname == "/biblioteca/actividades" || window.location.pathname == "/biblioteca/objetivos" || window.location.pathname == "/biblioteca/recursos") {
        changeVisibility("app");
      }
      else {
        changeVisibility("biblio")
      }
    }
  }
  

  const refresh = () => {
    window.location.href = window.location.origin;
  }
  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  return (
    <>{Cookies.get('token')
      ? <>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          showBackdrop={false}
          duration={5000}
        />
        {showPage ?
          <> {!groups.find(group => group.status_relation == 1)
            ?
            <>
            
              {checkWindow()}
              {actualPage != 'todo'
                ? <IonPage>
                  <HeaderEmpty avatarProp={userAvatar} actualPage={actualPage} />
                  
                  <IonContent id="principal-page" fullscreen className={`fullSize pag-principal ${actualPage == 'todo' && 'home-main-page' }  ${actualPage == 'ultimaActividad' || actualPage == 'nuevoUsuario' ? 'bg-gris-clar' : null}`}>
                    <IonGrid className="px-4 container aaaa">
                      {actualPage === "todo" ?
                        <IonRow >
                          <IonCol size="12" className="iconPrincipal">
                            <button onClick={() => history.push("/biblioteca/actividades")}>
                              <IonIcon class="bigger-hover" src={BibliotecaLogo} />
                            </button>
                            <button onClick={() => history.push("/principal/ultimaActividad")}>
                              <IonIcon class="bigger-hover"  src={ActivUsers} />
                            </button>
                            <button onClick={() => setActualPage("nuevoUsuario")}>
                              <IonIcon class="bigger-hover" src={NuevoUser} />
                            </button>
                          </IonCol>
                        </IonRow>
                        : null}

                      {mostrarContenido(actualPage)}

                    </IonGrid>
                  </IonContent>
                </IonPage>
                :
                <IonPage>
                  <HeaderEmpty avatarProp={userAvatar} actualPage={actualPage} />
                  <IonGrid className="px-4 container d-flex justify-content-center align-items-center">
                    <IonRow className="">
                      <IonCol size-xs="2" size-md='4' />
                      <IonCol size-md='4' className="text-center  justify-content-center">
                        <img src={noGroupLogo} />
                        <IonImg src={noGroupIcons} />
                        <h5>¡Bienvenido a ComuniteCAA!</h5>
                        <p className='body1'>Empieza creando un usuario de comunicación</p>
                        <p className='body1'>A este usuario le podrás asignar objetivos, y crear actividades y recursos que te ayuden a alcanzarlos. Además podrás invitar a otras personas a unirse al grupo para interactuar con ellas. 
                        </p>
                        <IonButton className="saveBtn my-4" onClick={e => setActualPage("nuevoUsuario")}>Crear usuario</IonButton>
                      </IonCol>
                      <IonCol size-xs="2" size-md='4' />
                    </IonRow>

                  </IonGrid>
                </IonPage>}

            </>
            :
            <>
              {checkWindow()}
              <IonPage>
                <HeaderEmpty avatarProp={userAvatar} actualPage={actualPage} />
                {showAlert
                    ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
                    : null}
                <IonContent id="principal-page" fullscreen className={`fullSize pag-principal ${actualPage == 'todo' && 'home-main-page' }  ${actualPage == 'ultimaActividad' || actualPage == 'nuevoUsuario'  ? 'canvi-bg-mvl bg-gris-clar' : null}`}>
                  <IonGrid className="px-4 container">
                    {actualPage === "todo" ?
                      <IonRow >
                        <IonCol size="12" className="iconPrincipal icono">
                          {window.screen.width <= 500 ?
                            <a href='/biblioteca/actividades' className='handCursor'><button >
                              <IonIcon class="bigger-hover" src={BibliotecaLogo} />
                            </button></a>
                            : <a href='/biblioteca'><button >
                              <IonIcon  class="bigger-hover" src={BibliotecaLogo} />
                            </button></a>
                          }

                          <a href='/principal/ultimaactividad' className='handCursor'><button >
                              <IonIcon class="bigger-hover" src={ActivUsers} />
                            </button></a>
                          <button onClick={() => setActualPage("nuevoUsuario")}>
                            <IonIcon class="bigger-hover" src={NuevoUser} />
                          </button>
                        </IonCol>
                      </IonRow>
                      : null}
                    {mostrarContenido(actualPage)}

                  </IonGrid>
                </IonContent>
              </IonPage>
            </>}</>
          : null}

      </>
      : <>{refresh()}</>}
    </>
  );


};

export default Principal;



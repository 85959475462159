import { IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, IonButton, IonPopover, IonAvatar, IonImg, IonSkeletonText, useIonViewWillEnter, useIonAlert } from '@ionic/react';
import  './Header.css';

import arrowDown from '../img/iconos/arrow-down.svg';
import noPhotoAvatar from '../img/iconos/noPhotoHeader_avatar.svg';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router';
import NotificationPopUp from './PopUpModals/NotificationPopUp';
import ModifyProfilePopUp from './PopUpModals/ModifyProfilePopUp';
import logoGroups from '../img/iconos/logo_groups.svg';
import AvisoPopUp from './PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { GlobalVariables } from '../hooks/GlobalVariables';


interface ContainerProps {
  avatarProp?: string,
  actualPage?: string
}

const HeaderEmpty: React.FC<ContainerProps> = ({avatarProp, actualPage}) => {
  const {urlMedia} = GlobalVariables();
  //REDUX 
  const photo = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const photo = window.localStorage.getItem("userPhoto");

  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  // const [checkNotificaciones] = useIonAlert();
  const [ notification, setNotification ] = useState(false);
  const [ roles, setRoles ] = useState(false);
  const [ modifyProfile, setModifyProfile ] = useState(false);
  const [lastAction, setLastAction] = useState("")
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState<string>('');


  let history = useHistory();


  const clickModificar = () => {
    setShowPopover({ showPopover: false, event: undefined });

    if(roles == true ||  notification == true){
      setRoles(false);
      setNotification(false);
    }
    setModifyProfile(!modifyProfile);
  }

  const changeNotificaciones = () => {
    setShowPopover({ showPopover: false, event: undefined });
    if(modifyProfile == true ||  roles == true){
      setModifyProfile(false);
      setRoles(false);
    }
    setNotification(!notification);
  }
  const [checkCerrar] = useIonAlert();

  const clickCerrar = () => {
    setShowPopover({ showPopover: false, event: undefined });
      checkCerrar({
        // header: `Cerrar sesión`,
        message: `<h3 style="color:black">Cerrar sesión</h3>Los usuarios, los contenidos compartidos y las conversaciones dejarán de estar accesibles. Para poderlos visualizar de nuevo tendrás que acceder con la misma cuenta.`,
        buttons: [
          'CANCELAR', { text: 'CERRAR SESIÓN', handler: () => logout() },
        ],
        onDidDismiss: () => console.log(""),
      })
  }

  const logout = () => {
    Cookies.remove('token');
     // eslint-disable-next-line no-restricted-globals
     location.reload();
  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  return(
    <>
    <IonHeader >
    <IonPopover cssClass='popoveCustom'
      // cssClass='my-custom-class'
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
    >
      <a className='popup-select'><p className="body1" onClick={() => clickModificar()}>Modificar mi perfil</p></a>
      <a className='popup-select'><p className="body1" onClick={() => changeNotificaciones()}>Notificaciones</p></a>
      <a className='popup-select'><p className="body1" onClick={() => clickCerrar()}>Cerrar sesión</p></a>
    </IonPopover>


    
    {showAlert
        ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
        : null}
      <IonToolbar className="d-flex header">
        <IonButtons>
          <IonButton href="/home" >
            <IonTitle slot="start" >
            </IonTitle>
          </IonButton>

        </IonButtons>
        {Cookies.get('token')
        ? <IonButtons slot="end">
          {actualPage != 'todo'
            ? <IonButton href="/principal" onClick={e => Cookies.remove('group_id')}>
                <IonImg className="muyGrande" src={logoGroups}></IonImg>
              </IonButton>

            : null}


          <IonButton onClick={
            (e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e })
            }}>
              <IonAvatar>
                {/* {setVisible()} */}
                {/* <IonSkeletonText id="skeletonHeader"  animated className={`skeleton avatar m-0`}/> */}
                {photo != ""
                  ? <IonImg alt="Avatar Icon"  className="photo avatar" src={""+urlMedia+photo}></IonImg>
                  : <IonImg  className="photo " src={noPhotoAvatar}></IonImg>}
              </IonAvatar>
              <IonIcon className="mx-3 small" src={arrowDown}></IonIcon>
          </IonButton>
          
        </IonButtons>
      : null}
      </IonToolbar>
    </IonHeader>
    {notification ? <NotificationPopUp setNotification={setNotification} setLastAction={setLastAction} lastAction={lastAction}></NotificationPopUp> : null}
    {modifyProfile ? <ModifyProfilePopUp setModifyProfile={setModifyProfile} setLastAction={setLastAction} lastAction={lastAction} setTextAlert={setTextAlert} setShowAlert={setShowAlert}></ModifyProfilePopUp> : null}
    </>
  );

};
export default HeaderEmpty;
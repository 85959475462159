import { IonRow, IonCol, IonItem, IonInput, IonIcon, IonButton, IonImg } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import $ from 'jquery';


import remove_img from '../../img/iconos/remove_img.svg';

import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {setPreview} from '../../features/resource/resourceSlice'
import { GlobalVariables } from "../../hooks/GlobalVariables";

interface ContainerProps {
  setFile: any, 
  myFile: File, 
  selectedType: string,
  valor: string,
  setValor: any,
  removeMedia: any,
  setIsMediaSelected: any,
  selectedFileName? :string
} 

const DetalleTipoRecursoComp: React.FC<ContainerProps> = ({ setFile, myFile,  selectedType, removeMedia, setValor, valor, setIsMediaSelected, selectedFileName }) => {
  const dispatch = useAppDispatch();
  const preview = useAppSelector(state => state.reducer.resource.preview);


  const fileInput = useRef(null);
  const videoInput = useRef(null);
  const pdfInput = useRef(null);
  const audioInput = useRef(null);

  useEffect(() => {
    if (!myFile) {
        dispatch(setPreview(undefined));
        return
    }

    if (!selectedFileName) {
      const objectUrl = URL.createObjectURL(myFile);
      dispatch(setPreview(objectUrl));

      return () => URL.revokeObjectURL(objectUrl)
    } 
    
  }, [myFile])

  
  const onSelectFileImg = (e: any) => {
    setIsMediaSelected(true);
    
    if (!e.target.files || e.target.files.length === 0) {
        setFile(undefined);
        return
    }

    if (e.target.files[0].size > 100000000){
      setFile(undefined);
      $('.aviso-detalle-grande').removeClass('hidden');
    } else {
      setFile(e.target.files[0]);
      $('.aviso-detalle-grande').addClass('hidden');
    }
  }
  const onSelectFileVideo = (e: any) => {
    setIsMediaSelected(true);
    setFile(undefined);
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined);
        return
    }

    if (e.target.files[0].size > 100000000){
      setFile(undefined);
      $('.aviso-detalle-grande').removeClass('hidden');
    } else {
      setFile(e.target.files[0]);
      $('.aviso-detalle-grande').addClass('hidden');
    }
  }
  const onSelectFilePdf = (e: any) => {
    setIsMediaSelected(true);

    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined);

        return
    }

    if (e.target.files[0].size > 100000000){
      setFile(undefined);
      $('.aviso-detalle-grande').removeClass('hidden');
    } else {
      setFile(e.target.files[0]);
      $('.aviso-detalle-grande').addClass('hidden');
    }
  }
  const onSelectFileAudio = (e: any) => {
    setIsMediaSelected(true);
    if (!e.target.files || e.target.files.length === 0) {
    setFile(undefined);
      return
    }

    if (e.target.files[0].size > 100000000){
      setFile(undefined);
      $('.aviso-detalle-grande').removeClass('hidden');
    } else {
      setFile(e.target.files[0]);
      $('.aviso-detalle-grande').addClass('hidden');
    }
  }

  const {urlMedia} = GlobalVariables();
  const detalle = () => {
    switch (selectedType) {
      case "link":
        return (
          <IonRow>
            <IonCol size="12">
              <p>Url del enlace</p>
              <IonItem>
                <IonInput value={valor} onIonChange={e => <> {e.detail.value != undefined ? setValor(e.detail.value?.toString()) : null}</>}></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        )
      case "video":
        return (
          <IonRow>
            {myFile &&
              <div className="col-12 d-flex justify-content-center" style={{'position':'relative'}}>
                <video id="video" controls className="preview-image mt-3">
                  <source src={preview} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <div className="edit-remove-container">
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div> 
            }

            {selectedFileName &&
              <div className="col-12 d-flex justify-content-center" style={{'position':'relative'}}>
                <video id="video" controls className="preview-image mt-3">
                  <source src={`${urlMedia}${selectedFileName}`} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video>
                <div className="edit-remove-container">
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div> 
            }

            {(!myFile && !selectedFileName) &&
              <>
                <IonCol size="12">
                    <p>Url del video</p>
                    <IonItem>
                      <IonInput value={valor} onIonChange={e => <> {e.detail.value != undefined ? setValor(e.detail.value?.toString()) : null}</>}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12">

                    <IonButton className="btn-recursos" onClick={() => {
                      // @ts-ignore
                      videoInput?.current?.click();
                    }}><p>SUBIR VIDEO</p> </IonButton>
                  </IonCol>
              </>

            }
          </IonRow>
        )
      case "pdf":
        return (
          <IonRow>
            {myFile &&
              <div className="col-12 d-flex justify-content-center align-items-center" style={{'position':'relative'}}>
                <embed src={preview} width="500" height="375" type="application/pdf" />
                <div className="edit-remove-container" >
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div> 
            }

            {selectedFileName &&
              <div className="col-12 d-flex justify-content-center align-items-center" style={{'position':'relative'}}>
                <embed src={`${urlMedia}${selectedFileName}`} width="500" height="375" type="application/pdf" />
                <div className="edit-remove-container" >
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div> 
            }

            {(!myFile && !selectedFileName) &&
              <IonButton className="btn-recursos" onClick={() => {
                // @ts-ignore
                pdfInput?.current?.click();
              }}><p>SUBIR PDF</p> </IonButton>
            }




            {/* {myFile
              ? 
              <div className="col-12 d-flex justify-content-center align-items-center" style={{'position':'relative'}}>
                  <embed src={preview} width="500" height="375" type="application/pdf" />
                  <div className="edit-remove-container" >
                    <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                  </div>
                </div>              
              : <>  
                <IonButton className="imagenBtn" onClick={() => {
                  // @ts-ignore
                  pdfInput?.current?.click();
                }}><p>SUBIR PDF</p> </IonButton></>
            } */}
          </IonRow>
        )
      case "audio":
        return (
          <IonRow>
            {/* {selectedFileAudio
              ? 
              <div className="col-12 d-flex justify-content-center align-items-center align-self-center" style={{'position':'relative'}}>
                  <audio controls >
                  <source src={preview} type="audio/mp3"></source>
                  <source src={preview} type="audio/ogg"></source>
                  Your browser does not support the audio element.
                </audio>
                  <div className="edit-remove-container h-100" style={{'position':'inherit'}}>
                    <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                  </div>
                </div>
              : <>  
                <IonButton className="imagenBtn" onClick={() => {
                  // @ts-ignore
                  audioInput?.current?.click();
                }}><p>SUBIR AUDIO</p> </IonButton></>
            } */}
            {myFile &&
              <div className="col-12 d-flex justify-content-center align-items-center align-self-center" style={{'position':'relative'}}>
                <audio controls >
                  <source src={preview} type="audio/mp3"></source>
                  <source src={preview} type="audio/ogg"></source>
                  Your browser does not support the audio element.
                </audio>
                <div className="edit-remove-container h-100" style={{'position':'inherit'}}>
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div>
            }

            {selectedFileName &&
              <div className="col-12 d-flex justify-content-center align-items-center align-self-center" style={{'position':'relative'}}>
                <audio controls >
                  <source src={`${urlMedia}${selectedFileName}`} type="audio/mp3"></source>
                  <source src={`${urlMedia}${selectedFileName}`} type="audio/ogg"></source>
                  Your browser does not support the audio element.
                </audio>
                <div className="edit-remove-container h-100" style={{'position':'inherit'}}>
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </div>
            }

            {(!myFile && !selectedFileName) &&
              <IonButton className="btn-recursos" onClick={() => {
                // @ts-ignore
                audioInput?.current?.click();
              }}><p>SUBIR AUDIO</p> </IonButton>
            }
          </IonRow>
        )

      default: // imagen
        return (
          <>
            <IonRow>
              <IonCol offset="2" size="8" className="d-flex justify-content-center">
                {myFile &&
                  <> 
                    <IonImg src={preview} className="preview-image"/>
                    <div className="edit-remove-container">
                      <IonIcon onClick={e => removeMedia()} className="grande handCursor" src={remove_img} />
                    </div>
                  </> 
                }
                {selectedFileName &&
                  <> 
                    <IonImg src={`${urlMedia}${selectedFileName}`} className="preview-image"/>
                    <div className="edit-remove-container">
                      <IonIcon onClick={e => removeMedia()} className="grande handCursor" src={remove_img} />
                    </div>
                  </> 
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {(!myFile && !selectedFileName) && 
                  <IonButton className="btn-recursos" onClick={() => {
                    // @ts-ignore
                    fileInput?.current?.click();
                  }}><p className="">SUBIR IMAGEN</p> </IonButton>
                }
              </IonCol>
            </IonRow>

          </>
        )
    }
  }
  return (
    <>
      <input id="imageInput" ref={fileInput} hidden type="file" accept="image/*" onChange={onSelectFileImg} />
      <input id="videoInput" ref={videoInput} hidden type="file" accept="video/mp4" onChange={onSelectFileVideo}/>
      <input id="pdfInput" ref={pdfInput} hidden type="file" accept="application/pdf" onChange={onSelectFilePdf}/>
      <input id="audioInput" ref={audioInput} hidden type="file" accept="audio/*" onChange={onSelectFileAudio}/>

      {detalle()}
    </>
  );

};
export default DetalleTipoRecursoComp;
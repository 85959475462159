import { IonRow, IonCol, IonItem, IonInput, IonButton, IonGrid, IonIcon, IonTextarea, IonRadioGroup, IonLabel, IonRadio, IonCheckbox, IonLoading, IonImg } from "@ionic/react";
import Cookies from "js-cookie";
import { useHistory } from "react-router";

import TituloRecurso from "../TituloRecurso";

/* ICONOS */
import imgColor from '../../img/iconos/img_color.svg';
import vidColor from '../../img/iconos/vid_color.svg';
import linkColor from '../../img/iconos/link_color.svg';
import pdfColor from '../../img/iconos/pdf_color.svg';
import audioColor from '../../img/iconos/audio_color.svg';
import imgBW from '../../img/iconos/img_bw.svg';
import vidBW from '../../img/iconos/vid_bw.svg';
import linkBW from '../../img/iconos/link_bw.svg';
import pdfBW from '../../img/iconos/pdf_bw.svg';
import audioBW from '../../img/iconos/audio_bw.svg';
import { useEffect, useRef, useState } from "react";
import { getData } from "../../api/apiRecursos";
import $ from 'jquery';
import star from '../../img/iconos/star.svg';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";

import edit_img from '../../img/iconos/edit_img.svg';
import remove_img from '../../img/iconos/remove_img.svg';
import DetalleTipoRecursoComp from "../helpers/DetalleTipoRecursoComp";

import {useAppDispatch, useAppSelector} from '../../app/hooks'


import {setSelectedFileImg, setSelectedFileVideo, setSelectedFilePdf, setSelectedFileAudio} from '../../features/resource/resourceSlice'


interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  setShowAlert: any,
  setTextAlert: any,
}

const RecursoNueva: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setActualPage, setLastAction }) => {
  const dispatch = useAppDispatch();
  const { crearRecurso, goBack, publicarRecurso } = GlobalFunctions();
  const [selectedType, setSelected] = useState("imagen");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [estado, setEstado] = useState(0);
  const [destacado, setDestacado] = useState(false);
  const [valor, setValor] = useState("");
  const [loadingRecurso, setLoadingRecurso] = useState(false);

  const [isMediaSelected, setIsMediaSelected] = useState(false);   

  const [myFile, setFile] = useState<File>();


  const publicarYCrear = () => {
    
    const formData = createFormData();

    if ((selectedType == 'imagen' && !myFile) 
    || (selectedType == 'video' && (!myFile && valor == ""))
    || (selectedType == 'pdf' && !myFile)
    || (selectedType == 'audio' && !myFile)
    ||  (selectedType == "link" && valor == "")) {
      $('.aviso-naranja.aviso-detalle').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-detalle').addClass('hidden');
    }


    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }
    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }
    if (nombre != "" && descripcion != "" 
    && (
      (selectedType == 'imagen' && myFile) 
      || (selectedType == 'video' && (myFile || valor != ""))
      || (selectedType == 'pdf' && myFile)
      || (selectedType == 'audio' && myFile)
      ||  (selectedType == "link" && valor != "")
    )) 
    {
      setLoadingRecurso(true);
      publicarRecurso("crear", formData, setLastAction, setActualPage, setTextAlert, setShowAlert,undefined, setLoadingRecurso);
    } 
  }

  const createRecurso = () => {
    const formData = createFormData();

    if ((selectedType == 'imagen' && !myFile) 
    || (selectedType == 'video' && (!myFile && valor == ""))
    || (selectedType == 'pdf' && !myFile)
    || (selectedType == 'audio' && !myFile)
    ||  (selectedType == "link" && valor == "")) {
      $('.aviso-naranja.aviso-detalle').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-detalle').addClass('hidden');
    }

    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }
    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }

    if (nombre != "" && descripcion != "" 
    && (
        (selectedType == 'imagen' && myFile) 
        || (selectedType == 'video' && (myFile || valor != ""))
        || (selectedType == 'pdf' && myFile)
        || (selectedType == 'audio' && myFile)
        ||  (selectedType == "link" && valor != "")
      )) 
    {
      setLoadingRecurso(true);
      crearRecurso('crearRecurso', formData, setActualPage, setShowAlert, setTextAlert, undefined, setLastAction, setLoadingRecurso);
    } else {
    }


  }

  const removeMedia = () => {
    setIsMediaSelected(false);

    $('#videoInput').val('');
    $('#imageInput').val('');
    $('#pdfInput').val('');
    $('#audioInput').val('');

    dispatch(setSelectedFileImg(undefined));
    dispatch(setSelectedFileVideo(undefined));
    dispatch(setSelectedFilePdf(undefined));
    dispatch(setSelectedFileAudio(undefined));
    setFile(undefined);
  }

  const createFormData = () => {
    const formData = new FormData();
    if (selectedType != 'link') {
      formData.append('file', myFile!);
      formData.append('type', selectedType);
    }


    formData.append("token", Cookies.get('token')!);
    formData.append("isTemplate", `no`);
    formData.append("group_id", Cookies.get('group_id')?.toString()!);
    formData.append("name", nombre);
    formData.append("value", valor);
    formData.append("description", descripcion);
    formData.append("type", selectedType);
    formData.append("status", estado.toString());
    formData.append("important", `${destacado ? "1" : "0"}`);

    return formData;
  }


  return (
    <IonGrid className="container">

      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      <IonRow>
        <TituloRecurso titulo="Crear recurso" />
      </IonRow>

      <IonGrid className="form">
        
        <IonLoading
          isOpen={loadingRecurso}
          onDidDismiss={() => setLoadingRecurso(false)}
          message={'Creando recurso'}
          spinner={'bubbles'}
          duration={50000}
        />
        <IonRow>
          <IonCol size="12">
            <p>Tipo de Recurso</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <button disabled={isMediaSelected ? true : false} onClick={() => setSelected("imagen")} >
              {selectedType === "imagen"
                ? <IonIcon src={imgColor} className="muyGrande" />
                : <IonIcon src={imgBW} className="muyGrande" />}
              <p className="body">Imagen</p>
            </button>
            <button disabled={isMediaSelected ? true : false} onClick={() => setSelected("video")} >
              {selectedType === "video"
                ? <IonIcon src={vidColor} className="muyGrande" />
                : <IonIcon src={vidBW} className="muyGrande" />}
              <p className="body">Vídeo</p>
            </button>
            <button disabled={isMediaSelected ? true : false} onClick={() => setSelected("link")}>
              {selectedType === "link"
                ? <IonIcon src={linkColor} className="muyGrande" />
                : <IonIcon src={linkBW} className="muyGrande" />}
              <p className="body">Enlace</p>
            </button>
            <button disabled={isMediaSelected ? true : false} onClick={() => setSelected("pdf")}>
              {selectedType === "pdf"
                ? <IonIcon src={pdfColor} className="muyGrande" />
                : <IonIcon src={pdfBW} className="muyGrande" />}
              <p className="body">PDF</p>
            </button>
            <button disabled={isMediaSelected ? true : false} onClick={() => setSelected("audio")}>
              {selectedType === "audio"
                ? <IonIcon src={audioColor} className="muyGrande" />
                : <IonIcon src={audioBW} className="muyGrande" />}
              <p className="body">Audio</p>
            </button>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <p>Nombre del recurso</p>
            <IonItem>
              <IonInput maxlength={70} type="text" value={nombre} onIonChange={e => <> {e.detail.value != undefined ? setNombre(e.detail.value?.toString()) : null}</>}></IonInput>
            </IonItem>
            <p className="aviso-naranja aviso-nombre small hidden"> * Campo obligatorio</p>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <p>Descripción</p>
              <IonTextarea className="big-textarea" value={descripcion} onIonChange={e => <> {e.detail.value != undefined ? setDescripcion(e.detail.value?.toString()) : null}</>}></IonTextarea>
            <p className="aviso-naranja aviso-descripcion small hidden"> * Campo obligatorio</p>
          </IonCol>
        </IonRow>
        <DetalleTipoRecursoComp setFile={setFile} myFile={myFile!} selectedType={selectedType} valor={valor} setValor= {setValor} removeMedia={removeMedia} setIsMediaSelected={setIsMediaSelected} />
        <p className="aviso-naranja aviso-detalle small hidden"> * Campo obligatorio</p>
        <p className="aviso-naranja aviso-detalle-grande small hidden">El archivo seleccionado es demasiado grande. Seleciona uno de máximo 100MB</p>

        <IonRow>
          <IonCol size="12">
            <p>Estado</p>
            <IonRadioGroup className="radioGroup" value={estado} onIonChange={e => { setEstado(e.detail.value); if (e.detail.value == '1') { setDestacado(true) } }}>
              <IonItem>
                <IonLabel>Activo</IonLabel>
                <IonRadio mode="md" slot="start" value={1}></IonRadio>
              </IonItem>
              <IonItem>
                <IonLabel>Inactivo<p className="body2 text-gris desktop">No se mostrará a los acompañantes con rol "Colaborador"</p></IonLabel>
                <IonRadio mode="md" slot="start" value={0}></IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonCol>
        </IonRow>
        <IonRow className="mt-3">
          <IonCol size="12">
            <IonItem className="destacadoCheck">
              <IonLabel>Destacado <IonIcon src={star} /> <br /><p className="body2 text-gris desktop">Se visualizará en el menú lateral. </p></IonLabel>
              <IonCheckbox slot="start"
                disabled={estado != 0 ? false : true}
                checked={estado != 0 ? destacado : false}
                onIonChange={e => setDestacado(e.detail.checked)} />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" className="lineal-flex">
            <IonButton onClick={() => goBack('recurso', setActualPage)} className="cancelBtn"><p>CANCELAR</p></IonButton>
            <IonButton className="saveBtn" onClick={() => createRecurso()}><p className="">CREAR RECURSO</p></IonButton>
          </IonCol>
          <IonCol size="12" className="">
            <IonButton className="timelineBtn" disabled={estado == 0 ? true : false} onClick={() => publicarYCrear()}><p className='px-5'>Crear y publicar en el timeline</p></IonButton>
          </IonCol>
        </IonRow>

      </IonGrid>


    </IonGrid>
  );
};

export default RecursoNueva;



import { IonButton, IonCard, IonCheckbox, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import { User } from "../../interfaces/intUser";
import { getData } from "../../api/apiRecursos";
import logoComuniteCaa from '../../img/iconos/logoComuniteCAA.svg';
import logoOrange from '../../img/iconos/logoOrange.png';
import AvisoPopUp from "../PopUpModals/AvisoPopUp";
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import { OneSignalFunctions } from "../../hooks/OneSignalFunctions";

//REDUX
import { useAppDispatch } from '../../app/hooks'
import { setUser as setUserSlice } from '../../features/users/userSlice';
import { fail } from "assert";


interface Props {
  setActualPage: any;
}

const LoginPage: React.FC<Props> = ({ setActualPage }) => {
  //REDUX 
  const dispatch = useAppDispatch();

  const [user, setUser] = useState<User>();
  const [mail, setMail] = useState<string>();
  const [contraseña, setContraseña] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [acceptSubmit, setAcceptSubmit] = useState(false);
  const [showTextRed, setShowTextRed] = useState(false);
  const [showTextActivateRed, setShowTextActivateRed] = useState(false);
  const axios = require('axios');
  const {isEmail} = GlobalFunctions();
  const {storeUserInfo, loginUser} = OneSignalFunctions();


  let history = useHistory();
  const getUserData = async (token: string) => {
    const userData = {
      'token': token
    }
    await getData.post<User>('/userDetail', userData)
      .then((res: any) => {
        dispatch(setUserSlice(res.data));
        setUser(res.data);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const tryLogin = (mail: string, contraseña: string) => {
    const loginData = {
      email: mail,
      password: contraseña,
    };



    getData.post("/login", loginData)
      .then((res: any) => {
        if (res.status === 200) {
          getUserData(res.data.token);
          Cookies.set('token', res.data.token, { sameSite: 'strict' });
          storeUserInfo(mail, res.data.user_id);
          loginUser(res.data.user_id);

          history.push({
            pathname: "/principal/", state: { actualPage: 'home' }
          });
        }else if (res.status == 202) {
          setShowTextActivateRed(true);
          setShowTextRed(false);
        } else {
          setShowTextActivateRed(false);
          setShowTextRed(true);
        }
      })
      .catch((error: any) => {
      });
  }



  const checkSubmit = (email?: string, password: string = "") => {
    /***********************       ************************/

    if (email) {
      if ((contraseña != "") && isEmail(email!)){
        setAcceptSubmit(true);
      } else {
        setAcceptSubmit(false);
      }
    } else if (password != "") {
      if (isEmail(mail!)) {
        setAcceptSubmit(true);
      } else {
        setAcceptSubmit(false);
      }
    } else {
      setAcceptSubmit(false);
    }
  }
  const descriptionText = "ComuniteCAA facilita la coordinación y organización entre familiares, profesionales y todo el entorno que rodea a una persona con TEA, con el fin de poner al usuario en el centro y mejorar su comunicación."
  return (
    <>
      <IonGrid className="container desktop loginPage">
        <IonRow className="mt-5">
          <IonCol size-lg="6" size-md="6" className="mt-5 p-5">
            <div className="text-center" style={{width: 'fit-content'}}>
              <img className="logo-comunitecaa" src={logoComuniteCaa} />
              <h2 className="no-wrap-text">COMUNITE<b>CAA</b></h2>
            </div>
            <h4 className="my-5">{descriptionText}</h4>
            <img src={logoOrange} />

          </IonCol>
          <IonCol size-lg="6" size-md="6" className="p-5 recuadroLogin d-flex align-content-center justify-content-center">
            <form className="w-100" >
              <IonRow>
                <IonCol size="12" className="px-0">
                  <IonItem>
                  <IonInput required={true} type="email" placeholder="Correo electrónico" onIonChange={e => {setMail(e.detail.value?.toString()!);checkSubmit(e.detail.value?.toString()!)}}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12" className="px-0">
                  <IonItem>
                    <IonInput type={showPassword ? "text" : "password"} required={true} placeholder="Contraseña" onIonChange={e => {setContraseña(e.detail.value?.toString()!); checkSubmit(undefined, e.detail.value?.toString())}}></IonInput>
                    <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} className="handCursor" onClick={e => setShowPassword(!showPassword)}></IonIcon>
                  </IonItem>

                </IonCol>

              </IonRow>


              <IonRow className="d-flex justify-content-center login-row">
                <IonCol className="mb-4 px-0" size="12">
                  {
                    showTextActivateRed ? 
                    <p style={{'color':'red'}}>* El correo electrónico todavía no ha sido activado.</p>
                    :null
                  }
                  {showTextRed ? 
                   <p style={{'color':'red'}}>* El correo electrónico/contraseña no coincide o el usuario no existe. Vuelve a intentarlo</p>
                   :null
                  }

                  <IonButton  disabled={acceptSubmit? false : true} className="btn-enabled-principal login-button " onClick={(e) => tryLogin(mail!, contraseña!)} ><p className="">Entrar</p></IonButton>
                 
                </IonCol>
                <IonCol size="12">

                  <a onClick={e => setActualPage("recuperaContraseña")}><p className="body2 text-naranja text-center">¿Has olvidado tu contraseña?</p></a>
                </IonCol>
                <IonCol size="12">
                </IonCol>
              </IonRow>
              <hr />

              <IonRow>
                <IonCol className="d-flex justify-content-center">
                  <IonButton  className="btn-enabled-principal register-button mb-4" onClick={e => setActualPage("register")}><p className="okButton">Crear cuenta nueva</p></IonButton>
                </IonCol>
              </IonRow>
            </form>

          </IonCol>
         
        </IonRow>
        

      </IonGrid>

      <IonGrid className="px-4 loginPageMvl container mvl text-center">
        <img className="logoComunite" src={logoComuniteCaa} />
        <h2 style={{fontSize: "4rem"}}>COMUNITE<b>CAA</b></h2>
        <p>{descriptionText}</p>
        <IonRow>
          <IonCol size-lg="6" size-md="6" className="my-5 p-5 recuadroLogin d-flex align-content-center justify-content-center">
          <form className="w-100" onSubmit={(e) => tryLogin(mail!, contraseña!)}>
              <IonRow>
                <IonCol size="12" className="px-0">
                  <IonItem>
                  <IonInput required={true}  type="email" placeholder="Correo electrónico" onIonChange={e => setMail(e.detail.value?.toString()!)}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12" className="px-0">
                  <IonItem>
                    <IonInput  type={showPassword ? "text" : "password"} required={true} placeholder="Contraseña" onIonChange={e => setContraseña(e.detail.value?.toString()!)}></IonInput>
                    <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} className="handCursor" onClick={e => setShowPassword(!showPassword)}></IonIcon>
                  </IonItem>

                </IonCol>

              </IonRow>

              <IonRow className=" justify-content-center login-row">
                {
                  showTextActivateRed ?
                  <p style={{'color':'red'}}>* El correo electrónico todavía no ha sido activado.</p>
                  :null
                }
                {showTextRed ?
                  <p style={{'color':'red'}}>* El correo electrónico/contraseña no coincide o el usuario no existe. Vuelve a intentarlo</p>
                  :null
                }
                <IonButton className="btn-enabled-principal login-button mb-4" onClick={(e) => tryLogin(mail!, contraseña!)}>
                  <p style={{fontSize: '2rem'}}>Entrar</p>
                </IonButton>

                <br></br><a onClick={e => setActualPage("recuperaContraseña")}><p className="body2 text-naranja text-center">¿Has olvidado tu contraseña?</p></a>
              </IonRow>
              <hr />

              <IonRow>
                <IonCol className="d-flex justify-content-center px-0">
                  <IonButton type="submit" className="btn-enabled-principal register-button mb-4" onClick={e => setActualPage("register")}>
                    <p className="okButton" style={{fontSize: '2rem'}}>Crear cuenta nueva</p>
                    </IonButton>
                </IonCol>
              </IonRow>
            </form>

          </IonCol>
        </IonRow>
        <img className="logoOrange" src={logoOrange} />
      </IonGrid>
    </>
  );
}

export default LoginPage;



import { IonGrid, IonRow, IonCol, IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';


/* CSS */
import '../theme/global.css';

/* ICONOS */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { User } from '../interfaces/intUser';
import MenuLateralBiblio from '../components/Biblioteca/MenuLateralBiblio';
import BiblObjetivo from '../components/Biblioteca/BiblObjetivo';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'

interface Props {
  page?:string,
}
const MvlBiblioObjetivo: React.FC<Props> = ({page}) => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);

  const [actualPage, setActualPage] = useState("biblObjetivo");
  const [showAlertMvl, setShowAlertMvl] = useState(false);
  const [textAlertMvl, setTextAlertMvl ] = useState("");

  
    var tabsBiblio = Array.from(document.getElementsByClassName('tab-biblio') as HTMLCollectionOf<HTMLElement>);
    var tabsApp = Array.from(document.getElementsByClassName('tab-app') as HTMLCollectionOf<HTMLElement>);

    var i;
    for (i = 0; i < tabsBiblio.length; i++) {
      tabsBiblio[i].setAttribute('style', 'display: block !important');
    }
    for (i = 0; i < tabsApp.length; i++) {
      tabsApp[i].setAttribute('style', 'display: none !important');
    }  


  const [selectedId, setSelectedId] = useState<number>();



  useIonViewWillEnter(() => {
      setActualPage('biblObjetivo');
  });

  

  
  function mostrarContenido() {
    switch (actualPage) {
      case "biblObjetivo":
        return <BiblObjetivo setShowAlertMvl={setShowAlertMvl} setTextAlertMvl={setTextAlertMvl} setActualPage={setActualPage} setSelectedId={setSelectedId} />
      default:
        break;
    }
  }

  const userData = {
    'token': Cookies.get('token')
  }

  useEffect(() => {
    // setUserAvatar(window.localStorage.getItem('userPhoto')!);


  }, [])

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlertMvl(false);
    }, 4000);
  }

  return (
    <IonPage>
      <HeaderEmpty avatarProp={userAvatar} />
      {showAlertMvl
          ? <>{showPopUp()} <AvisoPopUp text={textAlertMvl}/></>
          : null}

      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="3" sizeMd="4" className="left desktop">
              <MenuLateralBiblio setActualPage={setActualPage} actualPage={actualPage} />
            </IonCol>
            <IonCol className="right">
              {mostrarContenido()}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MvlBiblioObjetivo;
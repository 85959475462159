import { IonGrid, IonRow, IonCol, IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';

/* COMPONENTES */
import Header from '../components/Header';

/* CSS */
import '../theme/global.css';
import '../theme/responsive.css';

/* IMAGENES */
import Home from '../components/Home';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { getData } from '../api/apiRecursos';
import { Group } from '../interfaces/intGroups';
import { Role, User } from '../interfaces/intUser';
import Actividades from '../components/Actividades/Actividades';
import ActividadDetalle from '../components/Actividades/ActividadDetalle';
import NuevaActividad from '../components/Actividades/ActividadNueva';
import { useHistory } from 'react-router';
import ActividadModificar from '../components/Actividades/ActividadModificar';
import ActividadesObjetivos from '../components/Actividades/ActividadesObjetivos';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';
import ActividadBiblio from '../components/Actividades/ActividadBiblio';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'



const ActivitatsMvl: React.FC = () => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const role = useAppSelector(state=>state.reducer.groupSelected.userRole);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);
  
  let history = useHistory();

  const [group, setGroup] = useState<Group>();
  const [lastAction, setLastAction] = useState("");
  const [showBack, setShowBack] = useState(false);



  useIonViewWillEnter(() => {
    setActualPage('actividades');
  });

  //@ts-ignore
  const [actualPage, setActualPage] = useState('actividades');
  const [selectedId, setSelectedId] = useState<number>();
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert ] = useState("");



  const userData = {
    'token': Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  useEffect(() => {
    if (!Cookies.get('token')) {
      history.push("/");
    }
    getData.get<Group>('/getGroups/' + Cookies.get('group_id'))
      .then(resp => {
        //@ts-ignore
        setGroup(resp.data);
      })
      .catch(err => console.log(err));

    // setUserAvatar(window.localStorage.getItem('userPhoto')!);


  }, [lastAction])

  const showContent = () => {

    switch (actualPage) {

      case "actividades":
        return <Actividades textAlert={textAlert} setTextAlert={setTextAlert} setShowBack={setShowBack} setShowAlert={setShowAlert} showAlert={showAlert} setSelectedId={setSelectedId} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case "activityDetail":
        return <ActividadDetalle showBack={showBack} setShowBack={setShowBack} setSelectedId={setSelectedId} selectedId={selectedId!} setActualPage={setActualPage} />
      case "activityNew":
        return <NuevaActividad setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case "activityModify":
        return <ActividadModificar setTextAlert={setTextAlert} setShowAlert={setShowAlert} selectedId={selectedId!} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case 'activObjetivosActivos':
        return <ActividadesObjetivos setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />
      case 'activityBiblio':
        return <ActividadBiblio setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />

      default:
        break;
    }

  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  return (
    <>
      {Cookies.get('group_id')
        ?
        <>
          <IonPage className="mvl">
          {showAlert
            ? <>{showPopUp()} <AvisoPopUp text={textAlert}/></>
            : null}
            <Header name={group?.name!} avatar={group?.media_name!} avatarProp={userAvatar} setLastAction={setLastAction} lastAction={lastAction} />

            <IonContent fullscreen>
            
              <IonGrid>
                <IonRow>
                  <IonCol className="right">
                    {showContent()}
                  </IonCol>
                </IonRow>
              </IonGrid>

            </IonContent>
          </IonPage>
        </>
        : history.push('/')}
    </>

  );
};

export default ActivitatsMvl;

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonDatetime, IonFooter, IonButtons, IonIcon, IonButton, IonPopover, IonGrid, IonRow, IonCol, IonToggle, IonList } from '@ionic/react';
import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { OneSignalFunctions } from '../../hooks/OneSignalFunctions';


interface Props {
  setNotification: any;
  setLastAction: any;
  lastAction: string;
}

const NotificationPopUp: React.FC<Props> = ({ setNotification, setLastAction, lastAction }) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [ notifAll, setNotifAll ] = useState(Boolean);
  const [ notifPubli, setNotifPubli ] = useState(Boolean);
  const [ notifReac, setNotifReac] = useState(Boolean);
  const [ notifComent, setNotifComent] = useState(Boolean);
  const [ notifGrupo, setNotifGrupo] = useState(Boolean);
  const {changeNotificationsStatus, changeNotificationPublicaciones, changeNotificationReacciones, changeNotificationComentarios, changeNotificationGrupo} = OneSignalFunctions();
  let history = useHistory();

  const changeNotification = (isChecked: boolean) => {
    changeNotificationsStatus(isChecked);
    setNotifAll(isChecked);
    if (isChecked) {
      Cookies.set('notif_all', 'true');
    } else {
      Cookies.set('notif_all', 'false');
    }
  }
  const changeNotificationPubli = (isChecked: boolean) => {
    changeNotificationPublicaciones(isChecked);
    setNotifPubli(isChecked);
    if (isChecked) {
      Cookies.set('notif_publi', 'true');
    } else {
      Cookies.set('notif_publi', 'false');
    }
  }
  const changeNotificationReacc = (isChecked: boolean) => {
    changeNotificationReacciones(isChecked);
    setNotifReac(isChecked);
    if (isChecked) {
      Cookies.set('notif_reac', 'true');
    } else {
      Cookies.set('notif_reac', 'false');
    }
  }
  const changeNotificationComent = (isChecked: boolean) => {
    changeNotificationComentarios(isChecked);
    setNotifComent(isChecked);
    if (isChecked) {
      Cookies.set('notif_coment', 'true');
    } else {
      Cookies.set('notif_coment', 'false');
    }
  }
  const changeNotificationGroup = (isChecked: boolean) => {
    changeNotificationGrupo(isChecked);
    setNotifGrupo(isChecked);
    if (isChecked) {
      Cookies.set('notif_grupo', 'true');
    } else {
      Cookies.set('notif_grupo', 'false');
    }
  }

  useEffect(() => {
    if (Cookies.get('notif_all') == 'true') {
      setNotifAll(true);
    } else {
      setNotifAll(false);
    }
    
    if (Cookies.get('notif_publi') == 'true') {
      setNotifPubli(true);
    } else {
      setNotifPubli(false);
    }

    if (Cookies.get('notif_reac') == 'true') {
      setNotifReac(true);
    } else {
      setNotifReac(false);
    }

    if (Cookies.get('notif_coment') == 'true') {
      setNotifComent(true);
    } else {
      setNotifComent(false);
    }

    if (Cookies.get('notif_grupo') == 'true') {
      setNotifGrupo(true);
    } else {
      setNotifGrupo(false);
    }
  }, []);
  

  return (
    <div className="modal modal_notificaciones">
      <div className="modal_content">
        <IonIcon src={close} className="close" onClick={e => setNotification(false)} />
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeXs='12'>
              <h5>Notificaciones</h5>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonLabel>TODAS</IonLabel>
                <IonToggle checked={notifAll} slot="start" name="notif_all" color="warning"  onIonChange={e => changeNotification(e.detail.checked)}></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          <hr/>
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonLabel>Nueva publicación</IonLabel>
                <IonToggle checked={notifPubli} slot="start" name="notif_publi" color="warning"  onIonChange={e => changeNotificationPubli(e.detail.checked)}></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonLabel>Me gusta (o reacciones a las publicaciones y a los comentarios)</IonLabel>
                <IonToggle checked={notifReac} slot="start" name="notif_reac" color="warning"  onIonChange={e => changeNotificationReacc(e.detail.checked)}></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonLabel>Comentarios</IonLabel>
                <IonToggle checked={notifComent} slot="start" name="notif_coment" color="warning"  onIonChange={e => changeNotificationComent(e.detail.checked)}></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          <hr/>
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonLabel>Cuando alguien te añade a un grupo</IonLabel>
                <IonToggle checked={notifGrupo} slot="start" name="notif_grupo" color="warning"  onIonChange={e => changeNotificationGroup(e.detail.checked)}></IonToggle>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <p className="body2">Te recomendamos que actives las notificaciones para mantenerte al tanto de todo lo que pasa en el grupo.</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );

};
export default NotificationPopUp;
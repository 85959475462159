import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Activity } from "../../interfaces/intActivity";
import { PostsMyGroups } from "../../interfaces/intPosts";
import { Resource } from "../../interfaces/intRecurso";

// Define a type for the slice state
interface ItemsBiblio {
  activities: Activity[],
  resources: Resource[],
  recursosOrdenados: Resource[],
  postsAllUsers: PostsMyGroups[]

}

const initialState: ItemsBiblio = {
  activities: [],
  resources: [],
  recursosOrdenados: [],
  postsAllUsers: []
}

// const initialState: User;

export const itemsBiblioSlice = createSlice({
  name: 'itemsBiblio',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setActivities: (state, action: PayloadAction<Activity[]>) => {
      state.activities = action.payload;
    }, 
    setResources: (state, action: PayloadAction<Resource[]>) => {
      state.resources = action.payload;
    }, 
    setPostsAll: (state, action: PayloadAction<PostsMyGroups[]>) => {
      state.postsAllUsers = action.payload;
    }, 
  },
});


export const { setActivities, setResources, setPostsAll } = itemsBiblioSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default itemsBiblioSlice.reducer
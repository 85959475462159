import { IonGrid, IonRow, IonCol, IonContent, IonPage, IonBackdrop, useIonViewWillEnter } from '@ionic/react';

/* COMPONENTES */
import MenuLateral from '../components/MenuLateral';
import Header from '../components/Header';

/* CSS */
import '../theme/global.css';
import '../theme/responsive.css';

/* IMAGENES */
import Home from '../components/Home';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import  $ from 'jquery';
import { Group } from '../interfaces/intGroups';
import { Role, User } from '../interfaces/intUser';
import Actividades from '../components/Actividades/Actividades';
import ActividadDetalle from '../components/Actividades/ActividadDetalle';
import NuevaActividad from '../components/Actividades/ActividadNueva';
import { useHistory, useLocation } from 'react-router-dom';
import ActividadModificar from '../components/Actividades/ActividadModificar';
import Objetivos from '../components/Objetivos/Objetivos';
import ObjetivoDetalle from '../components/Objetivos/ObjetivoDetalle';
import ObjetivoModificar from '../components/Objetivos/ObjetivoModificar';
import ObjetivoNuevo from '../components/Objetivos/ObjetivoNuevo';
import RecursoModificar from '../components/Recursos/RecursoModificar';
import RecursoNueva from '../components/Recursos/RecursoNueva';
import RecursoDetalle from '../components/Recursos/RecursoDetalle';
import Recursos from '../components/Recursos/Recursos';
import ActividadesObjetivos from '../components/Actividades/ActividadesObjetivos';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';
import ActividadBiblio from '../components/Actividades/ActividadBiblio';
import RecursosComunitecaa from '../components/Recursos/RecursosComunitecaa';
import RecursosBiblio from '../components/Recursos/RecursosBiblio';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setUserRole} from '../features/groups/groupSelectedSlice';
import { GlobalFunctions } from '../hooks/GlobalFunctions';

const Tab1: React.FC = () => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const groupAvatar = useAppSelector(state=>state.reducer.groupSelected.media_name == null ? "" : state.reducer.groupSelected.media_name);
  const groupName = useAppSelector(state=>state.reducer.groupSelected.name);
  const role = useAppSelector(state=>state.reducer.groupSelected.userRole);
  const idGroupSelected = useAppSelector(state=>state.reducer.groupSelected.id);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);

  const location = useLocation();
  let history = useHistory();

  const [group, setGroup] = useState<Group>();
  const [lastAction, setLastAction] = useState("");

  //@ts-ignore
  const [actualPage, setActualPage] = useState("home");
  const [selectedId, setSelectedId] = useState<number>();
  // const [role, setRole] = useState<Role>();

  const [showBack, setShowBack] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");


  const { getGroupDataById } = GlobalFunctions();

  useIonViewWillEnter(() => {
    setActualPage('home');
  });


  if (window.location.pathname == '/home') {
    var tabsBiblio = Array.from(document.getElementsByClassName('tab-biblio') as HTMLCollectionOf<HTMLElement>);
    var tabsApp = Array.from(document.getElementsByClassName('tab-app') as HTMLCollectionOf<HTMLElement>);

    var i;
    for (i = 0; i < tabsBiblio.length; i++) {
      tabsBiblio[i].setAttribute('style', 'display: none !important');
    }
    for (i = 0; i < tabsApp.length; i++) {
      tabsApp[i].setAttribute('style', 'display: block !important');
    }
  }


  const userData = {
    'token': Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  useEffect(() => {
    if (window.screen.width <= 425) {
      if (location.state != undefined) {
        //@ts-ignore
        if (location.state.text) {
          setShowAlert(true);//@ts-ignore
          setTextAlert(location.state.text);
        }
      }
    }
    

    if (!Cookies.get('token')) {
      history.push("/");
    } else {
      $("body").css("cursor", "default");
      document.getElementById("ml-objetivos")!.scrollIntoView();
      document.getElementById("cnt-header")!.scrollIntoView();
    }

  }, [])



  const showContent = () => {

    switch (actualPage) {
      case "home":
        return <Home textAlert={textAlert} showAlert={showAlert} setTextAlert={setTextAlert} setShowAlert={setShowAlert} setSelectedId={setSelectedId} setActualPage={setActualPage}  setLastAction={setLastAction} lastAction={lastAction} />

      // Carga componentes Actividades
      case "actividades":
        return <Actividades textAlert={textAlert} setTextAlert={setTextAlert} showAlert={showAlert} setShowAlert={setShowAlert} setShowBack={setShowBack} setSelectedId={setSelectedId} setActualPage={setActualPage}  setLastAction={setLastAction} lastAction={lastAction} />
      case "activityDetail":
        return <ActividadDetalle showBack={showBack} setShowBack={setShowBack} setSelectedId={setSelectedId} selectedId={selectedId!} setActualPage={setActualPage} />
      case "activityNew":
        return <NuevaActividad setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case "activityModify":
        return <ActividadModificar setTextAlert={setTextAlert} setShowAlert={setShowAlert} selectedId={selectedId!} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case 'activObjetivosActivos':
        return <ActividadesObjetivos setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />
      case 'activityBiblio':
        return <ActividadBiblio setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />


      // Carga componentes Objetivos
      case "objetivos":
        return <Objetivos textAlert={textAlert} setTextAlert={setTextAlert} showAlert={showAlert} setShowAlert={setShowAlert} setShowBack={setShowBack} setSelectedId={setSelectedId} setActualPage={setActualPage}  setLastAction={setLastAction} lastAction={lastAction} />
      case "objectiveDetail":
        return <ObjetivoDetalle showBack={showBack} setShowBack={setShowBack} selectedId={selectedId!} setActualPage={setActualPage} />
      case "objectiveNew":
        return <ObjetivoNuevo setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case "objectiveModify":
        return <ObjetivoModificar setTextAlert={setTextAlert} setShowAlert={setShowAlert} selectedId={selectedId!} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />

      // Carga componentes Recursos
      case "recursos":
        return <Recursos textAlert={textAlert} setTextAlert={setTextAlert} showAlert={showAlert} setShowAlert={setShowAlert} setShowBack={setShowBack} setSelectedId={setSelectedId} setActualPage={setActualPage}  setLastAction={setLastAction} lastAction={lastAction} />
      case "resourceDetail":
        return <RecursoDetalle showBack={showBack} setShowBack={setShowBack} selectedId={selectedId!} setActualPage={setActualPage} />
      case "resourceNew":
        return <RecursoNueva setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case "resourceModify":
        return <RecursoModificar setTextAlert={setTextAlert} setShowAlert={setShowAlert} selectedId={selectedId!} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case "resourceComunitecaa":
        return <RecursosComunitecaa setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />
      case "resourceBiblio":
        return <RecursosBiblio setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />
      
      default:
        break;
    }

  }
  const refresh = () => {
    window.location.href = window.location.origin;
  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }



  return (

    <>
      {Cookies.get('group_id')
      ?
      <>
        <IonPage className="">
          {showAlert
          ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
          : null}
          <Header setActualPage={setActualPage} name={groupName} avatar={groupAvatar!} avatarProp={userAvatar!} setLastAction={setLastAction} lastAction={lastAction} />

          <IonContent fullscreen>
            <IonGrid>
              <IonRow className='content-height'>
                <IonCol sizeLg="3" sizeMd="4" className="left desktop">
                  <MenuLateral setTextAlert={setTextAlert} setShowAlert={setShowAlert} setShowBack={setShowBack} setActualPage={setActualPage} setSelectedId={setSelectedId} setLastAction={setLastAction} lastAction={lastAction} />
                </IonCol>
                <IonCol className={`right ${actualPage == 'home' && 'no-scroll'}`}>
                  {showContent()}
                </IonCol>
              </IonRow>
            </IonGrid>


          </IonContent>
        </IonPage>
      </>
      : refresh()}
  </>

  );
};

export default Tab1;

import { IonGrid, IonRow, IonInput, IonImg, IonPopover, IonLoading } from "@ionic/react";

import { useRef, useState } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router";
import { Media } from "../interfaces/intPosts";
import noPhotoGroup from '../img/iconos/noPhoto_group.svg';
import close from '../img/iconos/close.svg';
import { getData } from "../api/apiRecursos";
import { usePhotoGallery } from "../hooks/usePhotoGallery";
import { isPlatform } from '@ionic/react';
import { OneSignalFunctions } from "../hooks/OneSignalFunctions";
import { GlobalFunctions } from "../hooks/GlobalFunctions";

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setUserRole} from '../features/groups/groupSelectedSlice';
import { GlobalVariables } from "../hooks/GlobalVariables";


interface Props { 
  setActualPage: any;
  setTextAlert: any;
  setShowAlert: any;
}

const NuevoUsuario: React.FC<Props> = ({ setActualPage, setShowAlert, setTextAlert }) => {
  const [isSelected, setSelected] = useState(false);
  const [nombreCompleto, setNombreCompleto] = useState<string>('');
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState("");
  const [customFilename, setCustomFilename] = useState<string>();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [popoverStateMvl, setShowPopoverMvl] = useState({ showPopover: false, event: undefined });
  const {storeNewGroupTag} = OneSignalFunctions();
  const { getGroupDataById, getGroupData } = GlobalFunctions();
  
  const [loadingFoto, setLoadingFoto] = useState(false);




  const { takePhoto, lastPhoto, setLastPhoto, filePhoto } = usePhotoGallery();
  const [loadingUser, setLoadingUser] = useState(false);


  const formData = new FormData();
  let history = useHistory();

  const axios = require('axios');
  const fileInput = useRef(null);

  const userData = {
    'token': Cookies.get('token')
  }
  const {urlMedia, urlApi} = GlobalVariables();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  }
  const api = axios.create({
    baseURL: urlApi
  });
  


  const storeFile = (file: any) => {
    setLastPhoto(undefined)
    setSelected(false);
    setFile(file);
    if (file != undefined) {
      formData.append("media", file);
    }

    getData.post<Media>('/media', formData, config)
      .then((res: any) => {
        setFileName(res.data.name)
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const imgBackground = {
    backgroundImage: "url(" + urlMedia + fileName + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  const imgPhotoBackground = {
    backgroundImage: "url(" + lastPhoto?.webviewPath + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  const imgCustomBackground = {
    backgroundImage: "url( "+ urlMedia + customFilename + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }


  const createGroup = () => {
    const arr = nombreCompleto.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    if (lastPhoto != undefined) {
      formData.append('media', filePhoto!);
    } else if (file != undefined) {
      formData.append('media', file);
    }

    formData.append("token", Cookies.get('token')!);
    formData.append("name", arr.join(" ")!);
    if (file) {
      formData.append("media", file);
    }


    api.post("/groups/register", formData, config)
      .then((res: any) => {
        getGroupDataById(res.data);
        getGroupData(res.data);

        storeNewGroupTag(res.data);
        setTextAlert('Usuario creado correctamente');
        setShowAlert(true);
        setTimeout(function () {
            // if (!fileName){
            //   window.localStorage.setItem('groupPhoto', '');
            // } else {
            //   window.localStorage.setItem('groupPhoto', fileName);
            // }
          Cookies.set('group_id', res.data, { sameSite: 'strict' });
          // history.push({
          //   pathname: "/home"
          // });
          window.location.href = "home";
        }, 1000);

      })
      .catch((error: any) => {
        console.log("error", error);
      });

  }


  const showButton = () => {
    if (nombreCompleto != '') {
      return <button className="btn-enabled-principal" onClick={e => { createGroup(); setLoadingUser(true) }}><p>Crear usuario</p></button>;
    } else {
      return <button disabled={true} className="btn-disabled-principal"><p>Crear usuario</p></button>;
    }
  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }
  const takePhotoLoader = () => {
    setTimeout(() => {
      setLoadingFoto(true);
    }, 1000);
    takePhoto()
      .then(() => {
        setLoadingFoto(false);
      })
      .catch(() => {
        setLoadingFoto(false);
      });
  } 

  return (
    <>
      <IonLoading
        isOpen={loadingUser}
        onDidDismiss={() => setLoadingUser(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={5000}
      />
      <IonLoading
            isOpen={loadingFoto}
            onDidDismiss={() => setLoadingFoto(false)}
            message={'Cargando foto'}
            spinner={'bubbles'}
            duration={5000}
          />
      
      <IonGrid className="m-5 cnt-blanc">
        <div>
          <IonGrid className="nuevoUsuario">
            <IonRow className="d-flex justify-content-end py-3">
              <a><img className="btn-close handCursor"  src={close} onClick={ () => setActualPage('todo')} /></a>
            </IonRow>
            <IonRow className="titol">
              <div><h3>Crea un nuevo usuario</h3></div>
            </IonRow>
            <div className="">
              <div className="cnt-usuari" >
                <div>
                  {lastPhoto == undefined
                    ? <>{customFilename == undefined
                      ? <span className={`d-flex roundColumn ${fileName != '' ? '' : 'border-none'}`} style={fileName != '' ? imgBackground : undefined}>
                        {fileName != '' ? '' : <IonImg src={noPhotoGroup} style={{'width':'90px', 'height':'90px'}}/>}
                      </span>
                      : <span className={`d-flex roundColumn`} style={imgCustomBackground} />} </>
                    : <span className={`d-flex roundColumn`} style={imgPhotoBackground} />}

                  <>  <input ref={fileInput} hidden type="file" accept="image/*" onClick={() => { '' }} onChange={(e) => {
                    // @ts-ignore 
                    storeFile(e.target.files[0]);
                  }} />
                  {isPlatform('hybrid') 
                    ? <a className="body2 " onClick={(e: any) => {
                        e.persist();
                        setShowPopover({ showPopover: true, event: e })
                      }}><p className="body2 mt-2 text-gris text-center">Elige una foto</p> </a>

                    : <a className="body2 " onClick={(e: any) => {//@ts-ignore
                        fileInput?.current?.click()}}><p className="body2 mt-2 text-gris text-center">Elige una foto</p> </a>
                    }
                  </>
                </div>
              </div>
              <div className="mt-5 cnt-input-name">
                <IonInput type="text" maxlength={40} className="inputRedondeado body1 px-2" style={nombreCompleto == "" ? { 'textTransform': 'initial' } : { 'textTransform': 'capitalize' }} placeholder="Introduce el nombre completo" value={nombreCompleto} onIonChange={e => setNombreCompleto(e.detail.value!)}> </IonInput>
              </div>
              <div className="text-center mt-3">
              {showButton()}
              </div>
            </div>


          </IonGrid>
        </div>

        <IonPopover
                  // cssClass='my-custom-class'
                  event={popoverState.event}
          isOpen={popoverState.showPopover}
          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        >
          <a className='popup-select'> {/* @ts-ignore*/}
            <p className="body1" onClick={() => { fileInput?.current?.click(); setShowPopover({ showPopover: false, event: undefined }) }}>De la galería</p>
          </a>
          {navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) 
            ? <a className='popup-select'><p className="body1" onClick={() => { takePhotoLoader(); setShowPopover({ showPopover: false, event: undefined }) }}>Hacer foto</p></a>
            : null }
          </IonPopover>
      </IonGrid>
    </>
  );

};
export default NuevoUsuario;







import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { User } from "../../interfaces/intUser";

// Define a type for the slice state
// interface UserState {
//   id: number, 
//   name: string,
//   email:string,
//   image:string
// }

const initialState: User = {
  id: 0,
  name: "",
  email: "",
  password: '',
  media_id: null,
  media_name: null,
  type: null,
  status: 0,
  created_at: null,
  updated_at: null,
}

// const initialState: User;

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.media_id = action.payload.media_id;
      state.media_name = action.payload.media_name;
      
    }, 
    setMediaName: (state, action: PayloadAction<string>) => {
      state.media_name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    clearResults() {

    }

  },
});


export const { setUser, setMediaName, setEmail, clearResults } = userSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default userSlice.reducer
interface Props {
  text: string
}

const AvisoPopUp: React.FC<Props> = ({ text }) => {

  return (
    <div className="modal modal_aviso modal_notificacion_aviso">
      <div className="modal_content ">
        <p className="pl-5">{text}</p>
      </div>
    </div>
  );

};
export default AvisoPopUp;
import { IonAvatar, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonLoading, IonPopover, IonRow, IonTextarea } from "@ionic/react";
import chevronDown from "../img/iconos/chevron-down-outline.svg";
import { useEffect, useRef, useState } from "react";
import { getData } from "../api/apiRecursos";
import Cookies from "js-cookie";
import { Group } from "../interfaces/intGroups";
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';
import foto_video from '../img/iconos/foto_video.svg';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import 'react-image-crop/dist/ReactCrop.css';
import { isPlatform } from '@ionic/react';

import edit_img from '../img/iconos/edit_img.svg';
import remove_img from '../img/iconos/remove_img.svg';
import { GlobalFunctions } from "../hooks/GlobalFunctions";
import $ from 'jquery';
import { OneSignalFunctions } from "../hooks/OneSignalFunctions";
//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { GlobalVariables } from "../hooks/GlobalVariables";


interface ContainerProps {
  setLastAction: any,
  lastAction: string,
  isDestacado: any
}

const NuevaPublicacion: React.FC<ContainerProps> = ({ setLastAction, lastAction, isDestacado }) => {
  //REDUX 
  const avatarProp = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const groupSelectedName = useAppSelector(state=>state.reducer.groupSelected.name);
  // const avatarProp = window.localStorage.getItem('userPhoto');

  const { showThirdButton, sendPost } = GlobalFunctions();

  const { notifyNewPost } = OneSignalFunctions();
  const [isActive, setActive] = useState(false);
  

  const [content, setContent] = useState("");
  const fileInput = useRef(null);
  const videoInput = useRef<HTMLInputElement>(null);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const formData = new FormData();

  const { takePhoto, lastPhoto, setLastPhoto, filePhoto } = usePhotoGallery();
  const [loadingFoto, setLoadingFoto] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);

  const [selectedFileImg, setSelectedFileImg] = useState()
  const [selectedFileVideo, setSelectedFileVideo] = useState()
  const [preview, setPreview] = useState<any>()
  const [fileSizeWarning, setFileSizeWarning] = useState(false);


  const newPost = (type: string) => {
    setLoadingPost(true);
    if (lastPhoto != undefined) {
      formData.append('file', filePhoto!);
    } else if (selectedFileImg != undefined) {
      formData.append('file', selectedFileImg);
    } else if (selectedFileVideo != undefined) {
      formData.append('file', selectedFileVideo);
    }


    formData.append("token", Cookies.get('token')!);
    formData.append("group_id", Cookies.get('group_id')?.toString()!);
    formData.append("title", "");
    formData.append("content", content);
    formData.append("type", type);

    sendPost('post', formData, setLastAction, setLoadingPost);

    notifyNewPost(Cookies.get('group_id')?.toString()!);
    removeMedia();
    $(".inputTexto textarea").val('');
    checkContent("");
    setContent('');
   
  }


  const checkContent = (text: any) => {
    if (text == undefined || text == '') {
      Array.from(document.getElementsByClassName("btn-enabled-principal")).forEach(
        function (element) {
          element.classList.remove('btn-enabled-principal');
          element.classList.add('btn-disabled-principal');
          setActive(false);
        }
      );
    } else {
      Array.from(document.getElementsByClassName("btn-disabled-principal")).forEach(
        function (element) {
          element.classList.remove('btn-disabled-principal');
          element.classList.add('btn-enabled-principal');
          setActive(true);
        }
      );
    }
  }

   const {urlMedia} = GlobalVariables();

  const takePhotoLoader = () => {
    setTimeout(() => {
      setLoadingFoto(true);
    }, 1000);
    takePhoto()
      .then(() => {
        setLoadingFoto(false);
        checkContent('foto');
      })
      .catch(() => {
        setLoadingFoto(false);
      });
  } 

  const removeMedia = () => {

    $('#videoInput').val('');
    $('#imageInput').val('');

    checkContent(content);

    setLastPhoto(undefined);
    setSelectedFileImg(undefined);
    setSelectedFileVideo(undefined);
  }

  useEffect(() => {
    if (!selectedFileImg) {
        setPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(selectedFileImg)
    setPreview(objectUrl)
    checkContent('foto');

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFileImg])

  useEffect(() => {
    if (!selectedFileVideo) {
        setPreview(undefined)
        return
    }

    const objectUrl = URL.createObjectURL(selectedFileVideo)
    setPreview(objectUrl);
    checkContent('video');

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFileVideo])

  const onSelectFileImg = (e: any) => {
    setSelectedFileVideo(undefined);
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFileImg(undefined);
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFileImg(e.target.files[0]);
  }
  const onSelectFileVideo = (e: any) => {
    setSelectedFileImg(undefined);
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFileVideo(undefined);
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    
    if (e.target.files[0].size > 100000000){
      setFileSizeWarning(true);
    } else {
      setSelectedFileVideo(e.target.files[0]);
      setFileSizeWarning(false);
    }
  }

  return (
    <IonGrid id="nuevaPublicacion" className="mt-2">
      <IonLoading
        isOpen={loadingFoto}
        onDidDismiss={() => setLoadingFoto(false)}
        message={'Cargando foto'}
        spinner={'bubbles'}
        duration={5000}
      />
      <IonLoading
        isOpen={loadingVideo}
        onDidDismiss={() => setLoadingVideo(false)}
        message={'Cargando video'}
        spinner={'bubbles'}
        duration={5000}
      />

      <IonLoading
        isOpen={loadingPost}
        onDidDismiss={() => setLoadingPost(false)}
        message={'Subiendo post'}
        spinner={'bubbles'}
        duration={500000}
      />
      <IonRow className="nuevaPubliTop py-2">
        <IonCol size="12" className="col-ajustada lineal-flex align-items-center">
          <IonAvatar className="d-flex justify-content-center align-self-center">
            <img className={`photo avatar-small m-auto ${avatarProp != "" ? 'border-gris' : null}`} src={avatarProp != "" ? urlMedia + avatarProp : noPhotoAvatar}></img>
          </IonAvatar>
          <IonTextarea id="postText" required={true} className="inputTexto body2 desktop" value={content} 
            placeholder="¿Quieres compartir algo con los demás acompañantes?"
            
            onIonChange={e => {
              <>{e.detail.value != undefined ? setContent(e.detail.value?.toString()) : null}</>;
              checkContent(e.detail.value)
            }}
          ></IonTextarea>

          <IonTextarea id="postText" required={true} className="inputTexto body2 mvl" value={content}
              placeholder="¿Qué quieres compartir?" 
              autoGrow={true}
              onIonChange={e => {
                <>{e.detail.value != undefined ? setContent(e.detail.value?.toString()) : null}</>;
                checkContent(e.detail.value);
              }}
            ></IonTextarea>
        </IonCol>
      </IonRow>
      <IonRow className="nuevaPubliBottom">
        <IonCol size="12" className="d-flex justify-content-center">
          {selectedFileVideo &&  
            <>
              <video id="video" controls className="preview-image">
                <source src={preview} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
              <div className="edit-remove-container">
                <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
              </div>
            </> 
          }

          {lastPhoto == undefined 
            ?
              <>
                {selectedFileImg && 
                  <>             
                    <IonImg src={preview} className="preview-image"/>
                    <div className="edit-remove-container">                      
                      <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                    </div>
                  </>
                }
              </>
            : 
              <>             
                <IonImg src={lastPhoto.webviewPath} className="preview-image"/>
                <div className="edit-remove-container">
                  <IonIcon onClick={() => removeMedia()} className="grande handCursor" src={remove_img} />
                </div>
              </>
          }
        </IonCol>

        <IonCol size="6" className={`col-6 lineal-flex align-items-center ${(selectedFileVideo || selectedFileImg || lastPhoto) && 'invisible' }`}>
          <IonIcon className="medio" src={foto_video}></IonIcon>
            {isPlatform('hybrid') 
              ? <a className="body2 " onClick={(e: any) => {
                  e.persist();
                  setShowPopover({ showPopover: true, event: e })
                }}><p className="okButton m-0" style={{ 'fontWeight': 400 }}>&nbsp;&nbsp;Foto</p></a>
              :<a className="body2 " onClick={() => { //@ts-ignore
                fileInput?.current?.click()}}><p className="okButton m-0" style={{ 'fontWeight': 400 }}>&nbsp;&nbsp;Foto</p></a>
            }
          <p className="okButton m-0 handCursor" style={{ 'fontWeight': 400 }} onClick={() => { videoInput?.current?.click() }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vídeo</p>

          <IonPopover event={popoverState.event} isOpen={popoverState.showPopover} 
                  onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })} >
              <a className='popup-select'> {/* @ts-ignore*/}
                <p className="body1" onClick={() => { fileInput?.current?.click(); setShowPopover({ showPopover: false, event: undefined }) }}>De la galería</p>
              </a>
              {navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) 
              ? <a className='popup-select'><p className="body1" onClick={() => { takePhotoLoader(); setShowPopover({ showPopover: false, event: undefined }) }}>Hacer foto</p></a>
              : null}
          </IonPopover>

        </IonCol>
        <IonCol size="6" className="col-6 d-flex justify-content-end">
          <button className="btn-disabled-principal split-left-btn" disabled={isActive ? false : true} onClick={() => { newPost('post'); setLoadingPost(true) }}><p>Publicar</p></button>
          <button className="btn-disabled-principal split-right-btn" disabled={isActive ? false : true} onClick={() => { showThirdButton('nuevaPubli'); }}><IonIcon src={chevronDown}></IonIcon></button>
          <button className={`btn-enabled-third btn-auxiliar btn-third nuevaPubli`} disabled={isActive ? false : true} onClick={() => { newPost('post-user');showThirdButton('nuevaPubli');setLoadingPost(true) }}><p>Publicar como {groupSelectedName.split(" ", 1)}</p></button>
        </IonCol>
          {fileSizeWarning ? 
            <IonCol size="12">
                <p className="aviso-naranja small">El archivo seleccionado es demasiado grande. Seleciona uno de máximo 100MB</p>
            </IonCol>
            :null
          }
      </IonRow>
      <input id="videoInput" ref={videoInput} hidden type="file" accept="video/mp4" onChange={onSelectFileVideo}/>
      <input id="imageInput" ref={fileInput} hidden type="file" accept="image/*" onChange={onSelectFileImg} />
    </IonGrid>
  );
}

export default NuevaPublicacion;
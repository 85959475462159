import { IonButton, IonCheckbox, IonCol, IonGrid, IonImg, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useState } from "react";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import logoComuniteCaa from '../../img/iconos/logoComuniteCAA.svg';
import $ from 'jquery';


interface Props {
  setActualPage: any;
}

const ForgetPasswordPage: React.FC<Props> = ({ setActualPage }) => {
  const  {recuperarPassword, isEmail} = GlobalFunctions();
  const [mail, setMail] = useState<string>();
  const [mail2, setMail2] = useState<string>();
  const [title, setTitle] = useState('¿Has olvidado tu contraseña?');
  const [showRedText, setShowRedText] = useState(false);

  function resendActivationMail(): void {
    if (isEmail(mail!)){
      setMail2(mail);
      recuperarPassword(mail!);
      $('#input-email').val('');
      setTitle('Correo electrónico enviado');
      $('.cnt-form').addClass('d-none');
      $('.cnt-enviat').removeClass('d-none');
      setShowRedText(false);
    } else {
      setShowRedText(true);
    }
    
    
  }

  const sendAgain = () => {
    setTitle('¿Has olvidado tu contraseña?');
    $('.cnt-form').removeClass('d-none');
    $('.cnt-enviat').addClass('d-none');
  }


  return (
    <IonGrid className="px-4 container forget-password-page">
      <IonRow className="my-4">
        <IonCol className="d-flex flex-column justify-content-center">
          <img style={{ 'width': '5rem' }} src={logoComuniteCaa} />
          <p className="pt-2">COMUNITE<b>CAA</b></p>
        </IonCol>
      </IonRow>
      <IonRow >
        <IonCol size-md="1" size-xs="0" >
        </IonCol>
        <IonCol size-md="10" size-xs="12" className="bg-white p-5 ">
          <div className="headerLogin p-1 m-0">
            <h3>{title}</h3>
          </div>
          <div className="cnt-form">
            <form className="mt-4">
              <IonRow>
                <IonCol size="12">
                  <p className="pb-3">Introduce el correo electrónico para restablecer la contraseña.</p>
                  <IonItem className="mt-2">
                    <IonInput id="input-email" required={true} type="email" placeholder="Introduce tu correo electrónico" onIonChange={e => setMail(e.detail.value?.toString()!)}></IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <p className={`${showRedText ? "" : 'hidden'}`} style={{'color':'red'}}>* Introduce un correo válido</p>
              <IonRow className="separator">
                <IonCol className="d-flex justify-content-center">
                  <IonButton className="btn-enabled-principal my-3" onClick={(e) => resendActivationMail()} style={{height: 47}}><p>Restablecer contraseña</p></IonButton>
                </IonCol>
              </IonRow>
            </form>
            <IonRow className="">
              <IonCol className="d-grid mt-4 justify-content-center">
                <IonButton className="my-4 btn-objetivos selected" onClick={e => setActualPage('register')} style={{height: 47}}>
                  <p style={{fontSize:'1.4rem'}} >CREAR CUENTA NUEVA</p>
                  </IonButton>
                <br/>
                <a onClick={e => setActualPage('login')} className="text-center handCursor">Volver al inicio de sesión</a>
              </IonCol>
            </IonRow>
          </div>

          <div className="cnt-enviat d-none">
            <div className="mt-4">
              <p>Consulta la bandeja de entrada de <b>{mail2}</b>, donde te hemos enviado instrucciones para restablecer tu contraseña. Es posible que tengas que comprobar la carpeta de spam.</p>
              <IonRow>
                <IonCol className="d-flex justify-content-center">
                  <IonButton className="btn-enabled-principal my-3" onClick={(e) => setActualPage('login')} ><p>De acuerdo</p></IonButton>
                </IonCol>
              </IonRow>
              <p>¿No has recibido el correo electrónico? <a onClick={e => sendAgain()}>Vuelve a intentarlo</a></p>
            </div>

          </div>
          
        </IonCol>
        <IonCol size-md="1" size-xs="0" />
      </IonRow>


    </IonGrid>
  );
}

export default ForgetPasswordPage;



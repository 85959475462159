import { IonGrid, IonRow, IonCol, IonIcon, IonPopover, IonAvatar, IonImg, useIonAlert, IonSkeletonText } from "@ionic/react";

import CampanaTachada from '../img/iconos/campana-tachada.svg';
import Campana from '../img/iconos/campana.svg';
import Unlink from '../img/iconos/unlinkGroup.svg';
import noPhotoGroup from '../img/iconos/noPhoto_group.svg';
import papelera from '../img/iconos/papelera.svg';
import Punts from '../img/iconos/punts.svg';
import { useState, useEffect, useRef } from "react";
import { getData } from "../api/apiRecursos";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { usePhotoGallery } from "../hooks/usePhotoGallery";
import { Media } from "../interfaces/intPosts";
import { OneSignalFunctions } from "../hooks/OneSignalFunctions";
import { GlobalFunctions } from "../hooks/GlobalFunctions";
import $ from 'jquery';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setMediaName, setId, setName, setPosts} from '../features/groups/groupSelectedSlice';
import { getgroups } from "process";
import { GlobalVariables } from "../hooks/GlobalVariables";


interface Props {
  setActualPage: any;
  setLastAction: any,
  lastAction: string,
  setTextAlert: any;
  setShowAlert: any;
}


const PantallaPrincipal: React.FC<Props> = ({setShowAlert, setTextAlert}) => {
  //REDUX
  const dispatch = useAppDispatch();
  const groups = useAppSelector(state=>state.reducer.groups.group);
  // const [groups, setGroups] = useState<Group[]>([]);

  
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedRole, setSelectedRole] = useState<number>();
  const [selectedName, setSelectedName] = useState<string>();
  const { takePhoto } = usePhotoGallery();
  const fileInput = useRef(null);
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState("");
  const formData = new FormData();
  const [loadingFoto, setLoadingFoto] = useState(false);
  const {changeNotificationTag } = OneSignalFunctions();
  const { changeNotifBBDD, getGroupDataById, getGroupData, getGroups, unlinkGroup: unlinkFunction} = GlobalFunctions();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  }
  const {urlMedia, urlApi} = GlobalVariables();

  const [popoverStateMvl, setShowPopoverMvl] = useState({ showPopover: false, event: undefined });

  const [popoverStateNuevo, setShowPopoverNuevo] = useState({ showPopover: false, event: undefined });

  let history = useHistory();


  const [checkAlert] = useIonAlert();

  const changeNotification = (group_id: number, statusNotif:number) => {
    if (statusNotif == 1) {
      changeNotificationTag(group_id, 'false');
      changeNotifBBDD(group_id, 0);
    } else {
      changeNotificationTag(group_id, 'true');
      changeNotifBBDD(group_id, 1);
    }
    setTimeout(() => {
    }, 200);
  }

  

  const loadPage =  (id: number, media_name: string) => {
    $("body").css("cursor", "progress");
    dispatch(setId(id));
    getGroupData(id);
    setNotNew(id);
    // if (!media_name){
    //   window.localStorage.setItem('groupPhoto', '');
    // } else {
    //   window.localStorage.setItem('groupPhoto', media_name);
    // }
    // const userData = {
    //   token: Cookies.get('token'),
    //   group_id: id
    // }

    dispatch(setMediaName(media_name == null ? "" : media_name));
    getGroupDataById(id, 'home');
    // getPostsGroupSelected(id);
    
    Cookies.set('group_id', id.toString(), { sameSite: 'strict' });

  };



  const setNotNew = (id_group: number) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id_group,
    }

    getData.post("/setNotNew", userData)
      .then(() => {
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const unlinkGroup = (id_group: number, name:string) => {
    checkAlert({
      header:'Salir del grupo',
      message: `¿Seguro que quieres salir del grupo `+name+`? `,
      buttons: [
        'CANCELAR', { text: 'SALIR', handler: () => unlink(id_group, name) },
      ],
      onDidDismiss: () => '',
    })
  }
  const unlink = (id_group: number,name:string) => {
    unlinkFunction(id_group, name, setTextAlert, setShowAlert, unlinkGroup);
    // api.post("/unsetStatus", userData)
    //   .then(() => {
    //     // setLastAction("Has dejado de seguir "+Date.now());
    //     setTextAlert("Has dejado de seguir a "+name+".");
    //     setShowAlert(true);
    //     unlinkGroupTag(id_group+"");
    //   })
    //   .catch((error: any) => {
    //     console.log("error", error);
    //   });
  }

  const eliminarGroup = (id_group: number, name:string) => {
    checkAlert({
      header:'Eliminar usuario',
      message: `¿Seguro que quieres eliminar el usuario ${name}? <br><p class="small">Esto hará que el grupo y las conversaciones se borren por completo, y que todo lo que se haya hablado y compartido, como objetivos, actividades y recursos, desaparezca. </p>`,
      buttons: [
        'CANCELAR', { text: 'ELIMINAR', handler: () => deleteGroup(id_group) },
      ],
      onDidDismiss: () => '',
    })
  }

  const deleteGroup = (id_group: number) => {
    getData.delete(`/groups/delete/${id_group}`)
      .then(() => {
        // setLastAction("Usuario eliminado "+Date.now());
        setTextAlert("Usuario eliminado.");
        setShowAlert(true);
      })
      .then(() => {
        getGroups(undefined, undefined);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  const storeFile = (file: any) => {
    setLoadingFoto(true)
    setFile(file);
    if (file != undefined) {
      formData.append("media", file);
    }

    getData.post<Media>('/media', formData, config)
      .then((res: any) => {
        setFileName(res.data.name)
      })
      .then(() => {
        setLoadingFoto(false);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  const showRole = (roleNumber: number) => {
    switch (roleNumber) {
      case 1:
        return "colaborador"
      case 2:
        return "editor"
      case 3:
        return "propietario"

      default:
        break;
    }

  }
  // const showPopUp = () => {
  //   setTimeout(() => {
  //     setShowAlert(false);
  //   }, 4000);
  // }
  
 



  return (
    <>
      {Cookies.get('token')
        ? <div className="mb-4">
          <IonRow>
           {/* {showAlert
            ? <>{showPopUp()} <AvisoPopUp text={textAlert}/></>
            : null} */}
            <IonCol>
              <h3>Mis usuarios de ComuniteCAA</h3>
            </IonCol>
          </IonRow>
          <input ref={fileInput} hidden type="file" accept="image/*" onClick={() => { '' }} onChange={(e) => {
            // @ts-ignore 
            storeFile(e.target.files[0]);
          }} />
          <IonGrid className="usuaris">
            {groups.filter(group => group.status_relation == 1).map((group, index) => {
              return (
                <IonRow className="usuari" key={index}>
                  <IonCol size="10" className="handCursor" >
                    <IonRow>
                      <IonCol className="lineal-flex" onClick={() => loadPage(group.id, group.media_name!)}>
                        {group.is_new == 1 ? <span className="dot"></span> : null}
                        {group.media_name != null
                          ?
                          <IonAvatar className="avatar-group">
                            <IonImg className='photo  avatar-principal m-0 m-auto border-gris' src={urlMedia + group.media_name} />
                          </IonAvatar>
                          : 
                            <IonAvatar className="avatar-group">
                              <IonImg className=" photo avatar-principal m-0 m-auto" src={noPhotoGroup} />
                            </IonAvatar>
                            }
                        <h4 className="capitalize">{group.name}</h4>
                        <a className="mx-3"><p className="body1 text-gris m-2">{showRole(group.role)}</p></a>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                  <IonCol size="2" className="lineal-flex ion-justify-content-end">
                    <IonIcon  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Silenciar notificaciones" className="grande mx-2 handCursor " onClick={ () => changeNotification(group.id, group.notificationsEnabled)} src={group.notificationsEnabled == 1 ? Campana : CampanaTachada} />
                    {group.role == 3
                      ? <><IonIcon className="grande mx-3 handCursor" src={Punts} onClick={
                        (e: any) => {
                          setSelectedId(group.id);
                          setSelectedRole(group.role);
                          setSelectedName(group.name);
                          e.persist();
                          setShowPopoverNuevo({ showPopover: true, event: e })
                        }} />

                        <IonPopover
                          event={popoverStateNuevo.event}
                          isOpen={popoverStateNuevo.showPopover}
                          onDidDismiss={() => setShowPopoverNuevo({ showPopover: false, event: undefined })}
                        >
                          <a className="popup-select" href={`/editarusuario/${selectedId}`}><p className="body1" onClick={() => ""}>Editar perfil</p></a>
                          {selectedRole == 3 
                          ? <a className="popup-select">
                              <p className="body1" style={{'color':'red'}}onClick={() => { eliminarGroup(selectedId!, selectedName!); setShowPopoverNuevo({ showPopover: false, event: undefined }) }}>Eliminar usuario <IonIcon src={papelera}/></p>
                            </a> 
                          : null}
                        </IonPopover>
                      </>
                      : <IonIcon className="grande mx-3 handCursor" src={Unlink} onClick={() => unlinkGroup(group.id, group.name)} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Salir del grupo" />}

                  </IonCol>
                  <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateMvl.event}
                    isOpen={popoverStateMvl.showPopover}
                    onDidDismiss={() => setShowPopoverMvl({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select'> {/* @ts-ignore*/}
                      <p className="body1" onClick={() => { fileInput?.current?.click(); setShowPopoverMvl({ showPopover: false, event: undefined }) }}>De la galería</p>
                    </a>
                  {navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) 
                    ? <a className='popup-select'><p className="body1" onClick={() => { takePhoto().catch((error) => console.error(error)); setShowPopoverMvl({ showPopover: false, event: undefined }) }}>Hacer foto</p></a>
                  : null }
                  </IonPopover>
                </IonRow>
              )

            })}

          </IonGrid>
        </div>
        : history.push('/')}

    </>
  );

};
export default PantallaPrincipal;



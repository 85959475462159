import { IonRow, IonCol, IonIcon, IonPopover, IonGrid, IonImg, IonAvatar } from '@ionic/react';
import { PostsMyGroups } from "../interfaces/intPosts";

import punts from '../img/iconos/punts.svg';
import NuevoComentario from './NuevoComentario';
import Comentario from "./Comentario";


import objetivosLogo from '../img/iconos/objetivos-logo.svg';
import actividadesLogo from '../img/iconos/actividades-logo.svg';
import recursosLogo from '../img/iconos/recursos-logo.svg';
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';

import actividad_final from '../img/iconos/logros/Actividad_final.svg';
import actividad_inicio from '../img/iconos/logros/Actividad_inicio.svg';
import obj_inicio from '../img/iconos/logros/Obj_0.svg';
import obj_25 from '../img/iconos/logros/Obj_25.svg';
import obj_50 from '../img/iconos/logros/Obj_50.svg';
import obj_75 from '../img/iconos/logros/Obj_75.svg';
import obj_100 from '../img/iconos/logros/Obj_100.svg';
import recurso from '../img/iconos/logros/Recurso_nuevo.svg';

import actividad_final_mvl from '../img/iconos/logros/Actividad_final-mvl.svg';
import actividad_inicio_mvl from '../img/iconos/logros/Actividad_inicio-mvl.svg';
import obj_inicio_mvl from '../img/iconos/logros/Obj_0-mvl.svg';
import obj_25_mvl from '../img/iconos/logros/Obj_25-mvl.svg';
import obj_50_mvl from '../img/iconos/logros/Obj_50-mvl.svg';
import obj_75_mvl from '../img/iconos/logros/Obj_75-mvl.svg';
import obj_100_mvl from '../img/iconos/logros/Obj_100-mvl.svg';
import recurso_mvl from '../img/iconos/logros/Recurso_nuevo-mvl.svg';

import {useAppSelector} from "../app/hooks";
import { useState } from 'react';
import { GlobalFunctions } from '../hooks/GlobalFunctions';
import { GlobalVariables } from '../hooks/GlobalVariables';




interface ContainerProps {
  publicacion: PostsMyGroups,
  index: number
}

const PostComponent: React.FC<ContainerProps> = ({ publicacion, index }) => {
  const activityGroups = useAppSelector(state=>state.reducer.itemsBiblio.postsAllUsers);
  const userID = useAppSelector(state=>state.reducer.user);
  const [popoverStateErase, setShowPopoverErase] = useState({ showPopover: false, event: undefined });

  const { showMedia, removePost, selectedId, showDetail, setShowDetail, activity, resource, objective,addGroupToUser} = GlobalFunctions();
  const [editPost, setEditPost] = useState(0);

  const [showDetailRes, setShowDetailRes] = useState(false);
  const [showDetailObj, setShowDetailObj] = useState(false);
  const [showDetailAct, setShowDetailAct] = useState(false);

  const [showPhoto, setShowPhoto] = useState(false);
  const [lastAction, setLastAction] = useState("");








  const showAchievementLogo = (type: string, value: number) => {
    switch (type) {
      case 'resource-achievement':
        return <><IonImg className="achievement-logo-width desktop" src={recurso} />
        <IonImg className="achievement-logo-width mvl" src={recurso_mvl} /></>;
      case 'activity-achievement':
        if (value == 0) {
          return <><IonImg className="achievement-logo-width desktop" src={actividad_inicio} />
                 <IonImg className="achievement-logo-width mvl" src={actividad_inicio_mvl} /></>;
        } else {
          return <><IonImg className="achievement-logo-width desktop" src={actividad_final} />
          <IonImg className="achievement-logo-width mvl" src={actividad_final_mvl} /></>;
        }
      case 'objective-achievement':
        if (value == 0) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_inicio} />
          <IonImg className="achievement-logo-width mvl" src={obj_inicio_mvl} /></>;
        } else if (value == 25) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_25} />
          <IonImg className="achievement-logo-width mvl" src={obj_25_mvl} /></>;
        } else if (value == 50) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_50} />
          <IonImg className="achievement-logo-width mvl" src={obj_50_mvl} /></>;
        } else if (value == 75) {
          return <><IonImg className="achievement-logo-width desktop" src={obj_75} />
          <IonImg className="achievement-logo-width mvl" src={obj_75_mvl} /></>;
        } else {
          return <><IonImg className="achievement-logo-width desktop" src={obj_100} />
          <IonImg className="achievement-logo-width mvl" src={obj_100_mvl} /></>;
        }
      default:
        return null;
    }

  }
  const {urlMedia, urlApi} = GlobalVariables();

  const mostraImatge = (avatar: string, type: string) => {
    if (type === "post" || type === "post-user") {
      return (<IonAvatar className='d-flex'>
        {/* <IonSkeletonText animated className={`skeleton avatar-small border-gris m-auto`} /> */}
        <img className={`photo m-auto avatar-small ${avatar != undefined ? 'border-gris' : null}`} src={avatar != undefined ? urlMedia + avatar : noPhotoAvatar}></img>
      </IonAvatar>)
    } else {
      switch (type) {
        case "resource-achievement":
          return <IonAvatar className='d-flex'><img className="avatar-small m-auto " src={recursosLogo}></img></IonAvatar>

        case "activity-achievement":
          return <IonAvatar className='d-flex'><img className="avatar-small m-auto" src={actividadesLogo}></img></IonAvatar>

        case "objective-achievement":
          return <IonAvatar className='d-flex'><img className="avatar-small m-auto" src={objetivosLogo}></img></IonAvatar>

        default:
          break;
      }
    }

  }

  const showDate = (date: Date) => {
    let now = Date.now();
    let postedTime = new Date(date);
    let elapsedTime = now - postedTime.getTime();
    let minutes = Math.floor(elapsedTime / 60000) - 59;
    const month = postedTime.toLocaleString('default', { month: 'long' });

    if (minutes > 60) {
      return `${postedTime.getDate()} de ${month} de ${postedTime.getFullYear()} - ${postedTime.getHours()}:${String(postedTime.getMinutes()).padStart(2,'0')}`;

    } else {
      return `Hace ${minutes} minutos`;
    }
  }

  const showElement = (type: string, id: number) => {
    switch (type) {
      case 'activity-achievement':
        selectedId(id, 'activity'); 
        setShowDetailAct(true);
        break;
      case 'objective-achievement':
        selectedId(id, 'objective'); 
        setShowDetailObj(true);
        break;
      case 'resource-achievement':
        selectedId(id, 'resource'); 
        setShowDetailRes(true);
        break;
      default:
        break;
    }
  }

  const erasePost = (id: number) => {
    setShowPopoverErase({ showPopover: false, event: undefined })
    removePost(id, setLastAction, setShowPopoverErase, undefined);
  }

  


  return (
    <IonGrid key={index} className={`publicacion mb-4 ${publicacion.type}`}>
      {publicacion.type === "activity-achievement" || publicacion.type === "resource-achievement" || publicacion.type === "objective-achievement"
        ? <IonRow className={`m-0 achievement-logo ${publicacion.type}`} >
          <IonCol className="d-flex justify-content-center">
            {showAchievementLogo(publicacion.type, publicacion.value!)}
          </IonCol>
        </IonRow>
        : null}
      <IonRow className="mx-3">
        <IonCol size="12" className="lineal-flex ion-align-items-center ion-justify-items-center">
          {mostraImatge(publicacion.creator_media.media_name, publicacion.type)}
          <div>
            <p><b>{publicacion.creator_name}</b></p>
            <p className="body2 text-gris" >{showDate(publicacion.created_at)}</p>
          </div>
          <div className="right-0">

          {publicacion?.role == 3 || userID.id == publicacion.created_by ?
            <>
              <IonPopover
                  // cssClass='my-custom-class'
                  event={popoverStateErase.event}
                isOpen={popoverStateErase.showPopover}
                onDidDismiss={() => setShowPopoverErase({ showPopover: false, event: undefined })}
              >
                <a className='popup-select' onClick={e => erasePost(editPost)}><p className="body1">Eliminar este mensaje</p></a>
              </IonPopover>
              <IonIcon className="medio handCursor" src={punts} onClick={
                (e: any) => {
                  e.persist();
                  setEditPost(publicacion.id)
                  setShowPopoverErase({ showPopover: true, event: e })
                }} >
              </IonIcon>
            </>
          : null}

          </div>
        </IonCol>
      </IonRow>
      {publicacion.content || publicacion.media_id
        ?
        <IonRow className="mx-3">
          <IonCol size="12">
            {publicacion.content
              ? <p className={`body2 mx-4 ${publicacion.type == 'activity-achievement' ||
                                            publicacion.type == 'objective-achievement' ||
                                            publicacion.type == 'resource-achievement'
                                            ? 'handCursor'
                                            : ''} 
                                          ${publicacion.type}`} dangerouslySetInnerHTML={{ __html: publicacion.content }} 
                onClick={e => <>
                  {publicacion.type == 'activity-achievement' ||
                    publicacion.type == 'objective-achievement' ||
                    publicacion.type == 'resource-achievement'
                    ? showElement(publicacion.type, publicacion.id_referencia)
                    : null}</>}></p>
              : null}


            {publicacion.media_id
              ? <div className="d-flex justify-content-center">
              {showMedia(publicacion.id, publicacion.media_name, publicacion.media_name?.split('.')[1], setShowPhoto)}              
          </div>
              : null}
          </IonCol>
        </IonRow>
        : null}
      <IonGrid className="container comentarios">
        {publicacion.comments != undefined
          ? publicacion.comments.map((comentario, index) =>
            <Comentario key={index} comentario={comentario} setLastAction={setLastAction} />
          )
          : null}
      </IonGrid>
      <NuevoComentario groupId={publicacion.group_id} idNuevoComentario={index} parentId={publicacion.id} setLastAction={setLastAction} />
    </IonGrid>
  )

};
export default PostComponent;


import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  IonContent,
  IonHeader,
  IonPage,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import logoComuniteCaa from "../img/iconos/logoComuniteCAA.svg";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { GlobalFunctions } from "../hooks/GlobalFunctions";
import $ from 'jquery';
import HeaderEmpty from "../components/HeaderEmpty";
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

interface ObjetivoDetalleProps
  extends RouteComponentProps<{
    token: string;
  }> {}

const ChangePassword: React.FC<ObjetivoDetalleProps> = ({ match }) => {
  const [password, setPassword] = useState<string>();
  const [password2, setPassword2] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showRedTextPassword, setShowRedTextPassword] = useState(false);
  const [showRedTextCoincidir, setShowRedTextCoincidir] = useState(false);
  const [acceptSubmit, setAcceptSubmit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState<string>('La contraseña se ha establecido correctamente.');
  
  const [isActive, setIsActive] = useState(true);

  const { updatePassword, findToken } = GlobalFunctions();

  let history = useHistory();

  useEffect(() => {
    $("ion-tab-bar#tabBar").addClass('d-none');
    $("ion-tab-bar#tabBar").removeClass('mvl');
    findToken(match.params.token, setIsActive);
  }, []);

  const checkPassword = (password: string) => {
    // if (password != undefined && password.length < 8) {
    //   setShowRedTextPassword(true);
    //   // setAcceptSubmit(false);
    // } else {
    //   setShowRedTextPassword(false);
    //   // setAcceptSubmit(true);
    // }
  };

  const checkSubmit = () => {
    // const pass1 = (
    //   document.getElementById("passwordInput1") as HTMLInputElement
    // ).value;
    // const pass2 = (
    //   document.getElementById("passwordInput2") as HTMLInputElement
    // ).value;

    // if (pass1 != pass2) {
    //   // setShowRedTextCoincidir(true);
    //   // setAcceptSubmit(false);
    // } else {
    //   setShowRedTextCoincidir(false);
    //   if (pass1 != undefined && pass1.length >= 8) {
    //     // setAcceptSubmit(true);
    //   }
    // }
  };

  
  const changePassword = () => {
    const pass1 = (
      document.getElementById("passwordInput1") as HTMLInputElement
    ).value;
    const pass2 = (
      document.getElementById("passwordInput2") as HTMLInputElement
    ).value; 

    if(pass1 != pass2) {
      setShowRedTextCoincidir(true);
      setShowRedTextPassword(false);
    } else {
      setShowRedTextCoincidir(false);
      if (pass1 != undefined && pass1.length >= 8) {
        updatePassword(password!, match.params.token);
        setShowAlert(true);
        setTimeout(() => {
          window.location.href = "login";
        }, 1000);

        
      } else {
        setShowRedTextPassword(true);
      }
    }
  };
  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  return (
    <IonPage className="test-password">
      {showAlert
        ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
        : null}
      <HeaderEmpty />
      <IonContent fullscreen className="fullSize bg-gris-clar">
        <IonHeader collapse="condense"></IonHeader>
        <IonGrid className="px-4 container forget-password-page">
          <IonRow className="my-4">
            <IonCol className="d-flex justify-content-center">
              <img style={{ width: "5%" }} src={logoComuniteCaa} />
              <p className="body2">
                COMUNITE<b>CAA</b>
              </p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size-md="1" size-xs="0"></IonCol>
            <IonCol size-md="10" size-xs="12" className="bg-white p-5 ">
              <div className="headerLogin p-1 m-0">
                <h3>Restablece la contraseña</h3>
              </div>
              {isActive ? (
                <div>
                  <form className="mt-4">
                    <IonRow>
                      <IonCol size="12" className="px-3 py-4">
                        <p>Introduce la nueva contraseña</p>
                        <IonItem className="my-3">
                          <IonInput
                            id="passwordInput1"
                            type={showPassword ? "text" : "password"}
                            required={true}
                            placeholder="Contraseña"
                            value={password}
                            onIonChange={(e) => {
                              // checkPassword(e.detail.value?.toString()!);
                              setPassword(e.detail.value?.toString());
                              // checkSubmit();
                            }}
                          ></IonInput>
                          <IonIcon
                            icon={showPassword ? eyeOutline : eyeOffOutline}
                            className="handCursor"
                            onClick={(e) => setShowPassword(!showPassword)}
                          ></IonIcon>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12" className="px-3 py-4">
                        <p>Repite la contraseña</p>
                        <IonItem className="my-3">
                          <IonInput
                            id="passwordInput2"
                            type={showPassword2 ? "text" : "password"}
                            required={true}
                            placeholder="Contraseña"
                            value={password2}
                            onIonChange={(e) => {
                              // checkPassword(e.detail.value?.toString()!);
                              setPassword2(e.detail.value?.toString());
                              // checkSubmit();
                            }}
                          ></IonInput>
                          <IonIcon
                            icon={showPassword2 ? eyeOutline : eyeOffOutline}
                            className="handCursor"
                            onClick={(e) => setShowPassword2(!showPassword2)}
                          ></IonIcon>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <p
                      className={`${showRedTextCoincidir ? "" : "hidden"}`}
                      style={{ color: "red" }}
                    >
                      * La contraseña no coincide.
                    </p>
                    <p
                      className={`${showRedTextPassword ? "" : "hidden"}`}
                      style={{ color: "red" }}
                    >
                      * Tu contraseña debe tener al menos 8 caracteres. Prueba
                      con otra.
                    </p>
                  </form>
                  <IonRow className="">
                    <IonCol className="d-grid mt-4 justify-content-center">
                      <IonButton
                        className="mt-4 btn-objetivos selected"
                        onClick={() => changePassword()}
                      >
                        <p>Modificar contraseña</p>
                      </IonButton>
                      <br />
                    </IonCol>
                  </IonRow>
                </div>
              ) : (
                <div>
                  <p  className="mt-4">Este enlace ya no está activo</p>
                </div>
              )}
            </IonCol>
            <IonCol size-md="1" size-xs="0" />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;

export function GlobalVariables() {
  const url = "https://backtea.qinera.com";
  const urlMedia = "https://backtea.qinera.com/uploads/";
  const urlApi = "https://backtea.qinera.com/api";

  // const url = "http://127.0.0.1:8000";
  // const urlMedia = "http://127.0.0.1:8000/uploads/";
  // const urlApi = "http://127.0.0.1:8000/api";





  return {
    url, urlMedia, urlApi
  };
}



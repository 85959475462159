import { IonItem, IonIcon, IonButton, IonPopover, IonCol, IonGrid, IonRow, IonInput, useIonAlert } from '@ionic/react';
import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { getData } from '../../api/apiRecursos';
import { Group, UserGroup } from '../../interfaces/intGroups';
import chevron_down from '../../img/iconos/chevron_down_black.svg';
import { Role } from '../../interfaces/intUser';
import AvisoPopUp from './AvisoPopUp';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';


interface Props {
  setRoles: any;
  setLastAction: any;
  lastAction: string;
  setTextAlert:any;
  setShowAlert:any;
}


const ChangeRolesPopUp: React.FC<Props> = ({ setRoles, setLastAction, lastAction, setTextAlert, setShowAlert }) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [popoverState2, setShowPopover2] = useState({ showPopover: false, event: undefined });
  var [userGroup, setUserGroup] = useState<UserGroup[]>([]);
  const [email, setEmail] = useState('');
  const [roleAdded, setRoleAdded] = useState("editor");
  const [userId, setUserId] = useState<number>();
  const [userName, setUserName] = useState<string>();
  const [positionInArray, setPositionInArray] = useState<number>();
  var arrayRoles = Array<string>();
  const [ role, setRole] = useState<Role>();
  const [userClickedRole, setUserClickedRole] = useState<number>();
  const {getCompanions} = GlobalFunctions();
  
  const userData = {
    token: Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  useEffect(() => {
    getData.post('/getRole', userData)
      .then(resp => {
        setRole(resp.data);
      })
      .catch(err => console.log(err));

    getData.post<UserGroup>('/relByGroup', userData)
      .then(resp => {
        //@ts-ignore
        setUserGroup(resp.data);
      })
      .catch(err => console.log(err));

  }, [lastAction]);


  const clickModificar = (nuevoRol: number, id: number) => {
     setShowPopover({ showPopover: false, event: undefined });
    userGroup[id].role = nuevoRol;
  }
  const showRelation = (role: number) => {
    switch (role) {
      case 1:

        return "colaborador";
      case 2:

        return "editor";
      case 3:

        return "propietario";
        case 4:

        return "quitar";
    }
  }
  
  const [checkEliminar] = useIonAlert();

  const clickEliminar = (index:number) => {
    // setShowAlert(false);
      checkEliminar({
        header: `Quitar del grupo`,
        message: `¿Seguro que quieres quitar a ${userName} de este grupo?`,
        buttons: [
          'CANCELAR', { text: 'QUITAR', handler: () => deleteRelation(index) },
        ],
        onDidDismiss: () => console.log(""),
      })

  }

  const deleteRelation = (index:number) => {
    getData.delete('/relUsersGroup/delete/'+userGroup[index].id)
        .then(() => {
          if (index == userGroup.length-1)
            setShowAlert(true);

          setTextAlert('Los cambios se han realizado correctamente.');
        })
        .then(() => {
          getCompanions();
        })
        .catch(err => console.log(err));
        
        setLastAction("Roles modificados "+Date.now());

  }

  const changeRole = () => {
    setRoles(false);
    for (let index = 0; index < userGroup.length; index++) {
      const userData = {
        role: userGroup[index].role
      }

      if (userGroup[index].role == 4) {
        clickEliminar(index);

      } else {
        getData.post('/relUsersGroup/' + userGroup[index].id, userData)
        .then(() => {
          if (index == userGroup.length-1)
          // alert("Canvi realitzat");

          if (userGroup[index].role != 4){
            setShowAlert(true);
          }

          setTextAlert('Los cambios se han realizado correctamente.');
        })
        .catch(err => console.log(err));
      }
      
    }
    setLastAction("Roles modificados "+Date.now());
  }

  const showOptions = () => {
    if (role?.role == 3 && userGroup.length < 2) {
      return <p className="body1" onClick={e=> clickModificar(3, positionInArray!)} ><a>propietario</a></p>
    } else if (role?.role == 2 && userClickedRole != 3) {
      return (
        <>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(1, positionInArray!)} >colaborador</p></a>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(2, positionInArray!)} >editor</p></a>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(4, positionInArray!)} >quitar</p></a>
        </>
      )

    } else if(userClickedRole == 3 && userGroup.filter(user => user.role == 3).length<2){
      return <a className='popup-select'><p className="body1" onClick={e=> clickModificar(3, positionInArray!)} >propietario</p></a>
    }else {
      return (
        <>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(1, positionInArray!)} >colaborador</p></a>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(2, positionInArray!)} >editor</p></a>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(3, positionInArray!)} >propietario</p></a>
          <a className='popup-select'><p className="body1" onClick={e=> clickModificar(4, positionInArray!)} >quitar</p></a>
        </>
      )
    }
  }

  

  return (
    <div className="modal modal_roles">
      <div className="modal_content ">
        <IonIcon src={close} className="close" onClick={() => setRoles(false)} />
        <IonGrid>
          <IonRow className="roleTitle">
            <IonCol size="12">
              <h5>Roles de los acompañantes</h5>
            </IonCol>

          </IonRow>
          {!Cookies.get('group_id')
            ? <IonGrid>
              <p>Primero debes seleccionar un usuario de CA</p>
            </IonGrid>
            : <>

              {userGroup.map((user, index) => {
                return (
                  <Fragment key={index}>
                    <IonRow className="roleRow" key={user.id}>
                      <IonCol size="6"><p className="body2">{user.name}</p></IonCol>
                      <IonCol size="6" className="text-right">
                        <a onClick={
                          (e: any) => {
                            e.persist();
                            setShowPopover({ showPopover: true, event: e });
                            setUserId(user.id);
                            setUserName(user.name);
                            setUserClickedRole(user.role);
                            setPositionInArray(index);
                          }} className="body2 text-naranja">
                          {showRelation(user.role)} <IonIcon src={chevron_down} />
                        </a>
                        <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverState.event}
                          isOpen={popoverState.showPopover}
                          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                        >
                          {showOptions()}
                         
                        </IonPopover>
                      </IonCol>
                    </IonRow>
                  </Fragment>
                )
              })}
              <IonRow>
                <IonCol className="d-flex justify-content-end mt-5">
                  <IonButton className="cancelBtn" onClick={e => setRoles(false)}><p>CANCELAR</p></IonButton>
                  <IonButton className="saveBtn" onClick={e => changeRole()}><p>GUARDAR</p></IonButton>
                </IonCol>
              </IonRow>
            </>}
        </IonGrid>
      </div>
    </div>
  );

};
export default ChangeRolesPopUp;
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import '../MenuLateral.css';


// LOGOS
import objetivosLogo from '../../img/iconos/objetivos-logo.svg';
import actividadesLogo from '../../img/iconos/actividades-logo.svg';
import recursosLogo from '../../img/iconos/recursos-logo.svg';

import activBW from '../../img/iconos/actividades-logo-bw.svg';
import recBW from '../../img/iconos/recursos-logo-bw.svg';
import objBW from '../../img/iconos/objetivos-logo-bw.svg';

interface ContainerProps {
  setActualPage?: any,
  actualPage: string
}

const MenuLateralBiblio: React.FC<ContainerProps> = ({ setActualPage, actualPage }) => {

  return(
    
    <div className="lateral-biblio mx-lg-4 px-4 pt-4">
      <h3>Mi biblioteca</h3>
      {/* OBJETIVOS */}
      <IonGrid className="my-4">
        <IonRow className="objetivos">
          <IonCol className="lineal-flex align-items-center bigger-hover handCursor" onClick={e => setActualPage("biblObjetivo")}>
            <img className="logotipo" src={actualPage == "biblObjetivo" ? objetivosLogo : objBW}/>
          <h4 className={actualPage == "biblObjetivo" ? "text-verd" : ""}>Objetivos</h4>
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* ACTIVIDADES */}
      <IonGrid className="my-4">
        <IonRow className="actividades">
        <IonCol className="lineal-flex align-items-center bigger-hover handCursor" onClick={e => setActualPage("biblActividad")}>
          <img className="logotipo" src={(actualPage == "biblActividad" || actualPage == 'biblNuevaActividad' || actualPage == 'biblModifActividad') ? actividadesLogo : activBW}/>
          <h4 className={(actualPage == "biblActividad" || actualPage == 'biblNuevaActividad' || actualPage == 'biblModifActividad') ? "text-lila" : ""}>Actividades</h4>
        </IonCol>
        </IonRow>
      </IonGrid>

      {/* RECURSOS */}
      <IonGrid className="my-4">
        <IonRow className="recursos">
          <IonCol className="lineal-flex align-items-center bigger-hover handCursor" onClick={e => setActualPage("biblRecurso")}>
            <img className="logotipo" src={(actualPage == "biblRecurso" || actualPage == "biblNuevoRecurso" || actualPage == "biblModifRecurso") ? recursosLogo : recBW}/>
            <h4 className={(actualPage == "biblRecurso" || actualPage == "biblNuevoRecurso" || actualPage == "biblModifRecurso") ? "text-blau" : ""}>Recursos</h4>
          </IonCol>
        </IonRow>
      </IonGrid>      
    </div>

  );

};
export default MenuLateralBiblio;
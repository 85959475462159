import { IonItem, IonIcon, IonButton, IonCol, IonGrid, IonRow, IonInput } from '@ionic/react';
import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getData } from '../../api/apiRecursos';
import { UserGroup } from '../../interfaces/intGroups';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';

import { useAppSelector } from '../../app/hooks'


interface Props {
  setAdd: any;
  setLastAction: any;
  setShowAlert:any,
  setTextAlert:any,
}

const AddNewUserPopUp: React.FC<Props> = ({ setAdd, setLastAction, setShowAlert, setTextAlert }) => {
  const [userGroup, setUserGroup] = useState<UserGroup[]>([]);
  const [email, setEmail] = useState('');
  const [roleAdded, setRoleAdded] = useState("editor");
  const [isCorrect, setIsCorrect] = useState(false);
  const {isEmail, addGroupToUser} = GlobalFunctions();
  const [showRedInfo, setShowRedInfo] = useState(false);
  const [redText, setRedText] = useState("");

  const groupID = useAppSelector(state => state.reducer.groupSelected.id);




  const userData = {
    token: Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  useEffect(() => {
    getData.post<UserGroup>('/relByGroup', userData)
      .then(resp => {
        //@ts-ignore
        setUserGroup(resp.data);
      })
      .catch(err => console.log(err));

  }, []);


  const añadirUserGrupo = () => {   
    const userData = {
      token:Cookies.get('token'),
      role: 1,
      email: email,
      group_id: groupID
    }

    addGroupToUser(userData, setShowRedInfo,setRedText, setAdd, setTextAlert, setShowAlert, setLastAction );
  }

  const checkMail = (mail:string) => {
    if (isEmail(mail)){
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  }

  return (
    <div className="modal modal_add">
      <div className="modal_content ">
        
        <IonIcon src={close} className="close" onClick={() => setAdd(false)} />
        <IonGrid>
          <IonRow className="roleTitle">
            <IonCol size="12">
            <h5>Añadir acompañante al grupo</h5>
            </IonCol>

          </IonRow>
          {!Cookies.get('group_id')
            ? <IonGrid>
              <p>Primero debes seleccionar un usuario de CA</p>
            </IonGrid>
            : <>
              <IonRow>
                <IonCol size="12">
                  <IonItem>
                    <IonCol>
                      <IonInput className="body2" required={true} type="email" placeholder="Correo electrónico" onIonChange={e => {setEmail(e.detail.value?.toString()!); checkMail(e.detail.value?.toString()!)}}></IonInput>
                    </IonCol>
                    
                  </IonItem>
                </IonCol>
              </IonRow>
              <p className={`small ${showRedInfo ? "" : 'hidden'}`}style={{'color':'red'}}>{redText}</p>
              <IonRow>
                <p className="body2 text-gris">Se le enviará una notificación por correo electrónico para que sepa que lo has añadido como acompañante.</p>
              </IonRow>
              <IonRow>
                <IonCol className="d-flex justify-content-end">
                  <IonButton className="cancelBtn" onClick={() => setAdd(false)}><p>CANCELAR</p></IonButton>
                  <IonButton className="saveBtn" disabled={isCorrect ? false : true} onClick={() => añadirUserGrupo()}><p>AÑADIR</p></IonButton>
                </IonCol>
              </IonRow>
            </>}
        </IonGrid>
      </div>
    </div>
  );

};
export default AddNewUserPopUp;
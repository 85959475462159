import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonLoading, IonPopover, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";

/* COMPONENTES */
import TituloRecurso from "../../components/TituloRecurso";
import Recurso from "../../components/Recursos/Recurso";


/* ICONOS */
import editar from '../../img/iconos/lapiz.svg';
import check from '../../img/iconos/check_blanco.svg';
import addCircle from '../../img/iconos/add-circle.svg';
import arrowBot from '../../img/iconos/flecha_azul.svg';

/* CSS */
import '../../theme/global.css';
import Cookies from "js-cookie";
import { useHistory } from "react-router";
import { Role } from "../../interfaces/intUser";
import AvisoPopUp from "../PopUpModals/AvisoPopUp";

//REDUX
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { getData } from "../../api/apiRecursos";



interface ContainerProps {
  setActualPage: any,
  setSelectedId: any,
  setLastAction: any,
  lastAction: string,
  setShowBack?: any,
  setShowAlert: any,
  showAlert:boolean,
  textAlert:string,
  setTextAlert:any,
}

const Recursos: React.FC<ContainerProps> = ({setTextAlert, setShowAlert,textAlert, showAlert, setShowBack, setActualPage, setSelectedId, setLastAction, lastAction }) => {
  //REDUX
  const resources = useAppSelector(state => state.reducer.groupSelected.resources);
  const roleUser = useAppSelector(state => state.reducer.groupSelected.userRole);
  const role = useAppSelector(state => state.reducer.groupSelected.userRole?.role);
  
  const [estado, setEstado] = useState("todo");
  const [showHide, setShowHide] = useState(false);
  const [isReversed, setReversed] = useState(false);
  const history = useHistory();
  const [emptyResources, setEmptyResources] = useState(true);
  const [order, setOrderBy] = useState("Alfabéticamente");
  const [loadingObjetDefecto, setLoadingObjetDefecto] = useState(false);


  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [popoverStateNuevo, setShowPopoverNuevo] = useState({ showPopover: false, event: undefined });

  function changeOrder(): void {
    if (isReversed) {
      setReversed(!isReversed);
    } else {
      setReversed(!isReversed);
    }
  }

  function setOrder(text: string): void {
    setOrderBy(text);
    setShowPopover({ showPopover: false, event: undefined });
  }


  useEffect(() => {
    if (role != 1) {
      setShowHide(true);
    }

    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      history.push('');
    }
  }, [])

  const abrirBiblio = () => {
    // setShowPopoverNuevo({ showPopover: !popoverStateNuevo.showPopover, event: undefined })
    // setShowPopoverNuevo({ showPopover: false, event: undefined });
    history.push({
      pathname: '/biblioteca/recursos',
    })
  }

  const setDefaultResources = () => {
    setLoadingObjetDefecto(true)
    const data = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    };
    getData.post("/resources/storeDefault", data)
      .then((res: any) => {
        setLastAction("Recursos por defecto asignados " + Date.now());
      })
      .then(() =>
        setLoadingObjetDefecto(false)
      )
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  return (
    <>
      <IonGrid className="container">
      <IonLoading
          isOpen={loadingObjetDefecto}
          onDidDismiss={() => setLoadingObjetDefecto(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          duration={10000}
        />
        <IonRow className="rowTitulo">
          <TituloRecurso titulo="Todos los recursos" />
          <IonCol size="4" className="d-flex justify-content-end align-self-center btns-title">
            {showHide &&  resources.length >= 1
              ?
              <>
                <button className="addObjetivo bigger-hover" onClick={
                  (e: any) => {
                    e.persist();
                    setShowPopoverNuevo({ showPopover: true, event: e })
                  }}>
                  <IonCol>
                    <IonIcon className="grande" src={addCircle}></IonIcon>
                    <p className="body2">Crear nuevo<br />recurso</p>
                  </IonCol>
                  <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateNuevo.event}
                    isOpen={popoverStateNuevo.showPopover}
                    onDidDismiss={() => setShowPopoverNuevo({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select' onClick={() => setActualPage("resourceNew")}><p className="body1">Crear nuevo recurso</p></a>
                    {/* <a className='popup-select'><p className="body1" onClick={() => setActualPage("resourceComunitecaa")}>Añadir recursos de ComuniteCAA</p></a> */}
                    <a className='popup-select'><p className="body1" onClick={() => { setShowPopoverNuevo({ showPopover: !popoverStateNuevo.showPopover, event: undefined }); setActualPage("resourceBiblio");setShowPopoverNuevo({ showPopover: false, event: undefined })}}>Añadir recursos de Mi biblioteca</p></a>
                    
                  </IonPopover>
                </button>
              </>
              : null
            }
          </IonCol>
        </IonRow>

        { resources.length < 1
          ? null
          : <IonRow>
            <IonCol size="12" className="lineal-flex my-3">
              <div>
                {showHide
                  ? <>
                    <button className={`btn-recursos titulo-seccion ${estado === "todo" ? "selected" : null}`}
                      onClick={(e) => setEstado("todo")}>
                      Todos
                    </button>
                    <button className={`btn-recursos titulo-seccion ${estado === "activo" ? "selected" : null}`}
                      onClick={(e) => setEstado("activo")}>
                      Activos
                    </button>
                    <button className={`btn-recursos titulo-seccion ${estado === "inactivo" ? "selected" : null}`}
                      onClick={(e) => setEstado("inactivo")}>
                      Inactivos
                    </button>
                  </>
                  : null
                }
              </div></IonCol>
              <IonCol size="12" className="d-flex">
              <div className="lineal-flex ml-auto align-items-center my-2">
                <p className="body2 text-blau mx-2" onClick={
                  (e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e })
                  }}>{order}</p> <IonIcon src={arrowBot} className={`${isReversed ? "reversed" : ""} text-blau medio`} onClick={() => changeOrder()} />
                <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverState.event}
                  isOpen={popoverState.showPopover}
                  onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                >
                  <a className='popup-select'><p className="body1 text-gris">Ordenar por</p></a>
                  <a className='popup-select'><p className="body1" onClick={() => setOrder("Tipo")}>Tipo</p></a>
                  <a className='popup-select'><p className="body1" onClick={() => setOrder("Alfabéticamente")}>Alfabéticamente</p></a>
                </IonPopover>
              </div>
            </IonCol>
          </IonRow>}


 
        <IonRow>
          <Recurso setTextAlert={setTextAlert} setShowAlert={setShowAlert} setShowBack={setShowBack} estado={estado} orden={order} isReversed={isReversed} showHide={showHide} setSelectedId={setSelectedId} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
        </IonRow>
      </IonGrid>

    </>
  );
};

export default Recursos;
import { IonGrid, IonRow, IonCol, IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';

/* COMPONENTES */
import Header from '../components/Header';

/* CSS */
import '../theme/global.css';
import '../theme/responsive.css';

/* IMAGENES */
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { getData } from '../api/apiRecursos';
import { Group } from '../interfaces/intGroups';
import { Role, User } from '../interfaces/intUser';
import { useHistory } from 'react-router';
import Objetivos from '../components/Objetivos/Objetivos';
import ObjetivoDetalle from '../components/Objetivos/ObjetivoDetalle';
import ObjetivoModificar from '../components/Objetivos/ObjetivoModificar';
import ObjetivoNuevo from '../components/Objetivos/ObjetivoNuevo';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'



const ObjectiusMvl: React.FC = () => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const role = useAppSelector(state=>state.reducer.groupSelected.userRole);

  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);

  useIonViewWillEnter(() => {
    setActualPage('objetivos');
  });

  let history = useHistory();
  const [group, setGroup] = useState<Group>();
  const [lastAction, setLastAction] = useState("");
  const [textAlert, setTextAlert ] = useState("");

  //@ts-ignore
  const [actualPage, setActualPage] = useState("objetivos");
  const [selectedId, setSelectedId] = useState<number>();
  const [showBack, setShowBack] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const userData = {
    'token': Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  // useEffect(() => {
  //   getData.post('/getRole', userData)
  //     .then(resp => {
  //       setRole(resp.data);
  //     })
  //     .catch(err => console.log(err));
  // }, [])
  

  useEffect(() => {
    if (!Cookies.get('token')) {
      history.push("/");
    }
    getData.get<Group>('/getGroups/' + Cookies.get('group_id'))
      .then(resp => {
        //@ts-ignore
        setGroup(resp.data);
      })
      .catch(err => console.log(err));

    // setUserAvatar(window.localStorage.getItem('userPhoto')!);

  }, [lastAction])

  const showContent = () => {

    switch (actualPage) {
      // Carga componentes Objetivos
      case "objetivos":
        return <Objetivos textAlert={textAlert} setTextAlert={setTextAlert} setShowAlert={setShowAlert} showAlert={showAlert} setShowBack={setShowBack} setSelectedId={setSelectedId} setActualPage={setActualPage}  setLastAction={setLastAction} lastAction={lastAction}/>
      case "objectiveDetail":
        return <ObjetivoDetalle setShowBack={setShowBack} showBack={showBack} selectedId={selectedId!} setActualPage={setActualPage} />
      case "objectiveNew":
        return <ObjetivoNuevo setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction}/>
      case "objectiveModify":
        return <ObjetivoModificar setTextAlert={setTextAlert}  setShowAlert={setShowAlert} selectedId={selectedId!} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction}/>


      default:
        break;
    }

  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

          


  return (
    <>
      {Cookies.get('group_id')
      ?
      <>
        <IonPage className="mvl">
        {showAlert
            ? <>{showPopUp()} <AvisoPopUp text={textAlert}/></>
            : null}
          <Header name={group?.name!} avatar={group?.media_name!} avatarProp={userAvatar} setLastAction={setLastAction} lastAction={lastAction}/>

          <IonContent fullscreen>
            <IonGrid>
            
              <IonRow>
                <IonCol className="right">
                  {showContent()}
                </IonCol>
              </IonRow>
            </IonGrid>
                      
          </IonContent>
        </IonPage>
      </>
      : history.push('/')}
    </>

  );
};

export default ObjectiusMvl;

import { IonRow, IonCol, IonGrid, useIonAlert, IonLoading } from "@ionic/react";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";

import addIcon from '../../img/iconos/add_icon.svg';
import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import { EtapaThis, ObjActiv } from "../../interfaces/intObjective";
import objLogoMin from '../../img/iconos/objetivos-logo-min.svg';
import moreIcon from '../../img/iconos/plus.svg';
import $ from 'jquery';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";


interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  type: string,
  setShowAlert: any,
  setTextAlert:any,
  setShowDetail: any,
  setSelectedId: any,
}

const ActividadesObjetivosTodos: React.FC<ContainerProps> = ({ setShowDetail, setSelectedId, setTextAlert, setShowAlert, setActualPage, setLastAction }) => {
  const history = useHistory();
  const [competencia, setCompetencia] = useState<string>('');
  const [objectsActiv, setObjectsActiv] = useState<ObjActiv>();
  const [loadingCopy, setLoadingCopy] = useState(false);
  const [loading, setLoading] = useState(true);
  const {getActivities, storeActivityCopy} = GlobalFunctions();



  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    const userData = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    }

    getData.post<ObjActiv>('/objectiveActivitiesTodas', userData)
      .then(resp => {
        //@ts-ignore
        setObjectsActiv(resp.data);
        setCompetencia(resp.data.categories[0].name)
        console.log("TEST ACTIV", resp.data)
      })
      .then(resp => {
        //@ts-ignore
        setLoading(false)
      })
      .catch(err => console.log(err));
  }, [])

  const [checkCopiar] = useIonAlert();
  const [checkNoObj] = useIonAlert();

  const clickCopiar = (activId: number, objId: number, objective_active: boolean) => {
    if (objective_active) {
      checkCopiar({
        message: `¿Seguro que quieres añadir esta actividad?`,
        buttons: [
          'CANCELAR', 
          { text: 'AÑADIR', handler: () => copyActivity(activId, objId, 'no') }
        ],
        onDidDismiss: () => console.log(""),
      })
    } else {
      checkNoObj({
        message: `Esta actividad tiene asociado un objetivo que no ha sido definido para este usuario. ¿Qué deseas hacer? `,
        cssClass: "custom-alert",
        buttons: [
          { text: 'CANCELAR', 
            role: 'cancel',
            cssClass: 'custom-btn alert-cancel'
          },
          { text: 'AÑADIR SOLO ACTIVIDAD', 
            role: 'activity',
            handler: () => copyActivity(activId, objId, 'no'),
            cssClass: 'custom-btn alert-activity'
          },
          { text: 'AÑADIR ACTIVIDAD Y OBJETIVO', 
            role: 'objet',
            handler: () => copyActivity(activId, objId, 'yes'), 
            cssClass: 'custom-btn alert-object-too'
          },
          
        ],
        onDidDismiss: () => console.log(""),
      })
    }
    
  }

  const copyActivity = (activId: number, objId: number, copyObjective: string) => {
    setLoadingCopy(true);
    const dataRelation = {
      token: Cookies.get('token'),
      activity_id: activId,
      group_id: Cookies.get('group_id'),
      objective_id: objId,
      is_template: true,
      copyObjective: copyObjective
    };


    storeActivityCopy(dataRelation, setLoadingCopy, setShowAlert, setTextAlert);
  }
  
  const showListObjectives = (etapa: EtapaThis, i: number, sinCategoria = false) => {
    return (
      <><IonGrid className={`interiorEtapa panel-interior panel-interior${i} ${sinCategoria ? 'show' : ''}`}>
        <IonRow>
          {etapa.objectives.filter(obj => obj.category_name == competencia).map((objective, index) =>
            <IonRow className="w-100" key={index} >
              <IonCol size="12" className="d-flex">
                <p className="body1 align-self-center" style={{'marginLeft':'5px'}}>{objective.obj_name} <br /> <span className="overline text-verd">{objective.category_name}</span></p>
              </IonCol>
              <IonGrid>
                <IonRow>
                  {objective.activities.map((activity, index) =>
                    <IonCol size-xs="6" size-md="4" key={index} >
                      <div id={`activity-${activity.id}`} onClick={e => setSelectedId(activity.id, 'activity')} className={`actividad boxActividad pt-3`}>
                        <a className="lineal-flex" onClick={e => setSelectedId(activity.id, 'activity')}>
                          <p className="body2 activity-clipped-text">{activity.name == "" ? activity.description : activity.name }</p>
                        </a>
                      </div>
                      <img src={addIcon} className="fabAdd handCursor" onClick={e => { clickCopiar(activity.id!, objective.obj_template_id!, activity.objective_active!) }} />
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </IonRow>
          )}
        </IonRow>
      </IonGrid>
      </>
    );
  }
  const openEtapa = (panelNumber: number) => {
    var panelInterior = $('.panel-interior'+panelNumber);
    var verticalLine = $('.vertical-line-'+panelNumber);

    if (panelInterior.hasClass('show')){
      panelInterior.removeClass('show');
      verticalLine?.removeClass('show');
    } else {
      panelInterior.addClass('show');
      verticalLine?.addClass('show');
    }
   
  }



  return (
    <>
    <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <IonLoading
        isOpen={loadingCopy}
        onDidDismiss={() => setLoadingCopy(false)}
        message={'Copiando actividad'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={9999999}
      />

      <IonRow className="row mt-2" >
        <p className="body1 text-gris"><b>Competencia</b></p>
        <IonCol size="11" className="lineal-flex">
          {objectsActiv?.categories.map((categoria, index) =>
            <Fragment key={index}>
              <button
                className={`btn-objetivos titulo-seccion
                          ${competencia === categoria.name
                    ? "selected"
                    : null}`}
                onClick={() => setCompetencia(categoria.name)}>
                {categoria.name}
              </button>
            </Fragment>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <p className="body1 text-gris"><b>Etapa y objetivos</b></p>
      </IonRow>

      {objectsActiv?.etapas.map((etapa, index) =>
        <Fragment key={index}>
          {etapa.objectives.filter(obj => obj.category_name == competencia).length > 0
            ? <>
              <IonRow className="pb-2 etapa-titulo mt-3 etapa">
                <IonCol size="12" className="d-flex interior">
                  <div className='cnt-vertical-line'>
                    <img src={objLogoMin} className='grande 3rem' />
                    <div className={`vertical-line2 vertical-line-${index}`}>
                    </div>
                  </div>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <div className="contenidorEtapa">
                          <div className='d-flex handCursor'  >
                            <h5 className="accordion" onClick={e => openEtapa(index)} id={`accordion${index}`} >{etapa.nom}</h5>
                            <img src={moreIcon} style={{}} onClick={e => openEtapa(index)} />
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      {showListObjectives(etapa, index)}
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </>
            : null
          }
        </Fragment>
      )}
    </>
  );
};
export default ActividadesObjetivosTodos;







import { IonProgressBar, IonGrid, IonRow, IonCol, IonIcon, IonPopover, IonAvatar, IonLoading } from '@ionic/react';
import './MenuLateral.css';


// LOGOS
import objetivosLogo from '../img/iconos/objetivos-logo.svg';
import actividadesLogo from '../img/iconos/actividades-logo.svg';
import recursosLogo from '../img/iconos/recursos-logo.svg';
import acompañantesLogo from '../img/iconos/acompanyantes-logo.svg';
import logoImg from '../img/iconos/img-small.svg';
import logoVid from '../img/iconos/video-small.svg';
import logoLink from '../img/iconos/enlace-small.svg';
import logoPdf from '../img/iconos/pdf-small.svg';
import logoAud from '../img/iconos/audio-small.svg';
import chevronRight from '../img/iconos/chevron-right.svg';
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';
import nouAcompanyant from '../img/iconos/nouAcompanyant.svg';


// JSON DATA
// import {objetivos as objectives} from "../data/objetivos.json";
import { Fragment, useState } from 'react';
import Cookies from 'js-cookie';
import AddNewUserPopUp from './PopUpModals/AddNewUserPopUp';
import ImportObjectivePopUp from './PopUpModals/ImportObjectivePopUp';
import { useHistory } from 'react-router';
import { GlobalFunctions } from '../hooks/GlobalFunctions';

//REDUX
import { useAppSelector } from '../app/hooks'
import { GlobalVariables } from '../hooks/GlobalVariables';




function logoRecurso(tipo: string) {
  switch (tipo) {
    case "imagen":
      return <IonIcon className="grande mx-2" src={logoImg} />

    case "video":
      return <IonIcon className="grande mx-2" src={logoVid} />

    case "link":
      return <IonIcon className="grande mx-2" src={logoLink} />

    case "pdf":
      return <IonIcon className="grande mx-2" src={logoPdf} />

    case "audio":
      return <IonIcon className="grande mx-2" src={logoAud} />
    default:
      break;
  }
}

interface ContainerProps {
  setActualPage?: any,
  setSelectedId?: any,
  setLastAction: any,
  lastAction: string
  role?: number,
  setShowBack: any,
  setShowAlert: any,
  setTextAlert: any,
}



const MenuLateral: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setShowBack, setActualPage, setSelectedId: setSelectedId, setLastAction }) => {
  //REDUX
  const idGroupSelected = useAppSelector(state=>state.reducer.groupSelected.id);
  const recursos = useAppSelector(state=>state.reducer.groupSelected.resources);
  const objectives = useAppSelector(state=>state.reducer.groupSelected.objectives);
  const activities = useAppSelector(state=>state.reducer.groupSelected.activities);
  const company = useAppSelector(state=>state.reducer.groupSelected.companions);
  const role = useAppSelector(state=>state.reducer.groupSelected.userRole?.role);


  const {getActivities, getResources, getObjectives, getGroupData, setDefaultObjectives} = GlobalFunctions();
  
  const exportList = useAppSelector(state=> state.reducer.groupSelected.exportedObjectives);
  const [add, setAdd] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const axios = require('axios');

  const [popoverStateObj, setShowPopoverObj] = useState({ showPopover: false, event: undefined });
  const [popoverStateActiv, setShowPopoverActiv] = useState({ showPopover: false, event: undefined });
  const [popoverStateRec, setShowPopoverRec] = useState({ showPopover: false, event: undefined });

  const [loadingObjetDefecto, setLoadingObjetDefecto] = useState(false);
  const history = useHistory();


  const mostrarActivity = (id: number) => {
    setSelectedId(id)
    setActualPage("activityDetail");
    setShowBack(false);
  }
  const mostrarObjective = (id: number) => {
    setSelectedId(id);
    setActualPage("objectiveDetail");
    setShowBack(false);
  }
  const mostrarResource = (id: number) => {
    setSelectedId(id)
    setActualPage("resourceDetail");

    setShowBack(false);
  }
  
  const {urlApi, urlMedia} = GlobalVariables();

  const api = axios.create({
    baseURL: urlApi,
  });


  const setDefObjectives = () => {
    setLoadingObjetDefecto(true)
    const dataObjetivo = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    };

    setDefaultObjectives(dataObjetivo, setLoadingObjetDefecto, setShowPopoverObj, setShowAlert, setTextAlert, setActualPage);
  }

  const setDefaultResources = () => {
    setLoadingObjetDefecto(true)
    const data = {
      token: Cookies.get('token'),
      group_id: idGroupSelected,
    };
    api.post("/resources/storeDefault", data)
      .then((res: any) => {
        setShowPopoverRec({ showPopover: false, event: undefined });
        setTextAlert('Recursos de ComuniteCAA creados correctamente.')
        setShowAlert(true);

        setActualPage("recursos");
        // alert("Objetivos por defecto creados correctamente");
        // setLastAction("Recursos por defecto asignados " + Date.now());
      })
      .then(() =>
        setLoadingObjetDefecto(false)
      )
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  return (

      <div id='menu-lateral' className="container-left">
        <div>
        <IonLoading
          isOpen={loadingObjetDefecto}
          onDidDismiss={() => setLoadingObjetDefecto(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          duration={200000}
        />
        {/* OBJETIVOS */}
        <IonGrid id="ml-objetivos" className="menu-lateral-objetivos">
          <IonRow className="objetivos">
            <IonCol className="lineal-flex align-items-center">
              <img className="logotipo" src={objetivosLogo} />
              <h4>Objetivos</h4>
              {objectives.length < 1
                ? null
                : <a className="text-naranja" onClick={e => setActualPage("objetivos")}>
                  VER TODO
                </a>}

            </IonCol>

          </IonRow>
          <div style={{ 'borderBottom': 'none' }}>
            {objectives.map((objetivo, i) =>
              <Fragment key={i}>
                {objetivo.important == 1
                  ?
                  <IonRow className="objetivo">
                    <p className="body2 col-12"><a className="body2" onClick={e => { mostrarObjective(objetivo.id) }}>{objetivo.name}</a></p>
                    {objetivo.category_name != 'Sin competencia' ? <p className="overline col-12">{objetivo.category_name}</p> : null}
                    <IonProgressBar value={objetivo.value / 100}></IonProgressBar>
                  </IonRow>
                  : null}
              </Fragment>
            )}
          </div>
          {objectives.length < 1
            ? <IonRow className="sinElementos">
              {role != 1
                ? <>
                <p className="body1 w-100">Empieza a definir los objetivos.</p>
                  <a className="body2 mt-5" onClick={
                    (e: any) => {
                      e.persist();
                      setShowPopoverObj({ showPopover: true, event: e })
                    }}><p className="body1 col-12 text-naranja no-break">ESTABLECER OBJETIVOS <IonIcon className='aligned-text-link-icon' src={chevronRight} /></p></a>
                  <IonPopover
                    event={popoverStateObj.event}
                    isOpen={popoverStateObj.showPopover}
                    onDidDismiss={() => setShowPopoverObj({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select' onClick={e => { setShowPopoverObj({ showPopover: false, event: undefined }); setActualPage("objectiveNew"); }}><p className="body1">Crear objetivos desde cero</p></a>
                    <a className='popup-select'><p className="body1" onClick={e => setDefObjectives()}>Usar set de objetivos ComuniteCAA</p></a>
                    {exportList.length > 0 
                      && 
                      <a className='popup-select'><p className="body1" onClick={() => { setShowPopoverObj({ showPopover: false, event: undefined }); setShowImportModal(true) }}>Usar set de objetivos de Mi biblioteca</p></a>
                    }
                  </IonPopover>
                </>
                :
                <p className="body1">Aún no hay objetivos definidos.</p>
              }
            </IonRow>
            : null}


        </IonGrid>

        {/* ACTIVIDADES */}
        <IonGrid className="menu-lateral-actividades">
          <IonRow className="actividades">
            <IonCol className="lineal-flex align-items-center">
              <img className="logotipo" src={actividadesLogo} />
              <h4>Actividades</h4>
              {activities.length < 1
                ? null
                : <a className="text-naranja" onClick={e => setActualPage("actividades")}>
                  VER TODO
                </a>}
            </IonCol>
          </IonRow>
          {activities.length < 1
            ? <IonRow className="sinElementos">
              {role != 1
                ? <><p className="body1 w-100">Empieza a elaborar las actividades.</p>
                  <a className="body2 mt-5" onClick={
                    (e: any) => {
                      e.persist();
                      setShowPopoverActiv({ showPopover: true, event: e })
                    }}><p className="body1 col-12 text-naranja no-break aligned-text-link">CREAR ACTIVIDADES <IonIcon className='aligned-text-link-icon' src={chevronRight} /></p></a>
                  <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateActiv.event}
                    isOpen={popoverStateActiv.showPopover}
                    onDidDismiss={() => setShowPopoverActiv({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select' onClick={() => { setActualPage("activityNew"); setShowPopoverActiv({ showPopover: false, event: undefined }) }}><p className="body1">Crear nueva actividad</p></a>
                    {/* <a><p className="body1" onClick={() => { setShowPopoverActiv({ showPopover: false, event: undefined }); setActualPage('activObjetivosActivos') }}>Añadir actividades de ComuniteCAA</p></a> */}
                    <a className='popup-select'><p className="body1" onClick={() => {  setShowPopoverActiv({ showPopover: false, event: undefined }); setActualPage('activityBiblio') }}>Añadir actividades de Mi biblioteca</p></a>
                  </IonPopover>
                </>
                :
                <p className="body1">Aún no hay actividades creadas.</p>
              }
              </IonRow>
            : <IonRow className="mb-2 marginElementos">
                {activities.map((actividad, i) =>
                  <Fragment key={i}>
                    {actividad.status !== 2 && actividad.important == 1 ?
                      <IonCol key={i}  size="6">
                        <div className="actividad p-3 d-flex align-items-start handCursor" onClick={e => mostrarActivity(actividad.id)}>
                          <p className="body2 col-12 activity-clipped-text-medium"><a className="body2"  dangerouslySetInnerHTML={{ __html: actividad.name }}>
                          </a></p>
                        </div>
                      </IonCol>
                      : null
                    }
                  </Fragment>
                )}
              </IonRow>
            }
        </IonGrid>

        {/* RECURSOS */}
        <IonGrid className="menu-lateral-recursos">
          <IonRow className="recursos">
            <IonCol className="lineal-flex align-items-center">
              <img className="logotipo" src={recursosLogo} />
              <h4>Recursos</h4>

              {recursos != undefined && recursos.length < 1
                ? null
                : <a className="text-naranja" onClick={e => setActualPage("recursos")}>
                  VER TODO
                </a>}
            </IonCol>
          </IonRow>
          {recursos != undefined && recursos.length < 1
            ? <IonRow className="sinElementos">
              {role!= 1
                ? <><p className="body1 w-100">Empieza a elaborar los recursos.</p>
                  <a className="body2 mt-5" onClick={
                    (e: any) => {
                      e.persist();
                      setShowPopoverRec({ showPopover: true, event: e })
                    }}><p className="body1 col-12 text-naranja no-break aligned-text-link">CREAR RECURSOS <IonIcon className='aligned-text-link-icon' src={chevronRight} /></p></a>
                  <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateRec.event}
                    isOpen={popoverStateRec.showPopover}
                    onDidDismiss={() => setShowPopoverRec({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select' onClick={() => { setActualPage("resourceNew"); setShowPopoverRec({ showPopover: false, event: undefined }) }}><p className="body1">Crear nuevo recurso</p></a>
                    {/* <a><p className="body1" onClick={() =>  { setActualPage("resourceComunitecaa"); setShowPopoverRec({ showPopover: false, event: undefined }) }}>Añadir recursos de ComuniteCAA</p></a> */}
                    <a className='popup-select'><p className="body1" onClick={() => { setActualPage("resourceBiblio"); setShowPopoverRec({ showPopover: false, event: undefined }) }}>Añadir recursos de Mi biblioteca</p></a>
                  </IonPopover>
                </>
                :
                <p className="body1">Aún no hay recursos compartidos.</p>
              }
            </IonRow>
            : <>
              {recursos.map((recurso, index) =>
                <Fragment key={index}>
                  {recurso.important == 1
                    ? <IonRow key={index} className="marginElementos">
                      <IonCol size="12" className="lineal-flex" >
                        <div className='menuLateral-recurso' onClick={e => mostrarResource(recurso.id)}>
                          {logoRecurso(recurso.type)}
                          <a ><p className="body2">{recurso.name}</p></a>
                        </div>
                      </IonCol>
                    </IonRow>
                    : null}
                </Fragment>
              )}</>
          }
          
        </IonGrid>

        {/* ACOMPAÑANTES */}
        <IonGrid className='menu-lateral-acompañantes'>
          {/* {setVisible()} */}
          <IonRow className="acompañantes">
            <IonCol className="lineal-flex align-items-center">
              <img className="logotipo" src={acompañantesLogo} />
              <h4>Acompañantes</h4>
            </IonCol>
          </IonRow>
          <IonRow className="acompanyant acompanyant-lateral p-2">
            {company.map((acompañante, index) =>
              <IonCol size-xl="3" size-md="4" size-lg="6" size-sm="6" key={index}>
                {acompañante?.media_name != undefined
                  ? <IonAvatar className="m-auto">
                    {/* <IonSkeletonText animated className={`m-0 skeleton center border-gris`}/> */}
                    <img className="photo center  border-gris" src={`${urlMedia}${acompañante?.media_name}`} />
                  </IonAvatar>
                  : <IonAvatar className="m-auto">
                    {/* <IonSkeletonText animated className={`m-0 skeleton center border-gris`}/> */}
                    <img className="photo  center" style={{ 'border': 'none' }} src={noPhotoAvatar} />
                  </IonAvatar>}
                <p className="body2 text-center">{acompañante.name}</p>
              </IonCol>
            )}
            {role != 1
              ? <IonCol size-xl="3" size-md="4" size-lg="6" size-sm="6">
                  <img className="img-avatar center handCursor bigger-hover" src={nouAcompanyant} onClick={e => setAdd(true)} />
                </IonCol>
              : null}

            {add ? <AddNewUserPopUp setTextAlert={setTextAlert} setShowAlert={setShowAlert} setAdd={setAdd} setLastAction={setLastAction}></AddNewUserPopUp> : null}


          </IonRow>
        </IonGrid>

        {showImportModal ? <ImportObjectivePopUp setTextAlert={setTextAlert} setShowAlert={setShowAlert} setShowImportModal={setShowImportModal} setLastAction={setLastAction} /> : null}
        </div>
      </div>

  );

};
export default MenuLateral;




/* CSS */
import '../theme/global.css';


import { IonRow, IonGrid, IonInput, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonImg, IonLoading, IonPopover } from '@ionic/react';
import Cookies from 'js-cookie';
import { useState, useRef, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import HeaderEmpty from '../components/HeaderEmpty';
import { Group } from '../interfaces/intGroups';
import { getData } from '../api/apiRecursos';
import { Media } from '../interfaces/intPosts';
import noPhotoGroup from '../img/iconos/noPhoto_group.svg';
import { usePhotoGallery } from '../hooks/usePhotoGallery';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';
import close from '../img/iconos/close.svg';
import { isPlatform } from '@ionic/react';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { GlobalVariables } from '../hooks/GlobalVariables';



interface ObjetivoDetalleProps
  extends RouteComponentProps<{
    id: string;
  }> { }

const EditarUsuario: React.FC<ObjetivoDetalleProps> = ({ match }) => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);


  const [group, setGroup] = useState<Group>();
  const [isSelected, setSelected] = useState(false);
  const [nombreCompleto, setNombreCompleto] = useState<string>();
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState("");
  const formData = new FormData();
  const [loadingFoto, setLoadingFoto] = useState(false);
  const [loadingCambios, setLoadingCambios] = useState(false);
  const { takePhoto, lastPhoto, filePhoto, setLastPhoto } = usePhotoGallery();
  const [customFilename, setCustomFilename] = useState<string>();
  const [popoverStateMvl, setShowPopoverMvl] = useState({ showPopover: false, event: undefined });
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [changed, setChanged] = useState(false);

  document.getElementById("tabBar")?.setAttribute("style", "display:none !important")
  const fileInput = useRef(null);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  }

  const {urlMedia} = GlobalVariables();
  const imgBackground = {
    backgroundImage: "url("+urlMedia + fileName + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  const imgPhotoBackground = {
    backgroundImage: "url(" + lastPhoto?.webviewPath + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  const imgCustomBackground = {
    backgroundImage: "url("+urlMedia + customFilename + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }


  useEffect(() => {
    getData.get<Group>('/getGroups/' + match.params.id)
      .then(resp => {
        //@ts-ignore
        setGroup(resp.data); setNombreCompleto(resp.data.name);
        setFileName(resp.data.media_name!);
        setCustomFilename(resp.data.media_name!);
      })
      .catch(err => console.log(err))

    // setUserAvatar(window.localStorage.getItem('userPhoto')!);

  }, [])


  const modifyGroup = () => {
    console.log("NOMBRE", nombreCompleto)
    const arr = nombreCompleto!.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    setLoadingCambios(true);
    if (lastPhoto != undefined) {
      formData.append('media', filePhoto!);
    } else if (file != undefined) {
      formData.append('media', file);
    }

    formData.append('name', nombreCompleto!);

    getData.post("/groups/edit/" + match.params.id, formData, config)
      .then((res: any) => {
          setTextAlert('Usuario modificado correctamente.')
          setShowAlert(true);
          setTimeout(() => {
            // history.push('/principal');
            window.location.href = "home";
          }, 1500); 
      })
      .then(() => {
        setLoadingCambios(false);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const showButton = () => {
    if (nombreCompleto != "") {
      return <button disabled={changed ? false : true} className={`a ${changed ? 'btn-enabled-principal' : 'btn-disabled-principal'}`} onClick={() => modifyGroup()}><p>Modificar</p></button>;
    } else {
      return <button disabled={true} className="btn-disabled-principal"><p>Modificar</p></button>;
    }
  }

  const storeFile = (file: any) => {
    setLastPhoto(undefined);
    setLoadingFoto(true);
    setFile(file);
    if (file != undefined) {
      formData.append("media", file);
    }

    getData.post<Media>('/media', formData, config)
      .then((res: any) => {
        setFileName(res.data.name);
        setCustomFilename(res.data.name);
      })
      .then(() => {
        setLoadingFoto(false);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }


  const takePhotoLoader = () => {
    setTimeout(() => {
      setLoadingFoto(true);
    }, 1000);
    takePhoto()
      .then(() => {
        setLoadingFoto(false);
      })
      .catch(() => {
        setLoadingFoto(false);
      });
  } 


  return (
    <>
      {Cookies.get('token')
        ?
        <IonPage>
          {showAlert
            ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
            : null}
          <IonLoading
            isOpen={loadingFoto}
            onDidDismiss={() => setLoadingFoto(false)}
            message={'Cargando foto'}
            spinner={'bubbles'}
            duration={5000}
          />
          <IonLoading
            isOpen={loadingCambios}
            onDidDismiss={() => setLoadingCambios(false)}
            message={'Guardando cambios'}
            spinner={'bubbles'}
            duration={5000}
          />
          <HeaderEmpty avatarProp={userAvatar!} />
          <IonContent fullscreen className="fullSize canvi-bg-mvl bg-gris-clar">
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Edita Usuario</IonTitle>
              </IonToolbar>
            </IonHeader>



            <IonGrid className="px-4 container">
              <IonGrid className="m-5 cnt-blanc">
                <div>
                  <IonGrid className="nuevoUsuario">
                    <IonRow className="titol">
                      <div><h3>Editar usuario</h3></div>
                      <a href='/principal'><img className="btn-close handCursor" src={close} /></a>
                    </IonRow>
                    <div className="">
                      <div className="cnt-usuari">
                        <div>
                          {lastPhoto == undefined
                            ? <> 
                            {customFilename == undefined
                              ? <span className={`d-flex roundColumn 1 ${fileName != undefined ? '' : 'border-none'}`} style={fileName != undefined ? imgBackground : undefined}>
                                  {fileName != undefined ? '' : <IonImg src={noPhotoGroup} style={{'width':'90px', 'height':'90px'}}/>}
                                </span>
                              : <span className={`d-flex roundColumn 2`} style={imgCustomBackground} />} </>
                            : <span className={`d-flex roundColumn 3`} style={imgPhotoBackground} />}

                          <>  <input ref={fileInput} hidden type="file" accept="image/*" onClick={() => { '' }} onChange={(e) => {
                            // @ts-ignore 
                            storeFile(e.target.files[0]);
                          }} />
                          {isPlatform('hybrid') 
                            ? <a className="imagenBtn" onClick={
                              (e: any) => {
                                e.persist();
                                setShowPopover({ showPopover: true, event: e })
                              }}><p className="body2 mt-2 text-gris text-center">Elige una foto</p> </a>

                            : <a className="imagenBtn" onClick={ (e: any) => {//@ts-ignore
                              fileInput?.current?.click()}}><p className="body2 mt-2 text-gris text-center">Elige una foto</p> </a>
                            }

                            
                          </>
                        </div>
                      </div>
                      <div className="mt-5 cnt-input-name">
                        <IonInput type="text" maxlength={40} className="inputRedondeado body1 px-2" style={nombreCompleto == "" ? { 'textTransform': 'initial' } : { 'textTransform': 'capitalize' }} placeholder="Introduce el nombre completo" value={nombreCompleto} onIonChange={e => {setNombreCompleto(e.detail.value!); setChanged(true)}}> </IonInput>
                      </div>
                      <div className="text-center mt-3">
                        {showButton()}
                      </div>
                    </div>


                  </IonGrid>
                </div>

                <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverState.event}
                  isOpen={popoverState.showPopover}
                  onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                >
                  <a className='popup-select'> {/* @ts-ignore*/}
                    <p className="body1" onClick={() => { fileInput?.current?.click(); setShowPopover({ showPopover: false, event: undefined }) }}>De la galería</p>
                  </a>
                  {navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) 
                  ? <a className='popup-select'><p className="body1" onClick={() => { takePhotoLoader(); setShowPopover({ showPopover: false, event: undefined }) }}>Hacer foto</p></a>
                  : null }
                </IonPopover>
              </IonGrid>
            </IonGrid>

          </IonContent>
        </IonPage>
        : null}
    </>


  );

};
export default EditarUsuario;


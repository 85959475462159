import { IonRow, IonCol, IonIcon, IonGrid, IonPopover, IonLoading, useIonAlert } from "@ionic/react";
import addCircle from '../../img/iconos/add-circle.svg';
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";

import Etapas from "./Etapas";
import TituloObjetivo from "../TituloObjetivo";
import { useHistory } from "react-router";
import { Role } from "../../interfaces/intUser";
import { getData } from "../../api/apiRecursos";
import { Categories, Category, Obj } from "../../interfaces/intObjective";
import ImportObjectivePopUp from "../PopUpModals/ImportObjectivePopUp";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";

//REDUX
import { useAppSelector, useAppDispatch } from '../../app/hooks'

interface ContainerProps {
  setActualPage: any,
  setSelectedId: any,
  setLastAction: any,
  lastAction: string,
  setShowBack?: any,
  setShowAlert: any,
  showAlert: boolean,
  textAlert:string,
  setTextAlert:any,
}

const Objetivos: React.FC<ContainerProps> = ({ setTextAlert, setShowAlert, setShowBack, setActualPage, setSelectedId, setLastAction, lastAction }) => {
  const role = useAppSelector(state => state.reducer.groupSelected.userRole?.role);
  const categoriesGroup = useAppSelector(state => state.reducer.groupSelected.categories);
  const history = useHistory();

  const [competencia, setCompetencia] = useState<string>('Sin competencia');
  const [showHide, setShowHide] = useState(false);
  const [objGroup, setObjGroup] = useState<Obj[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [loadingObjetDefecto, setLoadingObjetDefecto] = useState(false);
  const [checkAlert] = useIonAlert();
  const [showImportModal, setShowImportModal] = useState(false);
  const {getCategories, emptyObjectives, createGroupObjectiveTemplate, exportSet, setDefaultObjectives} = GlobalFunctions();

  const axios = require('axios');



  const showObjetivo = () => {
    return <Etapas setTextAlert={setTextAlert} setShowAlert={setShowAlert}setDefaultObjectives={setDefaultObjectivesConst} setShowBack={setShowBack} setActualPage={setActualPage} setSelectedId={setSelectedId} competencia={competencia!} showHide={showHide} setLastAction={setLastAction} lastAction={lastAction} />
  }

  useEffect(() => {
    if (role != 1) {
      setShowHide(true);
    }

    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    const userData = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    }

    getData.post<Obj>('/getObjectivesByEtapa', userData)
      .then(resp => {
        //@ts-ignore
        setObjGroup(resp.data);
      })
      .catch(err => console.log(err));

      getCategories(setCategories, setCompetencia);
  }, [lastAction])

  const descriptionCateg = () => {
    switch (competencia) {
      case "Linguística":
        return "Se refiere al nivel de manejo del código lingüístico: el idioma nativo hablado y el código lingüístico de su SAAC."
        
        break;
        case "Operacional":
          return "Se refiere a las habilidades técnicas necesarias para manejar el sistema entre las que se incluyen las competencias para acceder al mismo, o para manejar determinadas características como el encendido o control del volumen. También se incluyen competencias cognitivas y sensoriales."
        
        break;
        case "Social":
          return "Se refiere al conocimiento de las reglas sociales de la comunicación que el usuario del SAAC también debe manejar, incluyendo aspectos pragmalingüísticos y sociales."
        break;
        case "Estratégica":
          return "Se refiere a las habilidades estratégicas para optimizar y comunicarse de forma efectiva sin restricciones."
        break;
    
      default:
        break;
    }
  }

  const showButtons = () => {
    return (
      <>
        {categoriesGroup?.length == 0 || categoriesGroup[0].name == 'Sin competencia'
          ? <><br/><br/></>
          : <>
            <IonRow className="row">
              <IonCol className="col-12">
                <p className="text-gris"><b>Competencia</b></p>
              </IonCol>
            </IonRow>
            <IonRow className="row mt-2 row-competencies" >
              <IonCol size="11" className="lineal buttons-scroll">
                {categoriesGroup.map((categoria, index) =>
                  <Fragment key={index}>
                    <button key={index}
                      className={`btn-objetivos titulo-seccion  ${categoria.name}
                          ${competencia === categoria.name
                          ? "selected"
                          : null}`}
                      onClick={() => setCompetencia(categoria.name)}>
                        {categoria.name == "Sin competencia" ? "Otros" : categoria.name}
                    </button>
                  </Fragment>
                )}
              </IonCol>
            </IonRow>
            <IonRow className="row">
              <IonCol className="col-12">
                <p className="body2">{descriptionCateg()}</p>
              </IonCol>
            </IonRow>

            <div className="row mt-5">
              <div className="col-12">
                {competencia != 'Sin competencia'
                  ? <p className="text-gris"><b>Etapa</b></p>
                  : null}
              </div>
            </div>
          </>}
      </>
    )
  }
  const setDefaultObjectivesConst = () => {
    setLoadingObjetDefecto(true)
    const dataObjetivo = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    };

    setDefaultObjectives(dataObjetivo, setLoadingObjetDefecto, setShowPopover,setShowAlert,setTextAlert, setActualPage);
    setLastAction("Objetivos por defecto asignados " + Date.now());
  }
  const exportThisSet = () => {
    setShowPopover({ showPopover: false, event: undefined });
    exportSet(setShowAlert, setTextAlert);
  }




  const eliminarObjetivos = () => {
    setShowPopover({ showPopover: false, event: undefined });
    checkAlert({
      header:'Eliminar objetivos',
      message: `¿Seguro que quieres eliminar todos los objetivos?`,
      buttons: [
        'CANCELAR', { text: 'ELIMINAR', handler: () => deleteAll() },
      ],
      onDidDismiss: () => '',
    })
  }

  const deleteAll = () => {
    setShowPopover({ showPopover: false, event: undefined });
    emptyObjectives(setTextAlert, setShowAlert, setActualPage);
  }
  


  return (
    <>
      <IonGrid className="container">
        <IonLoading
          isOpen={loadingObjetDefecto}
          onDidDismiss={() => setLoadingObjetDefecto(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          duration={10000}
        />
        <IonRow className="rowTitulo">
          <TituloObjetivo titulo="Todos los objetivos" />
          <IonCol size="4" className="d-flex justify-content-end align-self-center btns-title">
            {showHide
              ?
              <>
                <button className="addObjetivo bigger-hover" onClick={() => setActualPage("objectiveNew")}>
                  <IonCol>
                    <IonIcon className="grande" src={addCircle}></IonIcon>
                    <p className="body2" >Crear nuevo<br/>objetivo</p>
                  </IonCol>
                </button>
                
                <IonPopover
                    event={popoverState.event}
                    isOpen={popoverState.showPopover}
                    onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select'><p className="body1" onClick={() =>  exportThisSet()}>Guardar set de objetivos como plantilla</p></a>
                    <a className='popup-select'><p className="body1" onClick={() =>  eliminarObjetivos()}>Eliminar todos los objetivos</p></a>
                  </IonPopover>

                <button className="addObjetivo" onClick={
                  (e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e })
                  }}>
                    <i className="fa-regular fa-2x black fa-ellipsis-vertical"></i>
                </button>
                {/* {categoriesGroup.length < 1 && role != 1
                  ? null
                  :  <button className="addObjetivo" onClick={() => setActualPage("objectiveNew")}>
                      <IonCol>
                        <IonIcon className="grande" src={addCircle}></IonIcon>
                        <p className="body2" >Crear nuevo<br/>objetivo</p>

                      </IonCol>
                    </button>} */}
              </>
              : null
            }
          </IonCol>
        </IonRow>

        {showButtons()}


        <div className="row">
          {showObjetivo()}
        </div>
      </IonGrid>
      {showImportModal ? <ImportObjectivePopUp setTextAlert={setTextAlert} setShowAlert={setShowAlert} setShowImportModal={setShowImportModal} setLastAction={setLastAction} /> : null}

    </>
  );
};
export default Objetivos;







import { IonCol, IonGrid, IonIcon, IonImg, IonLoading, IonRow } from '@ionic/react';
import '../Header.css';

import Cookies from 'js-cookie';
import logoRecurso from '../../img/iconos/recursos-logo-neg.svg';
import imgColor from '../../img/iconos/img_color.svg';
import vidColor from '../../img/iconos/vid_color.svg';
import linkColor from '../../img/iconos/link_color.svg';
import link from '../../img/iconos/link.svg';
import pdfColor from '../../img/iconos/pdf_color.svg';
import audioColor from '../../img/iconos/audio_color.svg';
import close from '../../img/iconos/close.svg';
import { Resource } from '../../interfaces/intRecurso';
import { useValidateYouTubeUrl } from '../../hooks/useValidateYoutubeUrl';
import BigPhotoPopUp from './BigPhotoPopUp';
import { useState } from 'react';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';
import { GlobalVariables } from '../../hooks/GlobalVariables';




interface Props {
  setShowDetail: any
  resource: Resource
}

const ShowResourceDetail: React.FC<Props> = ({ setShowDetail, resource }) => {
  const {uploadUrl} = GlobalFunctions();
  const { validateYouTubeUrl } = useValidateYouTubeUrl();
  const [showPhoto, setShowPhoto] = useState(false);
  
  
  const userData = {
    token: Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }

  const showIcon = () => {
    switch (resource?.type) {
      case "imagen":
        return (<>
          <IonIcon className="grande" src={imgColor} />
          <a href={`${uploadUrl}${resource?.media_name}`} target="_blank"><p className="body1 mx-3 text-blau">{resource?.value}</p></a>
        </>);

      case "video":
        return (<>
          <IonIcon className="grande" src={vidColor} />
          {validateYouTubeUrl(resource?.value) == false
              ? <a href={`${uploadUrl}${resource?.media_name}`} target="_blank"><p className="body1 mx-3 text-blau">{resource?.value}</p></a>

              :
              <a href={`${resource?.value}`} target="_blank"><p className="body1 mx-3 text-blau">{resource?.value}</p></a>
          }
        </>);
      case "link":
        return (<>
          <IonIcon className="grande" src={linkColor} />
          <p></p>
          <a href={`${resource?.value.startsWith('http://') || resource?.value.startsWith('https://') ? resource?.value : 'http://' + resource?.value}`} target="_blank"><p className="body1 mx-3 text-blau">{resource?.value}</p></a>
        </>);


      case "pdf":
        return (<>
          <IonIcon className="grande" src={pdfColor} />
          <a target="_blank" href={uploadUrl+resource?.media_name}><p className="body1 mx-3 text-blau">Abrir PDF</p></a>
        </>);

      case "audio":
        return (<>
          <IonIcon className="grande" src={audioColor} />
          <p className="body1 mx-3 text-blau">{resource?.media_name}</p>
        </>);

      default:
        break;
    }
  }

  const {urlMedia} = GlobalVariables();
  const showMedia = () => {
    console.log("Res", resource)
    switch (resource?.type) {
      case "imagen":
        return (<>
          {resource.media_name != undefined ?
              <>
                <a href={`${uploadUrl}${resource?.media_name}`} target="_blank">
                  <img src={urlMedia + resource.media_name} className="img-fluid img-bordered" width="200px" />
                </a>
                {showPhoto
                    ? <BigPhotoPopUp photoName={resource.media_name!} setShowPhoto={setShowPhoto} /> : null}
              </>
              : null}
        </>);

      case "video":
        return (<>
          {validateYouTubeUrl(resource?.value) == false
              ? <video className=" mt-3" id="video" controls>
                <source src={` ${urlMedia}${resource?.media_name}`} type="video/mp4"/>
                Your browser does not support HTML5 video.
              </video>
              : <><iframe className="desktop"width="100%" height='400px' src={validateYouTubeUrl(resource!.value)?.toString()} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe className="mvl"width="100%" height='200px' src={validateYouTubeUrl(resource!.value)?.toString()} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </>
          }
        </>);

      case "audio":
        return (<audio src={`${uploadUrl}${resource?.media_name}`} controls style={{'width':'100%'}}></audio>)
      default:
        break;
    }
  }

  return (
    <div className="modal modal_activity_detail">
      
      <div className="modal_content ">
        <IonIcon src={close} className="close" onClick={() => setShowDetail(false)} />
        <IonGrid className="m-5">
          <IonRow>
            <div className="col-1 col-ajustada borde-recursos">
              <img src={logoRecurso} />
            </div>
            <div className="col-7 d-flex flex-column justify-content-center borde-recursos">

              <p className="ml-4 text-blau">Recurso</p>
            </div>
          </IonRow>
          <IonRow className="mt-3">
            <IonCol size="12" >
              <h3>{resource?.name}</h3>
            </IonCol>

          </IonRow>
          <IonRow>
            <IonCol className="mt-2 html-description">

              {resource?.description
                ? <p dangerouslySetInnerHTML={{ __html: resource!.description }} />
                : <p>{resource?.description}</p>
              }
            </IonCol>
          </IonRow>
          <IonRow className='lineal'>
            <div >{showMedia()}</div>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              <div className='lineal-flex align-items-center'>{showIcon()} </div>
              
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );

};
export default ShowResourceDetail;
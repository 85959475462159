import axios from 'axios';


export const getRecursos = axios.create({
  baseURL: 'https://backtea.qinera.com/api'
  // baseURL: 'http://127.0.0.1:8000/api'
});

export const getData = axios.create({
  baseURL: 'https://backtea.qinera.com/api/'
  // baseURL: 'http://127.0.0.1:8000/api'

});

import { IonCol, IonGrid, IonIcon, IonImg, IonLoading, IonProgressBar, IonRow } from '@ionic/react';
import '../Header.css';

import Cookies from 'js-cookie';
import logoObjective from '../../img/iconos/objetivos-logo-neg.svg';
import imgColor from '../../img/iconos/img_color.svg';
import vidColor from '../../img/iconos/vid_color.svg';
import linkColor from '../../img/iconos/link_color.svg';
import link from '../../img/iconos/link.svg';
import pdfColor from '../../img/iconos/pdf_color.svg';
import audioColor from '../../img/iconos/audio_color.svg';
import close from '../../img/iconos/close.svg';
import { Datum } from '../../interfaces/intRecurso';
import { useValidateYouTubeUrl } from '../../hooks/useValidateYoutubeUrl';
import BigPhotoPopUp from './BigPhotoPopUp';
import { useState } from 'react';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';
import { Objective } from '../../interfaces/intObjective';




interface Props {
  setShowDetail: any,
  objective: Objective
}

const ShowObjectiveDetail: React.FC<Props> = ({ setShowDetail, objective }) => {
  const {uploadUrl} = GlobalFunctions();
  const { validateYouTubeUrl } = useValidateYouTubeUrl();
  const [showPhoto, setShowPhoto] = useState(false);
  
  
  const userData = {
    token: Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }


  return (
    <div className="modal modal_activity_detail">
      
      <div className="modal_content ">
        <IonIcon src={close} className="close" onClick={() => setShowDetail(false)} />
        <IonGrid className="m-5">
          <IonRow>
            <div className="col-1 col-ajustada borde-objetivo">
              <img src={logoObjective} />
            </div>
            <div className="col-7 d-flex flex-column justify-content-center borde-objetivo">

              <p className="ml-4 text-verd">Recurso</p>
            </div>
          </IonRow>
        
          <IonRow className="">
            <IonCol size="12" >
              <h3>{objective?.name}</h3>
            </IonCol>
            <IonCol>
             <p className="overline text-verd">{objective.category_name == "Sin competencia" ? "" : objective.category_name}</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="mt-2">
              <p>{objective?.description}</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <p><b>Estado orientativo:</b></p>
            </IonCol>
            <IonCol size="6">
              {objective?.value === 0 ? <IonProgressBar value={0} /> : null}
              {objective?.value ? <IonProgressBar value={objective?.value / 100} /> : null}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12'>
              {objective?.status === 2 ? <p className="body2">Objetivo completado</p> : null}
              {objective?.status === 0 ? <p className="body2">Aún no se ha progresado en este objetivo</p> : null}
            </IonCol>
          </IonRow>
        </IonGrid>        
      </div>
    </div>
  );

};
export default ShowObjectiveDetail;
import { 
  IonButton,
  IonContent, 
  IonHeader,
  IonInfiniteScroll, 
  IonInfiniteScrollContent, 
  IonItem,
  IonLabel,
  IonList,  
  IonPage, 
  IonTitle, 
  IonToolbar,
  useIonViewWillEnter
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

const Testing: React.FC = () => {
  const [data, setData] = useState<string[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  
  const pushData = () => {
    const max = data.length + 20;
    const min = max - 20;
    const newData = [];
    for (let i = min; i < max; i++) {
      newData.push('Item' + i);
    }
    
    setData([
      ...data,
      ...newData
    ]);
  }
  const loadData = (ev: any = "") => {
    setTimeout(() => {
      pushData();
      if (data.length === 1000) {
        setInfiniteDisabled(true);
      }
    }, 500);
  }  
  
  useIonViewWillEnter(() => {
    pushData();
  });

  const handleScroll = (event: any) => {
    const scrollHeight = event.currentTarget.offsetHeight;
    const currentHeight = Math.ceil(
      event.currentTarget.scrollTop + window.innerHeight
    );
    if (currentHeight + 1 >= scrollHeight) {
      loadData();
    }
  };


  const Footer = () => {
    return (
      <div
        style={{
          padding: '2rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Loading...
      </div>
    )
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Blank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Blank</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        <IonButton onClick={() => setInfiniteDisabled(!isInfiniteDisabled)} expand="block">
          Toggle Infinite Scroll
        </IonButton>
        
        {/* <IonList>
          {data.map((item, index) => {
            return (
              <IonItem key={index}>
                <IonLabel>{item}</IonLabel>
              </IonItem>
            )
          })}
        </IonList> */}

      <div
      style={{
        border: '3px solid black',
        width: '400px',
        height: '100px',
        overflow: 'scroll',
      }}
      onScroll={handleScroll}
    >
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
      <p>Content</p>
    </div>
        {/* <Virtuoso
          style={{ height: 300 }}
          data={data}
          endReached={loadData}
          overscan={200}
          itemContent={(index, user) => {
            return <div style={{ backgroundColor: 'red' }}>{user}</div>
          }}
          components={{ Footer }}
        /> */}
        
        <IonInfiniteScroll
          onIonInfinite={loadData}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
      <button onClick={() => loadData()}>click</button>
    </IonPage>
  );
};

export default Testing;
import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import checkNaranja from '../../img/iconos/check-naranja.svg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonIcon, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonToggle, IonButton, IonLoading, useIonAlert } from '@ionic/react';
import { UserGroup } from '../../interfaces/intGroups';
import { getData } from '../../api/apiRecursos';
import Cookies from 'js-cookie';
import { ActivityGroup } from '../../interfaces/intActivity';
import axios from 'axios';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';


interface Props {
  setCopyModal?: any;
  activityId: number,
  setShowAlert: any,
  setTextAlert: any,
}

const CopyActivity: React.FC<Props> = ({ setShowAlert, setTextAlert, setCopyModal, activityId }) => {
  const [loading, setLoading] = useState(false);
  const [userGroup, setUserGroup] = useState<ActivityGroup[]>([]);
  const [groupArray, setGroupArray] = useState<number[]>([]);

  const {storeActivityCopy} = GlobalFunctions();


  const userData = {
    token: Cookies.get('token'),
    activity_id: activityId
  }

  // const [checkNoObj] = useIonAlert();
  // const copyActivityPre = () => {
  //   checkNoObj({
  //     message: `Esta actividad tiene asociado un objetivo que no ha sido definido para XX(usuario). ¿Qué deseas hacer? `,
  //     buttons: [
  //       { text: 'AÑADIR SOLO ACTIVIDAD', handler: () => setCopy(false) },{ text: 'AÑADIR ACTIVIDAD Y OBJETIVO', handler: () => setCopy(true)}
  //     ],
  //     onDidDismiss: () => console.log(""),
  //   })
  // }

  useEffect(() => {
    getData.post<ActivityGroup>('/getMyGroupsActivity', userData)
      .then(resp => {
        //@ts-ignore
        setUserGroup(resp.data);
      })
      .catch(err => console.log(err));
  }, []);

  const setCopy = () => {
    setLoading(true);
    groupArray.forEach(element => {
      const dataRelation = {
        activity_id: activityId,
        group_id: element,
      };

      storeActivityCopy(dataRelation, setCopyModal, setShowAlert, setTextAlert);

    });
    setLoading(false)
    // }
  }

  const setSelected = (id: number) => {

    var element = document.getElementById('group-' + id);

    if (element?.classList.contains('selected')) {
      element.classList.remove('selected');
      for (var i = 0; i < groupArray.length; i++) {
        if (groupArray[i] === id) {
          setGroupArray(groupArray.splice(i,1));
        }
      }
    } else {
      setGroupArray([...groupArray, id]);
      element!.classList.add('selected');
    }
  }

  return (
    <div className="modal modal_copy">
      <div className="modal_content">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Copiando actividades'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
        <IonIcon src={close} className="close" onClick={e => setCopyModal(false)} />
        <IonGrid>
          <IonRow>
            <IonCol>
              <h5>Copiar actividad a usuario</h5>
            </IonCol>
          </IonRow>

          {userGroup.map((group, index) =>
            <IonRow className="p-2" key={index}>
              <IonCol className="lineal-flex mx-4 border-bottom">
                <a className={`${group.role == 1 ? 'no-link' : ''}`} onClick={e => <> {group.role != 1 ? setSelected(group.id) : null}</>}>
                  <p id={`group-${group.id}`} className={`group-selector body2 capitalize ${group.role == 1 ? 'text-gris' : null}`}><img className="medio mx-3" src={checkNaranja} /> {group.name}</p>
                </a>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol className="d-flex justify-content-end mt-5">
              <IonButton className="cancelBtn" onClick={e => setCopyModal(false)}><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" disabled={false} onClick={e => setCopy()}><p>Copiar</p></IonButton>
            </IonCol>
          </IonRow>

        </IonGrid>
      </div>
    </div>
  );

};
export default CopyActivity;
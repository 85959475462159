import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import '../Header.css';

import Cookies from 'js-cookie';
import logoActividad from '../../img/iconos/actividades-logo-neg.svg';
import { Activities } from '../../interfaces/intActivity';
import logoObj from '../../img/iconos/objetivos-logo-min.svg';
import close from '../../img/iconos/close.svg';
import { useState } from 'react';
import BigPhotoPopUp from './BigPhotoPopUp';
import { GlobalVariables } from '../../hooks/GlobalVariables';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';




interface Props {
  setShowDetail:any
  activity: Activities
}

const ShowActivityDetail: React.FC<Props> = ({ setShowDetail, activity }) => {
  const [showPhoto, setShowPhoto] = useState(false);

  const {urlMedia} = GlobalVariables();
  const { showMedia } = GlobalFunctions();



  return (
    <div className="modal modal_activity_detail">
      <div className="modal_content ">
      <IonIcon src={close} className="close" onClick={() => setShowDetail(false)} />
        <IonGrid className="m-5">
          <IonRow>
            <div className="col-1 col-ajustada borde-actividad">
              <img src={logoActividad} />
            </div>
            <div className="col-7 d-flex flex-column justify-content-center borde-actividad">

              <p className="ml-4 text-lila">Actividad</p>
            </div>
          </IonRow>
          <IonRow className="mt-3">
            <IonCol size="12" >
              <h3>{activity?.name}</h3>
            </IonCol>

      </IonRow>
      <IonRow>
        <IonCol className="mt-2 html-description">
          
          {activity?.description 
          ? <p dangerouslySetInnerHTML={{ __html: activity!.description }} />
          : <p>{activity?.description}</p>
}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="mt-2">
        {showMedia(activity?.media_id, activity?.media_name, activity?.media_type, setShowPhoto)}
          {/* {activity?.media_id
            ? <img src={urlMedia + activity.media_name} onClick={() => {setShowPhoto(true)} }className="img-fluid img-bordered" width="200px" />
            : null} */}
            {showPhoto
                ? <BigPhotoPopUp photoName={activity.media_name!} setShowPhoto={setShowPhoto} /> : null}
        </IonCol>
      </IonRow>
      <IonRow>

        {activity?.objectives.length! > 0
          ? <><IonCol size="12">
            <p><b>Objetivos asociados:</b></p>
          </IonCol>
            {activity?.objectives.map((objetivo, index) =>
              <>
                <IonCol size="12" className="lineal-flex">
                  <IonIcon src={logoObj} className="medio" />

                  <p className="mx-5">
                    <p className="body1">{objetivo.name}</p>
                    <p className="overline text-verd">{objetivo.category_name == "Sin competencia" ? "" : objetivo.category_name}</p>

                  </p>
                </IonCol>
              </>
            )}
          </>
          : null}

      </IonRow>


        </IonGrid>
      </div>
    </div>
  );

};
export default ShowActivityDetail;
import { IonCard, IonCol, IonGrid, IonRadio, IonRow } from "@ionic/react";
import { read, utils } from 'xlsx';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";

interface Props {
  text?: string
}

const ActividadesAdmin: React.FC<Props> = ({ text }) => {
  var jsonData: any;
  const { createActivitiesTemplate } = GlobalFunctions();

  const convertToJson = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target!.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        jsonData = json;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const createActivities = () => {
    createActivitiesTemplate(jsonData);
  }

  return (
    <div className="container">
       <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonCard>
              <div className="p-3">
                <h3>Selecciona el fichero para actualizar las actividades </h3>

                <div className="my-4">
                  {/*@ts-ignore */}
                  <input type="file" accept=".xlsx" onChange={(e) => {convertToJson(e);}} />
                </div>

                <div className="my-5">
                  {/*@ts-ignore */}
                  <button onClick={() => createActivities()}>Subir actividades</button>
                </div>


                

              </div>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
     
  );

};
export default ActividadesAdmin;
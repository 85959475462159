import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel, IonDatetime, IonFooter, IonButtons, IonIcon, IonButton, IonPopover, IonAvatar, IonImg, IonSkeletonText, useIonAlert } from '@ionic/react';
import './Header.css';

/** LOGOS **/
import hamburgerNav from '../img/iconos/hamburger_nav.svg';
import house from '../img/iconos/house.svg';
import arrowDown from '../img/iconos/arrow-down.svg';
import logoGroups from '../img/iconos/logo_groups.svg';
import noPhotoAvatar from '../img/iconos/noPhotoHeader_avatar.svg';
import noPhotoGroup from '../img/iconos/noPhotoHeader_group.svg';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import ChangeRolesPopUp from './PopUpModals/ChangeRolesPopUp';
import ModifyProfilePopUp from './PopUpModals/ModifyProfilePopUp';
import NotificationPopUp from './PopUpModals/NotificationPopUp';
import { getData } from '../api/apiRecursos';
import { Role } from '../interfaces/intUser';
import { GlobalFunctions } from '../hooks/GlobalFunctions';
import AvisoPopUp from './PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector } from '../app/hooks'
import { GlobalVariables } from '../hooks/GlobalVariables';
import { store } from '../app/store';


interface ContainerProps {
  name: string;
  avatar: string;
  avatarProp: string;
  setLastAction: any;
  lastAction: string;
  setActualPage?: any,
}

const Header: React.FC<ContainerProps> = ({ setActualPage = null, name, avatar, avatarProp, setLastAction, lastAction }) => {
  //REDUX
  const groupAvatar = useAppSelector(state=>state.reducer.groupSelected.media_name == null ? "" : state.reducer.groupSelected.media_name);
  const groupRole = useAppSelector(state=>state.reducer.groupSelected.userRole?.role);
  const {urlMedia} = GlobalVariables();

  const { goBack } = GlobalFunctions();
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [notification, setNotification] = useState(false);
  const [roles, setRoles] = useState(false);
  const [modifyProfile, setModifyProfile] = useState(false);
  let history = useHistory();

  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState<string>('');

  useEffect(() => {
    document.getElementById("cnt-header")!.scrollIntoView();
  },[] )
  


  const clickModificar = () => {
    setShowPopover({ showPopover: false, event: undefined });

    if (roles == true || notification == true) {
      setRoles(false);
      setNotification(false);
    }
    setModifyProfile(!modifyProfile);
  }
  const changeRoles = () => {
    setShowPopover({ showPopover: false, event: undefined });

    if (modifyProfile == true || notification == true) {
      setModifyProfile(false);
      setNotification(false);
    }
    setRoles(!roles);
  }
  const changeNotificaciones = () => {
    setShowPopover({ showPopover: false, event: undefined });
    if (modifyProfile == true || roles == true) {
      setModifyProfile(false);
      setRoles(false);
    }
    setNotification(!notification);
  }

  const [checkCerrar] = useIonAlert();

  const clickCerrar = () => {
    setShowPopover({ showPopover: false, event: undefined });
      checkCerrar({
        // header: `Cerrar sesión`,
        message: `<h3 style="color:black">Cerrar sesión</h3>Los usuarios, los contenidos compartidos y las conversaciones dejarán de estar accesibles. Para poderlos visualizar de nuevo tendrás que acceder con la misma cuenta.`,
        buttons: [
          'CANCELAR', { text: 'CERRAR SESIÓN', handler: () => logout() },
        ],
        onDidDismiss: () => console.log(""),
      })
  }
  
  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('group_id');
    store.dispatch({type: 'RESET'});

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  // const goPrincipal = () => {
  //   history.push('/principal');
  // }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }


  return (
    <div id="cnt-header">
      <IonPopover
        // cssClass='my-custom-class'
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <a className='popup-select'><p className="body1" onClick={() => {clickModificar();setShowPopover({ showPopover: false, event: undefined })}}>Modificar mi perfil</p></a>
        {groupRole != 1 ? <a className='popup-select'><p className="body1" onClick={() => {changeRoles();setShowPopover({ showPopover: false, event: undefined })}}>Gestionar roles</p></a> : null}
        <a className='popup-select'><p className="body1" onClick={() => {changeNotificaciones();setShowPopover({ showPopover: false, event: undefined })}}>Notificaciones</p></a>
        <a className='popup-select'><p className="body1" onClick={() => clickCerrar()}>Cerrar sesión</p></a>
      </IonPopover>
      <IonHeader className="desktop" >
        <IonToolbar className="header py-2">
            <button onClick={e => goBack('home', setActualPage)}>
              {/* <IonImg className="icono-casa" src={house}/> */}
              <IonAvatar style={{ 'minHeight': 'fit-content' }}>
                <IonImg className="photo avatar" src={groupAvatar != '' ? urlMedia + groupAvatar : noPhotoGroup}></IonImg>
              </IonAvatar>
              <div style={{'width':'100%'}}>
                <h3 className='activity-clipped-text-1'>{name}</h3>
              </div>
            </button>

            
          <IonButtons slot="end">
            <IonButton href='/principal' onClick={e => {Cookies.remove('group_id');window.localStorage.setItem('groupPhoto', '') }}>
              <IonImg className="muyGrande" src={logoGroups}></IonImg>
            </IonButton>

            <IonButton onClick={
              (e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e })
              }}>

              <IonAvatar style={{ 'minHeight': 'fit-content' }}>
                <IonImg className="photo avatar" src={avatarProp != "" ? urlMedia + avatarProp : noPhotoAvatar}></IonImg>
              </IonAvatar>
              <IonIcon className="mx-2" src={arrowDown}></IonIcon>
            </IonButton>
            
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonHeader className="mvl">
        <IonToolbar className="header">
            <button onClick={e => history.push('/home')} >
              <IonAvatar>
                {/* <IonSkeletonText id="skeletonHeader"  animated className={`skeleton avatar m-0`}/> */}
                <IonImg className="photo avatar" src={groupAvatar != '' ? urlMedia + groupAvatar : noPhotoGroup}></IonImg>
              </IonAvatar>
              <div style={{'width':'100%'}}>
                <h4 className='m-3 activity-clipped-text-1'>{name}</h4>
              </div>
              
            </button>
          {Cookies.get('token')
            ? 
            <><IonButtons slot="end">
              <IonButton href="/principal" onClick={e => {Cookies.remove('group_id');window.localStorage.setItem('groupPhoto', '') }}>
                <img className="muyGrande" src={logoGroups}></img>
              </IonButton>
              <IonButton onClick={
                (e: any) => {
                  e.persist();
                  setShowPopover({ showPopover: true, event: e })
                }}>
                <img src={hamburgerNav}></img>
              </IonButton>
              
            </IonButtons>
              
            </>
            : null}

        </IonToolbar>
      </IonHeader>
      
      {showAlert
        ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
        : null}
      {notification ? <NotificationPopUp setNotification={setNotification} setLastAction={setLastAction} lastAction={lastAction}></NotificationPopUp> : null}
      {modifyProfile ? <ModifyProfilePopUp setModifyProfile={setModifyProfile} setLastAction={setLastAction} lastAction={lastAction} setTextAlert={setTextAlert} setShowAlert={setShowAlert}></ModifyProfilePopUp> : null}
      {roles ? <ChangeRolesPopUp setRoles={setRoles} setLastAction={setLastAction} lastAction={lastAction} setTextAlert={setTextAlert} setShowAlert={setShowAlert}></ChangeRolesPopUp> : null}
    </div>
  );

};
export default Header;
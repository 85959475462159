import { IonGrid, IonRow, IonCol, IonProgressBar, IonLoading } from '@ionic/react';

/* CSS */
import '../../theme/global.css';

/* DATOS */
import { useEffect, useState } from 'react';
import { getData } from '../../api/apiRecursos';
import { Objective } from '../../interfaces/intObjective';
import TituloObjetivo from '../TituloObjetivo';


interface ContainerProps {
  setActualPage: any,
  selectedId: number,
  showBack?: boolean,
  setShowBack?: any
}

const ObjetivoDetalle: React.FC<ContainerProps> = ({ showBack,setShowBack, setActualPage, selectedId}) => {
   const [objetivo, setObjetivo] = useState<Objective>();
   const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData.get<Objective>('/objective/' + selectedId)
      .then(resp => {
        //@ts-ignore
        setObjetivo(resp.data);
      })
      .then(()=>
        setLoading(false)
      )
      .catch(err => console.log(err));

      
  }, [])
  useEffect(() => {
    getData.get<Objective>('/objective/' + selectedId)
      .then(resp => {
        //@ts-ignore
        setObjetivo(resp.data);
      })
      .catch(err => console.log(err));
  }, [selectedId])



  return (
    <>
    <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
    <IonGrid className="container">
    <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={10000}
      />
      <IonRow>
        {/* {showBack ? <a className="text-verd" onClick={e => { setActualPage("objetivos"); setShowBack(false) }}>
          VOLVER
        </a> : null} */}
      </IonRow>
      <IonRow>
        <TituloObjetivo titulo="Objetivo" setShowBack={setShowBack} setActualPage={setActualPage} showBack={showBack} isBiblio={false} />

      </IonRow>
      <div className="cnt-detalle">
        <IonRow className="">
          <IonCol size="12" >
            <h3>{objetivo?.name}</h3>
          </IonCol>
          <IonCol>

            <p className="overline text-verd">
              {//@ts-ignore
              objetivo?.category.name == "Sin competencia" ? "" : objetivo?.category.name}
              </p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="mt-2">
            <p>{objetivo?.description}</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <p><b>Estado orientativo:</b></p>
          </IonCol>
          <IonCol size="6">
            {objetivo?.value === 0 ? <IonProgressBar value={0} /> : null}
            {objetivo?.value ? <IonProgressBar value={objetivo?.value / 100} /> : null}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            {objetivo?.status === 2 ? <p className="body2">Objetivo completado</p> : null}
            {objetivo?.status === 0 ? <p className="body2">Aún no se ha progresado en este objetivo</p> : null}
          </IonCol>
        </IonRow>
      </div>


    </IonGrid>
    </>
  );
};

export default ObjetivoDetalle;
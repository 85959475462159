import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import checkNaranja from '../../img/iconos/check-naranja.svg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonIcon, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonToggle, IonButton } from '@ionic/react';
import { UserGroup } from '../../interfaces/intGroups';
import { getData } from '../../api/apiRecursos';
import Cookies from 'js-cookie';
import { ActivityGroup } from '../../interfaces/intActivity';
import axios from 'axios';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';


interface Props {
  setShowExportModal?: any;
  objectiveId: number,
  setLastAction: any,
  setShowAlert: any,
  setTextAlert: any,
}

const ExportObjectivePopUp: React.FC<Props> = ({ setShowAlert, setTextAlert, setShowExportModal, objectiveId, setLastAction }) => {
  const [idSelected, setIdSelected] = useState<number>();
  const [nameSelected, setNameSelected] = useState<string>();
  const [userGroup, setUserGroup] = useState<ActivityGroup[]>([]);

  const userData = {
    token: Cookies.get('token'),
    activity_id: objectiveId
  }

  useEffect(() => {
    getData.post<ActivityGroup>('/getMyGroups', userData)
      .then(resp => {
        //@ts-ignore
        setUserGroup(resp.data);
      })
      .catch(err => console.log(err));
  }, []);

  const exportSetThis = () => {
    const date = new Date();
    var today = date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();

    const dataRelation = {
      created_at: today,
      token: Cookies.get('token'),
      group_id: idSelected
    }; 

    getData.post("/storeSet", dataRelation)
      .then((res: any) => {
        setShowAlert(true);
        setTextAlert("set de objetivos de " + nameSelected + " exportados correctamente.")
        setShowExportModal(false)
        setLastAction("creadoExport " + Date.now());
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const setSelected = (id: number) => {
    const group = document.getElementById('group-' + id);
    const rows = Array.from(document.getElementsByClassName('row-group') as HTMLCollectionOf<HTMLElement>);
    var i;

    if (group?.classList.contains('selected')) {
      setIdSelected(undefined);
      group.classList.remove('selected');
    } else {
      setIdSelected(id);
      for (i = 0; i < rows.length; i++) {
        rows[i].classList.remove('selected');
      }
      group!.classList.add('selected');
    }
  }

  return (
    <div className="modal modal_export">
      <div className="modal_content">
        <IonIcon src={close} className="close" onClick={e => setShowExportModal(false)} />
        <IonGrid>
          <IonRow>
            <IonCol>
              <h5>Exportar set de objetivos de:</h5>
            </IonCol>
          </IonRow>


          {userGroup.map((group, index) =>
            <>
              {group.numObj != 0
                ?
                <IonRow className="border-bottom mx-4" key={index}>
                  <IonCol size='12' id={`group-${group.id}`} className="lineal-flex  row-group align-self-center">
                    {group.id == idSelected ? <img className="medio mx-3" src={checkNaranja} /> : <img className="medio mx-3" src={checkNaranja} style={{ 'visibility': 'hidden' }} />}
                    <a className={`px-3 lineal-flex align-self-center${group.role == 1 || group.elementInUser ? 'no-link' : ''}`} onClick={e => <> {group.role != 1 && group.numObj != 0 ? <>{setSelected(group.id)}{setNameSelected(group.name)}</> : null}</>}>
                      <p className={`body2 capitalize d-flex align-self-center 
                      ${group.id == idSelected ? 'text-naranja' : ""} ${group.role == 1 ? 'text-gris' : null}`}>
                        {group.name}
                      </p>

                    </a>
                  </IonCol>

                </IonRow> : null} </>
          )}
          <IonRow>
            <IonCol>
              <p className='text-gris px-2'>Se guardará una copia de los objetivos del usuario (solo los objetivos, no del estado), que podrás utilizar cuando crees un usuario nuevo. </p>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="d-flex justify-content-end mt-5">
              <IonButton className="cancelBtn" onClick={e => setShowExportModal(false)}><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" disabled={idSelected == undefined ? true : false} onClick={e => exportSetThis()}><p>exportar</p></IonButton>
            </IonCol>
          </IonRow>


        </IonGrid>
      </div>
    </div>
  );

};
export default ExportObjectivePopUp;
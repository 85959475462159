import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { User } from "../../interfaces/intUser";

// Define a type for the slice state
interface ResourceSlice {
  selectedFileImg: File | undefined,
  selectedFileVideo: File | undefined,
  selectedFilePdf: File | undefined,
  selectedFileAudio: File | undefined,
  selectedFileName: string | undefined
  preview: any
}

const initialState: ResourceSlice = {
  selectedFileImg: undefined,
  selectedFileVideo: undefined,
  selectedFilePdf: undefined,
  selectedFileAudio: undefined,
  selectedFileName: undefined,
  preview: undefined
}

// const initialState: User;

export const resourceSlice = createSlice({
  name: 'resource',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedFileImg: (state, action: PayloadAction<any>) => {
      state.selectedFileImg = action.payload;
    }, 
    setSelectedFileVideo: (state, action: PayloadAction<any>) => {
      state.selectedFileVideo = action.payload;
    }, 
    setSelectedFilePdf: (state, action: PayloadAction<any>) => {
      state.selectedFilePdf = action.payload;
    }, 
    setSelectedFileAudio: (state, action: PayloadAction<any>) => {
      state.selectedFileAudio = action.payload;
    }, 
    setSelectedFileName: (state, action: PayloadAction<any>) => {
      state.selectedFileName = action.payload;
    }, 
    setPreview: (state, action: PayloadAction<any>) => {
      state.preview = action.payload;
    },


  },
});


export const { setSelectedFileImg, setSelectedFileVideo, setSelectedFilePdf, 
  setSelectedFileAudio, setSelectedFileName, setPreview } = resourceSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default resourceSlice.reducer
import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonRadioGroup, IonRadio, IonCheckbox, IonButton, IonLoading, IonIcon, IonImg } from '@ionic/react';


/* COMPONENTES */
import TituloActividades from '../../components/TituloActividad';
import star from '../../img/iconos/star.svg';

/* CSS */
import '../../theme/global.css';

// import {etapas} from '../../data/etapas.json';
import { useRef, useState } from 'react';
import Cookies from 'js-cookie';
import ChooseObjPopUp from '../PopUpModals/ChooseObjPopUp';
import { Editor } from '@tinymce/tinymce-react';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';
import edit_img from '../../img/iconos/edit_img.svg';
import remove_img from '../../img/iconos/remove_img.svg';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import $ from 'jquery';
import DetalleTipoActividadComp from '../helpers/DetalleTipoActividadComp';

interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  setShowAlert: any,
  setTextAlert: any,
}

const ActividadNueva: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setActualPage, setLastAction }) => {
  const { setLastPhoto } = usePhotoGallery();
  const {publicarActividad, crearActividad} = GlobalFunctions();
  const { goBack } = GlobalFunctions();
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [estado, setEstado] = useState(0);
  const [destacado, setDestacado] = useState(false);
  const [searches, setSearches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingActividad, setLoadingActividad] = useState(false);

  const [selectedType, setSelectedType] = useState("");

  const [ myFile, setFile ] = useState<File>();
   


  const publicarYCrear = () => {
    const formData = createFormData();

    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }
    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }
 
    if (nombre != "" && descripcion != "" ) {
      setLoadingActividad(true);
      publicarActividad('nueva', formData, setLastAction, setActualPage, setTextAlert, setShowAlert);
    }
  }

  const createActivity = () => {
    const formData = createFormData();

    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }
    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }

    if (nombre != "" && descripcion != "" ) {
      setLoadingActividad(true);
      crearActividad('nueva', setLoadingActividad,  formData, setLastAction, setActualPage, setTextAlert, setShowAlert);
    }

  }

  const createFormData = () => {
    const formData = new FormData();
    if (myFile != undefined) {
      formData.append("media", myFile);
      formData.append('type', selectedType);
    }
    
    formData.append("token", Cookies.get('token')!);
    formData.append("group_id", Cookies.get('group_id')?.toString()!);
    formData.append("name", nombre);
    formData.append("description", descripcion);
    formData.append("status", estado.toString());
    formData.append("important", `${destacado ? "1" : "0"}`);
    formData.append("isTemplate", `no`);
    //@ts-ignore
    formData.append("objectives_id", searches);
    
    return formData;
  }

  const removeImage = () => {
    setLastPhoto(undefined);
    // setFilename(undefined);
    setFile(undefined);
    $('#videoInput').val('');
    $('#imageInput').val('');
  }

  return (
    <>
      <IonGrid className="container">
        <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
        <IonRow>
          <TituloActividades titulo="Nueva actividad"  />
        </IonRow>

        <IonGrid className="form">
          <IonLoading
            isOpen={loadingActividad}
            onDidDismiss={() => setLoadingActividad(false)}
            message={'Creando actividad'}
            spinner={'bubbles'}
            duration={500000}
          />
          <IonRow>
            <IonCol size="12">
              <p>Nombre de la actividad</p>
              <IonItem>
                <IonInput required maxlength={70} type="text" onIonChange={e => <> {e.detail.value != undefined ? setNombre(e.detail.value?.toString()) : null}</>}></IonInput>
              </IonItem>
            </IonCol>
            <p className="aviso-naranja aviso-nombre small hidden"> * Debes introducir un nombre</p>
          </IonRow>
          
          <IonRow>
            <IonCol size="12">
              <p>Descripción</p>
              <Editor apiKey="zcwhxx8ze45rb7b73snp6zr957eqtcu9hglafgu9fybxu8zs"
                initialValue=""
                value={descripcion}
                onEditorChange={(newValue) => setDescripcion(newValue)}
                init={{
                  height: 500,
                  menubar: false,
                  content_css: 'theme/global.css',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help'
                }}
              />
            </IonCol>
            <p className="aviso-naranja aviso-descripcion small hidden"> * Debes introducir una descripcion</p>
          </IonRow>
          <DetalleTipoActividadComp setFile={setFile} myFile={myFile!} removeMedia={removeImage} selectedType={selectedType} setSelectedType={setSelectedType}/>

          <IonRow>
            <IonCol>
              <p className='my-2'>Asociar objetivos</p>
              <button className={`btn-objetivos`}
                onClick={() => setShowModal(true)}>
                  <p>ELEGIR OBJETIVOS</p>
              </button>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <p>Estado</p>
              <IonRadioGroup value={estado.toString()} className="radioGroup" onIonChange={e => { setEstado(e.detail.value); if (e.detail.value == '1') { setDestacado(true) } }}>
                <IonItem>
                  <IonLabel>Pendiente</IonLabel>
                  <IonRadio mode="md" slot="start" value="0"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>En curso</IonLabel>
                  <IonRadio mode="md" slot="start" value="1"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>Terminada</IonLabel>
                  <IonRadio mode="md" slot="start" value="2"></IonRadio>
                </IonItem>
              </IonRadioGroup>
            </IonCol>
          </IonRow>
          <IonRow className="mt-3">
            <IonCol size="12">
              <IonItem className="destacadoCheck" >
                <IonLabel>Destacada <IonIcon src={star} /> <br /><p className="body2 text-gris desktop">Se visualizará en el menú lateral. </p></IonLabel>
                <IonCheckbox
                  slot="start"
                  checked={estado != 1 ? false : destacado}
                  disabled={estado != 1 ? true : false}
                  onIonChange={e => setDestacado(e.detail.checked)} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12" className="lineal-flex">
              <IonButton onClick={() => { goBack('actividad', setActualPage) }} className="cancelBtn"><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" onClick={() => createActivity()}><p>Crear Actividad</p></IonButton>
            </IonCol>
            <IonCol size="12" className="">
              <IonButton className="timelineBtn" disabled={estado == 0 ? true : false} onClick={() => publicarYCrear()}><p className='px-5'>Crear y publicar en el timeline</p></IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonGrid>
      {showModal ? <ChooseObjPopUp setShowModal={setShowModal} setSearchesActivity={setSearches} searchesActivity={searches} /> : null}
    </>

  );
};

export default ActividadNueva;





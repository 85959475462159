import { IonRow, IonGrid, IonCol, IonButton, IonItem, IonInput, IonTextarea, IonLabel, IonCheckbox, IonRadioGroup, IonRadio, IonIcon, IonLoading, IonImg } from "@ionic/react";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";
import TituloRecurso from "../TituloRecurso";
/* ICONOS */
import imgColor from '../../img/iconos/img_color.svg';
import vidColor from '../../img/iconos/vid_color.svg';
import linkColor from '../../img/iconos/link_color.svg';
import pdfColor from '../../img/iconos/pdf_color.svg';
import audioColor from '../../img/iconos/audio_color.svg';
import imgBW from '../../img/iconos/img_bw.svg';
import vidBW from '../../img/iconos/vid_bw.svg';
import linkBW from '../../img/iconos/link_bw.svg';
import pdfBW from '../../img/iconos/pdf_bw.svg';
import audioBW from '../../img/iconos/audio_bw.svg';
import { getRecursos } from "../../api/apiRecursos";
import $ from 'jquery';
import { Resource } from "../../interfaces/intRecurso";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import edit_img from '../../img/iconos/edit_img.svg';
import remove_img from '../../img/iconos/remove_img.svg';
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import DetalleTipoRecursoComp from "../helpers/DetalleTipoRecursoComp";
import {setSelectedFileImg, setSelectedFileVideo, setSelectedFilePdf, setSelectedFileAudio} from '../../features/resource/resourceSlice'

import {useAppDispatch, useAppSelector} from '../../app/hooks'

interface ContainerProps {
  setActualPage: any,
  modificar?: boolean,
  selectedId?: number,
  setShowAlertMvl: any,
  setTextAlertMvl: any,
}

const BiblNuevoRecurso: React.FC<ContainerProps> = ({ setShowAlertMvl: setShowAlertMvl, setTextAlertMvl: setTextAlertMvl, setActualPage, modificar = false, selectedId }) => {
  const dispatch = useAppDispatch();
  const { setLastPhoto } = usePhotoGallery();
  const [selectedType, setSelectedType] = useState("imagen");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [valor, setValor] = useState("");
  const [file, setFile] = useState<File>();
  const [filename, setFilename] = useState<string>();
  const [ loadingFoto, setLoadingFoto ] = useState(false);
  const [ loadingUpdatingResource, setLoadingUpdatingResource ] = useState(false);
  const [ loadingNewResource, setLoadingNewResource ] = useState(false);
  const [ loadingVideo, setLoadingVideo ] = useState(false);
  const { crearRecurso, saveFile, saveFileVideo, uploadUrl } = GlobalFunctions();
  const fileInput = useRef(null);
  const videoRef = useRef(null);

  const [isMediaSelected, setIsMediaSelected] = useState(false);

  let history = useHistory();

  const removeMedia = () => {
    setIsMediaSelected(false);

    $('#videoInput').val('');
    $('#imageInput').val('');
    $('#pdfInput').val('');
    $('#audioInput').val('');

    setFile(undefined);
    setFilename("");
  }


  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      history.push('');
    }

    if (modificar) {

      getRecursos.get<Resource>('/resource/' + selectedId)
        .then(resp => {
          setSelectedType(resp.data.type);
          setNombre(resp.data.name);
          setDescripcion(resp.data.description);
          setFilename(resp.data.media_name!);

          setValor(resp.data.value);
        })
        .catch(err => console.log(err))
    }

  }, [])

  useEffect(() => {  
    if (selectedType == 'video'){
      //@ts-ignore
      videoRef.current?.load();
    }  
    // 
  }, [filename]);



  const createRecurso = () => {
    const formData = createFormData(false);
    if ((selectedType == 'imagen' || selectedType == 'pdf' || selectedType == 'audio') && file == undefined ||
        (selectedType == "link" && valor == "" ) || 
        (selectedType == "video") && (valor =="" && file == undefined)) {
      $('.aviso-naranja.aviso-detalle').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-detalle').addClass('hidden');

      if (nombre != "" && descripcion != "") {
        setLoadingNewResource(true)
        crearRecurso('crearBiblio', formData, setActualPage, setShowAlertMvl, setTextAlertMvl,undefined, undefined, setLoadingNewResource);
      }
    }

    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }

    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }
  }

  const modificarRecurso = () => {
    const formData = createFormData(true);

    if ((selectedType == 'imagen' || selectedType == 'pdf' || selectedType == 'audio') && file == undefined ||
        (selectedType == "link" && valor == "" ) || 
        (selectedType == "video") && (valor =="" && file == undefined)) {
      $('.aviso-naranja.aviso-detalle').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-detalle').addClass('hidden');

      if (nombre != "" && descripcion != "") {
        setLoadingUpdatingResource(true);
        crearRecurso('modificarBiblio', formData, setActualPage, setShowAlertMvl, setTextAlertMvl, selectedId, undefined, setLoadingUpdatingResource);
      }
    }

    if (nombre == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
    }

    if (descripcion == "") {
      $('.aviso-naranja.aviso-descripcion').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-descripcion').addClass('hidden');
    }
  }

  const createFormData = (isModify: boolean) => {
    const formData = new FormData();

    if (selectedType != "link") {
      formData.append("file", file!);
      formData.append('type', selectedType);
    }
    formData.append("name", nombre);
    formData.append("value", valor);
    formData.append("isTemplate", `yes`);
    formData.append("description", descripcion);

    formData.append("type", selectedType);

    if (!isModify) {
      formData.append("token", Cookies.get('token')!);
      formData.append("status", '0');
      formData.append("important", `0`);
      formData.append('template', 'yes');
    }

    return formData;
  }

  return (
    <IonGrid className="container">
      <IonLoading
        isOpen={loadingFoto}
        onDidDismiss={() => setLoadingFoto(false)}
        message={'Cargando foto'}
        spinner={'bubbles'}
        duration={5000}
      />
        <IonLoading
          isOpen={loadingVideo}
          onDidDismiss={() => setLoadingVideo(false)}
          message={'Cargando video'}
          spinner={'bubbles'}
          duration={9999999}
        />
        <IonLoading
          isOpen={loadingNewResource}
          onDidDismiss={() => setLoadingNewResource(false)}
          message={'Creando recurso'}
          spinner={'bubbles'}
          duration={9999999}
        />
        <IonLoading
          isOpen={loadingUpdatingResource}
          onDidDismiss={() => setLoadingUpdatingResource(false)}
          message={'Guardando cambios'}
          spinner={'bubbles'}
          duration={9999999}
        />
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      <IonRow>
        {modificar ? <TituloRecurso titulo="Editar recurso" />
        : <TituloRecurso titulo="Crear recurso" /> }
      </IonRow>

      <IonGrid className="form">
        <IonRow>
          <IonCol size="12">
            <p>Tipo de Recurso</p>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <button disabled={!isMediaSelected && !modificar ? false : true} onClick={() => { setSelectedType("imagen") }}>
              {selectedType === "imagen"
                ? <IonIcon src={imgColor} className="muyGrande" />
                : <IonIcon src={imgBW} className="muyGrande" />}
              <p className="body">Imagen</p>
            </button>

            <button disabled={!isMediaSelected && !modificar ? false : true} onClick={() => setSelectedType("video")}>
              {selectedType === "video"
                ? <IonIcon src={vidColor} className="muyGrande" />
                : <IonIcon src={vidBW} className="muyGrande" />}
              <p className="body">Vídeo</p>
            </button>

            <button disabled={!isMediaSelected && !modificar ? false : true} onClick={() => setSelectedType("link")}>
              {selectedType === "link"
                ? <IonIcon src={linkColor} className="muyGrande" />
                : <IonIcon src={linkBW} className="muyGrande" />}
              <p className="body">Enlace</p>
            </button>

            <button disabled={!isMediaSelected && !modificar ? false : true} onClick={() => setSelectedType("pdf")}>
              {selectedType === "pdf"
                ? <IonIcon src={pdfColor} className="muyGrande" />
                : <IonIcon src={pdfBW} className="muyGrande" />}
              <p className="body">PDF</p>
            </button>

            <button disabled={!isMediaSelected && !modificar ? false : true} onClick={() => setSelectedType("audio")}>
              {selectedType === "audio"
                ? <IonIcon src={audioColor} className="muyGrande" />
                : <IonIcon src={audioBW} className="muyGrande" />}
              <p className="body">Audio</p>
            </button>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <p>Nombre del recurso</p>
            <IonItem>
              <IonInput maxlength={70} type="text" value={nombre} onIonChange={e => <> {e.detail.value != undefined ? setNombre(e.detail.value?.toString()) : null}</>}></IonInput>
            </IonItem>
            <p className="aviso-naranja aviso-nombre small hidden"> * Campo obligatorio</p>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <p>Descripción</p>
              <IonTextarea className="big-textarea" value={descripcion} onIonChange={e => <> {e.detail.value != undefined ? setDescripcion(e.detail.value?.toString()) : null}</>}></IonTextarea>
            <p className="aviso-naranja aviso-descripcion small hidden"> * Campo obligatorio</p>
          </IonCol>
        </IonRow>
        <DetalleTipoRecursoComp setFile={setFile} myFile={file!} selectedType={selectedType} selectedFileName={filename!} removeMedia={removeMedia} setValor={setValor} setIsMediaSelected={setIsMediaSelected} valor={valor} />
        {/* {detalleTipoRecurso()} */}
        <p className="aviso-naranja aviso-detalle small hidden"> * Campo obligatorio</p>
        <p className="aviso-naranja aviso-detalle-grande small hidden">El archivo seleccionado es demasiado grande. Seleciona uno de máximo 100MB</p>

        <IonRow className="mt-4">
          <IonCol size="12" className="desktop lineal">
            <IonButton onClick={() => setActualPage("biblRecurso")} className="cancelBtn"><p>CANCELAR</p></IonButton>
            {/* <br/> */}
            {modificar
              ? <IonButton className="saveBtn" onClick={() => modificarRecurso()}><p className="">Guardar</p></IonButton>
              : <IonButton className="saveBtn" onClick={() => createRecurso()}><p className="">Crear recurso</p></IonButton>}
          </IonCol>
          <IonCol size="12" className="mvl">
            <IonButton onClick={() => setActualPage("biblRecurso")} className="cancelBtn"><p>CANCELAR</p></IonButton>
            {/* <br/> */}
            {modificar
              ? <IonButton className="saveBtn" onClick={() => modificarRecurso()}><p className="">Guardar</p></IonButton>
              : <IonButton className="saveBtn" onClick={() => createRecurso()}><p className="">Crear recurso</p></IonButton>}
          </IonCol>
        </IonRow>

      </IonGrid>


    </IonGrid>
  );
};
export default BiblNuevoRecurso;







import { IonRow, IonGrid, IonButton, IonButtons, IonCol, IonIcon, IonImg, useIonAlert, IonLoading } from "@ionic/react";
import exportIcon from '../../img/iconos/exportar_objetivos.svg';
import papelera from '../../img/iconos/papelera.svg';
import noObjBiblio from '../../img/iconos/no_obj_biblio.svg';
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import TituloObjetivo from "../TituloObjetivo";
import ExportObjectivePopUp from "../PopUpModals/ExportObjectivePopUp";
import { getData } from "../../api/apiRecursos";
import { ExportGroup } from "../../interfaces/intGroups";
import {useAppSelector} from '../../app/hooks'
import { GlobalFunctions } from "../../hooks/GlobalFunctions";


interface ContainerProps {
  setActualPage: any,
  setSelectedId: any,
  setShowAlertMvl: any,
  setTextAlertMvl: any,
}

const BiblObjetivo: React.FC<ContainerProps> = ({  setTextAlertMvl, setShowAlertMvl,setActualPage, setSelectedId }) => {
  const history = useHistory();
  const [showExportModal, setShowExportModal] = useState(false);
  const [objectiveId, setObjectiveId] = useState<number>();
  const [lastAction, setLastAction] = useState('');
  const [loading, setLoading] = useState(true);
  const exportList = useAppSelector(state=> state.reducer.groupSelected.exportedObjectives);
  const {getExportObjectives} = GlobalFunctions();

  const userData = {
    token: Cookies.get('token'),
    biblioteca: true
  }

  const axios = require('axios');

  const [checkEliminar] = useIonAlert();

  const eliminar = (id: number) => {
    checkEliminar({
      header: `Eliminar set de objetivos`,
      message: `¿Seguro que quieres eliminar este set de objetivos de Mi biblioteca? `,
      buttons: [
        'CANCELAR', { text: 'ELIMINAR', handler: () => deleteElement(id) },
      ],
      onDidDismiss: () => console.log(""),
    })
  }
  const deleteElement = (id: number) => {
    getData.delete("/export/delete/" + id)
      .then(() => {
        // reload();
        setLastAction("delete export" + Date.now());
        setShowAlertMvl(true);
        setTextAlertMvl('Set de objetivos eliminado.');
        getExportObjectives(setLoading);

      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }




  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      history.push('');
    }

    getExportObjectives(setLoading);

  }, [lastAction])


  const setTime = (date: Date) => {
    date = new Date(date);
    return date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear();
  }
  return (
    <>
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      <IonGrid className="container">
        <IonRow className="rowTitulo">
        <h3 className="mvl">Mi biblioteca</h3>
          <TituloObjetivo titulo="Mis objetivos" />
          {exportList.length < 1
            ? null
             :          
            <IonCol size="4" className="d-flex justify-content-end ">
                  {/* <IonButtons className="btn-minusculas">
                    <IonButton className="addObjetivo export-button">
                      <IonCol onClick={e => setShowExportModal(true)}>
                        <IonIcon className=" muyGrande" src={exportIcon}></IonIcon>
                        <p className="body2" >Exportar<br />set<br />de objetivos</p>
                      </IonCol>
                    </IonButton>
                  </IonButtons> */}
                </IonCol>
                }

        </IonRow>

        {loading
          ? <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={'Cargando'}
            spinner={'bubbles'}
            showBackdrop={true}
            duration={5000}
          />
          : <>
            {exportList.length < 1
              ? <IonRow className="my-5">
                <IonCol size-md="12" size-lg="8" offset-md="2" className="text-center">
                  <p className="body1">Todavía no tienes ningún set de objetivos guardado</p>
                  <p><br/>Crea tus propios sets o plantillas de objetivos para usarlos con tus usuarios de ComuniteCAA.
Para preparar un set de objetivos personalizado, crea un perfil de usuario de ComuniteCAA, define unos objetivos y selecciona la opción "Guardar como set de objetivos" que encontrarás en el menú de la esquina superior derecha.
</p>
                  <img src={noObjBiblio} className="my-4"></img> <br />
                  {/* <IonButton className="saveBtn mt-5" onClick={e => setShowExportModal(true)}><p>EXPORTAR SET DE OBJETIVOS</p></IonButton> */}
                </IonCol>

              </IonRow>
              : <>{exportList.map((objExport, index) =>
                <IonRow key={index}>
                  <IonCol size="12" className="align-self-center">
                    <p className="body1">Objetivos de {objExport.name}_{setTime(objExport.created_at)} <img onClick={e => eliminar(objExport.id)} src={papelera} className="handCursor" /></p>
                  </IonCol>
                </IonRow>
              )}</>} </>}

        {showExportModal ? <ExportObjectivePopUp setShowAlert={setShowAlertMvl} setTextAlert={setTextAlertMvl} objectiveId={objectiveId!} setShowExportModal={setShowExportModal} setLastAction={setLastAction} /> : null}
      </IonGrid>
    </>
  );
};
export default BiblObjetivo;





import { IonCol, IonGrid, IonIcon, IonLoading, IonPopover, IonProgressBar, IonRow, isPlatform } from '@ionic/react';

/* COMPONENTES */
import ModificarElemento from '../ModificarElemento';

/* DATA */
// ICONES
import objLogo from '../../img/iconos/objetivos-logo-min.svg';
import star from '../../img/iconos/star.svg';
import checkmark_negro from '../../img/iconos/check_lila.svg';
import { Fragment, useEffect, useState } from 'react';
import { getData } from '../../api/apiRecursos';
import { Activities, Activity } from '../../interfaces/intActivity';
import Cookies from 'js-cookie';
import chevronRight from '../../img/iconos/chevron-right.svg';
import { useHistory } from 'react-router';
import noActivBiblio from '../../img/iconos/no_activ_biblio.svg';

//REDUX
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { GlobalFunctions } from '../../hooks/GlobalFunctions';

interface Props {
  setActualPage: any;
  setSelectedId?: any;
  estado: number;
  showHide: boolean;
  order: string;
  isReversed: boolean;
  setLastAction: any;
  lastAction: string;
  setShowBack?: any;
  setShowAlert: any,
  setTextAlert: any,
}

const Actividad: React.FC<Props> = ({ setTextAlert, setShowAlert, setShowBack, setActualPage, setSelectedId: setSelectedId, estado, showHide, order, isReversed, lastAction, setLastAction }) => {
  //REDUX
  const activities = useAppSelector(state => state.reducer.groupSelected.activities);
  const role = useAppSelector(state => state.reducer.groupSelected.userRole);

  const objectiveActivities = useAppSelector(state => state.reducer.activitiesSlice.objectiveActivities);
  const noObjectiveActivities = useAppSelector(state => state.reducer.activitiesSlice.noObjectiveActivities);

  // const [activities, setActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState(true);
  const [popoverStateActiv, setShowPopoverActiv] = useState({ showPopover: false, event: undefined });
  const history = useHistory();
  const {getObjectivesActivities} = GlobalFunctions();



  useEffect(() => {
    getObjectivesActivities(setLoading)
  }, []);

  function setEstado(estado: number) {
    switch (estado) {
      case 99:
        return "Todas"
      case 0:
        return "pendiente"

      case 1:
        return "enCurso"

      case 2:
        return "terminada"

      default:
        break;
    }
  }

  function setCategory(category: number) {
    switch (category) {
      case 1:
        return "Linguística"
      case 2:
        return "Operacional"

      case 3:
        return "Social"

      case 4:
        return "Estratégica"
      default:
        break;
    }
  }

  const ordered = [...activities].sort(function (a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });


  let actividReversed;

  if (isReversed) {
    actividReversed = [...ordered].reverse();
  } else {
    actividReversed = ordered;
  }

  const showDetail = (id: number) => {
    setSelectedId(id);
    setActualPage("activityDetail");
    if (setShowBack) {
      setShowBack(true);
    }
  }

  const showObjectiveDetail = (id: number) => {
    setSelectedId(id);
    setActualPage("objectiveDetail");
  }
  const checkEstado = (estado: number, destacado: number) => {

    if (estado === 2) {
      return (
        <IonIcon icon={checkmark_negro} className="medio"></IonIcon>
      )
    } else if (destacado == 1) {
      return (
        <IonIcon src={star} className="medio"></IonIcon>
      )
    }
  }
  const abrirBiblio = () => {
    setShowPopoverActiv({ showPopover: false, event: undefined });
    history.push({
      pathname: '/biblioteca/actividades',
    })
  }

  const showActivity = (actividad: Activity, isDesktop: boolean, objId?: number) => {
    return (
      <IonCol size-xs="6" size-md="4" className={`pt-0 ${setEstado(actividad.status)} ${actividad.important == 1 ? "destacada" : ""}`}>
        <IonRow >
          <IonCol size="12" className="pt-0">
            <div className="actividad boxActividad pt-3 lineal-flex" style={{ width: '100%', maxHeight: '10rem', overflow:'hidden'}}>
              <a className="activity-text" onClick={() => showDetail(actividad.id)}>
                { actividad.name == "" ?
                <p className="body2 activity-clipped-text-medium">{actividad.description}</p>
                :<>
                  <p className="body2 activity-clipped-text-medium strong" style={{'fontSize':'1.75rem', 'lineHeight':'2.3rem','lineBreak': 'anywhere'}}>{actividad.name}</p> 
                  {!isPlatform('ios') ? 
                    <p className="body2 activity-clipped-text-small" style={{'lineBreak': 'anywhere'}} dangerouslySetInnerHTML={{ __html: actividad.description }}></p> 
                  :null}
                </>
                }
                {/* <p className="body2 activity-clipped-text">{actividad.name == "" ? actividad.description : actividad.name }</p> */}
              </a>

              <div className='d-grid icons-vertical ml-auto'>
                {showHide
                  ? <ModificarElemento setTextAlert={setTextAlert} setShowAlert={setShowAlert} tipo="actividad" setActualPage={setActualPage} id={actividad.id} setSelectedId={setSelectedId} setLastAction={setLastAction} />
                  : null}
                {checkEstado(actividad.status, actividad.important)}
              </div>
            </div>          
            </IonCol>
        </IonRow>
      </IonCol>
    )
  }

  const showEmpty = (isObjective? :boolean) => {
    switch (estado) {
      case 0:
        return (
         <IonRow>
            <IonCol size-md="12" size-lg="6" offset-lg="3" className="text-center my-5">
              <p className="body1">No hay actividades {isObjective ? "con objetivos" : ""} pendientes</p>
              <img src={noActivBiblio} className="my-4"></img> <br />
            </IonCol>
         </IonRow>
        )

      case 1:
        return (
          <IonRow>
            <IonCol size-md="12" size-lg="6" offset-lg="3" className="text-center my-5">
            <p className="body1">No hay actividades {isObjective ? "con objetivos" : ""}  en curso</p>
            <img src={noActivBiblio} className="my-4"></img> <br />
          </IonCol>
          </IonRow>
        )

      case 2:
        return (
          <IonRow>
            <IonCol size-md="12" size-lg="6" offset-lg="3" className="text-center my-5">
            <p className="body1">No hay actividades {isObjective ? "con objetivos" : ""}  terminadas</p>
            <img src={noActivBiblio} className="my-4"></img> <br />
          </IonCol>
          </IonRow>
        )

      default:
        return (
          <IonRow>
            <IonCol size-md="12" size-lg="6" offset-lg="3" className="text-center my-5">
            <p className="body1">No hay actividades relacionadas con objetivos</p>
            <img src={noActivBiblio} className="my-4"></img> <br />
          </IonCol>
          </IonRow>
        )
    }
  }

  return (
    <>{loading
      ? <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={5000}
      />
      : <>
        {actividReversed.length < 1
          ? <> {role?.role != 1
            ? <IonGrid className="my-5 cardEmptyElement">
              <IonRow>
                <IonCol size="8" offset='2' className="bg-white p-5">
                  <p className="body1">Empieza a definir las actividades.</p>
                  <a className="body2" onClick={
                    (e: any) => {
                      e.persist();
                      setShowPopoverActiv({ showPopover: true, event: e })
                    }}><p className="body1 col-12 text-naranja mt-5">CREAR ACTIVIDADES <IonIcon src={chevronRight} /></p></a>
                  <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateActiv.event}
                    isOpen={popoverStateActiv.showPopover}
                    onDidDismiss={() => setShowPopoverActiv({ showPopover: false, event: undefined })}
                  >
                    <a className='popup-select' onClick={() => setActualPage("activityNew")}><p className="body1">Crear nueva actividad</p></a>
                    <a><p className="body1" onClick={() => { setActualPage("activObjetivosActivos"); setShowPopoverActiv({ showPopover: !popoverStateActiv.showPopover, event: undefined }) }}>Actividades de ComuniteCAA</p></a>
                    <a className='popup-select'><p className="body1" onClick={() => { setActualPage('activityBiblio'); setShowPopoverActiv({ showPopover: !popoverStateActiv.showPopover, event: undefined }) }
                    }>Importar actividad de Mi biblioteca</p></a>
                  </IonPopover>
                </IonCol>
              </IonRow>
            </IonGrid>
            : <IonGrid className="my-5 cardEmptyElement">
              <IonRow>
                <IonCol size="8" offset='2' className="bg-white p-5">
                  <p className="body1">Aún no hay actividades creadas.</p>
                </IonCol>
              </IonRow>
            </IonGrid>}</>
          : <>

            {order === "Objetivo"
              ?
              <>
                <IonGrid className="activities-main">
                  {(estado != 99 &&(objectiveActivities.filter(activ => activ.activities.find(act => act.status == estado)).length == 0)
                    && (noObjectiveActivities == undefined || !noObjectiveActivities!.activities.find(act => act.status == estado)))
                    ||
                    (estado == 99 && noObjectiveActivities == undefined && !objectiveActivities.find(activ => activ.activities.length > 0))
                    ? showEmpty(true)
                    :
                    <>
                      <h4>Actividades con objetivo asociado</h4>
                      <hr/>
                      {(objectiveActivities.filter(activ => activ.activities.find(act => act.status == estado)).length != 0) || estado == 99
                      ? <>

                        {estado == 99 && objectiveActivities.find(activ => activ.activities.length >= 1)?.activities.some(act => act.status == estado) == undefined
                          ? showEmpty(true)
                          : null }

                        {objectiveActivities.map((objetivo, i) =>

                          <Fragment key={i}>
                            {objetivo.activities.length > 0 && (objetivo.activities.find(activ => activ.status == estado) || estado == 99)
                              ?
                              <>
                                <IonRow key={i} className="pb-2 mt-3">
                                  <div className='d-inline-flex align-items-center '>
                                    <IonIcon src={objLogo} className="grande" />
                                    <div style={{'marginLeft':"15px"}}>
                                      <a onClick={() => showObjectiveDetail(objetivo.id)}><p className="body1">{objetivo.name}</p>
                                      <p className="overline text-verd">{setCategory(objetivo.category)}</p></a>
                                    </div>
                                  </div>

                                </IonRow>
                                <IonGrid >
                                  <IonRow>
                                    {objetivo.activities.map((actividad, index) =>
                                      <Fragment key={index}>
                                        {estado == 99
                                          ? <>

                                            {actividad.status == 0
                                              ? <> {showHide ? <>{showActivity(actividad, true)}</> : null}</>
                                              : <>{showActivity(actividad, true)}</>
                                            }
                                          </>
                                          : <> {actividad.status == estado ? <>{showActivity(actividad, true)}</> : null} </>}
                                      </Fragment>
                                    )}
                                  </IonRow>
                                </IonGrid>
                              </>
                              : null
                            }
                          </Fragment>
                        )}
                      </>
                      : showEmpty(true)}
                      <br/>
                      <br/>
                      <br/>

                      <h4>Actividades sin objetivo asociado</h4>
                      <hr/>

                      {(noObjectiveActivities!.activities.find(act => act.status == estado)) || estado == 99
                      ? <>
                        {estado == 99 && noObjectiveActivities!.activities.length == 0
                          ? showEmpty(true)
                          : null }

                        {noObjectiveActivities!.activities.map((activity, i) =>
                          <Fragment key={i}>
                            <IonRow>
                              {activity.status == estado || estado == 99
                              ? <>{showActivity(activity, true)}</>
                              :  null
                              }
                            </IonRow>
                          </Fragment>
                        )}
                      </>
                      : showEmpty(true)
                      }
                    </>
                  }



                </IonGrid>
              </>
              :
              <>
                <IonGrid className="activities-main">

                    {actividReversed.filter(activ => activ.status == estado).length < 1 && estado != 99
                      ? <>{showEmpty()}</>
                      : <IonRow> {actividReversed.map((actividad, index) =>
                        <Fragment key={index}>
                          {estado == 99
                            ? <>
                              {actividad.status == 0
                                ? <> {showHide ? <>{showActivity(actividad, true)}</> : null}</>
                                : <>{showActivity(actividad, true)}</>
                              }
                            </>
                            : <> {actividad.status == estado ? <>{showActivity(actividad, true)}</> : null} </>}
                        </Fragment>
                      )}
                      </IonRow>}
                </IonGrid>
              </>
            } </>}
      </>
    }
    </>
  );
}

export default Actividad;





import { IonGrid, IonRow, IonCol, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter } from '@ionic/react';

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';


/* CSS */
import '../theme/global.css';

/* ICONOS */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { User } from '../interfaces/intUser';
import { getData } from '../api/apiRecursos';
import MenuLateralBiblio from '../components/Biblioteca/MenuLateralBiblio';
import BiblActividad from '../components/Biblioteca/BiblActividad';
import BiblObjetivo from '../components/Biblioteca/BiblObjetivo';
import BiblRecurso from '../components/Biblioteca/BiblRecurso';
import BiblNuevaActividad from '../components/Biblioteca/BiblNuevaActividad';
import MvlTabsElement from '../components/MvlTabsElement';
import BiblNuevoRecurso from '../components/Biblioteca/BiblNuevoRecurso';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'

interface Props {
  page?:string,
}
const MvlBiblioRecurso: React.FC<Props> = ({page}) => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);

  const [actualPage, setActualPage] = useState("biblRecurso");
  const [showAlertMvl, setShowAlertMvlMvl] = useState(false);
  const [textAlertMvl, setTextAlertMvlMvl ] = useState("");

  const [user, setUser] = useState<User>();
  let history = useHistory();

  
  if (window.location.pathname == '/biblioteca/recursos'){
    var tabsBiblio = Array.from(document.getElementsByClassName('tab-biblio') as HTMLCollectionOf<HTMLElement>);
    var tabsApp = Array.from(document.getElementsByClassName('tab-app') as HTMLCollectionOf<HTMLElement>);

    var i;
    for (i = 0; i < tabsBiblio.length; i++) {
      tabsBiblio[i].setAttribute('style', 'display: block !important');
    }
    for (i = 0; i < tabsApp.length; i++) {
      tabsApp[i].setAttribute('style', 'display: none !important');
    }  
  }

  const [selectedId, setSelectedId] = useState<number>();



  useIonViewWillEnter(() => {
      setActualPage('biblRecurso');
  });


  

  
  function mostrarContenido() {
    switch (actualPage) {
      case "biblRecurso":
        return <BiblRecurso setShowAlertMvl={setShowAlertMvlMvl} setTextAlertMvl={setTextAlertMvlMvl} setActualPage={setActualPage} setSelectedId={setSelectedId} />

      case "biblNuevoRecurso":
        return <BiblNuevoRecurso setShowAlertMvl={setShowAlertMvlMvl} setTextAlertMvl={setTextAlertMvlMvl} setActualPage={setActualPage} />
      case "biblModifRecurso":
        return <BiblNuevoRecurso setShowAlertMvl={setShowAlertMvlMvl} setTextAlertMvl={setTextAlertMvlMvl} setActualPage={setActualPage} modificar={true} selectedId={selectedId} />

    }
  }

  const userData = {
    'token': Cookies.get('token')
  }

  useEffect(() => {
    // setUserAvatar(window.localStorage.getItem('userPhoto')!);


  }, [])
  const showPopUp = () => {
    setTimeout(() => {
      setShowAlertMvlMvl(false);
    }, 4000);
  }
  

  return (
    <IonPage>
      <HeaderEmpty avatarProp={userAvatar} />
      {showAlertMvl
          ? <>{showPopUp()} <AvisoPopUp text={textAlertMvl}/></>
          : null}

      <IonContent fullscreen>
        <IonGrid>
        
          <IonRow>
            <IonCol sizeLg="3" sizeMd="4" className="left desktop">
              <MenuLateralBiblio setActualPage={setActualPage} actualPage={actualPage} />
            </IonCol>
            <IonCol className="right">
              {mostrarContenido()}
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
      {/* <MvlTabsElement setActualPage={setActualPage}/> */}
    </IonPage>
  );
};

export default MvlBiblioRecurso;
import { IonRow, IonCol, IonSelect, IonSelectOption, IonLoading } from "@ionic/react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import { ObjActiv } from "../../interfaces/intObjective";
import BiblActividadesObjetivosTodos from "./BiblActividadesObjetivosTodos";
import ShowActivityDetail from "../PopUpModals/ShowActivityDetail";
import $ from 'jquery';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";



interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  type?: string,
  setShowAlert: any,
  setTextAlert:any
}

const BiblActividadesObjetivos: React.FC<ContainerProps> = ({setShowAlert,setTextAlert, setActualPage, setLastAction, lastAction }) => {
  const [activities, setActivities] = useState('todas');
  const [isEmpty, setIsEmpty] = useState(true);
  const history = useHistory();
  const [objectsActiv, setObjectsActiv] = useState<ObjActiv>();
  const [loading, setLoading] = useState(true);
  const {selectedId, showDetail, setShowDetail, activity} = GlobalFunctions();

  
  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    const userData = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    }

    getData.post<ObjActiv>('/objectiveActivitiesActives', userData)
      .then(resp => {
        //@ts-ignore
        setObjectsActiv(resp.data);
        if (resp.data.total_objectives > 0){
          setIsEmpty(false);
          setActivities('activas');
        }
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        
      })
      .catch(err => console.log(err));
  }, [])


  const options = {
    cssClass: 'select-activities-caa'
  };


  const mostrarElementos = () => {
      return <BiblActividadesObjetivosTodos setShowDetail={setShowDetail} setSelectedId={selectedId} setShowAlert={setShowAlert} setTextAlert={setTextAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='todos' />
  }


  return (
    <div className="actividades-defecto-comunitecaa">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <div className=" desktop-stiky">
        <div id="bannerEdicion" className="d-flex justify-content-center edicion"><p className="okButton">ACTIVIDADES DE COMUNITECAA</p></div>
      </div>
      <div className="container act-obj-copia " >
        <>
          {showDetail
          ? <><ShowActivityDetail setShowDetail={setShowDetail} activity={activity!}/></>
          : null}
          <IonRow className="rowTitulo">
            <IonCol size="12" className="">
                <div className="btn-salir handCursor" onClick={() => setActualPage('biblActividad')}>
                  <p>Salir</p>
                </div>
            </IonCol>
            <IonCol size="12" className="lineal-flex  align-items-center">
              <p>Elige actividades para añadir: </p>
              <div className="ml-auto">
              </div>
              
            </IonCol>
            
          </IonRow>
          {loading ? null : <>{mostrarElementos()}</> }

        </>
      </div>
    </div>
  );
};
export default BiblActividadesObjetivos;







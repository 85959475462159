import { IonRow, IonCol, IonLoading, IonGrid, useIonAlert, IonIcon } from "@ionic/react";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router";
import { getData, getRecursos } from "../../api/apiRecursos";
import addIcon from '../../img/iconos/add_icon.svg';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import { Resource, Recursos } from "../../interfaces/intRecurso";
import logoImg from '../../img/iconos/img-small.svg';
import logoVid from '../../img/iconos/video-small.svg';
import logoLink from '../../img/iconos/enlace-small.svg';
import logoPdf from '../../img/iconos/pdf-small.svg';
import logoAud from '../../img/iconos/audio-small.svg';
import ShowResourceDetail from "../PopUpModals/ShowResourceDetail";
import noRecBiblio from '../../img/iconos/no_rec_biblio.svg';




interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  type?: string,
  setShowAlert: any,
  setTextAlert: any
}

const RecursosBiblio: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setActualPage, setLastAction, lastAction }) => {
  const [recursos, setRecursos] = useState<Resource[]>([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const history = useHistory();
  const [copyModal, setCopyModal] = useState(false);
  const [resourceId, setResourceId] = useState(Number);
  const { selectedId, showDetail, setShowDetail, resource, goBack, copyResource} = GlobalFunctions();
  const [loadingCopy, setLoadingCopy] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    const userData = {
      token: Cookies.get('token'),
      // group_id: Cookies.get('group_id'),
    }

    getRecursos.post<Recursos>('/getResources', userData)
      .then(resp => {
        //@ts-ignore
        setRecursos(resp.data);
        
      })
      .then(() => {
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [])

  const [checkCopiar] = useIonAlert();

  const clickCopiar = (resourceId: number) => {
    checkCopiar({
      message: `¿Seguro que quieres añadir este recurso?`,
      buttons: [
        'CANCELAR', { text: 'AÑADIR', handler: () => copyResourceFunction(resourceId) },
      ],
      onDidDismiss: () => console.log(""),
    })
  }
  const copyResourceFunction = (resourceId: number) => {
    setLoadingCopy(true)
    const dataRelation = {
      token: Cookies.get('token'),
      resource_id: resourceId,
      group_id: Cookies.get('group_id'),
      is_template: true,
    };
    copyResource(dataRelation, setLoadingCopy, setShowAlert, setTextAlert, setLastAction);
  }

  const insertLogo = (tipo: string) => {
    switch (tipo) {
      case "imagen":
        return <IonIcon className="grande" src={logoImg} />

      case "video":
        return <IonIcon className="grande" src={logoVid} />

      case "link":
        return <IonIcon className="grande" src={logoLink} />

      case "pdf":
        return <IonIcon className="grande" src={logoPdf} />

      case "audio":
        return <IonIcon className="grande" src={logoAud} />

      default:
        break;
    }
  }

  return (
    <div className="actividades-defecto-comunitecaa">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <IonLoading
        isOpen={loadingCopy}
        onDidDismiss={() => setLoadingCopy(false)}
        message={'Copiando recurso'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <div className=" desktop-stiky">
        <div id="bannerEdicion" className="d-flex justify-content-center edicion"><p className="okButton">RECURSOS DE MI BIBLIOTECA</p></div>
      </div>
      {showDetail
          ? <ShowResourceDetail setShowDetail={setShowDetail} resource={resource!}/>
          : null}
      <div className="container act-obj-copia " >
        <>
          <IonRow className="rowTitulo">
            <IonCol size="12" className="">
              <div className="btn-salir handCursor" onClick={() => goBack('recurso', setActualPage)}>
                <p>Salir</p>
              </div>
            </IonCol>
            

          </IonRow>
          <IonGrid>
              {recursos.length == 0  
                ? <IonRow>
                    <IonCol size-md="12" size-lg="6" offset-md="3" className="text-center my-5">
                      <p className="body1">Todavía no tienes recursos en Mi biblioteca.</p>
                      <img src={noRecBiblio} className="my-4"></img> <br />
                    </IonCol>
                  </IonRow>
                : <>
                <IonRow>
                    <IonCol size="12" className="lineal-flex  align-items-center">
                      <p>Elige recursos para añadir a tu usuario: </p>
                    </IonCol></IonRow>
                    <IonGrid>
                      <>{recursos.map((recurso, index) =>
                        <Fragment key={index}>
                          <IonRow >
                            <div className="cnt-recurso handCursor">
                              {insertLogo(recurso.type)}
                              <a className="m-2" onClick={e => selectedId(recurso.id, 'resource')}><p className="body2">{recurso.name}</p></a>
                              <div className={`ml-auto inline-flex`}>
                                <img className='' src={addIcon} style={{}} onClick={() => clickCopiar(recurso.id)} />
                              </div>
                            </div>
                          </IonRow>
                        </Fragment>
                      )} </>
                    </IonGrid>
                  </> }


            
            



          </IonGrid>

        </>
      </div>
    </div>
  )};
export default RecursosBiblio;

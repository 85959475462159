import { IonRow, IonGrid, IonButton, IonButtons, IonCol, IonIcon, IonPopover, IonLoading } from "@ionic/react";
import addCircle from '../../img/iconos/add-circle.svg';
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router";
import TituloRecurso from "../TituloRecurso";
import arrowBot from '../../img/iconos/flecha_azul.svg';
import { getRecursos } from "../../api/apiRecursos";
import { Resource, Recursos } from "../../interfaces/intRecurso";

import logoImg from '../../img/iconos/img-small.svg';
import logoVid from '../../img/iconos/video-small.svg';
import logoLink from '../../img/iconos/enlace-small.svg';
import logoPdf from '../../img/iconos/pdf-small.svg';
import logoAud from '../../img/iconos/audio-small.svg';
import star from '../../img/iconos/star.svg';
import copyIcon from '../../img/iconos/copy-icon.svg';
import CopyResource from "../PopUpModals/CopyResource";
import ModificarElemento from "../ModificarElemento";
import noRecBiblio from '../../img/iconos/no_rec_biblio.svg';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import ShowResourceDetail from "../PopUpModals/ShowResourceDetail";

import {useAppSelector, useAppDispatch} from "../../app/hooks";
import {setActivities as setActivitiesBiblio, setResources as setResourcesBiblio} from '../../features/itemsBiblio/itemsBiblioSlice'






interface ContainerProps {
  setActualPage: any,
  setSelectedId: any,
  setShowAlertMvl: any,
  setTextAlertMvl: any,
}

const BiblRecurso: React.FC<ContainerProps> = ({ setShowAlertMvl, setTextAlertMvl,setActualPage, setSelectedId }) => {
  const dispatch = useAppDispatch();
  const {selectedId, showDetail, setShowDetail, resource, getAllResources} = GlobalFunctions();
  const history = useHistory();
  const [showHide, setShowHide] = useState(false);
  const [estado, setEstado] = useState("todo");
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [order, setOrderBy] = useState("Alfabéticamente");
  const [isReversed, setReversed] = useState(false);
  // const [recursos, setRecursos] = useState<Resource[]>([]);
  const recursos = useAppSelector(state => state.reducer.itemsBiblio.resources);
  const [resourceId, setResourceId] = useState(Number);
  const [copyModal, setCopyModal] = useState(false);
  const [lastAction, setLastAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [popoverStateRec, setShowPopoverRec] = useState({ showPopover: false, event: undefined });
  const [popoverStateRecEmpty, setShowPopoverRecEmpty] = useState({ showPopover: false, event: undefined });
  var recursosCopy;


  let recursosOrdenados: Resource[];


  const userData = {
    token: Cookies.get('token'),
  }

  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    getAllResources(setLoading);
  }, [lastAction])



  const changeOrder = () => {
    if (isReversed) {
      setReversed(!isReversed);
    } else {
      setReversed(!isReversed);
    }
  }
  const showModal = (id: number) => {
    setResourceId(id);
    setCopyModal(true);
  }

  const setOrder = (text: string) => {
    setOrderBy(text);
    setShowPopover({ showPopover: false, event: undefined });
  }

  if (order == "Alfabéticamente") {
    if (recursos.length != 0) {
      recursosCopy = [...recursos];
      recursosOrdenados = recursosCopy.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }

  } else {
    recursosOrdenados = [...recursos];
  }
  const showContent = () => {
    let filtrados;
    switch (estado) {
      case "todo":
        if (isReversed) {
          return mostrarRecursos(recursosOrdenados.reverse(), order, showHide);
        } else {
          return mostrarRecursos(recursosOrdenados, order, showHide);
        }

      case "activo":
        filtrados = recursosOrdenados.filter(recurso => recurso.status == 1);
        if (isReversed) {
          return mostrarRecursos(filtrados.reverse(), order, showHide);
        } else {
          return mostrarRecursos(filtrados, order, showHide);
        }

        break;
      case "inactivo":
        filtrados = recursosOrdenados.filter(recurso => recurso.status == 0);
        if (isReversed) {
          return mostrarRecursos(filtrados.reverse(), order, showHide);
        } else {
          return mostrarRecursos(filtrados, order, showHide);
        }

      default:
        break;
    }
  }
  const insertLogo = (tipo: string) => {
    switch (tipo) {
      case "imagen":
        return <IonIcon className="grande" src={logoImg} />

      case "video":
        return <IonIcon className="grande" src={logoVid} />

      case "link":
        return <IonIcon className="grande" src={logoLink} />

      case "pdf":
        return <IonIcon className="grande" src={logoPdf} />

      case "audio":
        return <IonIcon className="grande" src={logoAud} />

      default:
        break;
    }
  }


  function showEditButton(id: number, showHide: boolean) {
    return (
      <>
        <IonIcon className="medio" src={copyIcon} onClick={() => showModal(id)} />
        <ModificarElemento setShowAlert={setShowAlertMvl} setTextAlert={setTextAlertMvl} tipo="recurso" setSelectedId={setSelectedId} id={id} setActualPage={setActualPage} isBiblio={true} setLastAction={setLastAction} />
      </>
    )
  }

  const rowRecurso = (recursos: Resource []) =>{
    return (
      <>{recursos.map((recurso, index) =>
      <Fragment key={index}>
      <IonRow >
        <div className="cnt-recurso">
          {insertLogo(recurso.type)}
          <a className="m-2" onClick={e => selectedId(recurso.id, 'resource')}><p className="body2">{recurso.name}</p></a>
          <div className={`d-flex inline-flex`}>
            {showEditButton(recurso.id, showHide)}
          </div>
        </div>
      </IonRow>
    </Fragment>


    )}
    </>
    
    );

  }
  const mostrarRecursos = (recursos: Resource[], orden: string, showHide: boolean) => {
    if (orden === "Alfabéticamente") {
      return (
        <>
          {recursos.map((recurso, index) =>
            <Fragment key={index}>
              <IonRow >
                <div className="cnt-recurso">
                  {insertLogo(recurso.type)}
                  <a className="m-2" onClick={e => selectedId(recurso.id, 'resource')}><p className="body2">{recurso.name}</p></a>
                  <div className={`d-flex inline-flex flex-grow-1 justify-content-end`}>
                    {showEditButton(recurso.id, showHide)}
                  </div>
                </div>
              </IonRow>
            </Fragment>
          )}

        </>
      )
    } else {
      const arrVideo = recursos.filter(recurso => recurso.type == "video");
      const arrImg = recursos.filter(recurso => recurso.type == "imagen");
      const arrEnlace = recursos.filter(recurso => recurso.type == "link");
      const arrAudio = recursos.filter(recurso => recurso.type == "audio");
      const arrPdf = recursos.filter(recurso => recurso.type == "pdf");

      return (
        <>
          {arrVideo.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Vídeo</b></p>
                </IonCol>
              </IonRow>
                {rowRecurso(arrVideo)}
            </>
            : null}

          {arrImg.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Imagen</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrImg)}
            </>
            : null}

          {arrEnlace.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Enlace</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrEnlace)}
            </>
            : null}

          {arrAudio.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Audio</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrAudio)}
            </>
            : null}
          {arrPdf.length > 0 ?
            <>
              <IonRow>
                <IonCol size="12">
                  <p className="body1"><b>Pdf</b></p>
                </IonCol>
              </IonRow>
              {rowRecurso(arrPdf)}

            </>
            : null}
        </>
      )
    }
  }



  return (
    <>
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      {showDetail
          ? <ShowResourceDetail setShowDetail={setShowDetail} resource={resource!}/>
          : null}
      <IonGrid className="container">
        <IonRow className="rowTitulo">
        <h3 className="mvl">Mi biblioteca</h3>
          <TituloRecurso titulo="Mis recursos"/>
          {loading
            ? <IonLoading
              isOpen={loading}
              onDidDismiss={() => setLoading(false)}
              message={'Cargando'}
              spinner={'bubbles'}
              showBackdrop={true}
              duration={5000}
            />
            : <>
              {recursos.length < 1
                ? null
                :
                <IonCol size="4" className="d-flex justify-content-end">
                  <IonButtons className="btn-minusculas bigger-hover">
                    <IonButton className="addObjetivo">
                      <IonCol onClick={()=>setActualPage("biblNuevoRecurso")}>
                        <IonIcon className="grande" src={addCircle}></IonIcon>
                        <p className="body2" >Crear nuevo<br/>recurso</p>
                      </IonCol>
                    </IonButton>
                  </IonButtons>
                </IonCol>
              }
            </>}
        </IonRow>

        <IonRow>

          {recursos.length < 1
            ? null
            :
            <div className="lineal-flex ml-auto ion-justify-content-end">
              <p className="body2 text-blau mx-2" style={{cursor:"default"}} onClick={
                (e: any) => {
                  e.persist();
                  setShowPopover({ showPopover: true, event: e })
                }}>{order}</p> <IonIcon src={arrowBot} className={`${isReversed ? "reversed" : ""} text-blau medio`} onClick={() => changeOrder()} />
              <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
              >
                <a className='popup-select'><p className="body1 text-gris">Ordenar por</p></a>
                <a className='popup-select'><p className="body1" onClick={() => setOrder("Tipo")}>Tipo</p></a>
                <a className='popup-select'><p className="body1" onClick={() => setOrder("Alfabéticamente")}>Alfabéticamente</p></a>
              </IonPopover>

            </div>}
        </IonRow>

        <IonGrid>
          {loading
            ? null
            : <>
              {recursos.length < 1
                ? <IonRow className="my-5">
                  <IonCol size-md="12" size-lg="8" offset-md="2" className="text-center">
                    <p className="body1">Todavía no tienes recursos en Mi biblioteca.</p>
                    <p><br/>Usa los recursos de Mi biblioteca para compartirlos de manera sencilla con tus usuarios de ComuniteCAA.
                    <br/>Para añadir recursos a Mi biblioteca, usa la opción "Crear nuevo recurso" o entra dentro de uno de tus usuarios de ComuniteCAA, ve a Recursos y selecciona "Guardar recurso en Mi biblioteca" del menú de recurso. 
                    </p>
                    <img src={noRecBiblio} className="my-4"></img> <br />
                    <IonButton className="saveBtn mt-5" onClick={()=> setActualPage("biblNuevoRecurso")}
                    ><p>Crear nuevo recurso</p></IonButton>
                    {/* <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateRecEmpty.event}
                        isOpen={popoverStateRecEmpty.showPopover}
                        onDidDismiss={() => setShowPopoverRecEmpty({ showPopover: false, event: undefined })}
                      >
                        <a className='popup-select'><p className="body1" onClick={() => setActualPage('biblRecursoComunitecaa')}>Recursos de ComuniteCAA</p></a>
                        <a className='popup-select' onClick={() => { setActualPage("biblNuevoRecurso"); setShowPopoverRecEmpty({ showPopover: false, event: undefined }) }}><p className="body1">Crear nuevo recurso</p></a>
                      </IonPopover> */}
                  </IonCol>

                </IonRow>
                : <>{showContent()}</>}
            </>}
        </IonGrid>
        {copyModal ? <CopyResource setTextAlertMvl={setTextAlertMvl} setShowAlertMvl={setShowAlertMvl} resourceId={resourceId} setCopyModal={setCopyModal} /> : null}
      </IonGrid>
    </>
  );
};
export default BiblRecurso;





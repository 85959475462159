import { IonButton, IonIcon, IonPopover, useIonAlert } from '@ionic/react';

// ICONES
import punts from '../img/iconos/punts.svg';
import { useState } from 'react';
import { getData } from '../api/apiRecursos';
import Cookies from 'js-cookie';
import { GlobalFunctions } from '../hooks/GlobalFunctions';
import { GlobalVariables } from '../hooks/GlobalVariables';


interface Props {
  tipo: string;
  id: number;
  setActualPage?: any;
  setSelectedId?: any;
  isBiblio?: boolean;
  setLastAction: any;
  setTextAlert: any,
  setShowAlert: any,
}

const ModificarElemento: React.FC<Props> = ({ setTextAlert, setShowAlert, tipo, id, setActualPage, setSelectedId, setLastAction, isBiblio = false }) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const {deleteActivity, deleteObjetive, deleteResource, copyTemplate} = GlobalFunctions();
  const {urlApi} = GlobalVariables();

  const axios = require('axios');

  const api = axios.create({
    baseURL: urlApi
  });


  const [checkEliminar] = useIonAlert();

  const clickEliminar = () => {
    setShowPopover({ showPopover: false, event: undefined });

    if (isBiblio == true) {
      switch (tipo) {
        case 'actividad':

          checkEliminar({
            header: 'Eliminar actividad',
            message: `¿Seguro que quieres eliminar esta ${tipo} de mi biblioteca? `,
            buttons: [
              'CANCELAR', { text: 'ELIMINAR', handler: () => deleteElement(id) },
            ],
            onDidDismiss: () => console.log(""),
          })
          break;
        case 'objetivo':
        case 'recurso':
          checkEliminar({
            header: 'Eliminar '+tipo,
            message: `¿Seguro que quieres eliminar este ${tipo} de mi biblioteca? `,
            buttons: [
              'CANCELAR', { text: 'ELIMINAR', handler: () => deleteElement(id) },
            ],
            onDidDismiss: () => console.log(""),
          })
          break;

        default:
          break;
      }

    } else if (tipo == 'objetivo' || tipo == 'recurso') {
      checkEliminar({
        message: `¿Seguro que quieres eliminar este ${tipo}? `,
        buttons: [
          'CANCELAR', { text: 'ELIMINAR', handler: () => deleteElement(id) },
        ],
        onDidDismiss: () => console.log(""),
      })
    } else {
      checkEliminar({
        message: `¿Seguro que quieres eliminar esta ${tipo}? `,
        buttons: [
          'CANCELAR', { text: 'ELIMINAR', handler: () => deleteElement(id) },
        ],
        onDidDismiss: () => console.log(""),
      })
    }
  }

  const reload = () => {
    // setLastAction("Elemento eliminado " + Date.now());
  }

  const userData = {
    group_id: Cookies.get('group_id')
  }

  const deleteElement = (id: number) => {
    if (tipo === "objetivo") {
      deleteObjetive(id, userData, setActualPage, setShowAlert, setTextAlert, isBiblio);
      reload();

    } else if (tipo === "recurso") {
      deleteResource(id, userData, setActualPage, setShowAlert, setTextAlert, isBiblio);
      reload();
    } else {
      deleteActivity(id, userData, setActualPage, setShowAlert, setTextAlert, isBiblio);
      reload();
    }
  }

  const copiarTemplate = () => {
    copyTemplate(id, tipo, setTextAlert, setShowAlert);
  }

  const clickModificar = () => {
    setShowPopover({ showPopover: false, event: undefined });
    setSelectedId(id);

    if (!isBiblio) {
      switch (tipo) {
        case "actividad":
          setActualPage("activityModify");
          break;
        case "objetivo":
          setActualPage("objectiveModify");
          break;
        case "recurso":
          setActualPage("resourceModify");
          break;

        default:
          break;
      }
    } else {
      switch (tipo) {
        case "actividad":
          setActualPage("biblModifActividad");
          break;
        case "objetivo":
          setActualPage("biblModifObjetivo");
          break;
        case "recurso":
          setActualPage("biblModifRecurso");
          break;

        default:
          break;
      }
    }
  }

  return (
    <>
    <IonPopover
                  // cssClass='my-custom-class'
                  event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <a className='popup-select'><p className="body1" onClick={() => clickModificar()}>Editar</p></a>
        <a className='popup-select'><p className="body1" onClick={() => clickEliminar()}>Eliminar</p></a>
        {tipo != 'objetivo' && <>{!isBiblio && <a className='popup-select'><p className="body1" onClick={() => {copiarTemplate(); setShowPopover({ showPopover: false, event: undefined })}}>Guardar en Mi biblioteca</p></a>}</>}
      </IonPopover>

      <IonIcon src={punts} className="medio handCursor" style={{ 'justifySelf': 'center', 'minWidth': '24px' }} onClick={
        (e: any) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e })
        }}></IonIcon>
        
    </>
  )
}

export default ModificarElemento;


import { IonRow, IonCol, IonLoading, IonGrid, useIonAlert } from "@ionic/react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import addIcon from '../../img/iconos/add_icon.svg';
import ShowActivityDetail from "../PopUpModals/ShowActivityDetail";
import { Activities, Activity } from "../../interfaces/intActivity";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import noActivBiblio from '../../img/iconos/no_activ_biblio.svg';



interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  type?: string,
  setShowAlert: any,
  setTextAlert: any
}

const ActividadBiblio: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setActualPage, setLastAction, lastAction }) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const history = useHistory();
  const { selectedId, showDetail, setShowDetail, activity, goBack, storeActivityCopy } = GlobalFunctions();
  const [loadingCopy, setLoadingCopy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkCopiar] = useIonAlert();


  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    const userData = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    }

    getData.post<Activities>('/getAllActivities', userData)
      .then(resp => {
        //@ts-ignore
        setActivities(resp.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [])

  const clickCopiar = (activId: number) => {
    checkCopiar({
      message: `¿Seguro que quieres añadir esta actividad?`,
      buttons: [
        'CANCELAR', { text: 'AÑADIR', handler: () => copyActivity(activId) },
      ],
      onDidDismiss: () => console.log(""),
    })
  }
  const copyActivity = (activId: number) => {
    setLoadingCopy(true)
    const dataRelation = {
      token: Cookies.get('token'),
      activity_id: activId,
      group_id: Cookies.get('group_id'),
      is_template: true,
    };

    storeActivityCopy(dataRelation, setLoadingCopy, setShowAlert, setTextAlert);
  }

  return (
    <div className="actividades-defecto-comunitecaa">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <IonLoading
        isOpen={loadingCopy}
        onDidDismiss={() => setLoadingCopy(false)}
        message={'Copiando actividad'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <div className="desktop-stiky">
        <div id="bannerEdicion" className="d-flex justify-content-center edicion"><p className="okButton">ACTIVIDADES DE MI BIBLIOTECA</p></div>
      </div>
      <div className="container act-obj-copia " >
        <>
          {showDetail
          ? <ShowActivityDetail setShowDetail={setShowDetail} activity={activity!}/>
          : null}
          <IonRow className="rowTitulo">
            <IonCol size="12" className="">
              <div className="btn-salir handCursor" onClick={() => goBack('actividad', setActualPage)}>
                <p>Salir</p>
              </div>
            </IonCol>
            {/* <IonCol size="12" className="lineal-flex  align-items-center">
              <p>Elige actividades para añadir: </p>
            </IonCol> */}

          </IonRow>
          <IonGrid>
            

              {activities.length < 1 
                ? <IonRow><IonCol size-md="12" size-lg="6" offset-md="3" className="text-center my-5">
                    <p className="body1">Todavía no tienes actividades en Mi biblioteca.</p>
                    <img src={noActivBiblio} className="my-4"></img> <br />

                  </IonCol></IonRow>
                : <>
                <IonRow>
                  <IonCol size="12" className="lineal-flex  align-items-center">
                    <p>Elige actividades para añadir: </p>
                  </IonCol>
                  </IonRow>
                  <IonRow>
                  {activities.map((activity, index) =>
                    <IonCol size-xs="6" size-md="4" key={index} >
                      <div id={`activity-${activity.id}`} className={`actividad actividadCAA`}>
                        <a className="lineal-flex" onClick={e => selectedId(activity.id, 'activity')}>
                          <p className="body2 activity-clipped-text">{activity.name}</p>
                        </a>
                        <img src={addIcon} className="fabAdd handCursor" onClick={e => { clickCopiar(activity.id!) }} />
                      </div>
                    </IonCol>
                  )} </IonRow></> }
              
           
          </IonGrid>

        </>
      </div>
    </div>
  );
};
export default ActividadBiblio;







import link from '../../img/iconos/link.svg';
import imgColor from '../../img/iconos/img_color.svg';
import vidColor from '../../img/iconos/vid_color.svg';
import linkColor from '../../img/iconos/link_color.svg';
import pdfColor from '../../img/iconos/pdf_color.svg';
import audioColor from '../../img/iconos/audio_color.svg';

import { Resource, Recursos } from '../../interfaces/intRecurso';
import { useEffect, useState } from 'react';
import { getData, getRecursos } from '../../api/apiRecursos';
import Cookies from 'js-cookie';
import { Group } from '../../interfaces/intGroups';
import { User } from '../../interfaces/intUser';
import { IonCol, IonGrid, IonIcon, IonImg, IonLoading, IonRow } from '@ionic/react';
import { Link } from 'react-router-dom';
import TituloRecurso from '../TituloRecurso';
import { useValidateYouTubeUrl } from '../../hooks/useValidateYoutubeUrl';
import BigPhotoPopUp from '../PopUpModals/BigPhotoPopUp';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';
import { Fragment } from 'ionicons/dist/types/stencil-public-runtime';
import { GlobalVariables } from '../../hooks/GlobalVariables';



interface ContainerProps {
  setActualPage: any,
  selectedId: number,
  showBack?: boolean,
  setShowBack?: any
}

const RecursoDetalle: React.FC<ContainerProps> = ({ showBack,setShowBack, setActualPage, selectedId }) => {
  const {uploadUrl, showMedia} = GlobalFunctions();
  const { validateYouTubeUrl } = useValidateYouTubeUrl();
  const [recurso, setRecurso] = useState<Resource>();
  const [loading, setLoading] = useState(true);
  const [showPhoto, setShowPhoto] = useState(false);


  useEffect(() => {
    getRecursos.get<Recursos>('/resource/' + selectedId)
      .then(resp => {
        //@ts-ignore
        setRecurso(resp.data);
      })
      .then(()=>
        setLoading(false)
      )
      .catch(err => console.log(err))
  }, [selectedId])

  const {urlMedia} = GlobalVariables();

  const logoRecurso = (recurso: Resource ) => {
    switch (recurso?.type) {
      case "imagen":
        return (
          <>
            <IonRow>
              <IonCol size="1">
                <IonIcon className="grande" src={imgColor} />
              </IonCol>
              <IonCol size="11">
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                {recurso?.media_name != undefined 
                ?  <>{showMedia(recurso.id, recurso?.media_name, 'jpg', setShowPhoto)}</>
                : null}
              </IonCol>
              {showPhoto
                ? <BigPhotoPopUp photoName={recurso?.media_name!} setShowPhoto={setShowPhoto} /> : null}
            </IonRow>
          </>
        )
      case "video":
        return (
          <>
            <IonRow>
              <IonCol size="1">
                <IonIcon className="grande" src={vidColor} />
              </IonCol>
              <IonCol size="11">
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
              <div className="text-center"> 
                {validateYouTubeUrl(recurso?.value) == false
                  ? <video className=" mt-3" id="video" controls>
                      <source src={`${uploadUrl}${recurso?.media_name}`} type="video/mp4"/>
                      Your browser does not support HTML5 video.
                    </video>
                : <><iframe className="desktop"width="100%" height='400px' src={validateYouTubeUrl(recurso!.value)?.toString()} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <iframe className="mvl"width="100%" height='200px' src={validateYouTubeUrl(recurso!.value)?.toString()} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </>
                }
              </div>
              </IonCol>
            </IonRow>
          </>
        )
      case "link":
        return (
          <>
            <IonRow>
              <IonCol size="1">
                <IonIcon className="grande" src={linkColor} />
              </IonCol>
              <IonCol size="10">
                <a href={`${recurso?.value.startsWith('http://') || recurso?.value.startsWith('https://') ? recurso?.value : 'http://' + recurso?.value}`} target="_blank"><p className="body1 text-blau">{recurso?.value}</p></a>
              </IonCol>
              <IonCol size="1">
                {recurso?.value ?
                  <a href={`${recurso?.value.startsWith('http://') || recurso?.value.startsWith('https://') ? recurso?.value : 'http://' + recurso?.value}`} target="_blank">
                    <IonIcon className="grande" src={link} />
                  </a>
                  : null
                }
              </IonCol>
            </IonRow>
          </>
        )
      case "pdf":
        return (
          <>
            <IonRow>
              <IonCol size="1">
                <IonIcon className="grande" src={pdfColor} />
              </IonCol>
              <IonCol size="10">
              <a target="_blank" href={uploadUrl+recurso?.media_name}><p className="body1 text-blau">{recurso?.value}</p></a>
              </IonCol>
              <IonCol size="1">
                {console.log('recurso', recurso)}
                {recurso?.value ?
                  <Link href={uploadUrl+recurso?.media_name} target="_blank" to={recurso?.media_name!}>
                    <IonIcon className="grande" src={link} />
                  </Link>
                  : null
                }
              </IonCol>
            </IonRow>
            <IonRow>
            {recurso?.media_name ?
              <div className="col-12 d-flex justify-content-center align-items-center" style={{'position':'relative'}}>
                <embed src={`${urlMedia}${recurso?.media_name}`} width="100%" height="600" type="application/pdf" />
              </div> 
            : null}
            </IonRow>
          </>
        )
  
      case "audio":
        return (
          <>
            <IonRow>
              <IonCol size="1">
                <IonIcon className="grande" src={audioColor} />
              </IonCol>
              <IonCol size="10">
                <audio src={`${urlMedia}${recurso?.media_name}`} controls style={{'width':'100%'}}></audio>
              </IonCol>
            </IonRow>
          </>
        )
  
      default:
        break;
    }
  }





  return (
    <IonGrid className="container">
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={10000}
      />
      <IonRow>
      </IonRow>
      <IonRow>
        <TituloRecurso titulo="Recurso" setShowBack={setShowBack} setActualPage={setActualPage} showBack={showBack} isBiblio={false}/>
      </IonRow>

      <div className='cnt-detalle'>

      
      <IonRow className="">
        <IonCol size="12" >
          <h3>{recurso?.name}</h3>
        </IonCol>

      </IonRow>
      <IonRow>
        <IonCol className="mt-2">
          {recurso?.description
            ? <p dangerouslySetInnerHTML={{ __html: recurso!.description }}></p>
          : <p>{recurso?.description}</p>}
          
        </IonCol>
      </IonRow>

      {logoRecurso(recurso!)}
      </div>
    </IonGrid>
  );
};

export default RecursoDetalle;



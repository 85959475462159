import { IonButton, IonButtons, IonCol, IonIcon, IonRow } from "@ionic/react";
import { useState } from "react";

/* ICONOS */
import logoObjetivo from '../img/iconos/objetivos-logo-neg.svg';
import back from '../img/iconos/back-arrow.svg'
import creu from '../img/iconos/icona_creu.svg'



interface Props {
  titulo: string;
  setActualPage?: any;
  setShowBack?: any;
  showBack?: boolean;
  isBiblio?:boolean;
}

const TituloObjetivo: React.FC<Props> = ({titulo, setActualPage, setShowBack, showBack = false, isBiblio = true}) => {
  
  

  return(
    <>
      <IonCol size="8" className="lineal-flex align-items-center ion-align-self-center col-ajustada">
        {showBack 
        ? <img src={creu} className="handCursor" onClick={e => { setActualPage("objetivos"); setShowBack(false) }}></img> 
        : <> { isBiblio ? null : <img src={creu} className="handCursor" onClick={e => { setActualPage("home"); setShowBack(false) }}/>} </>
        }
        
        <div className="borde-objetivo w-100 align-items-center ion-align-self-center lineal-flex">
        <IonIcon src={logoObjetivo} className="grande m-2"/>
        <p className="ml-4 text-verd">{titulo}</p>
        </div>
      </IonCol>
    </>
    
  );
}

export default TituloObjetivo;
import { useState } from "react";
import $ from 'jquery';
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { getData } from "../api/apiRecursos";
import { Activities, Activity } from "../interfaces/intActivity";
import { Datum, Recursos, Resource } from "../interfaces/intRecurso";
import { Categories, Etapa, Obj, Objective } from "../interfaces/intObjective";
import { CrearObjetivo } from "../interfaces/intAuxiliar";
import { Media } from "../interfaces/intPosts";
import { ExportGroup, Group, Groups, UserGroup } from "../interfaces/intGroups";
import { group } from "console";
import { setUser as setUserSlice } from '../features/users/userSlice';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setMediaName as setSelectedGroupMediaName, setId as setSelectedGroupId, setName as setSelectedGroupName, setPosts,
   setUserRole, setResources, setActivities, setObjectives, setAcompañantes, setCategories, setEtapas, setExportedObjectives} from '../features/groups/groupSelectedSlice';
import {setSelectedFileImg, setSelectedFileVideo, setSelectedFileAudio, setSelectedFilePdf, setSelectedFileName} from '../features/resource/resourceSlice'
import {setGroups} from '../features/groups/groupsSlice'
import {setNoObjectiveActivities, setObjectiveActivities} from '../features/activities/activitiesSlice'
import {setActivities as setActivitiesBiblio, setPostsAll, setResources as setResourcesBiblio} from '../features/itemsBiblio/itemsBiblioSlice'
import { User } from "../interfaces/intUser";
import { GlobalVariables } from "./GlobalVariables";
import PostComponent from "../components/PostComponent";
export function GlobalFunctions() {
  //REDUX
  const dispatch = useAppDispatch();
  const idGroupSelected = useAppSelector(state=>state.reducer.groupSelected.id);
  const groupSelected = useAppSelector(state=> state.reducer.groupSelected);
  const test = useAppSelector(state=>state.reducer.groupSelected);

  // let test = "hola";
  let history = useHistory();
  const [showDetail, setShowDetail] = useState(false);
  const [activity, setActivity] = useState<Activities>();
  const [resource, setResource] = useState<Resource>();
  const [objective, setObjective] = useState<Objective>();



  const {urlMedia} = GlobalVariables();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  }
  // isNotifEnabled
  const getUserData = () => {
    const userData = {
      'token' : Cookies.get('token'),
    }
    getData.post<User>('/userDetail', userData)
      .then((res: any) => {
        dispatch(setUserSlice(res.data));
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const getNotif = async (setNotif: any) => {
    const data = {
      'token' : Cookies.get('token'),
    }
    getData.post<Notification>('/isNotifEnabled', data)
      .then(resp => {
        setNotif(resp.data);
      })
      .catch(err => console.log(err));
  }

  const updatePassword = (password:string, token:string) => {
    const data = {
      'token' : token,
      'password': password
    }; 
    getData.post("/changePassword", data)
      .then(() => {
      })
      .catch((error: any) => {
        console.log("error", error);
      });

  }

  const changeNotifBBDD = (group_id: number, status: number) => {
    const data = {
      'token' : Cookies.get('token'),
      'group_id' : group_id,
      'status': status
    }
    getData.post<Notification>('/changeNotification', data)
      .then(resp => {
        console.log(resp);
        getGroups(undefined, undefined);
      })
      .catch(err => console.log(err));

  }

  const findToken = (token:string, setIsActive: any) => {
    const data = {
      'token' : token,
    }; 
    getData.post("/findToken", data)
      .then((resp: any) => {
        if (resp.status == 201) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }

      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  const getNames = async () => {
    const data = {
      'token' : Cookies.get('token'),
      'group_id' : Cookies.get('group_id')
    }

      const resp = await getData.post("/getNames", data);
      // var group = resp.data;
      return resp.data;
  }

  const showThirdButton = (nameClass: string) => {
    if ($("." + nameClass).hasClass('show')) {
      $("." + nameClass).removeClass('show');
      $('.btn-third').removeClass('show');
    } else {
      $('.btn-third').removeClass('show');
      $("." + nameClass).addClass('show');
    }
  }
  const recuperarPassword = (email: string) => {
    getData.get("/retrievePassword?email=" + email)
      .then((resp: any) => {
      });
  }
  const getNumberObjectives = (groupId: string, setValue?: any) => {
    getData.get("/getNumberObjectives?group_id=" + groupId)
      .then((resp: any) => {
        if (resp.data < 1) {
          setValue(false);
        } else {
          setValue(true);
        }
      });
    //return true si hi ha un o més objectius
  }
  const getCategories = (setCategories?: any, setCompetencia?: any) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: idGroupSelected,
    }
    getData.post<Categories>('/categoryList', userData)
      .then(resp => {
        //@ts-ignore
        setCategories(resp.data.categories);
        setCompetencia(resp.data.categories[0].name);
      })
      .catch(err => console.log(err));
  }
  const goBack = (tipoElemento: string, setActualPage: any) => {
    const axios = require('axios');

    switch (tipoElemento) {
      case 'actividad':
        if (groupSelected.activities.length > 0) {
          setActualPage('actividades');
        } else {
          if (window.screen.width <= 425) {
            setActualPage('actividades');
          } else {
            setActualPage('home');
          }
        }

        break;
      case 'recurso':
        if (groupSelected.resources.length > 0) {
          setActualPage('recursos');
        } else {
          if (window.screen.width <= 425) {
            setActualPage('recursos');
          } else {
            setActualPage('home');
          }
        }
        
        break;
      case 'objetivo':        
        if (groupSelected.objectives.length > 0) {
          setActualPage('objetivos');
        } else {
          if (window.screen.width <= 425) {
            setActualPage('objetivos');
          } else {
            setActualPage('home');
          }
        }

        break;
      case 'home':

        history.push('home');
        setActualPage('home');
        break;
      default:
        break;
    }
  }

  const unlinkGroup = (id_group: number, name:string, setTextAlert: any, setShowAlert: any, unlinkGroupTag:any) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id_group,
    }
    getData.post("/unsetStatus", userData)
      .then(() => {
        // setLastAction("Has dejado de seguir "+Date.now());
        setTextAlert("Has dejado de seguir a "+name+".");
        setShowAlert(true);
        // unlinkGroupTag(id_group+"");
      })
      .then(()=> {
        getGroups(undefined, undefined);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  
  const showMedia = (mediaId: any, mediaName: any, mediaType: any, setShowPhoto?: any) => {
    if (mediaId) {
      if (mediaType == 'video' || mediaType == 'mp4') {
        return <video className=" mt-3" controls style={{ 'width': '100%' }} preload="metadata"><source src={urlMedia + mediaName+"#t=0.5"} /></video>
      } else {
        return <img src={urlMedia + mediaName} onClick={e => setShowPhoto(true)} className="img-fluid img-bordered handCursor" />
      }
    }
  }
  const selectedId = (selectedId: number, type: string) => {
    if (selectedId != undefined && type == "activity") {
      getData.get<Activities>('/activity/' + selectedId)
        .then(resp => {
          //@ts-ignore
          setActivity(resp.data);
        })
        .then(() => {
          setShowDetail(true);
        })
        .catch(err => console.log("error", err));
    } else if (selectedId != undefined && type == "resource") {
      getData.get<Resource>('/resource/' + selectedId)
        .then(resp => {
          //@ts-ignore
          setResource(resp.data);
          // setLoading(false);
        })
        .then(() => {
          setShowDetail(true);
        })
        .catch(err => console.log("error", err));
    } else if (selectedId != undefined && type == "objective") {
      getData.get<Objective>('/objective/' + selectedId)
        .then(resp => {
          //@ts-ignore
          setObjective(resp.data);
          // setLoading(false);
        })
        .then(() => {
          setShowDetail(true);
        })
        .catch(err => console.log("error", err));
    }
  }


  // Funcions d'objectius
  const publicarYCrearObjetivo = (obj: CrearObjetivo, setLastAction: any, setActualPage: any, setTextAlert: any, setShowAlert: any, funcion: string, objSelected = 0) => {
    if (funcion == "modificar") {
      getData.post("/objective/updateAndPost/" + objSelected, obj)
        .then(() => {
          getObjectives();
          // setLastAction('objetivo modificado y posteado' + Date.now());
        })
        .then(() => {
          getPostsGroupSelected();
          if (window.screen.width <= 425) {
            setActualPage('home');
            history.push({
              pathname: '/home',
              state: {
                text: 'Objetivo modificado y publicado en el timeline correctamente',
                showAlert: true,
              },
            });
          } else {
            setTextAlert('Objetivo modificado y publicado en el timeline correctamente');
            setShowAlert(true);
            setActualPage('home');
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    } else {
      getData.post("/objective/createAndPost", obj)
        .then(() => {
          getObjectives();
          // alert("objetivo creado correctamente");
          // setLastAction('nuevo Obj y publicacion' + Date.now());
          // refresh();
        })
        .then(() => {
          getPostsGroupSelected();
          if (window.screen.width <= 425) {
            setActualPage('home');
            history.push({
              pathname: '/home',
              state: {
                text: 'Objetivo creado y publicado en el timeline correctamente',
                showAlert: true,
              },
            });
          } else {
            setTextAlert('Objetivo creado y publicado en el timeline correctamente');
            setShowAlert(true);
            setActualPage('home');
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  }
  const crearObjetivo = (obj: CrearObjetivo, setLastAction: any, setActualPage: any, setTextAlert: any, setShowAlert: any, funcion: string, objSelected = 0) => {
    if (funcion == 'modificar') {
      getData.post("/objective/edit/" + objSelected, obj)
        .then(() => {
          getObjectives();
          // setLastAction('objetivo modificado ' + Date.now());
          // refresh();
        })
        .then(() => {
          setTextAlert('Objetivo modificado correctamente');
          setShowAlert(true);
          setActualPage('objetivos');
        })
        .catch((error: any) => {
          console.log("error", error);
        });

    } else {
      getData.post("/objective", obj)
        .then(() => {
          getObjectives();
          // setLastAction('nuevo Obj ' + Date.now());
          // refresh();
        })
        .then(() => {
          setTextAlert('Objetivo creado correctamente');
          setShowAlert(true);
          setActualPage('objetivos');
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  }
  const createObjectivesTemplate = (json: any) => {
    getData.post("/objective/createTemplates", json)
      .then((resp: any) => {
        getObjectives();
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }
  const deleteObjetive = (id:number, userData:any, setActualPage:any, setShowAlert:any, setTextAlert:any, isBiblio?:boolean) => {
    getData.post("/objective/delete/" + id, userData)
    .then((resp: any) => {
      if (resp.data < 1) {
        if (!isBiblio) {
          if (window.screen.width > 500) {
            setActualPage("home");
          }
        }
      }

      setTextAlert('Objetivo eliminado correctamente');
      setShowAlert(true);
    })
    .then ((resp: any) => {
      getObjectives();
    })
    .catch((error: any) => {
      console.log("error", error);
    });
  }
  const setDefaultObjectives = (dataObjetivo: any, setLoadingObjetDefecto: any, setShowPopoverObj: any,  setShowAlert: any, setTextAlert: any, setActualPage: any) => {
    setLoadingObjetDefecto(true)

    getData.post("/objectives/storeDefault", dataObjetivo)
      .then((res: any) => {
        setShowPopoverObj({ showPopover: false, event: undefined });
        setShowAlert(true);
      })
      .then(() =>{
        getObjectives();
        setLoadingObjetDefecto(false);
        setActualPage("objetivos");
        setTextAlert('Objetivos de ComuniteCAA creados correctamente.');
      })
      .then(() =>{
        getCategories();
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }
  const exportSet = (setShowAlert:any, setTextAlert:any, ) => {
    const date = new Date();
    var today = date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate();
    const dataRelation = {
      created_at: today,
      token: Cookies.get('token'),
      group_id: idGroupSelected
    }; 

    getData.post("/storeSet", dataRelation)
      .then((res: any) => {
        setShowAlert(true);
        setTextAlert("El set de objetivos se ha guardado a Mi biblioteca correctamente")
      })
      .then (() => {
        getExportObjectives();
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  const getBiblioActivities = () => {

  }

  const getBiblioResource = () => {

  }


  // Funcions d'activitats
  const publicarActividad = (funcion: string, formData: FormData, setLastAction: any, setActualPage: any, setTextAlert: any, setShowAlert: any, activSelected = 0, loading?: any) => {
    if (funcion == 'modificar') {
      getData.post("/activity/updateAndPost/" + activSelected, formData, config)
        .then(() => {
          getActivities();
        })
        .then(() => {
          getPostsGroupSelected();
          if (window.screen.width <= 425) {
            setActualPage('home');
            history.push({
              pathname: '/home',
              state: {
                text: 'Actividad modificada y publicada en el timeline correctamente.',
                showAlert: true,
              },
            });
          } else {
            setTextAlert('Actividad modificada y publicada en el timeline correctamente.');
            setShowAlert(true);
            setActualPage('home');
          }
        })
        .then(() => {
          if (loading) {
            loading(false);
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });


    } else {
      getData.post("/activity/createAndPost", formData, config,)
        .then(() => {
          getActivities();
          getPostsGroupSelected();
          if (window.screen.width <= 425) {
            setActualPage('home');
            history.push({
              pathname: '/home',
              state: {
                text: 'Actividad creada y publicada en el timeline correctamente',
                showAlert: true,
              },
            });
          } else {
            // setLastAction('Actividad creada y publicada en el timeline correctamente ' + Date.now());
            setTextAlert('Actividad creada y publicada en el timeline correctamente');
            setShowAlert(true);
            setActualPage('home');
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  }
  const crearActividad = (funcion: string, setLoadingActividad: any, formData: FormData, setLastAction: any, setActualPage: any, setTextAlert: any, setShowAlert: any, activSelected = 0) => {
    if (funcion == 'modificar') {
      console.log("formData:", formData.get('objectives_id'));
      getData.post("/activity/edit/" + activSelected, formData, config)
        .then(() => {
          getActivities();
          setActualPage("actividades");
        })
        .then((res: any) => {
          setLoadingActividad(false);
        })
        .then((res: any) => {
          setShowAlert(true);
          setTextAlert('Actividad modificada correctamente.');
        })
        .catch((error: any) => {
          console.log("error", error);
        });


    } else if (funcion == 'nueva biblio') {
      getData.post("/activity", formData, config)
        .then((res: any) => {
          // alert("actividad creada correctamente");
          setActualPage("biblActividad");
          setLoadingActividad(false);
        })
        .then(() => {
          setShowAlert(true);
          setTextAlert('Actividad creada correctamente');
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    } else if (funcion == 'modificar biblio') {
      getData.post("/activity/edit/" + activSelected, formData, config)
        .then(() => {
          setActualPage("biblActividad");
          setLoadingActividad(false);
        })
        .then(() => {
          setShowAlert(true);
          setTextAlert('Actividad modificada correctamente')
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    } else {
      getData.post("/activity", formData, config)
        .then(() => {
          getActivities();
          setTextAlert('Actividad creada correctamente.');
          setShowAlert(true);

          setLoadingActividad(false);
          // setLastAction('Actividad creada ' + Date.now());
        })
        .then(() => {
          setActualPage("actividades");
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  }
  const createActivitiesTemplate = (json: any) => {

    getData.post("/activity/createTemplates", json)
      .then((resp: any) => {
      })
      .catch((error: any) => {
        console.log("error", error);
      });

  }
  const storeActivityCopy = (dataRelation:any, setLoadingCopy?:any, setShowAlert?:any, setTextAlert?:any) =>{
    getData.post("/activity/storeCopy", dataRelation)
      .then((res: any) => {
        getActivities();
        getObjectives();

        if (setLoadingCopy){
          setLoadingCopy(false);
        }
      })
      .then((res: any) => {
        setShowAlert(true);
        setTextAlert('Actividad añadida correctamente');
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const deleteActivity = (id:number, userData:any, setActualPage:any, setShowAlert:any, setTextAlert:any, isBiblio?:boolean) => {
    getData.post("/activity/delete/" + id, userData)
        .then((resp: any) => {
          getActivities();
          if (resp.data < 1) {
            if (!isBiblio) {
              if (window.screen.width > 500) {
                setActualPage("home");
              }
            }
          }
          setTextAlert('Actividad eliminada correctamente');
          setShowAlert(true);
          // reload();
        })
        .then (() => {
          getAllActivities(isBiblio!, null);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
  }

  // Funcions de recursos
  const publicarRecurso = (funcion: string, formData: FormData, setLastAction: any, setActualPage: any, setTextAlert: any, setShowAlert: any, selectedId?: number, setLoadingRecurso?: any) => {
    if (funcion == 'crear') {
      getData.post("/resource/createAndPost", formData, config)
        .then(() => {
          getResources();
          // setLastAction('Recurso creado ' + Date.now());
        })
        .then(()=>{
          getPostsGroupSelected();
          if (window.screen.width <= 425) {
            setActualPage('home');
            history.push({
              pathname: '/home',
              state: {
                text: 'Recurso creado y publicado en el timeline correctamente',
                showAlert: true,
              },
            });
          } else {
            setTextAlert('Recurso creado y publicado en el timeline correctamente');
            setShowAlert(true);
            setActualPage('home');
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    } else if (funcion == 'modificar') {
      getData.post("/resource/updateAndPost/" + selectedId, formData, config)
        .then(() => {
          // setLastAction('Recurso modificado y publicado ' + Date.now());
          getResources();
        })
        .then(() => {
          getPostsGroupSelected();
          if (window.screen.width <= 425) {
            setActualPage('home');
            history.push({
              pathname: '/home',
              state: {
                text: 'Recurso modificado y publicado en el timeline correctamente.',
                showAlert: true,
              },
            });
          } else {
            setTextAlert('Recurso modificado y publicado en el timeline correctamente.');
            setShowAlert(true);
            setActualPage('home');
          }
        })
        .then(() => {
          setLoadingRecurso(false);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
    unsetAllMedia();
  }
  const crearRecurso = (funcion: string, formData: FormData, setActualPage: any, setShowAlert: any, setTextAlert: any, recSelected = 0, setLastAction: any = "", setLoading: any = "", selectedId?: number )=> {
    if (funcion == "modificarBiblio") {
      getData.post("/resource/edit/" + recSelected, formData, config)
        .then((res: any) => {
          setActualPage("biblRecurso");
        })
        .then(() => {
          setShowAlert(true);
          setTextAlert('Recurso modificado correctamente')
        })
        .catch((error: any) => {
          console.log("error", error);
        });

    } else if (funcion == "crearRecurso") {
      getData.post("/resource", formData, config)
        .then((res: any) => {
          getResources();
          setTextAlert('Recurso creado correctamente.');
          setShowAlert(true);

          setActualPage("recursos");
        })
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("error", error);
        });

    } else if (funcion == "modificar") {
      console.log('form', formData);
      getData.post("/resource/edit/" + selectedId, formData, config)
        .then(() => {
          getResources();
          setTextAlert('Recurso modificado correctamente.');
          setShowAlert(true);

          // setLastAction('Recurso modificado ' + Date.now());
          setActualPage("recursos");
        })
        .then(() => {
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }else {
      getData.post("/resource", formData, config)
        .then((res: any) => {
          getResources();
          setActualPage("biblRecurso");
        })
        .then(() => {
          setTextAlert('Recurso creado correctamente.');
          setShowAlert(true);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
    unsetAllMedia();
  }
  const createResourcesTemplate = (json: any) => {
    getData.post("/resource/createTemplates", json)
      .then((resp: any) => {
      })
      .catch((error: any) => {
        console.log("error", error);
      });

  }
  const deleteResource = (id:number, userData:any, setActualPage:any, setShowAlert:any, setTextAlert:any, isBiblio?:boolean) => {
    getData.post("/resource/delete/" + id, userData)
        .then((resp: any) => {
          if (resp.data < 1) {
            if (!isBiblio) {
              // getResources();
              if (window.screen.width > 500) {
                setActualPage("home");
              }
            }
          }
          setTextAlert('Recurso eliminado correctamente');
          setShowAlert(true);
        })
        .then (() => {
          getResources();
          getAllResources();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
  }
  const copyResource = (dataRelation:any, setLoadingCopy: any, setShowAlert: any, setTextAlert:any, setLastAction: any ) => {
    getData.post("/resource/storeCopy", dataRelation)
      .then((res: any) => {
        setLoadingCopy(false);
      })
      .then((res: any) => {
        setShowAlert(true);
        setTextAlert('Recurso añadido correctamente');
      })
      .then(() => {
        // setLastAction('RecursoCopiada ' + Date.now())
        getResources();
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  // Funcions d'acompanyants
  const addGroupToUser = (userData: any, setShowRedInfo:any, setRedText:any,  setAdd:any, setTextAlert:any, setShowAlert:any, setLastAction:any) => {
    getData.post('/addGroupToUser', userData)
      .then(resp => {
        if(resp.status == 255) {
          setRedText('* Este acompañante ya forma parte del grupo');
          setShowRedInfo(true);
        } else if(resp.status == 222){
          setRedText('Se ha enviado el correo electrónico correctamente');
          setShowRedInfo(true);
        }else {
          setAdd(false);
          setTextAlert('Acompañante añadido correctamente')
          setShowAlert(true)
        }

        // if (resp.status != 200){
        //   // alert(`Esta relación ya existe`);
        //   setShowRedInfo(true);
        // } else{
        //   setAdd(false);
        //   setTextAlert('Acompañante añadido correctamente')
        //   setShowAlert(true)
        //   // alert(`Usuario con mail ${email} añadido correctamente`);
        // }
        // // setLastAction("usuarioAñadido "+Date.now());
        
      })
      .then(resp => {
        getCompanions();  
        getPostsGroupSelected();      
      })
      .catch(err => console.log(err));
  }
      

  // Funcions per tractar fitxers
  const removePost = (id: number, setLastAction: any, setShowPopoverNuevo:any, setLoading?:any) => {
    getData.delete("/post/delete/" + id + '/' + Cookies.get('token'))
      .then(() => {
        setLastAction("postDeleted " + Date.now())
      })
      .then(() => {
        getPostsGroupSelected();
        getPostsAllGroups();
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    setShowPopoverNuevo({ showPopover: false, event: undefined });
  }
  const getPostsGroupSelected = (id: number = idGroupSelected, setLoading?:any) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }
 
      getData.post('/post/show', userData)
      .then(resp => {
        
        dispatch(setPosts(resp.data));
      })
      .then (() => {
        // if (setLoading) {
        //   setLoading(false);
        // }
      })
      .catch(err => console.log(err));
  }

  const getPostsAllGroups = () => {
    const userData = {
      token: Cookies.get('token'),
    }
    getData.post('/getPostsMyGroups', userData)
      .then(resp => {
        dispatch(setPostsAll(resp.data))
      })
      .catch(err => console.log(err));
  }


  const sendPost = (type: string, body: any, setLastAction?:any, setLoading?:any,) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data;"
      }
    }
    if (type == "post") {
      getData.post('/post/store', body, config)
        .then((res: any) => {
          getPostsGroupSelected();
          getPostsAllGroups();
        })
        .then((res: any) => {
          setLoading(false);
        })

        .catch((error: any) => {
          console.log("error", error);
        });
    } else {
      getData.post('/post/store', body)
        .then((res: any) => {
          getPostsGroupSelected();
          getPostsAllGroups();
        })
        .then((res: any) => {
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
    // setLastAction('nuevo post'+Date.now());
  }



  // Funcions per tractar fitxers
  const saveFile = (file: any, setLoadingFoto: any, setFile: any, setFilename: any) => {
    const formData = new FormData;
    setLoadingFoto(true);
    setFile(file);
    if (file != undefined) {
      formData.append("media", file);
    }

    getData.post<Media>('/media', formData, config)
      .then((res: any) => {
        setFilename(res.data.name)
        setLoadingFoto(false);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }
  const saveFileVideo = (file: any, setLoadingVideo: any, setFile: any, setFilename: any, setMediaType?: any) => {
    const formData = new FormData;
    setLoadingVideo(true);
    setFile(file);
    if (file != undefined) {
      formData.append("media", file);
    }

    getData.post<Media>('/mediaVideo', formData, config)
      .then((res: any) => {
        setFilename(res.data.name);
        setLoadingVideo(false);
        setMediaType(res.data.type);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }



  const isEmail = (email: string) => {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  const getGroupDataById = (id: number, destination?:string) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }

    getData.get<Group>('/getGroups/' + id)
    .then(resp => {
      dispatch(setSelectedGroupName(resp.data.name));
      dispatch(setSelectedGroupMediaName(resp.data.media_name == null ? "" : resp.data.media_name));
      dispatch(setSelectedGroupId(id));

    })
    .catch(err => console.log(err));


    getData.post('/getRole', userData)
    .then(resp => {
      // setRole(resp.data.role);
      dispatch(setUserRole(resp.data));
    })
    .then(() => {
      if (destination) {
          // window.location.href = destination;
          history.push({
            pathname: `/${destination}`
          });
      }
    })
    .catch(err => console.log(err));

    getExportObjectives(undefined);
  }

  const getExportObjectives = (setLoading? :any ) => {
    const userData = {
      token: Cookies.get('token'),
    }
    getData.post<ExportGroup[]>('/getExports', userData)
    .then(resp => {
      dispatch(setExportedObjectives(resp.data));
    })
    .then (() => {
      if (setLoading) setLoading(false);
    })
    .catch(err => console.log(err));
  }



  const getObjectives = (id: number = idGroupSelected) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }

    getData.post<Objective[]>('/getObjectives', userData)
      .then(resp => {
        dispatch(setObjectives(resp.data));
      })
      .catch(err => console.log(err));

      getData.post<Etapa[]>('/objectiveEtapa', userData)
      .then(resp => {
        dispatch(setEtapas(resp.data));
      })
      .catch(err => console.log(err));

    getData.post<Categories>('/categoryList', userData)
    .then(resp => {
      //@ts-ignore
      dispatch(setCategories(resp.data.categories));
    })
    .catch(err => console.log(err));

  }

  const getResources = (id: number = idGroupSelected) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }

    getData.post<Resource[]>('/getResources', userData)
    .then(resp => {
      dispatch(setResources(resp.data));
    })
    .catch(err => console.log(err));
    
  }

  const getActivities = (id: number = idGroupSelected) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }
    
    getData.post<Activity[]>('/getActivities', userData)
      .then(resp => {
        dispatch(setActivities(resp.data));
      })
      .then (() => {
        getObjectivesActivities();
      })
      .catch(err => console.log(err));
      
  }

  const getAllActivities = (biblioteca: boolean = true, setLoading?: any) => {
    const userData = {
      token: Cookies.get('token'),
      biblioteca: biblioteca
    }

    getData.post<Activity[]>('/getAllActivities', userData)
      .then(resp => {
        dispatch(setActivitiesBiblio(resp.data));
      })
      .then(() => {
        if(setLoading){setLoading(false);}
      })
      .catch(err => console.log(err));
  }

  const getAllResources = (setLoading?: any) => {
    const userData = {
      token: Cookies.get('token'),
    }
    
    getData.post<Resource[]>('/getResources', userData)
      .then(resp => {
        //@ts-ignore
        dispatch(setResourcesBiblio(resp.data));
      })
      .then(() => {
        if(setLoading){setLoading(false);}
      })
      .catch(err => console.log(err))
  }

  const getCompanions = (id: number = idGroupSelected) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }
    
    getData.post<UserGroup[]>('/relByGroup', userData)
      .then(resp => {
        dispatch(setAcompañantes(resp.data));
      })
      .catch(err => console.log(err));
  }



  const getGroupData = (id: number = idGroupSelected) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: id
    }

     getData.post('/getAllData', userData)
      .then(resp => {
        dispatch(setResources(resp.data.resources));
        dispatch(setObjectives(resp.data.objectives));
        dispatch(setActivities(resp.data.activities));
        dispatch(setAcompañantes(resp.data.companions));
      })
      .catch(err => console.log(err));

      getData.post<Categories>('/categoryList', userData)
      .then(resp => {
        //@ts-ignore
        dispatch(setCategories(resp.data.categories));
      })
      .then (()=>{
        getPostsGroupSelected(id);
      })
      .catch(err => console.log(err));

  }

  const getGroups = (setShowPage: any, setLoading: any) => {
    getData.get<Groups>('/getGroups?token=' + Cookies.get('token'))
      .then(resp => {
        dispatch(setGroups(resp.data!));
      })
      .then(() => {
        setShowPage(true);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }

  const copyTemplate = (id: number, type:string, setTextAlert: any, setShowAlert: any) => {
    const postData = {
      'element_id': id,
      'token': Cookies.get('token'),
    }

    if (type=="actividad") {
      getData.post<any>('/activity/copyTemplate', postData)
      .then((res: any) => {
        setTextAlert('Actividad guardada en Mi biblioteca');
        setShowAlert(true);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    } else {
      getData.post<any>('/resource/copyTemplate', postData)
      .then((res: any) => {
        setTextAlert('Recurso guardado en Mi biblioteca');
        setShowAlert(true);
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    }

    
  }

  const emptyObjectives = (setTextAlert:any, setShowAlert:any, setActualPage:any) => {
    const postData = {
      'group_id': idGroupSelected,
      'token': Cookies.get('token'),
    }

    getData.post<any>('/objective/removeAll', postData)
      .then((res: any) => {
        getObjectives();
      })
      .then(() => {
        setTextAlert('Objetivos eliminados correctamente');
        setShowAlert(true);
        setActualPage('home');
        if (window.screen.width <= 425) {
          setActualPage('objetivos');
        } else {
          setActualPage('home');
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
    
  }

  const createGroupObjectiveTemplate = () => {

    
  }

  const proves = () => {
    return 0;
  }

  const getObjectiveEtapa = (setLoading: any) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: idGroupSelected
    }

    getData.post<Etapa>('/objectiveEtapa', userData)
      .then(resp => {
        //@ts-ignore
        dispatch(setEtapas(resp.data));
      })
      .then(() => {
        setLoading(false);
      })
      .catch(err => console.log(err));
  }

  const getObjectivesActivities = (setLoading?: any) => {
    const userData = {
      token: Cookies.get('token'),
      group_id: idGroupSelected
    }
  
      getData.post<Activities[]>('/getActivitiesObject', userData)
        .then(resp => {
          dispatch(setObjectiveActivities(resp.data))
  
        })
        .then(()=>{
          if (setLoading) setLoading(false);
        })
        .catch(err => console.log(err));
  
        getData.post<Activities>('/getActivitiesNoObject', userData)
        .then(resp => {
          dispatch(setNoObjectiveActivities(resp.data))
        })
        .then(()=>{
          if (setLoading) setLoading(false);
        })
        .catch(err => console.log(err));
  }



  const urlify = (text: string) => {
    const urlRegex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
    return text.replace(urlRegex, (url) => {
      return `<a target="_blank" href="${url}">${url}</a>`;
    })
  }

 

  const unsetAllMedia = () => {
    dispatch(setSelectedFileImg(undefined));
    dispatch(setSelectedFileVideo(undefined));
    dispatch(setSelectedFilePdf(undefined));
    dispatch(setSelectedFileAudio(undefined));
    dispatch(setSelectedFileName(undefined));
  }

  return {
    proves, getUserData,
    saveFile, saveFileVideo,
    showThirdButton, goBack, showMedia, selectedId,
    showDetail, setShowDetail, activity, resource, objective,
    publicarYCrearObjetivo, crearObjetivo,
    publicarActividad, crearActividad,
    publicarRecurso, crearRecurso,
    isEmail, updatePassword,
    createObjectivesTemplate, createResourcesTemplate, createActivitiesTemplate, storeActivityCopy, setDefaultObjectives,
    deleteActivity, deleteObjetive, deleteResource, copyResource,
    uploadUrl: urlMedia, getNumberObjectives,
    recuperarPassword, findToken,
    getNames, getNotif, changeNotifBBDD,
    getCategories,
    urlify,
    sendPost,
    addGroupToUser,
    unsetAllMedia,
    removePost,getGroups,
    copyTemplate,
    emptyObjectives, createGroupObjectiveTemplate, exportSet, 

    getGroupDataById, getPostsGroupSelected,
    getActivities, getResources, getObjectives, getCompanions, getGroupData, getObjectiveEtapa,
    getAllActivities, getAllResources, getObjectivesActivities, getExportObjectives, getPostsAllGroups,
    unlinkGroup,
  };
}



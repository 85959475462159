import { IonButton, IonCheckbox, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useState } from "react";
import logoComuniteCaa from '../../img/iconos/logoComuniteCAA.svg';
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import { getData } from "../../api/apiRecursos";

interface Props {
  setActualPage: any;
}

const RegisterComp: React.FC<Props> = ({ setActualPage }) => {
  const {isEmail} = GlobalFunctions();
  const [mail, setMail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false);
  const [ aceptCondiciones, setAceptCondiciones] = useState(false);
  const [registerDone, setRegisterDone] = useState(false);
  const [showRedText, setShowRedText] = useState(false);
  const [showRedTextPassword, setShowRedTextPassword] = useState(false);
  const [acceptSubmit, setAcceptSubmit] = useState(false);

  const axios = require('axios');


  const tryRegister = () => {

    const loginData = {
      name: name,
      email: mail,
      password: password,
    };



    getData.post("/register", loginData)
      .then((res: any) => {
        console.log("res.data", res.data);
        if (res.status == 201){
          setShowRedText(true);
        } else {
          setRegisterDone(true);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  const checkSubmit = (nombre?: string, email?: string, check?:boolean, pass: string = "") => {
    if (email){
      if (isEmail(email) && password != "" && isChecked == true && name != ""){
        setAcceptSubmit(true);
      } else {
        setAcceptSubmit(false);
      }
    } else if (pass != "") {
      if (isEmail(mail) && isChecked == true && name != ""){
        setAcceptSubmit(true);
      } else {
        setAcceptSubmit(false);
      }
    } else if (check != undefined) {
      if (isEmail(mail) && password != "" && check == true && name != ""){
        setChecked(true);
        setAcceptSubmit(true);
      } else {
        setChecked(false);
        setAcceptSubmit(false);
      }
    } else if (nombre != undefined) {
      if (nombre != "" && isEmail(mail) && password != "" && isChecked == true){
        setAcceptSubmit(true);
      } else {
        setAcceptSubmit(false);
      }
    }else {
      setAcceptSubmit(false);
    }
  }

  const checkPassword = (password: string) => {
    if (password != undefined && password.length < 8) {
      setShowRedTextPassword(true);
    } else {
      setShowRedTextPassword(false);
    }

  }


  return (
    <IonGrid className="px-4 container bg-gris-clar registerPage">
      <IonRow className="mt-4"><IonCol className="d-flex justify-content-center"><img style={{ 'width': '5%' }} src={logoComuniteCaa} /><p className="body2">COMUNITE<b>CAA</b></p></IonCol></IonRow>
      <IonRow className="mt-3">
        <IonCol size-md="0" size-xs="0" size-lg="1" className="p-5 desktop">
        </IonCol>
      
        <IonCol className="bg-white p-5 mb-5">
          {!registerDone
            ? <>
              <div className="mb-5 headerLogin">
                <h3 className="v.1">Registrarte</h3>
              </div>
              
              <form >
                  <IonRow>
                    <IonCol size="12" className="px-3 py-4">
                      <p>Nombre completo</p>
                      <IonItem>
                        <IonInput className={`my-3 ${name != undefined ? 'capitalize' : null}`} required={true} type="text" placeholder="Introduce tu nombre" onIonChange={e => {{setName(e.detail.value?.toString()!);checkSubmit(e.detail.value?.toString()!)}}}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12" className="px-3 py-4">
                      <p>Correo electrónico</p>
                      <IonItem>
                        <IonInput className="my-3" required={true} type="email" placeholder="Introduce tu dirección de correo electrónico" onIonChange={e => {setMail(e.detail.value?.toString()!);checkSubmit(undefined, e.detail.value?.toString()!)}}></IonInput>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12" className="px-3 py-4">
                      <p>Contraseña</p>
                      <IonItem className="my-3">
                      <IonInput id="passwordInput" type={showPassword ? "text" : "password"} required={true} placeholder="Introduce una contraseña" value={password} onIonChange={e => {checkPassword(e.detail.value?.toString()!);setPassword(e.detail.value?.toString()!); checkSubmit(undefined, undefined, undefined, e.detail.value?.toString())}}></IonInput>
                      <IonIcon icon={showPassword ? eyeOutline : eyeOffOutline} className="handCursor" onClick={e => setShowPassword(!showPassword)}></IonIcon>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="12" className="px-3 py-4">
                    <IonItem className="destacadoCheck">
                        <IonLabel><p className="body2 no-wrapped">Al registrarte, aceptas las condiciones del servicio de ComuniteCAA. </p></IonLabel>
                        <IonCheckbox slot="start" onIonChange={e => {setAceptCondiciones(!aceptCondiciones); checkSubmit(undefined, undefined, e.detail.checked)}} />
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <p className={`${showRedText ? "" : 'hidden'}`} style={{'color':'red'}}>* Ese correo electrónico ya está en uso. Prueba con otro.</p>
                  <p className={`${showRedTextPassword ? "" : 'hidden'}`} style={{'color':'red'}}>* Tu contraseña debe tener al menos 8 caracteres. Prueba con otra.</p>
                  <IonButton className="btn-objetivos selected mb-4" disabled={acceptSubmit ? false : true } onClick={(e) =>  tryRegister()} ><p>Registrarte</p></IonButton>

                  <p><a onClick={e => setActualPage("login")} className="text-naranja">Prefiero iniciar sesión</a></p>
                </form>
             
            </>
            : <>
                  <div className="mb-5 headerLogin ">
                    <h3>¡Te damos la bienvenida!</h3>
                  </div>
                  <p className="body1">La cuenta se ha creado de forma correcta. Hemos enviado un mensaje de verificación a tu cuenta de correo electrónico. </p>
                  <br/>
                  <IonRow className="m-5">
                    <IonCol className="d-flex justify-content-center">
                      <a href="/login">Aceptar</a>
                    </IonCol>
                  </IonRow>
                </>
              
            }
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}

export default RegisterComp;



import { IonGrid, IonRow, IonCol, IonContent, IonPage, useIonViewWillEnter, IonAvatar, IonImg } from '@ionic/react';

/* COMPONENTES */
import Header from '../components/Header';

/* CSS */
import '../theme/global.css';
import '../theme/responsive.css';

/* IMAGENES */
import Cookies from 'js-cookie';
import { useState, useEffect, Fragment } from 'react';
import { getData } from '../api/apiRecursos';
import { Group, UserGroup } from '../interfaces/intGroups';
import { Role, User } from '../interfaces/intUser';
import { useHistory } from 'react-router';
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';
import nouAcompanyant from '../img/iconos/nouAcompanyant.svg';
import AddNewUserPopUp from '../components/PopUpModals/AddNewUserPopUp';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { GlobalVariables } from '../hooks/GlobalVariables';




const RecursosMvl: React.FC = () => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);

  useIonViewWillEnter(() => {
    setAdd(false);
  });

  let history = useHistory();

  const [group, setGroup] = useState<Group>();
  const [lastAction, setLastAction] = useState("");
  const [company, setCompany] = useState<UserGroup[]>([]);
  const [add, setAdd] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");

  //@ts-ignore

  const userData = {
    'token': Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }
  const [role, setRole] = useState<Role>();

  useEffect(() => {
    getData.post('/getRole', userData)
      .then(resp => {
        setRole(resp.data);
        Cookies.set('role', resp.data.role)
      })
      .catch(err => console.log(err));
  }, [])

  useEffect(() => {
    if (!Cookies.get('token')) {
      history.push("/");
    }
    getData.get<Group>('/getGroups/' + Cookies.get('group_id'))
      .then(resp => {
        //@ts-ignore
        setGroup(resp.data);
      })
      .catch(err => console.log(err));
    // setUserAvatar(window.localStorage.getItem('userPhoto')!);

    getData.post<UserGroup>('/relByGroup', userData)
      .then(resp => {
        //@ts-ignore
        setCompany(resp.data);
      })
      .catch(err => console.log(err));
  }, [lastAction])


  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  const {urlMedia} = GlobalVariables();
  return (
    <>
      {Cookies.get('group_id')
        ?
        <>
          <IonPage className="mvl">
          {showAlert
                ? <>{showPopUp()} <AvisoPopUp text={textAlert}/></>
                : null}
            <Header name={group?.name!} avatar={group?.media_name!} avatarProp={userAvatar} setLastAction={setLastAction} lastAction={lastAction} />
            <IonContent fullscreen>
              <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
              <IonGrid style={{'minHeight':'80vh'}}>
              
                <IonRow className='p-3'>
                  <h2 className='m-auto'>Acompañantes</h2>
                </IonRow>
                <IonRow className="acompanyant p-2">
                  
                  {company.map((acompañante, index) =>
                    <Fragment key={index}>
                      <IonCol size-xl="3" size-lg="6" size-xs="4">
                        <div className="m-auto">
                          {acompañante?.media_name != undefined
                          ? <IonAvatar className="m-auto">
                              <img className="center border-gris" src={`${urlMedia}${acompañante?.media_name}`} />
                              </IonAvatar>
                          : <IonAvatar className="m-auto">
                              <img className="center" src={noPhotoAvatar} />
                            </IonAvatar>}
                          <p className='body2 text-center p-3'>{acompañante.name}</p>
                        </div>
                        
                      </IonCol>
                    </Fragment>
                  )}
                  {role?.role != 1
                    ? <IonCol size-xl="3" size-lg="6" size-xs="4">
                      {/* <IonAvatar className="m-auto"> */}
                        <img className="center img-avatar handCursor" src={nouAcompanyant} onClick={() => setAdd(true)} />
                        {/* </IonAvatar> */}
                      {/* <p className="body2 text-center">a<br />b</p> */}
                    </IonCol>
                    : null}

                  {add ? <AddNewUserPopUp setTextAlert={setTextAlert} setShowAlert={setShowAlert} setAdd={setAdd} setLastAction={setLastAction}></AddNewUserPopUp> : null}
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonPage>
        </>
        : history.push('/')}
    </>

  );
};

export default RecursosMvl;

import { IonRow, IonCol, IonLoading, IonGrid, useIonAlert, IonIcon } from "@ionic/react";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import addIcon from '../../img/iconos/add_icon.svg';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import { RecursosDefault } from "../../interfaces/intRecurso";

import logoImg from '../../img/iconos/img-small.svg';
import logoVid from '../../img/iconos/video-small.svg';
import logoLink from '../../img/iconos/enlace-small.svg';
import logoPdf from '../../img/iconos/pdf-small.svg';
import logoAud from '../../img/iconos/audio-small.svg';
import ShowResourceDetail from "../PopUpModals/ShowResourceDetail";

import { useAppSelector, useAppDispatch } from '../../app/hooks'



interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  type?: string,
  setShowAlert: any,
  setTextAlert: any
}

const RecursosComunitecaa: React.FC<ContainerProps> = ({ setShowAlert, setTextAlert, setActualPage, setLastAction, lastAction }) => {
  const [resources, setResources] = useState<RecursosDefault>();
  const history = useHistory();
  const recursos = useAppSelector(state=> state.reducer.groupSelected.resources);

  const { selectedId, showDetail, setShowDetail, resource } = GlobalFunctions();
  const [loadingCopy, setLoadingCopy] = useState(false);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    getData.get<RecursosDefault>('/getDefault')
      .then(resp => {
        //@ts-ignore
        setResources(resp.data);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [])

  const [checkCopiar] = useIonAlert();

  const clickCopiar = (resourceId: number) => {
    checkCopiar({
      message: `¿Seguro que quieres añadir este recurso?`,
      buttons: [
        'CANCELAR', { text: 'AÑADIR', handler: () => copyResource(resourceId) },
      ],
      onDidDismiss: () => console.log(""),
    })
  }
  const copyResource = (resourceId: number) => {
    setLoadingCopy(true)
    const dataRelation = {
      token: Cookies.get('token'),
      resource_id: resourceId,
      group_id: Cookies.get('group_id'),
      is_template: true,
    };

    getData.post("/resource/storeCopy", dataRelation)
      .then((res: any) => {
        setLoadingCopy(false);
      })
      .then((res: any) => {
        setShowAlert(true);
        setTextAlert('Recurso añadido correctamente');
      })
      .then(() => {
        setLastAction('RecursoCopiada ' + Date.now())
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }

  const insertLogo = (tipo: string) => {
    switch (tipo) {
      case "imagen":
        return <IonIcon className="grande" src={logoImg} />

      case "video":
        return <IonIcon className="grande" src={logoVid} />

      case "link":
        return <IonIcon className="grande" src={logoLink} />

      case "pdf":
        return <IonIcon className="grande" src={logoPdf} />

      case "audio":
        return <IonIcon className="grande" src={logoAud} />

      default:
        break;
    }
  }

  const goBack = () => {
    setActualPage('recursos');
    
  }

  return (
    <div className="actividades-defecto-comunitecaa">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <IonLoading
        isOpen={loadingCopy}
        onDidDismiss={() => setLoadingCopy(false)}
        message={'Copiando recurso'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <div className=" desktop-stiky">
        <div id="bannerEdicion" className="d-flex justify-content-center edicion"><p className="okButton">RECURSOS DE COMUNITECAA</p></div>
      </div>
      {showDetail
          ? <ShowResourceDetail setShowDetail={setShowDetail} resource={resource!}/>
          : null}
      <div className="container act-obj-copia " >
        <>
          <IonRow className="rowTitulo">
            <IonCol size="12" className="">
              <div className="btn-salir handCursor" onClick={() => goBack()}>
                <p>Salir</p>
              </div>
            </IonCol>
            <IonCol size="12" className="lineal-flex  align-items-center">
              <p>Elige recursos para añadir a tu usuario: </p>
            </IonCol>

          </IonRow>
          <IonGrid>
            <IonRow>
              <p className="ml-4 text-blau">Recursos propios de ComuniteCAA</p>
            </IonRow>
            <IonGrid>
              <>{resources?.recursos_comunitecaa.map((recurso, index) =>
                <Fragment key={index}>
                <IonRow >
                  <div className="cnt-recurso">
                    {insertLogo(recurso.type)}
                    <a className="m-2" onClick={e => selectedId(recurso.id, 'resource')}><p className="body2">{recurso.name}</p></a>
                    <div className={` inline-flex`}>
                    <img src={addIcon} style={{}} onClick={() => clickCopiar(recurso.id)} />
                    </div>
                  </div>
                </IonRow>
              </Fragment>
              )} </>
            </IonGrid>



            <IonRow>
            <p className="ml-4 text-blau">Otros recursos</p>
            </IonRow>
            <IonGrid>
              <>{resources?.recursos_defecto.map((recurso, index) =>
                <Fragment key={index}>
                <IonRow >
                  <div className="cnt-recurso">
                    {insertLogo(recurso.type)}
                    <a className="m-2" onClick={e => selectedId(recurso.id, 'resource')}><p className="body2">{recurso.name}</p></a>
                    <div className={` inline-flex`}>
                    <img src={addIcon} style={{}} onClick={() => clickCopiar(recurso.id)} />
                    </div>
                  </div>
                </IonRow>
              </Fragment>
              )} </>
            </IonGrid>
          </IonGrid>

        </>
      </div>
    </div>
  );
};
export default RecursosComunitecaa;

import { IonRow, IonCol, IonGrid, useIonAlert, IonLoading, IonIcon } from "@ionic/react";
import Cookies from "js-cookie";
import { Fragment, useEffect, useState } from "react";
import addIcon from '../../img/iconos/add_icon.svg';
import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import { Categories, Category, EtapaThis, ObjActiv, ObjActivActius, ObjectiveThis } from "../../interfaces/intObjective";
import logoObj from '../../img/iconos/objetivos-logo-min.svg';
import moreIcon from '../../img/iconos/plus.svg';
import $ from 'jquery';
import { GlobalFunctions } from "../../hooks/GlobalFunctions";


interface ContainerProps {
  setActualPage: any,
  setLastAction: any,
  lastAction: string,
  type: string,
  setTextAlert: any,
  setShowAlert: any,
  setShowDetail: any,
  setSelectedId: any,
}

const ActividadesObjetivosActivos: React.FC<ContainerProps> = ({ setShowDetail, setSelectedId, setTextAlert, setShowAlert, setLastAction }) => {
  const history = useHistory();

  const [competencia, setCompetencia] = useState<string>('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [objectsActiv, setObjectsActiv] = useState<ObjActivActius>();
  const {storeActivityCopy} = GlobalFunctions();

  const [loadingCopy, setLoadingCopy] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      history.push('');
    }

    const userData = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
    }

    getData.post<ObjActivActius>('/objectiveActivitiesActives', userData)
      .then(resp => {
        //@ts-ignore
        setObjectsActiv(resp.data);
      })
      .catch(err => console.log(err));

    getData.post<Categories>('/categoryList', userData)
      .then(resp => {
        //@ts-ignore
        setCategories(resp.data.categories);
        setCompetencia(resp.data.categories[0].name)
      })
      .catch(err => console.log(err));
  }, [])

  const [checkCopiar] = useIonAlert();

  const clickCopiar = (activId: number, objId: number) => {
    checkCopiar({
      message: `¿Seguro que quieres añadir esta actividad?`,
      buttons: [
        'CANCELAR', { text: 'AÑADIR', handler: () => copyActivity(activId, objId) }
      ],
      onDidDismiss: () => console.log(""),
    })
  }

  const copyActivity = (activId: number, objId: number) => {
    setLoadingCopy(true);
    const dataRelation = {
      token: Cookies.get('token'),
      activity_id: activId,
      group_id: Cookies.get('group_id'),
      objective_id: objId,
      is_template: false,
      copyObjective: 'no'
    };


    storeActivityCopy(dataRelation, setLoadingCopy, setShowAlert, setTextAlert);
  }

  const showListObjectives = (objective: ObjectiveThis, i: number, sinCategoria = false) => {
    return (
      <><IonGrid className={`panel-interior${i}`}>
        <IonRow>
          <IonGrid>
            <IonRow>
              {objective.activities.map((activity, index) =>
                <IonCol size-xs="6" size-md="4" key={index} >
                  <div id={`activity-${activity.id}`} onClick={e => setSelectedId(activity.id, 'activity')} className={`actividad boxActividad pt-3`}>
                    <a className="lineal-flex" onClick={e => setSelectedId(activity.id, 'activity')}>
                      <p className="body2 activity-clipped-text">{activity.name == "" ? activity.description : activity.name }</p>
                    </a>
                  </div>
                  <img src={addIcon} className="fabAdd handCursor" onClick={e => { clickCopiar(activity.id!, objective.obj_template_id!) }} />

                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        </IonRow>
      </IonGrid>
      </>
    );
  }
  const openEtapa = (panelNumber: number) => {
    var panelInterior = $('.panel-interior' + panelNumber);

    if (panelInterior.hasClass('show')) {
      panelInterior.removeClass('show');
    } else {
      panelInterior.addClass('show');
    }

  }


  return (
    <>
      <IonLoading
        isOpen={loadingCopy}
        onDidDismiss={() => setLoadingCopy(false)}
        message={'Copiando actividad'}
        spinner={'bubbles'}
        showBackdrop={true}
        duration={5000}
      />
      <IonRow className="row mt-2 " >
        <p className="body1 text-gris"><b>Competencia</b></p>
        <IonCol size="11" className="lineal-flex ">
          {categories.map((categoria, index) =>
            <Fragment key={index}>
              {objectsActiv?.objectives.find(obj => obj.category_name == categoria.name)
                ? <button key={index}
                  className={`btn-objetivos titulo-seccion 
                          ${competencia === categoria.name
                      ? "selected"
                      : null}`}
                  onClick={() => {setCompetencia(categoria.name)}}>
                  {categoria.name}
                </button>
                : null}
            </Fragment>
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <p className="body1 text-gris"><b>Etapa y objetivos</b></p>
      </IonRow>

      {/* {objectsActiv?.objectives.map((etapa, index) =>
        <Fragment key={index}> */}
      {objectsActiv?.objectives.filter(obj => obj.category_name == competencia).length! > 0
        ? <IonGrid>
          {objectsActiv?.objectives.filter(obj => obj.category_name == competencia).map((objective, index) => 
            <Fragment key={index}>
              <IonRow>
              <IonCol size="12" className="lineal">
                <div className="d-inline-flex">
                  <IonIcon src={logoObj} className="medio" />
                  <p className="mx-5">
                    <p className="body1">{objective.obj_name}</p>
                    <p className="overline text-verd">{objective.category_name}</p>
                  </p>
                </div>
                  
                  {showListObjectives(objective, index)}
                </IonCol>
              </IonRow>
              
            </Fragment>
          )}

        </IonGrid>
        : null
      }
      {/* </Fragment>
      )} */}
    </>
  );
};
export default ActividadesObjetivosActivos;







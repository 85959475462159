import { IonRow, IonGrid, IonCol, IonButton, IonItem, IonInput, IonTextarea, IonLabel, IonCheckbox, IonRadioGroup, IonRadio, IonImg, IonLoading, IonIcon } from "@ionic/react";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import { Activities, Activity } from "../../interfaces/intActivity";
import { Media } from "../../interfaces/intPosts";
import ChooseObjPopUp from "../PopUpModals/ChooseObjPopUp";
import { Editor } from '@tinymce/tinymce-react';
import TituloActividades from "../TituloActividad";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import edit_img from '../../img/iconos/edit_img.svg';
import remove_img from '../../img/iconos/remove_img.svg';


import $ from 'jquery';
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { GlobalVariables } from "../../hooks/GlobalVariables";
import DetalleTipoActividadComp from "../helpers/DetalleTipoActividadComp";


interface ContainerProps {
  setActualPage: any,
  modificar?: boolean,
  selectedId?: number,
  setShowAlertMvl: any,
  setTextAlertMvl: any,
}

const BiblNuevaActividad: React.FC<ContainerProps> = ({ setShowAlertMvl: setShowAlert, setTextAlertMvl: setTextAlert, setActualPage, modificar = false, selectedId }) => {
  const history = useHistory();
  const axios = require('axios');
  const [loadingFoto, setLoadingFoto] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const { setLastPhoto } = usePhotoGallery();
  const { crearActividad } = GlobalFunctions();
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showLoadingCreating, setShowLoadingCreating] = useState(false);
  const [deleteMedia, setDeleteMedia] = useState(false);

  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [searches, setSearches] = useState([]);
  const [ myFile, setFile ] = useState<File>();
  const [selectedType, setSelectedType] = useState("");
  const [filename, setFilename] = useState<string>();


  useEffect(() => {
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      history.push('');
    }

    if (modificar) {
      const userData = {
        token: Cookies.get('token'),
        group_id: Cookies.get('group_id'),
      }

      getData.get<Activities>('/activity/' + selectedId)
        .then(resp => {
          setDescripcion(resp.data.description);
          setNombre(resp.data.name);
          console.log('resp.data.media_name',resp.data.media_name);
          console.log('resp.data.media_name',resp.data.media_type);

          if (resp.data.media_name) {
            setFilename(resp.data.media_name);
            setSelectedType(resp.data.media_type);
          }
          resp.data.objectives.map((obj) => {
            //@ts-ignore
            setSearches(searches => [...searches, obj.id.toString()])
          })
        })
        .then(() => {
          setTimeout(() => {
            setShowModal(false);
            setShowLoading(false);
          }, 1000);
        })
        .catch(err => console.log("aaaa", err));
        console.log('filename', filename);
    } else {
      setShowLoading(false);
    }




  }, [])

  const createActividad = () => {
    const formData = createFormData();
    if (nombre == "" && descripcion == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
      
        setShowLoadingCreating(true);
        crearActividad('nueva biblio', setShowLoadingCreating, formData, undefined, setActualPage, setTextAlert, setShowAlert);
    }

  }
  const modificarActividad = () => {
    const formData = createFormData();
    if (nombre == "" && descripcion == "") {
      $('.aviso-naranja.aviso-nombre').removeClass('hidden');
    } else {
      $('.aviso-naranja.aviso-nombre').addClass('hidden');
      
        setShowLoadingCreating(true);
        crearActividad('modificar biblio', setShowLoadingCreating, formData, undefined, setActualPage, setTextAlert, setShowAlert, selectedId);
    }
  }

  const createFormData = () => {
    const formData = new FormData();
    if (deleteMedia) {
      formData.append('deleteMedia', 'si');
    } else {
      formData.append('deleteMedia', 'no');
    }
    if (myFile != undefined) {
      formData.append("media", myFile);
      formData.append('type', selectedType);
      formData.append('deleteMedia', 'no');
    }

    formData.append("token", Cookies.get('token')!);
    formData.append("template_id", "1");
    formData.append("name", nombre);
    formData.append("description", descripcion);
    formData.append("status", "0");
    formData.append("important", '0');
    //@ts-ignore
    formData.append("objectives_id", searches);

    return formData;
  }


  const removeImage = () => {
    setLastPhoto(undefined);
    setFile(undefined);
    setFilename(undefined);
    setDeleteMedia(true);
    $('#videoInput').val('');
    $('#imageInput').val('');
  }

  return (
    <>
      <IonGrid className="container">
        <IonRow className="rowTitulo">
          {modificar ? <TituloActividades titulo="Editar actividad"/>
          : <TituloActividades titulo="Nueva actividad"/> }
          
        </IonRow>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          showBackdrop={true}
          duration={5000}
        />
        <IonLoading
          isOpen={showLoadingCreating}
          onDidDismiss={() => setShowLoading(false)}
          message={'Guardando actividad'}
          spinner={'bubbles'}
          showBackdrop={true}
          duration={5000}
        />
        <IonLoading
          isOpen={loadingFoto}
          onDidDismiss={() => setLoadingFoto(false)}
          message={'Cargando foto'}
          spinner={'bubbles'}
          duration={5000}
        />
        <IonLoading
          isOpen={loadingVideo}
          onDidDismiss={() => setLoadingVideo(false)}
          message={'Cargando video'}
          spinner={'bubbles'}
          duration={9999999}
        />

        {descripcion == undefined && modificar
          ? null
          :
          <IonGrid className="form">
            <IonRow>
              <IonCol size="12">
                <p>Nombre de la actividad</p>
                <IonItem>
                  <IonInput maxlength={70} type="text" value={nombre} onIonChange={e => <> {e.detail.value != undefined ? setNombre(e.detail.value?.toString()) : null}</>}></IonInput>
                </IonItem>
              </IonCol>
              <p className="aviso-naranja aviso-nombre small hidden"> * Debes introducir nombre o descripción</p>

            </IonRow>
            <IonRow>
              <IonCol size="12">
                <p>Descripción</p>
                <Editor apiKey="zcwhxx8ze45rb7b73snp6zr957eqtcu9hglafgu9fybxu8zs"
                  initialValue=""
                  value={descripcion}
                  onEditorChange={(newValue, editor) => setDescripcion(newValue)}
                  init={{
                    height: 500,
                    menubar: false,
                    content_css: 'theme/global.css',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help'
                  }}
                />
                <p className="aviso-naranja aviso-nombre small hidden"> * Debes introducir nombre o descripción</p>

                <DetalleTipoActividadComp setFile={setFile} selectedFileName={filename!} myFile={myFile!} removeMedia={removeImage} selectedType={selectedType} setSelectedType={setSelectedType}/>

              </IonCol>
            </IonRow>
            {showModal ? <ChooseObjPopUp isBiblio={true} setShowModal={setShowModal} setSearchesActivity={setSearches} searchesActivity={searches} /> : null}
            <IonRow>
              <IonCol size="12">
                <IonButton onClick={e => setActualPage("biblActividad")} className="cancelBtn"><p className="">CANCELAR</p></IonButton>
                {modificar
                  ? <IonButton className="saveBtn" onClick={e => modificarActividad()}><p className="">Guardar</p></IonButton>
                  : <IonButton className="saveBtn" onClick={e => createActividad()}><p className="">Crear actividad</p></IonButton>}

              </IonCol>
            </IonRow>


          </IonGrid>
        }
      </IonGrid>
    </>
  );
};
export default BiblNuevaActividad;







import { IonIcon, IonButton, IonCol, IonGrid, IonRow, IonInput, IonImg, IonPopover } from '@ionic/react';
import '../Header.css';
import 'react-image-crop/dist/ReactCrop.css';

/** LOGOS **/
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import close from '../../img/iconos/close.svg';
import { User } from '../../interfaces/intUser';
import { getData } from '../../api/apiRecursos';

import eyeSlash from '../../img/iconos/eye-slash.svg';
import eye from '../../img/iconos/eye.svg';
import check from '../../img/iconos/check.svg';
import noPhotoGroup from '../../img/iconos/noPhoto_group.svg';

import { Media } from '../../interfaces/intPosts';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import { isPlatform } from '@ionic/react';
//REDUX
import { useAppDispatch } from '../../app/hooks'
import { setMediaName } from '../../features/users/userSlice';
import { GlobalVariables } from '../../hooks/GlobalVariables';



interface Props {
  setModifyProfile: any;
  setLastAction: any;
  lastAction: string;
  setTextAlert: any; 
  setShowAlert: any;
}

const ModifyProfilePopUp: React.FC<Props> = ({ setModifyProfile, setLastAction, lastAction, setTextAlert, setShowAlert}) => {
  //REDUX 
  const dispatch = useAppDispatch();

  const [file, setFile] = useState<any>();
  const [user, setUser] = useState<User>();
  const [fileName, setFileName] = useState("");
  const [customFilename, setCustomFilename] = useState<string>();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contraseña, setContraseña] = useState<string>();
  const [newPhoto, setNewPhoto] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [mostrarPass, setMostrarPass] = useState(false);
  const [modifyName, setModifyName] = useState(false);
  const [modifyEmail, setModifyEmail] = useState(false);
  const [showTextAlert, setShowTextAlert] = useState(false);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

  const { takePhoto, lastPhoto, setLastPhoto, filePhoto } = usePhotoGallery();

  const formData = new FormData();

  const fileInput = useRef(null);

  let history = useHistory();
  const axios = require('axios');

  const userData = {
    'token': Cookies.get('token')
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data;"
    }
  }

  useEffect(() => {
    getData.post<User>('/userDetail', userData)
      .then((res: any) => {
        setUser(res.data);
        setFileName(res.data.media_name)
        setFullName(res.data.name);
        setEmail(res.data.email.toString());
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }, [lastAction]);

  const storeFile = (file: any) => {
    setLastPhoto(undefined)
    setFile(file);
    if (file != undefined) {
      formData.append("media", file);
    }

    getData.post<Media>('/media', formData, config)
      .then((res: any) => {
        setNewPhoto(res.data.name);
        setCustomFilename(res.data.name)
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  }


  function updateUser(num: number): void {
    
    // let [name] = fullName.split(" ");
    //@ts-ignore

    if (lastPhoto != undefined) {
      formData.append('media', filePhoto!);
      dispatch(setMediaName(filePhoto?.name!));
      // window.localStorage.setItem('userPhoto', filePhoto?.name!);
    } else if (file != undefined) {
      formData.append('media', file);
      dispatch(setMediaName(newPhoto!));
      // window.localStorage.setItem('userPhoto', newPhoto);
    }
    formData.append('name', fullName);
    formData.append('email', email);
    formData.append('password', contraseña!);


    getData.post("/user-update/" + Cookies.get('token'), formData, config)
      .then((res: any) => {
        if (res.status === 200) {
          setLastAction("modificar usuario " + Date.now());
          setTextAlert("Los cambios se han realizado correctamente.");
          setShowAlert(true);
          setModifyProfile(false);
          
        } else if (res.status === 210) {
          setShowTextAlert(true)
        }

      })
      .catch((error: any) => {
        console.log("error", error);
      });

    enableModification(num);
  }

  const enableModification = (num: number) => {
    switch (num) {
      case 1:
        setModifyName(!modifyName);
        break;

      case 2:
        setModifyEmail(!modifyEmail);
        break;

      case 3:
        setMostrarPass(!mostrarPass);
        break;
      default:
        break;
    }

  }

  const {urlMedia} = GlobalVariables();

  const imgBackground = {
    backgroundImage: "url("+ urlMedia + fileName + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  const imgPhotoBackground = {
    backgroundImage: "url("+lastPhoto?.webviewPath+")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
  const imgCustomBackground = {
    backgroundImage: "url("+ urlMedia + customFilename + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }


  return (
    <div className="modal modal_modify">
      <div className="modal_content">
        <IonIcon src={close} className="close" onClick={e => setModifyProfile(false)} />
        <IonGrid>
          <IonRow>
            <IonCol size="4" >
              {lastPhoto == undefined
                ? <>{customFilename == undefined
                      ? <span className={`d-flex roundColumn ${fileName != null ? '' : 'border-none'}`} style={fileName != null ? imgBackground : undefined}>
                          {fileName != null ? '' : <IonImg src={noPhotoGroup} />}
                        </span>
                      : <span className={`d-flex roundColumn`} style={imgCustomBackground}/>} </>
                : <span className={`d-flex roundColumn`} style={imgPhotoBackground}/>}
              
              <>  <input ref={fileInput} hidden type="file" accept="image/*" onClick={() => { '' }} onChange={(e) => {
                // onSelectFile(e);
                // @ts-ignore 
                storeFile(e.target.files[0]);
                
              }} />

              {isPlatform('hybrid') 
                    ? <a className="imagenBtn" onClick={
                      (e: any) => {
                        e.persist();
                        setShowPopover({ showPopover: true, event: e })
                      }}><p className="body2 mt-2 text-gris text-center">Editar</p> </a>

                    : <a className="imagenBtn" onClick={(e: any) => {//@ts-ignore
                      fileInput?.current?.click()}}><p className="body2 mt-2 text-gris text-center">Editar</p> </a>
                    }

                
                <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverState.event}
                  isOpen={popoverState.showPopover}
                  onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                >
                  <a className='popup-select'> {/* @ts-ignore*/}
                    <p className="body1" onClick={() => { fileInput?.current?.click(); setShowPopover({ showPopover: false, event: undefined }) }}>De la galería</p>
                  </a>
                {navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i) 
                  ? <a className='popup-select'><p className="body1" onClick={() => { takePhoto(); setShowPopover({ showPopover: false, event: undefined }) }}>Hacer foto</p></a>
                : null}
                </IonPopover>
              </>
            </IonCol>
            <IonCol size="8">
              <h5>Nombre</h5>
              <IonRow>
                <IonCol size="8">
                  <IonInput className="body2 mt-2" value={fullName} disabled={!modifyName} onIonChange={e => setFullName(e.detail.value?.toString()!)}></IonInput>
                </IonCol>
                <IonCol className="d-flex align-self-center">
                  {modifyName
                    ? <IonIcon src={check} className="medio" onClick={e => enableModification(1)} />
                    : null}
                </IonCol>
              </IonRow>
              <a className="body2 text-naranja my-2" onClick={e => enableModification(1)}>Cambiar nombre</a>

              <h5 className="mt-4">Correo electrónico</h5>
              <IonRow>
                <IonCol size="10">
                  <IonInput className="body2 mt-2" value={email} disabled={!modifyEmail} onIonChange={e => setEmail(e.detail.value?.toString()!)}></IonInput>
                </IonCol>
                <IonCol className="d-flex align-self-center">
                  {modifyEmail
                    ? <IonIcon src={check} className="medio" onClick={e => enableModification(2)} />
                    : null}
                </IonCol>
              </IonRow>
              <a className="body2 text-naranja mt-2" onClick={e => enableModification(2)}>Cambiar correo electrónico</a>
              <p className={`body2 ${showTextAlert ? "" : 'hidden'}`} style={{'color':'red'}}>* Este correo electrónico ya está en uso en otro usuario</p>

              <h5 className="mt-4">Contraseña</h5>
              {mostrarPass
                ?
                <IonRow>
                  <IonCol>
                    <IonInput type={showPassword ? "text" : "password"} required={true} placeholder="Introduce la nueva contraseña" onIonChange={e => setContraseña(e.detail.value?.toString())}>
                    </IonInput>

                  </IonCol>

                  <IonCol className="d-flex align-self-center">
                    {showPassword
                      ? <>
                        <IonIcon src={eyeSlash} className="medio mx-3" onClick={e => setShowPassword(!showPassword)} />
                        <IonIcon src={check} className="medio" onClick={e => enableModification(3)} />
                      </>
                      : <>
                        <IonIcon src={eye} className="medio mx-3" onClick={e => setShowPassword(!showPassword)} />
                        <IonIcon src={check} className="medio" onClick={e => enableModification(3)} />
                      </>}
                  </IonCol>

                </IonRow>

                : null}

              <a className="body2 text-naranja mt-2" onClick={e => enableModification(3)}>Cambiar contraseña</a>
              <br />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="d-flex justify-content-end mt-5">
              <IonButton className="cancelBtn" onClick={e => setModifyProfile(false)}><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" onClick={e => updateUser(4)}><p>GUARDAR</p></IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );

};
export default ModifyProfilePopUp;
import { IonGrid, IonRow, IonCol, IonContent, IonPage, IonIcon, IonButton, IonImg, IonLoading } from '@ionic/react';

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';


/* CSS */
import '../theme/global.css';

/* ICONOS */
import BibliotecaLogo from '../img/iconos/biblioteca.svg';
import ActivUsers from '../img/iconos/activ-todos.svg';
import NuevoUser from '../img/iconos/nuevo-usuario.svg';
import { useEffect, useState } from 'react';
import PantallaPrincipal from '../components/PantallaPrincipal';
import NuevoUsuario from '../components/NuevoUsuario';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { User } from '../interfaces/intUser';
import { getData } from '../api/apiRecursos';
import UltimaActividad from '../components/UltimaActividad';
import { Group, Groups } from '../interfaces/intGroups';

import noGroupIcons from '../img/iconos/noGroups_icons.svg';
import noGroupLogo from '../img/iconos/noGroups_logo.svg';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { setGroups } from '../features/groups/groupsSlice';
import { OneSignalFunctions } from '../hooks/OneSignalFunctions';
import { GlobalFunctions } from '../hooks/GlobalFunctions';




const UltimaActividadPage: React.FC = (props) => {
  //REDUX
  const dispatch = useAppDispatch();
  const image_name = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const groups = useAppSelector(state=>state.reducer.groups.group);
  const OSUserID = useAppSelector(state=>state.reducer.oneSignal.userID);
  // const test = useAppSelector(state=>state.reducer.groups.group);

  const {getPostsAllGroups} = GlobalFunctions();

  const [actualPage, setActualPage] = useState("ultimaActividad");
  const [userAvatar, setUserAvatar] = useState('');
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState<string>('');

  
  if (window.location.pathname == '/principal') {
    var tabsBiblio = Array.from(document.getElementsByClassName('tab-biblio') as HTMLCollectionOf<HTMLElement>);
    var tabsApp = Array.from(document.getElementsByClassName('tab-app') as HTMLCollectionOf<HTMLElement>);

    var i;
    for (i = 0; i < tabsBiblio.length; i++) {
      tabsBiblio[i].setAttribute('style', 'display: none !important');
    }
    for (i = 0; i < tabsApp.length; i++) {
      tabsApp[i].setAttribute('style', 'display: none !important');
    }
  }

  useEffect(() => {
    getPostsAllGroups()
  }, [])

  const changeVisibility = (value: string) => {
    let removeClass = document.getElementsByClassName("invisible");
    for (var i = 0; i < removeClass.length; i++) {
      // @ts-ignore
      removeClass.item(i)?.classList.remove("invisible");
    }
    if (value == "app") {
      document.getElementById("tab-button-actividadesBiblio")?.classList.remove("invisible");
    }
    let array = document.getElementsByClassName("tab-" + value);
    for (var i = 0; i < array.length; i++) {
      if (!array.item(i)?.classList.contains("invisible")) {
        // @ts-ignore
        array.item(i).className += " invisible";
      }
    }
  }

  const checkWindow = () => {
    if (window.screen.width <= 425) {
      document.getElementById("tabBar")?.setAttribute("style", "display:none !important")

      if (window.location.pathname == "/biblioteca") {
        changeVisibility("app");
      } if (window.location.pathname == "/biblioteca/actividades" || window.location.pathname == "/biblioteca/objetivos" || window.location.pathname == "/biblioteca/recursos") {
        changeVisibility("app");
      }
      else {
        changeVisibility("biblio")
      }
    }
  }
  

  const refresh = () => {
    window.location.href = window.location.origin;
  }
  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  return (
    <>{Cookies.get('token')
      ? <>
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          showBackdrop={false}
          duration={3000}
        />
            {checkWindow()}
              <IonPage>
                <HeaderEmpty avatarProp={userAvatar} actualPage={actualPage} />
                {showAlert
                    ? <>{showPopUp()} <AvisoPopUp text={textAlert} /></>
                    : null}
                <IonContent id="principal-page" fullscreen className={`fullSize pag-principal canvi-bg-mvl bg-gris-clar`}>
                  <UltimaActividad />
                  
                </IonContent>
              </IonPage>
          

      </>
      : <>{refresh()}</>}
    </>
  );


};

export default UltimaActividadPage;



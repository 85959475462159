import { IonGrid, IonRow, IonCol, IonIcon, IonLoading } from '@ionic/react';

/* COMPONENTES */

/* CSS */
import '../../theme/global.css';

/* IMAGENES */
import logoActividad from '../../img/iconos/actividades-logo-neg.svg';
import logoObj from '../../img/iconos/objetivos-logo-min.svg';

/* DATOS */
import { Fragment, useEffect, useState } from 'react';
import { getData } from '../../api/apiRecursos';
import { Activities } from '../../interfaces/intActivity';
import BigPhotoPopUp from '../PopUpModals/BigPhotoPopUp';
import { GlobalFunctions } from '../../hooks/GlobalFunctions';
import TituloActividades from '../TituloActividad';

//


interface ContainerProps {
  setSelectedId: any,
  setActualPage: any,
  selectedId: number,
  showBack?: boolean,
  setShowBack?: any
}

const ActividadDetalle: React.FC<ContainerProps> = ({ setShowBack = false, showBack, setSelectedId, setActualPage, selectedId }) => {
  const { showMedia } = GlobalFunctions();
  const [activity, setActivity] = useState<Activities>();
  const [loading, setLoading] = useState(true);
  const [showPhoto, setShowPhoto] = useState(false);


  useEffect(() => {
    getData.get<Activities>('/activity/' + selectedId)
      .then(resp => {
        //@ts-ignore
        setActivity(resp.data);
      })
      .then(() =>
        setLoading(false)
      )
      .catch(err => console.log("error", err));

  }, [selectedId])

  const mostrarObjective = (id: number) => {
    setSelectedId(id);
    setActualPage("objectiveDetail");
  }



  return (
    <IonGrid className="container">
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={10000}
      />
      <IonRow>
        <TituloActividades titulo="Actividad" setShowBack={setShowBack} setActualPage={setActualPage} showBack={showBack} isBiblio={false}/>
      </IonRow>
      <div className='cnt-detalle'>

      
      <IonRow className="">
        <IonCol size="12" >
          <h3>{activity?.name}</h3>
        </IonCol>

      </IonRow>
      <IonRow>
        <IonCol className="mt-2 html-description">

          {activity?.description
            ? <p dangerouslySetInnerHTML={{ __html: activity!.description }} />
            : <p>{activity?.description}</p>
          }
        </IonCol>
      </IonRow>
      {showPhoto
        ? <BigPhotoPopUp photoName={activity?.media_name!} setShowPhoto={setShowPhoto} /> : null}

      <IonRow>
        <IonCol className="mt-2">
          {showMedia(activity?.media_id, activity?.media_name, activity?.media_type, setShowPhoto)}
        </IonCol>
      </IonRow>
      <IonRow>

        {activity?.objectives.length! > 0
          ? <><IonCol size="12">
            <p><b>Objetivos asociados:</b></p>
          </IonCol>
            {activity?.objectives.map((objetivo, index) =>
              <Fragment key={index}>
                <IonCol size="12" className="lineal-flex">
                  <IonIcon src={logoObj} className="medio" />

                  <a className="mx-5" onClick={e => mostrarObjective(objetivo.id)}>
                    <p className="body1">{objetivo.name}</p>
                    <p className="overline text-verd">{objetivo.category_name == "Sin competencia" ? "" : objetivo.category_name}</p>
                  </a>
                </IonCol>
              </Fragment>
            )}
          </>
          : null}
      </IonRow>
      
      </div>


    </IonGrid>
  );
};

export default ActividadDetalle;
import { IonGrid, IonRow, IonCol, IonItem, IonInput, IonTextarea, IonSelect, IonSelectOption, IonRadioGroup, IonLabel, IonRadio, IonCheckbox, IonButton, IonIcon } from "@ionic/react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import star from '../../img/iconos/star.svg';
import { getData } from "../../api/apiRecursos";
import { Category, Etapa, Objective, Objectives } from "../../interfaces/intObjective";
import TituloObjetivo from "../TituloObjetivo";
import { useHistory } from "react-router";
import SelectorCategoria from "../SelectorCategoria";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import { CrearObjetivo } from "../../interfaces/intAuxiliar";

interface ContainerProps {
  setActualPage: any,
  selectedId: number,
  setLastAction: any,
  lastAction: string,
  setShowAlert: any,
  setTextAlert:any,
}

const ObjetivoModificar: React.FC<ContainerProps> = ({ setTextAlert, setShowAlert,setActualPage, selectedId, setLastAction }) => {
  const axios = require('axios');
  const history = useHistory();

  const [categories, setCategories] = useState<Category[]>([]);
  const [etapa, setEtapas] = useState<Etapa[]>([]);
  const [isEditable, setIsEditable] = useState(false);

  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [estado, setEstado] = useState(0);
  const [destacado, setDestacado] = useState(false);
  const [valor, setValor] = useState(0);
  const [categorySelected, setCategorySelected] = useState(0);
  const [etapaSelected, setEtapaSelected] = useState(0);
  const [stateValue, setStateValue] = useState("");
  const [objetivo, setObjetivo] = useState<Objective>();
  const {publicarYCrearObjetivo, crearObjetivo} = GlobalFunctions();


  const changeState = (status: string) => {
    setStateValue(status);
    switch (status) {
      case "pendiente":
        setEstado(0);
        break;
      case "iniciando":
        setEstado(1);
        break;
      case "25":
        setEstado(1);
        setValor(25);
        break;
      case "50":
        setEstado(1);
        setValor(50);
        break;
      case "75":
        setEstado(1);
        setValor(75);
        break;
      case "100":
        setEstado(2);
        setValor(100);
        break;
      case "no aplica":
        setEstado(3);
        break;

      default:
        break;
    }

  }

  const changeValue = (status: number, value: number) => {
    switch (status) {
      case 0:
        setStateValue("pendiente");
        break;
      case 1:
        switch (value) {
          case 0:
            setStateValue("iniciando");

            break;
          case 25:
            setStateValue("25");

            break;
          case 50:
            setStateValue("50");

            break;
          case 75:
            setStateValue("75");

            break;

          default:
            break;
        }
        break;
      case 2:
        setStateValue("100");
        break;
      case 3:
        setStateValue("no aplica");
        break;
      default:
        break;
    }
  }

  const updateAndPost = () => {
    if (nombre != ""){
      const dataObjetivo: CrearObjetivo = {
        token: Cookies.get('token')!,
        group_id: Cookies.get('group_id')!,
        name: nombre,
        description: descripcion,
        status: estado,
        important: destacado,
        category_id: categorySelected,
        value: valor,
        etapa_id: etapaSelected
      };

      publicarYCrearObjetivo(dataObjetivo, setLastAction, setActualPage, setTextAlert, setShowAlert, 'modificar', selectedId);
    } else {
      $('.aviso-naranja').removeClass('hidden');
    }

  }

  const modificaObjetivo = () => {
    if (nombre != ""){
      const dataObjetivo: CrearObjetivo = {
        token: Cookies.get('token')!,
        group_id: Cookies.get('group_id')!,
        name: nombre,
        description: descripcion,
        status: estado,
        important: destacado,
        category_id: categorySelected,
        value: valor,
        etapa_id: etapaSelected
      };

      crearObjetivo(dataObjetivo, setLastAction, setActualPage, setTextAlert, setShowAlert, 'modificar', selectedId);
    } else {
      $('.aviso-naranja').removeClass('hidden');
    }

  }

  useEffect(() => {
    getData.get<Objective>('/objective/' + selectedId)
      .then(resp => {
        //@ts-ignore
        changeValue(resp.data.status, resp.data.value);
        setObjetivo(resp.data);
        setNombre(resp.data.name);
        setDescripcion(resp.data.description);
        setEtapaSelected(resp.data.etapa_id);
        if (resp.data.is_editable == 1) {
          setIsEditable(true);
        }
        console.log("TEST:", resp.data);


        if (resp.data.important == 1) {
          setDestacado(true);
        }
        //@ts-ignore
        setCategorySelected(resp.data.category.id);
      })
      .catch(err => console.log(err));

    getData.get<Objectives>('/etapa')
      .then(resp => {
        setEtapas(resp.data.etapas);
      })
      .catch(err => console.log(err));

    getData.get<Objectives>('/category')
      .then(resp => {
        setCategories(resp.data.categories);
      })
      .catch(err => console.log(err));

  }, []);


  return (
    <>
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>

      <IonGrid className="container">
        <IonRow>
          <TituloObjetivo titulo="Editar objetivo" />
        </IonRow>

        <IonGrid className="form">
          <IonRow>
            <IonCol size="12">
              <p>Nombre</p>
              <IonItem>
                <IonInput disabled={!isEditable} type="text" value={nombre} onIonChange={e =>
                  <>
                    {e.detail.value != undefined
                      ? setNombre(e.detail.value?.toString())
                      : null
                    }
                  </>} />
              </IonItem>
              <p className="aviso-naranja small hidden"> * Campo obligatorio</p>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <p>Descripción</p>
                <IonTextarea className="big-textarea" value={descripcion} onIonChange={e =>
                  <>
                    {e.detail.value != undefined
                      ? setDescripcion(e.detail.value?.toString())
                      : null
                    }
                  </>} />
            </IonCol>
          </IonRow>
          <IonRow>
            <SelectorCategoria isEditable={isEditable} categories={categories} etapa={etapa} setCategorySelected={setCategorySelected} categorySelected={categorySelected} etapaSelected={etapaSelected} setEtapaSelected={setEtapaSelected}/>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <p>Estado</p>
              <IonRadioGroup value={stateValue}
                onIonChange={e => {changeState(e.detail.value); if (e.detail.value == 'iniciando') { setDestacado(true)} }}
                className="radioGroup">
                <IonItem>
                  <IonLabel>Pendiente<p className="body2 text-gris desktop">No se mostrará a los acompañantes con rol "Colaborador"</p></IonLabel>
                  <IonRadio mode="md" slot="start" value="pendiente" ></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>Iniciando</IonLabel>
                  <IonRadio mode="md" slot="start" value="iniciando"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>25% alcanzado</IonLabel>
                  <IonRadio mode="md" slot="start" value="25"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>50% alcanzado</IonLabel>
                  <IonRadio mode="md" slot="start" value="50"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>75% alcanzado</IonLabel>
                  <IonRadio mode="md" slot="start" value="75"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>100% alcanzado</IonLabel>
                  <IonRadio mode="md" slot="start" value="100"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel>No aplica<p className="body2 text-gris desktop">No se mostrará a los acompañantes con rol "Colaborador"</p></IonLabel>
                  <IonRadio mode="md" slot="start" value="no aplica"></IonRadio>
                </IonItem>
              </IonRadioGroup>
            </IonCol>
          </IonRow>
          <IonRow className="mt-3">
            <IonCol size="12">
              <IonItem className="destacadoCheck">
                <IonLabel>Destacado <IonIcon src={star}/><br /><p className="body2 text-gris desktop">Se visualizará en el menú lateral. </p></IonLabel>
                <IonCheckbox
                  disabled={stateValue == "no aplica" || stateValue == "pendiente" || stateValue == "100" ? true : false}
                  slot="start"
                  checked={stateValue == "no aplica" || stateValue == "pendiente" || stateValue == "100" ? false : destacado}
                  onIonChange={e => setDestacado(e.detail.checked)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow className="mb-3">
            <IonCol size="12" className='lineal-flex'>
              <IonButton onClick={() => setActualPage("objetivos")} className="cancelBtn"><p>CANCELAR</p></IonButton>
              <IonButton className="saveBtn" onClick={modificaObjetivo}><p>Guardar</p></IonButton>
            </IonCol>
            <IonCol size='12' className="">
              <IonButton className="timelineBtn " disabled={stateValue == 'pendiente' || stateValue == 'no aplica' ? true : false} onClick={e => updateAndPost()}><p className='px-5'>Guardar y publicar en el timeline</p></IonButton>
            </IonCol>
          </IonRow>

        </IonGrid>



      </IonGrid>
    </>
  );
};

export default ObjetivoModificar;


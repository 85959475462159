import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { Groups } from "../../interfaces/intGroups";

// Define a type for the slice state
// interface UserState {
//   id: number, 
//   name: string,
//   email:string,
//   image:string
// }

const initialState: Groups = {
  group: []
}

// const initialState: User;

export const groupsSlice = createSlice({
  name: 'groups',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<Groups>) => {
      state.group = action.payload.group;
    }, 

  },
});


export const { setGroups } = groupsSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default groupsSlice.reducer
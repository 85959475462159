import { IonRow, IonCol, IonButtons, IonButton, IonIcon, IonPopover } from "@ionic/react";
import addCircle from '../../img/iconos/add-circle.svg';
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Group } from "../../interfaces/intGroups";
import Actividad from "./Actividad";
import TituloActividades from "../TituloActividad";

import check from '../../img/iconos/check_blanco.svg';
import editar from '../../img/iconos/lapiz.svg';
import arrowBot from '../../img/iconos/arrow_bottom.svg';
import { useHistory } from "react-router";
import { Role } from "../../interfaces/intUser";
import AvisoPopUp from "../PopUpModals/AvisoPopUp";

//REDUX
import { useAppSelector } from '../../app/hooks';


interface ContainerProps {
  setActualPage: any,
  setSelectedId: any,
  setLastAction: any,
  lastAction: string,
  setShowBack?: any,
  showAlert: boolean,
  setShowAlert: any,
  textAlert:string,
  setTextAlert:any,
}

const Actividades: React.FC<ContainerProps> = ({ setTextAlert,textAlert, setShowAlert, showAlert, setShowBack, setActualPage, setSelectedId: setSelectedId, setLastAction, lastAction }) => {
 //REDUX
 const activities = useAppSelector(state=> state.reducer.groupSelected.activities);
 const role = useAppSelector(state=>state.reducer.groupSelected.userRole?.role);
 
 const [estado, setEstado] = useState(99);
  const [showHide, setShowHide] = useState(false);
  const [isReversed, setReversed] = useState(false);
  const history = useHistory();

  const [order, setOrderBy] = useState("Alfabéticamente");

  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [popoverStateNuevo, setShowPopoverNuevo] = useState({ showPopover: false, event: undefined });


  useEffect(() => {
    if (role != 1){
      setShowHide(true);
    }
    if (!Cookies.get('token')) {
      alert("Ha caducado la sesión, vuelve a iniciar sesión por favor");
      // eslint-disable-next-line no-restricted-globals
      // location.reload();
      history.push('');
    }
  }, [])

  function changeOrder(): void {
    if (isReversed) {
      setReversed(!isReversed);
    } else {
      setReversed(!isReversed);
    }
  }

  function setOrder(text: string): void {
    setOrderBy(text);
    setShowPopover({ showPopover: false, event: undefined });
  }

  const showActivity = () => {
    if (setShowBack) {
      return <Actividad setTextAlert={setTextAlert} setShowAlert={setShowAlert} setShowBack={setShowBack} setActualPage={setActualPage} setSelectedId={setSelectedId} estado={estado} showHide={showHide} order={order} isReversed={isReversed} setLastAction={setLastAction} lastAction={lastAction} />;
    } else {
      return <Actividad setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setSelectedId={setSelectedId} estado={estado} showHide={showHide} order={order} isReversed={isReversed} setLastAction={setLastAction} lastAction={lastAction} />;
    }
  }

  const goToBiblio = () => {
    if (window.screen.width <= 425) {
      history.push('/biblioteca/actividades');
    } else {
      setActualPage("activityBiblio");
    }
  }


  return (
    <>
      <div className="container">
        <>
          <IonRow className="rowTitulo">
            <TituloActividades titulo="Todas las actividades" />

            <IonCol size="4" className="ion-justify-content-end d-flex align-self-center btns-title">
              {
                showHide && activities.length >= 1
                  ?
                  <>
                    <IonPopover
                      event={popoverStateNuevo.event}
                      isOpen={popoverStateNuevo.showPopover}
                      onDidDismiss={() => setShowPopoverNuevo({ showPopover: false, event: undefined })}
                    >
                      <a className='popup-select' onClick={() => setActualPage("activityNew")}><p className="body1">Crear nueva actividad</p></a>
                      {/* <a className='popup-select'><p className="body1" onClick={() => {setActualPage("activObjetivosActivos"); setShowPopoverNuevo({ showPopover: !popoverStateNuevo.showPopover, event: undefined })}}>Añadir actividades de ComuniteCAA</p></a> */}
                      <a className='popup-select'><p className="body1" onClick={() => {setActualPage("activityBiblio"); setShowPopoverNuevo({ showPopover: !popoverStateNuevo.showPopover, event: undefined })}}>Añadir actividades de Mi biblioteca</p></a>
                    </IonPopover>
                    <button className="addObjetivo bigger-hover" onClick={
                      (e: any) => {
                        e.persist();
                        setShowPopoverNuevo({ showPopover: !popoverStateNuevo.showPopover, event: e })
                      }}>
                      <IonCol>
                        <IonIcon className="grande" src={addCircle}></IonIcon>
                        <p className="body2">Crear nueva<br />actividad</p>
                      </IonCol>
                    </button>
                  </>
                  : null
              }
            </IonCol>
          </IonRow>

          {activities.length < 1
            ? null
            : <IonRow className="mt-2">
            <IonCol size="12" className="actividades-btns lineal-flex my-3">
              <div>
                <button className={`btn-actividades titulo-seccion ${estado === 99 ? "selected" : null}`}
                  onClick={() => setEstado(99)}>
                  Todas
                </button>
                <button className={`btn-actividades titulo-seccion ${estado === 1 ? "selected" : null}`}
                  onClick={() => setEstado(1)}>
                  En curso
                </button>
                <button className={`btn-actividades titulo-seccion ${estado === 2 ? "selected" : null}`}
                  onClick={() => setEstado(2)}>
                  Terminadas
                </button>
                {showHide
                  ? 
                    <button className={`btn-actividades titulo-seccion ${estado === 0 ? "selected" : null}`}
                      onClick={() => setEstado(0)}>
                      Pendientes
                    </button>
                  : null
                }
              </div>
            </IonCol>
            <IonCol size="12" className="d-flex">
              <div className="lineal-flex ml-auto align-items-center my-2">
                <p className="body2 text-lila mx-2" onClick={
                  (e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e })
                  }}>{order}</p>
                <IonIcon src={arrowBot} className={`${isReversed ? "reversed" : ""} medio`} onClick={() => changeOrder()} />
                <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverState.event}
                  isOpen={popoverState.showPopover}
                  onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                >
                  <a className='popup-select'><p className="body1 text-gris">Ordenar por</p></a>
                  <a className='popup-select'><p className="body1" onClick={() => setOrder("Objetivo")}>Objetivo</p></a>
                  <a className='popup-select'><p className="body1" onClick={() => setOrder("Alfabéticamente")}>Alfabéticamente</p></a>
                </IonPopover>
              </div>
            </IonCol>
          </IonRow> }
          <IonRow className="mt-4">
            {showActivity()}
          </IonRow>
        </>
      </div>
    </>
  );
};
export default Actividades;







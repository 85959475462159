import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import React from 'react'
import { useHistory } from 'react-router-dom';

/* COMPONENTES */
import HeaderEmpty from '../components/HeaderEmpty';


/* CSS */
import '../theme/global.css';
import LoginPage from '../components/login/LoginPage';
import RegisterPage from '../components/login/RegisterPage';
import ForgetPasswordPage from '../components/login/ForgetPasswordPage';
import Cookies from 'js-cookie';
import { User } from '../interfaces/intUser';
import { getData } from '../api/apiRecursos';
import ActivarUsuario from '../components/login/ActivarUsuario';
import FalseRegister from '../components/login/FalseRegister';
import RegisterComp from '../components/login/RegisterComp';



const Login: React.FC = (props) => {
  const [actualPage, setActualPage] = useState("login");
  const [user, setUser] = useState<User>();

  if (window.location.pathname == '/login'){
    document.getElementById('tabBar')?.setAttribute('style', 'display: none !important');
  }

  let history = useHistory();
  useEffect(() => {
    if (Cookies.get("token")){
      history.push({
        pathname: "/principal/", state: user
      });

    }
  }, [user]);

  const getUserData = () => {
    if (user == undefined) {
      const userData = {
        'token': Cookies.get('token')
      }
      getData.post<User>('/userDetail', userData)
        .then((res: any) => {
          setUser(res.data);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    }
  }


  const showContent = () => {
    switch (actualPage) {
      case "login":
        return <LoginPage setActualPage={setActualPage} />;
      case "recuperaContraseña":
        return <ForgetPasswordPage setActualPage={setActualPage} />;
      case "register":
        return <RegisterComp setActualPage={setActualPage} />;
      case "activarUsuario":
        return <ActivarUsuario setActualPage={setActualPage} />;
      default:
        break;
    }
  }

  return (
    <>
      <IonPage className="desktop">
        <HeaderEmpty />
        <IonContent fullscreen className="fullSize bg-gris-clar">
          <IonHeader collapse="condense">
            
          </IonHeader>
          {Cookies.get('token')
            ? getUserData()
            : showContent()}
        </IonContent>

      </IonPage>

      <IonPage className={`mvl ${Cookies.get('token')} this ${actualPage} that`}>
        
        <IonContent fullscreen className="fullSize bg-gris-clar">
          <>
          {Cookies.get('token')
            ? getUserData()
            : showContent()}
            </>

        </IonContent>


      </IonPage>
    </>
  );

};

export default Login;







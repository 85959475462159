import { IonCol, IonGrid, IonIcon, IonLoading, IonPopover, IonProgressBar, IonRow } from '@ionic/react';

// ICONES
import star from '../../img/iconos/star.svg';
import checkmark from '../../img/iconos/check_verd.svg';
import objLogoMin from '../../img/iconos/objetivos-logo-min.svg';


/* DATA */
import { Fragment, useEffect, useState } from 'react';
import ModificarElemento from '../ModificarElemento';
import { Etapa } from '../../interfaces/intObjective';
import chevronRight from '../../img/iconos/chevron-right.svg';
import infoIcon from '../../img/iconos/info.svg';
import plegado from '../../img/iconos/plegado.svg';
import desplegado from '../../img/iconos/desplegado.svg';
import ImportObjectivePopUp from '../PopUpModals/ImportObjectivePopUp';
import $ from 'jquery';

//REDUX
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { GlobalFunctions } from '../../hooks/GlobalFunctions';



const showIcons = (estado: number, destacado: number) => {
  if (estado === 2) {
    return (
      <>
        <IonIcon icon={checkmark} className="grande"></IonIcon>
      </>
    )
  } else if (destacado == 1) {
    return (
      <>
        <IonIcon icon={star} className="medio"></IonIcon>
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

interface Props {
  competencia: string;
  showHide: boolean;
  setActualPage?: any,
  setSelectedId?: any,
  setLastAction: any,
  lastAction: string,
  setShowBack?: any,
  setDefaultObjectives?: any,
  setShowAlert: any,
  setTextAlert: any,
}


const Etapas: React.FC<Props> = ({ setTextAlert, setShowAlert, setDefaultObjectives, setShowBack, competencia, showHide, setActualPage, setSelectedId, setLastAction, lastAction }) => {
  //REDUX
  const categories = useAppSelector(state => state.reducer.groupSelected.categories);
  const etapas = useAppSelector(state => state.reducer.groupSelected.etapas);
  const exportList = useAppSelector(state=> state.reducer.groupSelected.exportedObjectives);
  const role = useAppSelector(state => state.reducer.groupSelected.userRole);
  const [popoverStateObj, setShowPopoverObj] = useState({ showPopover: false, event: undefined });
  const [showImportModal, setShowImportModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { getObjectiveEtapa } = GlobalFunctions();
  

  useEffect(() => {
    setLoading(false);
    getObjectiveEtapa(setLoading);
  }, [lastAction]);

  const showDetail = (id: number) => {
    setSelectedId(id);
    setActualPage("objectiveDetail");
    if (setShowBack) {
      setShowBack(true);
    }
  }

  const showInfo = (panelNumber: number) => {
    var panelInfo = $('.panel' + panelNumber);
    if (panelInfo.hasClass('show')) {
      panelInfo?.removeClass('show');
    } else {
      panelInfo?.addClass('show');
    }
  }

  const openEtapa = (panelNumber: number) => {
    var accordions = Array.from(document.getElementsByClassName('accordion') as HTMLCollectionOf<HTMLElement>);
    var acc = $('.accordion-' + panelNumber);
    var icono = $('.icon' + panelNumber);

    var panelInterior = $('.panel-interior' + panelNumber);
    var verticalLine = $('.vertical-line-' + panelNumber);
    var i;
    for (i = 0; i < accordions.length; i++) {
      accordions[i].classList.remove('active');
    }

    if (panelInterior?.hasClass('show')) {
      acc?.removeClass("active");
      panelInterior?.removeClass('show');
      verticalLine?.removeClass('show');
      icono.attr('src', plegado);
    } else {
      acc?.addClass("active");
      panelInterior?.addClass('show');
      verticalLine?.addClass('show');
      icono.attr('src', desplegado);
    }
  }

  const showListObjectives = (etapa: Etapa, i: number, sinCategoria = false) => {
    return (
      <IonGrid className={`interiorEtapa panel-interior panel-interior${i} ${etapa.objectives.find(obj => obj.status >= 1 && //@ts-ignore
      obj.category.name === competencia) ? "show" : ""} ${sinCategoria ? 'show' : ''}`}>
        <IonRow >
          {categories?.length == 0 || categories[0].name == 'Sin competencia' || competencia == 'Sin competencia'
            ? null
            : <p className="titulo-seccion mt-3">Objetivos:</p>
          }
          {etapa.objectives.map((objetivo, i) =>
            <Fragment key={i}>

              {objetivo.status == 3 && !showHide
                ? null
                : <>
                  { //@ts-ignore
                    objetivo.category.name === competencia
                      ?
                      <IonRow className="etapa border-gray" key={i}>
                        <IonCol size="12" className="" style={{ 'position': 'relative' }}>
                          <div className='mt-3'>
                            <a onClick={e => showDetail(objetivo.id)} >
                              <p className={`body2 text-titol ${objetivo.status == 2 ? "finalizado" : ""} ${objetivo.status == 0 || objetivo.status == 3 ? 'status-0' : 'status-1'}`} dangerouslySetInnerHTML={{ __html: objetivo.name }}></p>
                              {objetivo.status == 3 ? <p className="my-2 mb-3 noAplica" style={{ 'width': 'fit-content' }}>no aplica</p> : null}
                            </a>
                            {objetivo.status == 1 || objetivo.status == 2
                              ?
                              <IonCol size="6">
                                <IonProgressBar className="mt-2" value={objetivo.value / 100} />
                              </IonCol>
                              : null}
                            <div className="tr-absolute d-grid">
                              {showHide
                                ?
                                <ModificarElemento setTextAlert={setTextAlert} setShowAlert={setShowAlert} setLastAction={setLastAction} tipo="objetivo" id={objetivo.id} setSelectedId={setSelectedId} setActualPage={setActualPage} />
                                : null}
                                {showIcons(objetivo.status, objetivo.important)}
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>
                      : null}
                </>
              }
            </Fragment>
          )}
        </IonRow>
      </IonGrid>
    )
  }

  return (
    <>
      {loading
        ?
        <IonLoading
          isOpen={loading}
          onDidDismiss={() => setLoading(false)}
          message={'Cargando'}
          spinner={'bubbles'}
          duration={10000}
        />
        :
        <IonGrid className="">
          {categories.length < 1
            ? <> {role?.role != 1
              ?
              <IonGrid className="my-5 cardEmptyElement">

                <IonRow>
                  <IonCol size="8" offset='2' className="bg-white p-5">
                    <p className="body1">Empieza a definir los objetivos.</p>
                    <a className="body2" onClick={
                      (e: any) => {
                        e.persist();
                        setShowPopoverObj({ showPopover: true, event: e })
                      }}><p className="body1 col-12 text-naranja mt-5">ESTABLECER OBJETIVOS <IonIcon src={chevronRight} /></p></a>
                    <IonPopover
                    // cssClass='my-custom-class'
                    event={popoverStateObj.event}
                      isOpen={popoverStateObj.showPopover}
                      onDidDismiss={() => setShowPopoverObj({ showPopover: false, event: undefined })}
                    >
                      <a className='popup-select' onClick={e => { setShowPopoverObj({ showPopover: false, event: undefined }); setActualPage("objectiveNew"); }}><p className="body1">Crear objetivos desde cero</p></a>
                      <a className='popup-select'><p className="body1" onClick={e => {setShowPopoverObj({ showPopover: false, event: undefined }); setDefaultObjectives()}}>Usar set de objetivos ComuniteCAA</p></a>
                      {exportList.length > 0 
                        && 
                        <a className='popup-select'><p className="body1" onClick={() => { setShowPopoverObj({ showPopover: false, event: undefined }); setShowImportModal(true) }}>Usar set de objetivos de Mi biblioteca</p></a>
                      }
                    </IonPopover> 
                  </IonCol>
                </IonRow>
              </IonGrid>
              : <IonGrid className="my-5 cardEmptyElement">
                <IonRow>
                  <IonCol size="8" offset='2' className="bg-white p-5">
                    <p className="body1">Aún no hay objetivos creados.</p>
                  </IonCol>
                </IonRow>
              </IonGrid>} </>
            : null
            }

            
          {etapas.map((etapa, i) =>
            <Fragment key={i}>
              {etapa.objectives.length > 0
                ?
                <>
                  {etapa.objectives.find(obj =>
                    //@ts-ignore
                    obj.category.name === competencia)
                    ? <>
                      {competencia == 'Sin competencia'
                        ? <div key={i} className='etapa'>
                          {showListObjectives(etapa, i, true)}
                        </div>
                        : <IonRow key={i} className="pb-2 etapa-titulo mt-3 etapa">
                          <IonCol size="12" className="d-flex interior">
                            <div className='cnt-vertical-line'>
                              <img src={objLogoMin} className='grande 3rem' />
                              <div 
                                className={
                                  `vertical-line2 vertical-line-${i} 
                                  ${etapa.objectives.find(obj => 
                                      obj.status >= 1 && //@ts-ignore
                                      obj.category.name === competencia) ? 
                                        "show"
                                        : (i === (etapas.length-1) ? "a d-none" : "a")
                                        }`
                                }
                              >
                                <span className='span-before' />
                              </div>
                            </div>
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="contenidorEtapa">
                                    <div className='d-flex'>
                                      <h5 className="accordion" onClick={e => openEtapa(i)} id={`accordion-${i}`} >{etapa.titulo}</h5>
                                      <img src={infoIcon} className='handCursor' onClick={e => showInfo(i)} />
                                      <img className={`icon-accordion icon${i}`} src={etapa.objectives.find(obj => 
                                      obj.status >= 1 && //@ts-ignore
                                      obj.category.name === competencia) ? desplegado : plegado } onClick={e => openEtapa(i)} />
                                    </div>
                                    <div className={`panel panel${i}`}>
                                      <p className="titulo-seccion">Qué es:</p>
                                      <p className="etapa-descripcion text-descripcion">{etapa.descripcion}</p>
                                    </div>
                                  </div>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                {showListObjectives(etapa, i)}
                              </IonRow>
                            </IonGrid>
                          </IonCol>
                        </IonRow>}
                    </>

                    : null}

                </>

                : null}
            </Fragment>


          )}
          {showImportModal ? <ImportObjectivePopUp setTextAlert={setTextAlert} setShowAlert={setShowAlert} setShowImportModal={setShowImportModal} setLastAction={setLastAction} /> : null}

        </IonGrid>}

    </>

  );
}

export default Etapas;



import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { Role, User } from "../../interfaces/intUser";
import { Post } from '../../interfaces/intPosts';
import { Category, Etapa, Objective } from '../../interfaces/intObjective';
import { Resource } from '../../interfaces/intRecurso';
import { Activity } from '../../interfaces/intActivity';
import { ExportGroup, UserGroup } from '../../interfaces/intGroups';


// Define a type for the slice state
interface GroupSelected {
  id: number,
  userRole:Role | null,
  name:string,
  media_name: string | null
  posts: Post[],
  objectives: Objective[],
  resources: Resource[],
  activities: Activity[],
  companions: UserGroup[],
  categories: Category[],
  etapas: Etapa[],
  exportedObjectives: ExportGroup[],

}

const initialState: GroupSelected = {
  id: 0,
  userRole: null,
  name: "",
  media_name: null,
  posts: [],
  objectives: [],
  resources: [],
  activities: [],
  companions: [],
  categories: [],
  etapas: [],
  exportedObjectives: []
}

// const initialState: User;

export const groupSelectedSlice = createSlice({
  name: 'groupSelected',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setUserRole: (state, action: PayloadAction<Role>) => {
      state.userRole = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setMediaName: (state, action: PayloadAction<string>) => {
      state.media_name = action.payload;
    },
    setPosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload;
    },
    setObjectives: (state, action: PayloadAction<Objective[]>) => {
      state.objectives = action.payload;
    },
    setResources: (state, action: PayloadAction<Resource[]>) => {
      state.resources = action.payload;
    },
    setActivities: (state, action: PayloadAction<Activity[]>) => {
      state.activities = action.payload;
    },
    setAcompañantes: (state, action: PayloadAction<UserGroup[]>) => {
      state.companions = action.payload;
    },
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setEtapas: (state, action: PayloadAction<Etapa[]>) => {
      state.etapas = action.payload;
    },
    setExportedObjectives: (state, action: PayloadAction<ExportGroup[]>) => {
      state.exportedObjectives = action.payload;
    },
    

  },
});


export const { setId, setName, setUserRole, setMediaName, setPosts, setObjectives, setResources, setActivities,
  setAcompañantes, setCategories, setEtapas, setExportedObjectives } = groupSelectedSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default groupSelectedSlice.reducer
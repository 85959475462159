import '../Header.css';

/** LOGOS **/
import close from '../../img/iconos/close.svg';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IonIcon, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonToggle, IonVirtualScroll, IonCheckbox, IonButton } from '@ionic/react';
import { getData } from '../../api/apiRecursos';
import { Categories, Category, Obj } from '../../interfaces/intObjective';
import Cookies from 'js-cookie';
import { Virtuoso } from 'react-virtuoso'
import { GlobalFunctions } from '../../hooks/GlobalFunctions';


interface Props {
  setShowModal: any;
  setSearchesActivity: any;
  searchesActivity: never[];
  isBiblio?: boolean;
}

const ChooseObjPopUp: React.FC<Props> = ({ setShowModal, setSearchesActivity, searchesActivity, isBiblio = false}) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [competencia, setCompetencia] = useState<string>();
  const [numberObj, setNumberObj] = useState<boolean>();
  const [objGroup, setObjGroup] = useState<Obj[]>([]);
  const [searches, setSearches] = useState(searchesActivity);
  const [categories, setCategories] = useState<Category[]>([]);
  const {getNumberObjectives} = GlobalFunctions();

  useEffect(() => {
    const userData = {
      token: Cookies.get('token'),
      group_id: Cookies.get('group_id'),
      biblio: isBiblio,
    }
    getData.post<Categories>('/categoryList', userData)
      .then(resp => {
        //@ts-ignore
        setCategories(resp.data.categories);
        setCompetencia(resp.data.categories[0].name);
      })
      .catch(err => console.log(err));

    if (!isBiblio){
      getNumberObjectives(Cookies.get('group_id')!, setNumberObj);
    } else {
      setNumberObj(true);
    }
    

    getData.post<Obj>('/getObjectivesByEtapa', userData)
      .then(resp => {
        //@ts-ignore
        setObjGroup(resp.data);

      })
      .catch(err => console.log(err));
  }, [])


  const manageObjectives = (checked: boolean, id: string) => {
    if (checked == true) {
      //@ts-ignore
      setSearches(searches => [...searches, id])
      setTimeout(() => {
      }, 1000);
      
    }
    else {
      searches.forEach((element, index) => {
        if (element == id) searches.splice(index, 1);
      });
    }

    
  }

  const changeObjectives = () =>{
    setSearchesActivity(searches);
    setShowModal(false);
  }


  return (
    <div className="modal modal_objetivos">
      <div className="modal_content">        
        <IonGrid> 
          <IonRow>
            <IonCol size="12">
              <h5>Elige los objetivos relacionados</h5>
            </IonCol>
          </IonRow>
          <IonRow className="row mt-2 row-competencies" >
            <IonCol size="12" className="p-0 lineal">
              <div>
              {categories.map((categoria, index) =>
                <>
                <button key={index}
                  className={`btn-objetivos titulo-seccion 
                            ${competencia === categoria.name
                      ? "selected"
                      : null}`}
                  onClick={() => setCompetencia(categoria.name)}>
                  {categoria.name == "Sin competencia" ? "Otros" : categoria.name}
                </button>

                
                </>
              )}</div>
            </IonCol>

        </IonRow>
          <IonRow>
            <IonCol>
              <hr className='mt-0'/>
              {!numberObj
                ? <p>Este usuario no tiene objetivos establecidos aún. Define primero los objetivos para poder relacionarlos luego con las actividades.</p>
                : <Virtuoso
                    style={{ height: '50vh' }}
                    totalCount={1}
                    itemContent={(index) => {
                      return (
                        <>
                        <div className='scrollable_modal'>
                          {objGroup.map((category) =>
                            <>{category.name == competencia
                              ? <> {category.etapas.map((etapa) =>
                                <>
                                  {etapa.objetivos.length > 0
                                    ? <div className='separator p-3'>
                                      {category.name == 'Sin competencia' ? null : 
                                        <IonRow className="m-3">
                                          <IonCol size="12" className="d-grid align-content-center"><p className='body2'><b>{etapa.titulo}</b></p></IonCol>
                                        </IonRow>
                                      }
                                      <IonRow>
                                        <IonCol size="12">
                                          {etapa.objetivos.map((objetivo, index) =>
                                            <IonItem key={index} className="objetivos-checkbox">
                                              <IonLabel>{objetivo.name}</IonLabel>
                                              <IonCheckbox slot="start" value={objetivo.id.toString()} checked={searches.find(checked => checked == objetivo.id.toString()) ? true : false} onIonChange={e => manageObjectives(e.detail.checked, objetivo.id.toString())} />
                                            </IonItem>
                                          )}

                                        </IonCol>
                                      </IonRow>
                                    </div>
                                    : null
                                  }
                                </>
                              )}

                              </>
                              : null}
                            </>
                          )}

                        </div> 
                        
                        
                        </>
                      );
                    }}
                  />
              }
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="d-flex justify-content-end mt-5">
              {!numberObj
                ? <IonButton className="saveBtn" onClick={e => setShowModal(false)}><p>ACEPTAR</p></IonButton>
                : <>
                    <IonButton className="cancelBtn" onClick={e => setShowModal(false)}><p>CANCELAR</p></IonButton>
                    <IonButton className="saveBtn" onClick={e => changeObjectives()}><p>GUARDAR</p></IonButton>
                  </>}

            </IonCol>
          </IonRow>

        </IonGrid>
      </div>
    </div>
  );

};
export default ChooseObjPopUp;
import { IonGrid, IonRow, IonCol, IonContent, IonPage, useIonViewWillEnter } from '@ionic/react';

/* COMPONENTES */
import Header from '../components/Header';

/* CSS */
import '../theme/global.css';
import '../theme/responsive.css';

/* IMAGENES */
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { getData } from '../api/apiRecursos';
import { Group } from '../interfaces/intGroups';
import { Role } from '../interfaces/intUser';
import { useHistory } from 'react-router';
import RecursoModificar from '../components/Recursos/RecursoModificar';
import RecursoNueva from '../components/Recursos/RecursoNueva';
import RecursoDetalle from '../components/Recursos/RecursoDetalle';
import Recursos from '../components/Recursos/Recursos';
import AvisoPopUp from '../components/PopUpModals/AvisoPopUp';
import RecursosBiblio from '../components/Recursos/RecursosBiblio';
import RecursosComunitecaa from '../components/Recursos/RecursosComunitecaa';

//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'



const RecursosMvl: React.FC= () => {
  //REDUX 
  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  const role = useAppSelector(state=>state.reducer.groupSelected.userRole);
  // const [userAvatar, setUserAvatar] = useState(window.localStorage.getItem('userPhoto')!);

  useIonViewWillEnter(() => {
    setActualPage('recursos');
  });

  let history = useHistory();

  const [group, setGroup] = useState<Group>();
  const [lastAction, setLastAction] = useState("");
  const [showBack, setShowBack] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert ] = useState("");

  //@ts-ignore
  const [actualPage, setActualPage] = useState("recursos");
  const [selectedId, setSelectedId] = useState<number>();

  const userData = {
    'token': Cookies.get('token'),
    group_id: Cookies.get('group_id')
  }


  // useEffect(() => {
  //   getData.post('/getRole', userData)
  //     .then(resp => {
  //       setRole(resp.data);
  //       Cookies.set('role', resp.data.role)
  //     })
  //     .catch(err => console.log(err));
  // }, [])

  useEffect(() => {
    if (!Cookies.get('token')) {
      history.push("/");
    }
    getData.get<Group>('/getGroups/' + Cookies.get('group_id'))
      .then(resp => {
        //@ts-ignore
        setGroup(resp.data);
      })
      .catch(err => console.log(err));

    // setUserAvatar(window.localStorage.getItem('userPhoto')!);

  }, [lastAction])

  const showContent = () => {

    switch (actualPage) {
      case "recursos":
        return <Recursos textAlert={textAlert} setTextAlert={setTextAlert} setShowAlert={setShowAlert} showAlert={showAlert} setShowBack={setShowBack} setSelectedId={setSelectedId} setActualPage={setActualPage}  setLastAction={setLastAction} lastAction={lastAction}/>
      case "resourceDetail":
        return <RecursoDetalle setShowBack={setShowBack} showBack={showBack} selectedId={selectedId!} setActualPage={setActualPage} />
      case "resourceNew":
        return <RecursoNueva setTextAlert={setTextAlert}  setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction}/>
      case "resourceModify":
        return <RecursoModificar setTextAlert={setTextAlert}  setShowAlert={setShowAlert} selectedId={selectedId!} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} />
      case 'resourceBiblio':
        return <RecursosBiblio setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />
      case 'resourceComunitecaa':
        return <RecursosComunitecaa setTextAlert={setTextAlert} setShowAlert={setShowAlert} setActualPage={setActualPage} setLastAction={setLastAction} lastAction={lastAction} type='activos' />

      default:
        break;
    }

  }

  const showPopUp = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }

  return (
    <>
      {Cookies.get('group_id')
      ?
      <>
        <IonPage className="mvl">
        {showAlert
            ? <>{showPopUp()} <AvisoPopUp text={textAlert}/></>
            : null}
          <Header name={group?.name!} avatar={group?.media_name!} avatarProp={userAvatar} setLastAction={setLastAction} lastAction={lastAction}/>

          <IonContent fullscreen>
            <IonGrid>
            
              <IonRow>
                <IonCol className="right">
                  {showContent()}
                </IonCol>
              </IonRow>
            </IonGrid>
                      
          </IonContent>
        </IonPage>
      </>
      : history.push('/')}
    </>

  );
};

export default RecursosMvl;

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface GroupOS {
  group_id: number,
  state: boolean
}
interface Notifications {
  new_notification: boolean,
  reaction_notification: boolean,
  comment_notification: boolean,
  added_notification: boolean
}
// Define a type for the slice state
interface OneSignalState {
  userID: string|null,
  groups: GroupOS[] | null, 
  new_notification: boolean,
  reaction_notification: boolean,
  comment_notification: boolean,
  added_notification: boolean

}

const initialState: OneSignalState = {
  userID: null,
  groups: null,
  new_notification: true,
  reaction_notification: true,
  comment_notification: true,
  added_notification: true,
}


export const oneSignalSlice = createSlice({
  name: 'oneSignal',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<GroupOS[]>) => {
      state.groups = action.payload;
    }, 
    setNotifications: (state, action: PayloadAction<Notifications>) => {
      state.new_notification = action.payload.new_notification;
      state.reaction_notification = action.payload.reaction_notification;
      state.comment_notification = action.payload.comment_notification;
      state.added_notification = action.payload.added_notification;
    },
    setNewNotification: (state, action: PayloadAction<boolean>) => {
      state.new_notification = action.payload;
    }, 
    setReactionNotification: (state, action: PayloadAction<boolean>) => {
      state.reaction_notification = action.payload;
    }, 
    setCommentNotification: (state, action: PayloadAction<boolean>) => {
      state.comment_notification = action.payload;
    }, 
    setAddedNotification: (state, action: PayloadAction<boolean>) => {
      state.added_notification = action.payload;
    }, 
    setUserID: (state, action: PayloadAction<string>) => {
      state.userID = action.payload;
    },


  },
});


export const { setGroups, setNotifications, setUserID,
  setNewNotification, setReactionNotification, setCommentNotification, setAddedNotification } = oneSignalSlice.actions
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.name

export default oneSignalSlice.reducer
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';
import { useHistory } from 'react-router';
import { IonRow, IonCol, IonIcon, IonPopover, IonAvatar, IonSkeletonText } from '@ionic/react';
import { Post } from "../interfaces/intPosts";

import like_color from '../img/iconos/like_color.svg';
import smiley from '../img/iconos/smiley.svg';
import cor from '../img/iconos/cor.svg';
import clap from '../img/iconos/clap.svg';
import arm from '../img/iconos/arm.svg';
import cohet from '../img/iconos/cohet.svg';
import bombeta from '../img/iconos/bombeta.svg';
import { useState } from 'react';
import Cookies from 'js-cookie';
import punts from '../img/iconos/punts.svg';
import { GlobalFunctions } from '../hooks/GlobalFunctions';

import {useAppSelector} from '../app/hooks'
import { GlobalVariables } from '../hooks/GlobalVariables';


interface ContainerProps {
  comentario: Post,
  setLastAction: any
}

const mostrarReaccion = (icon: string) => {
  switch (icon) {
    case "like":
      return <IonIcon className="muyGrande mx-3" src={like_color} />
    case "smiley":
      return <IonIcon className="muyGrande mx-3" src={smiley} />
    case "cor":
      return <IonIcon className="muyGrande mx-3" src={cor} />
    case "clap":
      return <IonIcon className="muyGrande mx-3" src={clap} />
    case "arm":
      return <IonIcon className="muyGrande mx-3" src={arm} />
    case "cohet":
      return <IonIcon className="muyGrande mx-3" src={cohet} />
    case "bombeta":
      return <IonIcon className="muyGrande mx-3" src={bombeta} />

    default:
      break;
  }
}

const showDate = (date: Date) => {
  let now = Date.now();
  let postedTime = new Date(date);
  let elapsedTime = now - postedTime.getTime();
  let minutes = Math.floor(elapsedTime / 60000) - 59;
  const month = postedTime.toLocaleString('default', { month: 'long' });

  if (minutes > 60) {
    let hours = Math.floor(minutes / 60);
    if (hours < 24) {
      if (hours == 1) return `${hours} hora`; else return `${hours} horas`;
    } else {
      if (hours >= 24 && hours < 48) return "1 dia";
      else if (hours >= 48 && hours < 72) return "2 dias";
      else if (hours >= 72 && hours < 94) return "3 dias";
      else if (hours >= 94 && hours < 118) return "4 dias";
      else if (hours >= 118 && hours < 142) return "5 dias";
      else if (hours >= 142 && hours < 166) return "6 dias";
      else if (hours >= 166 && hours < 190) return "7 dias";
      else return `${postedTime.getDate()} de ${month} de ${postedTime.getFullYear()} - ${postedTime.getHours()}:${String(postedTime.getMinutes()).padStart(2,'0')}`;

    }
  } else {
    return `${minutes} min`;
  }

}

const Comentario: React.FC<ContainerProps> = ({ comentario,setLastAction }) => {
  const role = useAppSelector(state=>state.reducer.groupSelected.userRole);
  const [popoverStateNuevo, setShowPopoverNuevo] = useState({ showPopover: false, event: undefined });
  const {urlify, removePost} = GlobalFunctions();
  const {urlMedia} = GlobalVariables();

  const [editPost, setEditPost] = useState(0);


  const erasePost = (id: number) => {
    removePost(id, setLastAction, setShowPopoverNuevo);
    setShowPopoverNuevo({ showPopover: false, event: undefined });
  }


  return (
    <IonRow className="row comentario  px-3" style={{"borderLeft":"1px solid grey"}}>
      <IonCol size='12' className="lineal-flex ion-align-items-center ion-justify-items-center">
        <div style={{'position':'relative', 'display':'inline-flex', 'width':'100%'}}>
          <IonAvatar className="avatar-small d-flex">
            <img id="" className={`photo m-auto avatar-small imgComentario ${comentario.user_media.media_name != 'undefined'  ? 'border-gris' : ""}`}
              src={comentario.user_media.media_name != 'undefined' ? urlMedia + comentario.user_media.media_name : noPhotoAvatar}></img>
          </IonAvatar>
          <div style={{'overflowWrap':'anywhere'}}>
            <p><b>{comentario.name}</b> <span className="body2 text-gris">{showDate(comentario.created_at)}</span></p>


            {comentario.type == 'react' 
              ? mostrarReaccion(comentario.content) 
              : 
              // <p className="body2">{comentario.content}</p>
              <p className={`body2`} dangerouslySetInnerHTML={{ __html: urlify(comentario.content) }}></p>
            }
          </div>
          <div className='ml-auto'>
            {role?.role == 3 || role?.user_id == comentario.created_by ?
            <>
              <IonPopover
                  // cssClass='my-custom-class'
                  event={popoverStateNuevo.event}
                isOpen={popoverStateNuevo.showPopover}
                onDidDismiss={() => setShowPopoverNuevo({ showPopover: false, event: undefined })}
              >
                <a className='popup-select' onClick={() => erasePost(editPost)}><p className="body1">Eliminar este comentario</p></a>
              </IonPopover>
              <IonIcon className="medio handCursor right-0" src={punts} onClick={
                (e: any) => {
                  e.persist();
                  setEditPost(comentario.id)
                  setShowPopoverNuevo({ showPopover: true, event: e })
                }} >
              </IonIcon>
            </>
            : null}
          </div>
        </div>

      </IonCol>
    </IonRow>
  );

};
export default Comentario;


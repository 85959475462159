import { IonRow, IonGrid, IonCol, IonButtons, IonButton, IonIcon, IonPopover, IonLoading, isPlatform } from "@ionic/react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { getData } from "../../api/apiRecursos";
import addCircle from '../../img/iconos/add-circle.svg';
import noActivBiblio from '../../img/iconos/no_activ_biblio.svg';

import copyIcon from '../../img/iconos/copy-icon.svg';
import { Activities, Activity } from "../../interfaces/intActivity";
import ModificarElemento from "../ModificarElemento";
import CopyActivity from "../PopUpModals/CopyActivity";
import TituloActividades from "../TituloActividad";
import { GlobalFunctions } from "../../hooks/GlobalFunctions";
import ShowActivityDetail from "../PopUpModals/ShowActivityDetail";

import {useAppSelector} from "../../app/hooks";


interface ContainerProps {
  setActualPage: any,
  setSelectedId: any,
  setShowAlertMvl: any,
  setTextAlertMvl: any,
}

const BiblActividad: React.FC<ContainerProps> = ({ setShowAlertMvl, setTextAlertMvl, setActualPage, setSelectedId }) => {
  const {selectedId, showDetail, setShowDetail, activity} = GlobalFunctions();
  const history = useHistory();
  const [activityId, setActivityId] = useState(Number);
  const [copyModal, setCopyModal] = useState(false);
  const [lastAction, setLastAction] = useState("");
  const [loading, setLoading] = useState(true);

  // const [activities, setActivities] = useState<Activity[]>([]);
  const activities = useAppSelector(state => state.reducer.itemsBiblio.activities);
  const {getAllActivities} = GlobalFunctions();

  const userData = {
    token: Cookies.get('token'),
    biblioteca: true
  }

  const [popoverStateActiv, setShowPopoverActiv] = useState({ showPopover: false, event: undefined });
  const [popoverStateActivEmpty, setShowPopoverActivEmpty] = useState({ showPopover: false, event: undefined });



  useEffect(() => {
    getAllActivities(true, setLoading);
  }, [lastAction])

  const showModal = (id: number) => {
    setActivityId(id);
    setCopyModal(true);
  }

  return (
    <>
      <div id="bannerEdicion" className="d-flex justify-content-center"><p className="okButton">Modo Edición</p></div>
      {showDetail
          ? <ShowActivityDetail setShowDetail={setShowDetail} activity={activity!}/>
          : null}

      <IonGrid className="container biblioteca activities-main">
        <IonRow className="rowTitulo">
          <h3 className="mvl">Mi biblioteca</h3>
          <TituloActividades titulo="Mis actividades"/>

          {loading 
            ? <IonLoading
              isOpen={loading}
              onDidDismiss={() => setLoading(false)}
              message={'Cargando'}
              spinner={'bubbles'}
              showBackdrop={true}
              duration={5000}
            />
            : <>
              {activities.length < 1
                ? null
                : <IonCol size="4" className="d-flex justify-content-end">
                  <IonButtons className="btn-minusculas bigger-hover">
                    <IonButton className="addObjetivo">
                      <IonCol onClick={() => setActualPage("biblNuevaActividad")}>
                        <IonIcon className="grande" src={addCircle}></IonIcon>
                        <p className="body2" >Crear nueva<br/>actividad</p>
                      </IonCol>
                    </IonButton>
                  </IonButtons>
                </IonCol>
              }
            </>}
        </IonRow>


          {loading
            ? null
            : <>
              {activities.length < 1
                ?<IonRow>
                  <IonCol size-md="12" size-lg="8" offset-md="2" className="text-center my-5">
                    <p className="body1">Todavía no tienes actividades en Mi biblioteca.</p>
                    <p><br/>Usa las actividades de Mi biblioteca para compartirlas de manera sencilla con tus usuarios de ComuniteCAA.
                    <br/>Para añadir actividades a Mi biblioteca, usa la opción "Crear nueva actividad" o entra dentro de uno de tus usuarios de ComuniteCAA, ve a Actividades y selecciona "Guardar actividad en Mi biblioteca" del menú de actividad. 
                    </p>
                    <img src={noActivBiblio} className="my-4"></img> <br />
                    <IonButton className="saveBtn mt-5" onClick={() => setActualPage("biblNuevaActividad")}><p>Crear nueva actividad</p></IonButton>
                  </IonCol>
                </IonRow>
                : <IonRow>
                {activities.map((actividad) =>
                  <IonCol size-xs="6" size-md="4" key={actividad.id} className={actividad.id + " pt-0"}>
                    <IonRow>
                      <IonCol className="pt-0" size="12">
                        <div className="actividad boxActividad pt-4 lineal-flex" style={{'width':'100%'}}>
                          <a className="activity-text" onClick={e => selectedId(actividad.id, 'activity')}>
                            <p className="body2 activity-clipped-text-medium strong" style={{'fontSize':'1.75rem', 'lineHeight':'2.3rem','lineBreak': 'anywhere'}}>{actividad.name == "" ? actividad.description : actividad.name}</p>
                            {!isPlatform('ios') ?
                              <p className="body2 activity-clipped-text-small" style={{'lineBreak': 'anywhere'}} dangerouslySetInnerHTML={{ __html: actividad.description }}></p>
                              :null
                            }
                          </a>
                          <div className="d-grid icons-vertical ml-auto">
                            <ModificarElemento setShowAlert={setShowAlertMvl} setTextAlert={setTextAlertMvl} tipo="actividad" setSelectedId={setSelectedId} id={actividad.id} setActualPage={setActualPage} isBiblio={true} setLastAction={setLastAction} />
                            <IonIcon className="medio handCursor bigger-hover" src={copyIcon} onClick={() => showModal(actividad.id)} />
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonCol>
                )} </IonRow>
              }
            </>}
        {copyModal ? <CopyActivity setShowAlert={setShowAlertMvl} setTextAlert={setTextAlertMvl} activityId={activityId} setCopyModal={setCopyModal} /> : null}
      </IonGrid >
    </>
  );
};
export default BiblActividad;







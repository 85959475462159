import { IonButton, IonButtons, IonCol, IonIcon, IonRow } from "@ionic/react";
import { useState } from "react";

/* ICONOS */
import logoRecurso from '../img/iconos/recursos-logo-neg.svg';
import back from '../img/iconos/back-arrow.svg'
import creu from '../img/iconos/icona_creu.svg'



interface Props {
  titulo: string;
  setActualPage?: any;
  setShowBack?: any;
  showBack?: boolean;
  isBiblio?:boolean;
}

const TituloRecurso: React.FC<Props> = ({titulo,setActualPage, setShowBack, showBack = false, isBiblio = true}) => {
  
  

  return(
    <>

      <IonCol size="8" className="lineal-flex align-items-center ion-align-self-center col-ajustada">
      {showBack 
        ? <img src={creu} className="handCursor" onClick={e => { setActualPage("recursos"); setShowBack(false) }}></img> 
        : <> { isBiblio ? null : <img src={creu} className="handCursor" onClick={e => { setActualPage("home"); setShowBack(false) }}/>} </>}
        <div className="borde-recursos w-100 align-items-center ion-align-self-center lineal-flex">
          <IonIcon src={logoRecurso} className="grande m-2"/>
        <p className="ml-4 text-blau">{titulo}</p>
        </div>
        
      </IonCol>
    </>
    
  );
}

export default TituloRecurso;
import { IonAvatar, IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonLoading, IonRow, IonSkeletonText, IonTextarea } from "@ionic/react";
import like from "../img/iconos/like.svg";
import fotoVideo from "../img/iconos/foto-video-icon.svg";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { getData } from "../api/apiRecursos";
import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';
import sendEnabled from '../img/iconos/sendEnabled.svg';
import sendDisabled from '../img/iconos/sendDisabled.svg';

import like_color from '../img/iconos/like_color.svg';
import smiley from '../img/iconos/smiley.svg';
import cor from '../img/iconos/cor.svg';
import clap from '../img/iconos/clap.svg';
import arm from '../img/iconos/arm.svg';
import cohet from '../img/iconos/cohet.svg';
import bombeta from '../img/iconos/bombeta.svg';
import { GlobalFunctions } from "../hooks/GlobalFunctions";
import $ from 'jquery';
//REDUX
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { GlobalVariables } from "../hooks/GlobalVariables";

interface ContainerProps {
  parentId: number,
  groupId?: number,
  idNuevoComentario: number,
  setLastAction: any
}

const NuevoComentario: React.FC<ContainerProps> = ({ idNuevoComentario, parentId, groupId, setLastAction }) => {
  //REDUX 
  const avatarProp = useAppSelector(state=>state.reducer.user.media_name == null ? "" : state.reducer.user.media_name);
  // const avatarProp = window.localStorage.getItem('userPhoto');

  const { showThirdButton, getPostsGroupSelected, sendPost } = GlobalFunctions();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  // const aux = false;

  
  

  const sendComment = () => {
    let id;
    if (groupId){
      id = groupId;
    } else{
      id = Cookies.get('group_id');
    }
    const body = {
      token: Cookies.get('token'),
      group_id: id,
      title: "",
      content: content,
      type: "comment",
      parent_id: parentId
    }
    $(".comment-input textarea").val('');
    setContent("");
   
    setLoading(true);
    sendPost('comment', body, setLastAction, setLoading);
    $(".comment-input textarea").val('');
  }

  const sendReaccion = (icono:string) => {
    closeThirdButton();
    let id;
    setLoading(true);
    
    if (groupId){
      id = groupId;
    } else{
      id = Cookies.get('group_id');
    }
    const body = {
      token: Cookies.get('token'),
      group_id: id,
      title: "",
      content: icono,
      type: "react",
      parent_id: parentId
    }

    sendPost('comment', body, setLastAction, setLoading);
  }

  // const pressButton = (value: any) => {
  //   if (value == "Enter") {
  //     sendComment();
  //   }
  // }

  const closeThirdButton = () => {
    $('.btn-third').removeClass('show');
  }
   const {urlMedia} = GlobalVariables();

  return (
    <IonRow id="nuevoComentario" className="mx-3 nuevaPubliTop ">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Cargando'}
        spinner={'bubbles'}
        duration={10000}
      />
      <IonCol size="12" className="lineal-flex ion-align-items-center ion-justify-items-center">
        <IonAvatar className="d-flex" >
          <img className={`photo  avatar-small  m-auto ${avatarProp != "" ? 'border-gris': ""}`} 
           src={avatarProp != "" ? urlMedia +avatarProp: noPhotoAvatar}></img>
        </IonAvatar>
        <div className="commentItem">
          <IonTextarea onClick={e => closeThirdButton()} style={{"paddingRight":"15px !important"}} value={content}
            required={true} className="comment-input body2 mt-0" 
              placeholder="Escribe un comentario..." 
              onIonChange={e => setContent(e.detail.value!)}
            ></IonTextarea>
            

          {/* <IonInput onClick={e => closeThirdButton()} style={{"paddingRight":"15px !important"}} value={content} 
          className=" body2" onKeyPress={e => pressButton(e.key)} onIonChange={e => setContent(e.detail.value!)} 
          placeholder="Escribe un comentario..." /> */}
          <IonIcon className={`grande comment-icon ${content != "" ? "handCursor": ""}`} src={content != "" ? sendEnabled: sendDisabled} onClick={e => content != "" ? (sendComment(), closeThirdButton()) : ""}/>
        </div>
        <div style={{'position':'relative'}}>
          <IonIcon className="grande mx-2 handCursor bigger-hover" src={like} onClick={e => {showThirdButton('reaccion'+idNuevoComentario)}}></IonIcon>
          <button className={`btn-enabled-third btn-reacciones btn-third reaccion${idNuevoComentario}`}>
            <IonIcon className="muyGrande mx-1" src={like_color} onClick={e => sendReaccion('like')}/>
            <IonIcon className="muyGrande mx-1" src={smiley}  onClick={e => sendReaccion('smiley')}/>
            <IonIcon className="muyGrande mx-1" src={cor}  onClick={e => sendReaccion('cor')}/>
            <IonIcon className="muyGrande mx-1" src={clap}  onClick={e => sendReaccion('clap')}/>
            <IonIcon className="muyGrande mx-1" src={arm}  onClick={e => sendReaccion('arm')}/>
            <IonIcon className="muyGrande mx-1" src={cohet}  onClick={e => sendReaccion('cohet')}/>
            <IonIcon className="muyGrande mx-1" src={bombeta}  onClick={e => sendReaccion('bombeta')}/>
          </button>
        </div>
      </IonCol>
    </IonRow>


  );
}

export default NuevoComentario;



import { IonCol, IonContent, IonInput, IonPage, IonRow } from '@ionic/react';
import React from 'react'
import { useState } from 'react';
import { read, utils } from 'xlsx';
import logoSrMunera from '../img/iconos/logotip-sr-munera.png';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

/* COMPONENTES */


/* CSS */
import '../theme/custom.css';

import { GlobalFunctions } from '../hooks/GlobalFunctions';
import RecursosAdmin from '../components/admin/RecursosAdmin';
import ActividadesAdmin from '../components/admin/ActividadesAdmin';
import { useHistory } from 'react-router';
import { getData } from '../api/apiRecursos';
import Cookies from 'js-cookie';




const AdminHome: React.FC = () => {
  const [actualPage, setActualPage] = useState("")
  const { createObjectivesTemplate } = GlobalFunctions();
  const [authenticate, setAuthenticate] = useState(false);
  var jsonData: any;
  const [username, setUsername] = useState<string>("");
  const [contraseña, setContraseña] = useState<string>("");
  const axios = require('axios');
  let history = useHistory();

  const tryLogin = () => {
    const loginData = {
      email: username,
      password: contraseña,
    };
    

    if (username != "" && contraseña != "") {
      getData.post("/loginAdmin", loginData)
      .then((res: any) => {
        console.log("res", res);
        if (res.status === 200) {
          setAuthenticate(true);
        } else {
          // alert("El mail/contraseña no coincide o el usuario no existe. Vuelve a intentarlo por favor.");
        }
      })
      .catch((error: any) => {
      });
    }

    
  }
  const convertToJson = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target!.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        jsonData = json;
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const createObjectives = () => {
    createObjectivesTemplate(jsonData)
  }

  const showContent = () => {
    switch (actualPage) {
      case 'recursos':
        return <RecursosAdmin/>
      case 'actividades':
        return <ActividadesAdmin/>

      default:
        return (<h2>trabajando en ello</h2>);
        break;
    }
  }



  return (
    <>
      <IonPage>
        {authenticate ?
        <IonContent fullscreen className="fullSize bg-gris-clar">
        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
            <a className="navbar-brand brand-logo" href="/admin">
              <img src={logoSrMunera} alt="logo" />
            </a>
            <a className="navbar-brand brand-logo-mini" href="/admin">
              <img src={logoSrMunera} alt="logo" />
            </a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center">
            <h3>ComuniteCAA</h3>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </nav>
        <div className="container-fluid page-body-wrapper">
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <a className="nav-link" aria-controls="ui-basic">
                        <span className="menu-title">Recursos</span>
                      </a>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='sub-menu'>
                    <ul className='nav'>
                      <li><a onClick={ () => setActualPage("recursos")}>Subir recursos</a></li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <a className="nav-link" aria-controls="ui-basic">
                        <span className="menu-title">Actividades</span>
                      </a>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='sub-menu'>
                  <ul className='nav'>
                      <li><a onClick={ () => setActualPage("actividades")}>Subir actividades</a></li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <a className="nav-link" aria-controls="ui-basic">
                        <span className="menu-title">Objetivos</span>
                      </a>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className='sub-menu'>
                  <ul className='nav'>
                      <li><a onClick={ () => setActualPage("objetivos")}>Subir objetivos</a></li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </ul>
          </nav>
          <div className=" main-panel">
            <div className="content-wrapper">

              {showContent()}
            </div>
          </div>
        </div>
      </IonContent>
        
      : <IonContent fullscreen className="fullSize bg-gris-clar">
      <IonRow style={{ 'height': '100vh' }}>
        <IonCol size='4' class='mx-auto align-self-center'>
          <div className='cnt-admin-login'>
            <div>
              <div className="form-group">
                <label className="label">Nombre de usuario</label>
                <div className="input-group">
                  <IonInput type="text" className="form-control" name="username" placeholder="username" onIonChange={e => {setUsername(e.detail.value?.toString()!);}}/>
                </div>
              </div>
              <div className="form-group">
                <label className="label">Password</label>
                <div className="input-group">
                  <IonInput type="password" name="password" className="form-control" placeholder="*********" onIonChange={e => {setContraseña(e.detail.value?.toString()!);}}/>
                </div>
              </div>
              <div className="form-group">
                <button className="btn btn-primary submit-btn btn-block w-100" onClick={e => tryLogin()}>Entrar</button>
              </div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonContent>
    }
        
      </IonPage>
    </>
  );

};



export default AdminHome;

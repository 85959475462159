import { IonGrid, IonRow, IonCol, IonImg, IonInfiniteScrollContent, IonAvatar } from "@ionic/react";
import Cookies from "js-cookie";
import $ from 'jquery';

import { Fragment, useEffect, useState } from "react";

import noPhotoAvatar from '../img/iconos/noPhoto_group.svg';

import { useHistory } from "react-router";
import { GlobalFunctions } from "../hooks/GlobalFunctions";

import logoEmptyTimeline from "../img/iconos/logo_empty_timeline.svg"
import ShowResourceDetail from "./PopUpModals/ShowResourceDetail";
import ShowActivityDetail from "./PopUpModals/ShowActivityDetail";
import ShowObjectiveDetail from "./PopUpModals/ShowObjectiveDetail";
import {useAppSelector} from "../app/hooks";
import PostComponent from "./PostComponent";
import { GlobalVariables } from "../hooks/GlobalVariables";



const UltimaActividad: React.FC = ({  }) => {
  const activityGroups = useAppSelector(state=>state.reducer.itemsBiblio.postsAllUsers);
  const { showDetail, activity, resource, objective, getUserData } = GlobalFunctions();
  let history = useHistory();
  const {urlMedia} = GlobalVariables();

  const [showDetailRes, setShowDetailRes] = useState(false);
  const [showDetailObj, setShowDetailObj] = useState(false);
  const [showDetailAct, setShowDetailAct] = useState(false);
  const [postLimit, setPostLimit] = useState(10);

  


  const loadPage = (id: number) => {
    // Cookies.set('group_id', id.toString(), { sameSite: 'strict' });
    // history.push("/home");
  };
  
  useEffect(() => {
    $('.bg-gris-clar').addClass('bg-fosc');
    getUserData();
  }, [])

  const loadData = () => {
    if (postLimit < activityGroups.length) {
      $('.infinite-loading').addClass('d-block');
      $('.infinite-loading').removeClass('d-none');
    }
    
    setTimeout(() => {
      setPostLimit(postLimit+10);
      if (postLimit >= activityGroups.length) {
      }
      $('.infinite-loading').addClass('d-none');
      $('.infinite-loading').removeClass('d-block');
    }, 500);
  } 

  const handleScroll = (event: any) => {
    const scrollHeight = event.currentTarget.scrollHeight;
    const scrollTop = event.currentTarget.scrollTop;
    const clientHeight = event.currentTarget.clientHeight;

    if (scrollHeight - (scrollTop + 200) <= clientHeight) {
      loadData();
    }
  };
  
  
  return (
    <>
    <div>
      <IonGrid className="container-right pb-0 scrollable-content" onScroll={handleScroll} >
        <div className="container actividadGrupos m-auto ">
            {showDetailRes && showDetail
              ? <ShowResourceDetail setShowDetail={setShowDetailRes} resource={resource!}/>
              : null}
            {showDetailAct && showDetail
              ? <ShowActivityDetail setShowDetail={setShowDetailAct} activity={activity!}/>
              : null}
            {showDetailObj && showDetail
              ? <ShowObjectiveDetail setShowDetail={setShowDetailObj} objective={objective!}/>
              : null}
            {activityGroups.length < 1 
              ? <IonRow className="my-3">
                  <IonCol size-md="4" size-xs="0" />
                  <IonCol size-md="4" className="text-center">
                    <p>Todavía no hay actividad en tus usuarios.</p>
                    <br /><IonImg className="empty-img" src={logoEmptyTimeline} />
                  </IonCol>
                </IonRow>
              : <>{activityGroups.map((post, index) =>
                    <Fragment key={index}>
                      
                    {index < postLimit && 
                      <> {index != 0 && activityGroups[index].group_id == activityGroups[index-1].group_id
                            ? null
                            : <IonRow className=" mt-5" onClick={()=> loadPage(post.group_id)}>
                                <IonCol className="d-flex align-content-center">
                                  <IonAvatar className='d-flex'>
                                    
                                    <img className={`photo circleImgGrande avatar-principal m-0 m-auto border-gris md hydrated`} src={post.group_media_name != undefined ? urlMedia + post.group_media_name : noPhotoAvatar}></img>
                                  </IonAvatar>
                                
                                  <h3 className="mx-3">{post.group_name}</h3>
                                </IonCol>
                              </IonRow>
                          }
                          <PostComponent publicacion={post} index={index} /> 
                        </> 
                      }
                    </Fragment>
                  )}
                </>}
                <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText="Cargando..."
                  ></IonInfiniteScrollContent>
        </div>
      </IonGrid>
    </div>

    
    </>
  );

};
export default UltimaActividad;

